import PropTypes from "prop-types";
import React, { Fragment } from "react";

import { Link } from "react-router-dom";
import clsx from "clsx";
//Components
import ProfileDetails from "./ProfileDetails";
import GeneralLinkButton from "../../components/controls/buttons/GeneralLinkButton";
import InboxLinkButton from "../controls/buttons/inbox/InboxLinkButton";
import ProfileSkeleton from "../../util/ProfileSkeleton";
import UserBadgeCard from "../badges/UserBadgeCard";
import GetVirtualAssistantButton from "../controls/buttons/cta/GetVirtualAssistantButton";
//MUI
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { uploadImage } from "../../redux/actions/userActions";
//Styles

import makeStyles from "@material-ui/core/styles/makeStyles";
import LoginSignupActions from "./LoginSignupActions";
const styles = makeStyles((theme) => ({
	...theme.customStyles,

	privateProfilePaper: {
		padding: 10,
		backgroundColor: theme.palette.primary.main,
	},
	privateProfileFields: {
		color: theme.palette.accent.main,
	},
	viewingPublicProfileText: {
		textAlign: "center",
		padding: 5,
	},
	profileDetails: {
		textAlgin: "right",
	},
	profileFields: {
		padding: 10,
	},
	profileFieldText: {
		color: theme.palette.accent.main,
	},
	toolTipButton: { color: "white" },
	textBackground: {
		backgroundColor: "white",
		width: "100%",
	},
	profileFieldSeparator: {
		width: "100%",
		borderWidth: 0.1,
		border: "dotted",
		borderBottom: "1px solid rgba(0,0,0,0.2)",
		marginBottom: 10,
	},
	imageWrapper: {
		marginBottom: 20,
	},
	icon: {
		verticalAlign: "middle",
		marginRight: 10,
	},
	userIcon: {
		borderRadius: "50%",
		width: 25,
	},
	infoIcon: {
		margin: "0 auto",
		textAlign: "center",
		display: "block",
	},
	linkGroup: {
		paddingTop: 10,
		fontSize: "0.8rem",
	},
	link: {
		color: "#fff",
	},
	getVirtualAssistant: {
		backgroundColor: theme.palette.information.dark,
	},
}));

const AuthProfile = ({ ctaText, ctaTo }) => {
	const classes = styles();
	const loading = useSelector((state) => state.user.loading);
	const authenticated = useSelector((state) => state.user.authenticated);
	const authUser = useSelector((state) => state.user.credentials);

	let authorized = false;
	if (authUser.roles) {
		authorized = authUser.roles.admin;
	}

	const dispatch = useDispatch();

	const handleImageChange = (event) => {
		const image = event.target.files[0];
		const formData = new FormData();
		formData.append("image", image, image.name);
		dispatch(uploadImage(formData));
	};

	const authProfileMarkup =
		authenticated && !loading ? (
			<Paper className={classes.privateProfilePaper}>
				<div className={clsx(classes.profile)}>
					<div className={("image-wrapper", classes.imageWrapper)}>
						{authUser && <UserBadgeCard user={authUser} />}
					</div>
					<input
						type="file"
						id="imageInput"
						onChange={(e) => handleImageChange(e)}
						hidden="hidden"
					/>
					<ProfileDetails user={authUser} />
					<hr className={classes.profileFieldSeparator} />
					<GeneralLinkButton
						id="dynamic-cta-button"
						text={ctaText}
						className={clsx(classes.sidebarButton)}
						variant="outlined"
						linkTo={ctaTo}
					/>
					<GetVirtualAssistantButton
						className={clsx(classes.sidebarButton)}
						fullWidth
					/>
					{authenticated && (
						<>
							<InboxLinkButton variant="outlined" />
							<GeneralLinkButton
								className={clsx(classes.sidebarButton)}
								id="account-page-button"
								text="Settings"
								variant="outlined"
								linkTo={`/settings/${authUser.handle}`}
							/>
						</>
					)}

					{authorized && (
						<Button
							fullWidth
							className={clsx(classes.sidebarButton)}
							variant="outlined"
							color={"secondary"}
							component={Link}
							to={"/system"}>
							Admin Console
						</Button>
					)}

					<hr className={classes.profileFieldSeparator} />
					<Typography className={classes.linkGroup} align="center">
						<Link to="/terms" className={classes.link}>
							Terms and Conditions
						</Link>{" "}
						and{" "}
						<Link to="/privacy" className={classes.link}>
							Privacy Policy
						</Link>
					</Typography>
				</div>
			</Paper>
		) : loading ? (
			<ProfileSkeleton />
		) : !authenticated ? (
			<LoginSignupActions virtualAssistantButton />
		) : null;

	return <Fragment>{authProfileMarkup}</Fragment>;
};

AuthProfile.propTypes = {
	ctaText: PropTypes.string.isRequired,
	ctaTo: PropTypes.string.isRequired,
};

export default AuthProfile;
