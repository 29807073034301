import React from "react";
import clsx from "clsx";
//MUI
import Button from "@material-ui/core/Button";
//Redux
import { useDispatch } from "react-redux";
import {
	getMessagesByConversationId,
	getConversations,
} from "../../../../redux/actions/inboxActions";

//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	root: {
		color: "#fff",
	},
}));

const MessagesRefreshButton = ({
	size,
	menu,
	variant,
	className,
	conversationId,
}) => {
	const dispatch = useDispatch();
	const classes = styles();

	const handleMessagesRefreshClick = () => {
		dispatch(getConversations());
		dispatch(getMessagesByConversationId(conversationId));
	};
	return (
		<Button
			underline="none"
			size={clsx(menu ? "small" : size)}
			fullWidth
			className={clsx(classes.root, className)}
			variant={variant}
			color={"secondary"}
			onClick={() => {
				handleMessagesRefreshClick();
			}}>
			Refresh Notes
		</Button>
	);
};

export default MessagesRefreshButton;
