import {
	SET_SUBSCRIPTION,
	SET_BILLING,
	SET_LATEST_INVOICE,
	SET_PAYMENT_METHOD,
	SET_PAYMENT_RESPONSE,
	SET_SETUP_INTENT,
	LOADING_SUBSCRIPTION,
	STOP_LOADING_SUBSCRIPTION,
	CLEAR_SUBSCRIPTION,
	CLEAR_RESPONSE
} from "../types"

const initialState = {
	data: {},
	latestInvoice: {},
	paymentMethod: {},
	virtualAssistant: {
		package: null
	},
	response: {},
	setupIntent: {},
	loading: false
}

export default function(state = initialState, action) {
	switch (action.type) {
		case CLEAR_RESPONSE:
			return {
				...state,
				response: {},
			};

		case SET_SUBSCRIPTION:
			return {
				...state,
				data: action.payload.subscription,
					latestInvoice: action.payload.latestInvoice,
					loading: false
			};
		case SET_PAYMENT_METHOD:
			return {
				...state,
				paymentMethod: action.payload
			};
		case SET_BILLING:
			return {
				...state,
				data: {
					...state.data,
					billing: action.payload
				}
			}
			case CLEAR_SUBSCRIPTION:
				return {
					...state,
					data: {},
						latestInvoice: {}
				};
			case SET_LATEST_INVOICE:
				return {
					...state,
					latestInvoice: action.payload,
						loading: false
				};
			case LOADING_SUBSCRIPTION:
				return {
					...state,
					loading: true
				};
			case STOP_LOADING_SUBSCRIPTION:
				return {
					...state,
					loading: false
				};
			case SET_PAYMENT_RESPONSE:
				return {
					...state,
					response: action.payload,
						loading: false
				};
			case SET_SETUP_INTENT:
				return {
					...state,
					setupIntent: action.payload
				};
			default:
				return state
	}
}
