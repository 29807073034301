import React from "react";
//Component
import UserBadge from "../../badges/UserBadge";
//MUI Components
import Grid from "@material-ui/core/Grid";

const SystemUserBadge = ({ user, noHandle, badgeFullwidth }) => {
	return (
		<Grid item xs={!badgeFullwidth ? 4 : 12}>
			<UserBadge hideVerified noHandle={noHandle} user={user} />
		</Grid>
	);
};

export default SystemUserBadge;
