import React, { useEffect } from "react";
//Components
import SystemNavBarMobile from "../components/navigation/mobile/SystemNavBarMobile";
import MainLayout from "../components/layouts/MainLayout";
import SystemSection from "../components/sections/SystemSection";
import AuthProfile from "../components/profile/AuthProfile";
import NavBarSystem from "../components/navigation/NavBarSystem";
import { useParams } from "react-router-dom";
import { compare } from "../util/UtilityFunctions";

//MUI
import Hidden from "@material-ui/core/Hidden";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { setSystemFilteredUsers } from "../redux/actions/systemActions";

const System = () => {
	const systemUsers = useSelector((state) => state.system.users);
	const filteredSystemUsers = useSelector(
		(state) => state.system.filteredUsers
	);
	const dispatch = useDispatch();

	let { systemUserId, showAllPending } = useParams();
	useEffect(() => {
		if (systemUserId && showAllPending !== "1") {
			dispatch(setSystemFilteredUsers(systemUsers, { systemUserId }));
		} else if (showAllPending === "1") {
			dispatch(
				setSystemFilteredUsers(systemUsers, { showAllPending: true })
			);
		}
	}, [systemUsers, systemUserId]);

	return (
		<MainLayout
			sidebar={<AuthProfile ctaTo={`/`} ctaText="Return to DealBoard" />}>
			<NavBarSystem color="secondary" />
			{(filteredSystemUsers || systemUsers) && (
				<SystemSection
					systemUsers={
						filteredSystemUsers
							? filteredSystemUsers
							: systemUsers.sort(compare)
					}
				/>
			)}
			<Hidden mdUp>
				<SystemNavBarMobile filter />
			</Hidden>
		</MainLayout>
	);
};

export default System;
