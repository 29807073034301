import React from "react";

const FormHiddenFileInput = ({ onChange }) => {
	return (
		<input
			type="file"
			id="dealImageInput"
			onChange={onChange}
			hidden="hidden"
		/>
	);
};

export default FormHiddenFileInput;
