import React from "react";
//Components
import LinkedInDealShareButton from "./buttons/social/LinkedInDealShareButton";
import FacebookDealShareButton from "./buttons/social/FacebookDealShareButton";
import TwitterDealShareButton from "./buttons/social/TwitterDealShareButton";
import EmailDealShareButton from "./buttons/social/EmailDealShareButton";
import FacebookPostButton from "../controls/buttons/facebook/FacebookPostButton";
//MUI Components
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
//Redux
import { useSelector } from "react-redux";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	shareArea: {
		padding: theme.spacing(2),
	},
	buttonText: {
		marginRight: 25,
	},
	buttonIcon: {
		maxWidth: 25,
		margin: "0 10px",
	},
}));
//https://reactjsexample.com/social-media-share-buttons-and-share-counts-for-react/
const DealShare = () => {
	const classes = styles();
	const deal = useSelector((state) => state.data.deal);
	const authUser = useSelector((state) => state.user.credentials);

	let authorized = false;

	authorized = authUser.type === 'wholesaler' || authUser.type === 'support';

	let location = window.location;
	let host = `${location.protocol}//${location.host}`;
	let shareUrl = `${host}/deals/${deal.dealId}`;

	return (
		<Grid item xs={12} component={Card} className={classes.formBackground}>
			<Grid
				container
				spacing={2}
				justify="space-evenly"
				className={classes.shareArea}>
				{/* {ENABLED} disable because a new access token is required every 60 days, if someone is preforming maintenance regularly this can be activated
				authorized && <FacebookPostButton menu variant="contained" /> */}
				{authorized && <FacebookPostButton menu variant="contained" />}
				<LinkedInDealShareButton
					shareUrl={shareUrl}
					title={deal.title}
				/>
				<FacebookDealShareButton shareUrl={shareUrl} />
				<TwitterDealShareButton shareUrl={shareUrl} />
				<EmailDealShareButton
					shareUrl={shareUrl}
					subject={deal.title}
				/>
			</Grid>
		</Grid>
	);
};

export default DealShare;
