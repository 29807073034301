import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";
//MUI
import CircularProgress from "@material-ui/core/CircularProgress";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	spinnerParent: { position: "relative", width: "100%", height: "100%" },
	spinnerProgress: {
		position: "absolute",
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		margin: "auto",
	},
}));

const DealSpinner = ({
	color,
	size,
	loading,
	spinnerClassName,
	spinnerParentClassName,
}) => {
	const classes = styles();
	const spinner = loading && (
		<CircularProgress
			color={color}
			size={size}
			className={clsx(classes.spinnerProgress, spinnerClassName)}
		/>
	);
	return (
		<div className={clsx(classes.spinnerParent, spinnerParentClassName)}>
			{spinner}
		</div>
	);
};

DealSpinner.propTypes = {
	color: PropTypes.string,
	loading: PropTypes.bool,
	size: PropTypes.number,
	spinnerClassName: PropTypes.string,
	spinnerParentClassName: PropTypes.string,
};

export default DealSpinner;
