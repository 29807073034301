import {
	CLEAR_RESPONSES,
	SET_ERRORS,
	CLEAR_ERRORS,
	SET_MESSAGES,
	CLEAR_MESSAGES,
	LOADING_UI,
	TOGGLE_FORM_DIALOG,
	TOGGLE_FORM_COMMENT,
	TOGGLE_NAV_DRAWER,
	TOGGLE_NAV_FILTER,
	SET_HEADERS,
	SET_RESPONSIVE,
	REDIRECT
} from "../types"

const initialState = {
	loading: false,
	headers: {},
	responses: {
		errors: {},
		messages: {},
		alerts: {},
		successful: false
	},
	dialog: {
		open: false,
		form: ""
	},
	confirmation: {
		open: false,
		name: ""
	},
	drawer: {
		open: false,
		name: ""
	},
	forms: {
		comment: {
			open: false
		}
	},
	navFilter: {
		show: false
	}
}

export default function(state = initialState, action) {
	switch (action.type) {

		case SET_HEADERS:
			return {
				...state,
				headers: action.payload
			};
		case SET_RESPONSIVE:
			return {
				...state,
				responsive: action.payload
			};
		case CLEAR_RESPONSES:
			return {
				...state,
				responses: {
					errors: {},
					messages: {},
					alerts: {},
					successful: false
				}
			};
		case SET_ERRORS:
			return {
				...state,
				loading: false,
					responses: {
						...state.responses,
						errors: action.payload
					}
			};
		case CLEAR_ERRORS:
			return {
				...state,
				loading: false,
					responses: {
						...state.responses,
						errors: {}
					}
			};
		case LOADING_UI:
			return {
				...state,
				loading: action.payload
			};
		case SET_MESSAGES:
			return {
				...state,
				loading: false,
					responses: {
						...state.responses,
						messages: action.payload,
						successful: true
					}
			};
		case CLEAR_MESSAGES:
			return {
				...state,
				loading: false,
					responses: {
						...state.responses,
						messages: {},
						successful: false
					}
			};
		case TOGGLE_NAV_DRAWER:
			return {
				...state,
				drawer: {
					open: action.payload.open,
					name: action.payload.toolbar
				}
			};
		case TOGGLE_FORM_DIALOG:
			return {
				...state,
				dialog: {
					open: action.payload.open,
					form: action.payload.form,
					args: action.payload.args
				}

			};
		case TOGGLE_FORM_COMMENT:
			return {
				...state,
				forms: {
						...state.forms,
						comment: {
							...state.forms.comment,
							open: !state.forms.comment.open,
						}
					},
					errors: {},
					messages: {},
			};
		case TOGGLE_NAV_FILTER:
			return {
				...state,
				navFilter: {
					show: !state.navFilter.show
				}
			};
		case REDIRECT:
			return {
				...state,
				redirectTo: action.payload
			};
		default:
			return state;
	}

}
