///SEE ChangePaymentMethod in subscription
import React, { useEffect } from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import ChangePaymentMethod from "./ChangePaymentMethod";
//MUI Components
import Grid from "@material-ui/core/Grid";
import PaymentDetailCard from "./PaymentDetailCard";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { getPaymentMethod } from "../../redux/actions/subcriptionActions";

const EditPaymentDetails = () => {
	const subscription = useSelector((state) => state.subscription.data);
	if (!subscription) {
		let subscription = {};
	}
	const paymentMethod = useSelector(
		(state) => state.subscription.paymentMethod
	);
	const dispatch = useDispatch();

	let publicKey;
	switch (process.env.NODE_ENV) {
		case "production":
			publicKey = process.env.REACT_APP_STRIPE_LIVE_PUBLIC_KEY;
			break;
		case "development":
			publicKey = process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY;
			break;
		default:
			publicKey = process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY;
	}

	useEffect(() => {
		if (subscription.defaultPaymentMethod) {
			dispatch(getPaymentMethod(subscription.defaultPaymentMethod));
		}
	}, [subscription.defaultPaymentMethod, dispatch]);

	const currentPaymentInformation = (
		<Grid item align="center" sm={12}>
			Current Payment Information
		</Grid>
	);

	return (
		<Grid item align="center" sm={12}>
			<StripeProvider apiKey={publicKey}>
				<div className="example">
					<PaymentDetailCard />
					<Elements
						style={{
							borderColor: "green",
							borderStyle: "solid",
							borderWidth: 1,
						}}>
						<ChangePaymentMethod />
					</Elements>
				</div>
			</StripeProvider>
		</Grid>
	);
};

export default EditPaymentDetails;
