import React, { useEffect } from "react";
import { secondsToISOString } from "../../util/UtilityFunctions";
import { captializeFirstLetter } from "../../util/UtilityFunctions";
import TextTableGrid from "../../components/controls/TextTableGrid";
import DealSpinner from "../../components/controls/DealSpinner";
//MUI Components
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
//Redux
import { useSelector } from "react-redux";
import { setGetVerificationFile } from "../../redux/actions/systemActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
//Dayjs
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);
dayjs.tz.guess();

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	subscriptionDetail: {
		padding: "5px 10px 5px 10px",
	},
	spinnerCard: {
		height: "100%",
		minHeight: 50,
	},
	spinner: {
		marginTop: 50,
	},
}));

const PaymentDetailCard = () => {
	const subscription = useSelector((state) => state.subscription.data);
	const paymentMethod = useSelector(
		(state) => state.subscription.paymentMethod
	);
	const latestInvoice = useSelector(
		(state) => state.subscription.latestInvoice
	);
	const subscriptionLoading = useSelector(
		(state) => state.subscription.loading
	);
	const classes = styles();

	const insertDecimal = (num) => {
		return (num / 100).toFixed(2);
	};

	// useEffect(() => {
	//setGetVerificationFile(subscription.userId);
	//});

	const rows = [
		{
			label: "Card On File",
			data: paymentMethod.card
				? captializeFirstLetter(paymentMethod.card.brand)
				: "retrieving...",
		},
		{
			label: "Last 4 Digits",
			data: paymentMethod.card
				? paymentMethod.card.last4
				: "retrieving...",
		},
		{
			label: "Exp Date",
			data: paymentMethod.card
				? `${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`
				: "retrieving...",
		},
		{
			label: "Name",
			data: paymentMethod.billing_details
				? `${paymentMethod.billing_details.name}`
				: "retrieving...",
		},
		{
			seperator: true,
			label: "Subscription Status",
			data:
				subscription && subscription.status === "trialing"
					? `Maintenance fee of $50/month begins ${dayjs(
							secondsToISOString(
								subscription.trialEnd + 1 * 24 * 60 * 60 //86400 sec
							)
					  ).format("MMM DD YYYY")}`
					: subscription.status &&
					  captializeFirstLetter(subscription.status),
			alert: subscription.status === "canceled" ? true : false,
		},
		{
			label: "Subscription Date",
			data: dayjs(secondsToISOString(subscription.createdAt)).format(
				"MMM DD YYYY"
			),
		},
	];

	let detail =
		Object.entries(subscription).length > 0 ? (
			<TextTableGrid
				rows={
					subscription
						? rows
						: { label: "retrieving...", data: "retrieving..." }
				}
				labelWidth={4}
				dataWidth={8}
			/>
		) : (
			<>
				<Grid container justify="center" alignItems="center">
					<Grid item xs={12} style={{ marginTop: 10 }}>
						<Typography paragraph variant="h6">
							Error Loading Subscription
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography paragraph variant="body2">
							Contact support of this continues.
						</Typography>
					</Grid>
				</Grid>
			</>
		);
	return (
		<Grid
			item
			xs={12}
			className={classes.subscriptionDetail}
			component={Card}>
			{subscription && !subscriptionLoading ? (
				<Grid container spacing={0}>
					{detail}
				</Grid>
			) : (
				<Grid item className={classes.spinnerCard}>
					<DealSpinner
						spinnerParentClassName={classes.spinner}
						loading
						size={75}
					/>
				</Grid>
			)}
		</Grid>
	);
};

export default PaymentDetailCard;
