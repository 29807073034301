import PropTypes from "prop-types";
import React from "react";
import { history } from "react-router-dom";
//MUI Components
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../redux/actions/userActions";
import { redirect } from "../../../redux/actions/uiActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	menuButtonRight: {
		marginRight: theme.spacing(2),
		float: "right",
	},
}));

const LogoutButton = ({ menuButtonRight, fullWidth }) => {
	const authenticated = useSelector((state) => state.user.authenticated);
	const classes = styles();
	const dispatch = useDispatch();

	const handleLogout = () => {
		dispatch(redirect("/"));
		dispatch(logoutUser());
	};

	return (
		<Grid item>
			{authenticated ? (
				<Button
					fullWidth={fullWidth}
					className={menuButtonRight ? classes.menuButtonRight : null}
					size="small"
					variant="outlined"
					color="secondary"
					onClick={() => {
						handleLogout();
					}}>
					Logout
				</Button>
			) : null}
		</Grid>
	);
};

LogoutButton.propTypes = {
	fullWidth: PropTypes.any,
	menuButtonRight: PropTypes.any,
};

export default LogoutButton;
