import React from "react";
import logoIcon from "../../images/svg/icon-logo.svg";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	logo: {
		maxWidth: "100%",
		display: "block",
		margin: "0 auto",
		width: "100%",
	},
	large: {
		maxWidth: 150,
	},
	small: {
		maxWidth: 75,
	},
}));

const InlineLogo = () => {
	const classes = styles();
	return (
		<Grid item xs={12}>
			<img
				className={clsx(
					classes.logo,
					classes.loginForms,
					classes.large
				)}
				src={logoIcon}
				alt="logo"></img>
		</Grid>
	);
};

export default InlineLogo;
