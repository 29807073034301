import PropTypes from "prop-types";
import React from "react";
import { EmailShareButton } from "react-share";
import EmailIcon from "../../../../images/svg/icon-email.svg";
//Components
import ShareButton from "../ShareButton";
//MUI Components
import Grid from "@material-ui/core/Grid";

const defaultProps = {
	body:
		"I found this great deal on sealdealfinder.com I know you will love!  ",
};

const EmailDealShareButton = ({
	shareUrl,
	subject,
	body = defaultProps.body,
}) => {
	return (
		<Grid item xs={12} sm={3}>
			<EmailShareButton
				style={{ width: "100%" }}
				url={shareUrl}
				subject={subject}
				body={body}>
				<ShareButton icon={EmailIcon} text="Email" />
			</EmailShareButton>
		</Grid>
	);
};

EmailDealShareButton.propTypes = {
	body: PropTypes.string,
	shareUrl: PropTypes.string,
	subject: PropTypes.string,
};

export default EmailDealShareButton;
