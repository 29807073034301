import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import DeleteUserButton from "../../controls/buttons/user/DeleteUserButton";
import SpinnerButton from "../../controls/SpinnerButton";
import AssignAdminAlert from "../../alerts/system/AssignAdminAlert";
import AssignWholesalerAlert from "../../alerts/system/AssignWholesalerAlert";
import AssignWholesalerNoChargeAlert from "../../alerts/system/AssignWholesalerNoChargeAlert";
import { checkUserRole } from "../../../util/UtilityFunctions";
//MUI Components
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { setUserRole } from "../../../redux/actions/systemActions";
import {
	setAlert,
	setAlertWithConfirmation,
	clearAlert,
} from "../../../redux/actions/alertActions";

import { systemGetUsers } from "../../../redux/actions/systemActions";

import clsx from "clsx";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	card: {
		position: "relative",
		display: "flex",
	},
	userId: {
		textAlign: "center",
	},
	adminUser: {
		backgroundColor: theme.palette.primary.main,
	},
	adminLabel: {
		color: theme.palette.accent.main,
	},
	assignAdmin: {
		color: "red",
	},
	confirmedChipColorDeactivated: {
		backgroundColor: "lightGrey",
		color: "grey",
	},
	confirmedChipColorActivated: {
		backgroundColor: theme.palette.accent.main,
		color: "#fff",
	},
	confirmedChip: {
		marginBottom: 10,
	},
	boldText: {
		fontWeight: 700,
	},
}));

const SystemAuthorizationForm = ({ size }) => {
	const user = useSelector((state) => state.system.user);
	const alert = useSelector((state) => state.alert);
	const loading = useSelector((state) => state.UI.loading);

	const dispatch = useDispatch();
	const classes = styles();

	const isAdmin = checkUserRole(user.roles, "admin");
	const isWholesaler = checkUserRole(user.roles, "wholesaler");
	const isWholesalerMaintenanceFeeOnly = checkUserRole(
		user.roles,
		"manualAuthMaintenanceFeeOnly"
	);
	const isWholesalerAuthNoCharge = checkUserRole(
		user.roles,
		"manualAuthNoCharge"
	);
	const isPaid = checkUserRole(user.roles, "subscriptionPaid");

	const [loadingAdmin, setLoadingAdmin] = useState(false);
	const [loadingWholesalerMaintFee, setLoadingWholesalerMaintFee] = useState(
		false
	);

	const [loadingWholesalerNoCharge, setLoadingWholesalerNoCharge] = useState(
		false
	);
	const userHasPaidSubscriptionAlert = () => {
		dispatch(
			setAlert(
				"subscriptionPaid",
				"information",
				"User Has a Paid Subscription",
				"This user has an active paid subscription. This user must cancel their current subscription before this role can be adjusted."
			)
		);
	};

	useEffect(() => {
		//confirmation turn off after server after response--> systemAction setUserRole()

		if (alert.confirmed) {
			const adminRole = {
				claim: { admin: !isAdmin },
				userId: user.userId,
			};
			const wholesalerRole = {
				claim: {
					wholesaler: !isWholesalerMaintenanceFeeOnly,
					subscriptionPaid: false,
					manualAuthMaintenanceFeeOnly: !isWholesalerMaintenanceFeeOnly,
					manualAuthNoCharge: false,
				},
				userId: user.userId,
			};
			const wholesalerRoleNoCharge = {
				claim: {
					wholesaler: !isWholesalerAuthNoCharge,
					subscriptionPaid: !isWholesalerAuthNoCharge,
					manualAuthMaintenanceFeeOnly: false,
					manualAuthNoCharge: !isWholesalerAuthNoCharge,
				},
				userId: user.userId,
			};

			switch (alert.confirmationForm) {
				case "systemAuthAdmin":
					dispatch(setUserRole(adminRole));
					dispatch(clearAlert());
					break;
				case "systemAuthWholesaler":
					dispatch(setUserRole(wholesalerRole));
					dispatch(clearAlert());
					break;
				case "systemAuthWholesalerNoCharge":
					dispatch(setUserRole(wholesalerRoleNoCharge));
					dispatch(clearAlert());
					break;
				default:
					break;
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [alert.confirmed, dispatch]);

	const handleAdminChange = () => {
		setLoadingAdmin(true);
		dispatch(
			setAlertWithConfirmation(
				"systemAuthAdmin",
				!user.roles.admin ? "error" : "information",
				!user.roles.admin
					? "Confirm Authorization Assignment "
					: "Confirm Authorization Removal",
				<AssignAdminAlert
					messageType={
						!user.roles.admin ? "assignMsg" : "unAssignMsg"
					}
					user={user}
				/>
			)
		);
	};

	const handleWholesalerChange = (chargeMaintenanceFee) => {
		dispatch(systemGetUsers());
		if (chargeMaintenanceFee) {
			setLoadingWholesalerMaintFee(true);
			dispatch(
				setAlertWithConfirmation(
					"systemAuthWholesaler",
					!isWholesaler ? "error" : "information",
					!isWholesalerMaintenanceFeeOnly
						? `Confirm Wholesaler Assignment ($50 per month)`
						: "Confirm Authorization Removal",
					<AssignWholesalerAlert
						messageType={
							!isWholesalerMaintenanceFeeOnly
								? "assignMsg"
								: "unAssignMsg"
						}
						user={user}
					/>
				)
			);
		} else {
			setLoadingWholesalerNoCharge(true);
			dispatch(
				setAlertWithConfirmation(
					"systemAuthWholesalerNoCharge",
					!isWholesaler ? "error" : "information",
					!isWholesalerAuthNoCharge
						? `Confirm Wholesaler Assignment (No Charge)`
						: "Confirm Authorization Removal",
					<AssignWholesalerNoChargeAlert
						messageType={
							!isWholesalerAuthNoCharge
								? "assignMsg"
								: "unAssignMsg"
						}
						user={user}
					/>
				)
			);
		}
	};
	const noMaunalAuth =
		!isWholesalerAuthNoCharge && !isWholesalerMaintenanceFeeOnly;
	return (
		<Fragment>
			<Grid
				item
				container
				alignItems="center"
				justify="center"
				xs={12}
				spacing={2}>
				<Grid item xs={12}>
					<Typography
						component="span"
						variant="body2"
						align="center"
						justify="center">
						Adjust Authorization for:{" "}
						<Typography
							display="inline"
							variant="body2"
							className={classes.boldText}>
							{user.handle}
						</Typography>
					</Typography>
				</Grid>
				{(isWholesalerMaintenanceFeeOnly || noMaunalAuth) && (
					<>
						<Grid item xs={10}>
							<SpinnerButton
								size={size}
								disabled={loading}
								className={clsx(
									isAdmin
										? classes.adminUser
										: classes.notAdminUser
								)}
								fullWidth
								variant="contained"
								color="primary"
								onClick={() => handleAdminChange()}>
								{isAdmin ? "Unassign Admin" : "Assign Admin"}
								{loading && loadingAdmin && (
									<CircularProgress
										size={30}
										className={classes.spinner}
									/>
								)}
							</SpinnerButton>
						</Grid>
						<Grid item xs={10}>
							<SpinnerButton
								size={size}
								disabled={loading}
								className={clsx(
									isAdmin
										? classes.adminUser
										: classes.notAdminUser
								)}
								fullWidth
								variant="contained"
								color="primary"
								onClick={() => handleWholesalerChange(true)}>
								{isWholesalerMaintenanceFeeOnly
									? "Unassign Wholesaler (Maintenance Fee Charge)"
									: "Assign Wholesaler ($50 per month)"}
								{loading && loadingWholesalerMaintFee && (
									<CircularProgress
										size={30}
										className={classes.spinner}
									/>
								)}
							</SpinnerButton>
						</Grid>
					</>
				)}

				{(isWholesalerAuthNoCharge || noMaunalAuth) && (
					<>
						<Grid item xs={10}>
							<SpinnerButton
								size={size}
								disabled={loading}
								className={clsx(
									isAdmin
										? classes.adminUser
										: classes.notAdminUser
								)}
								fullWidth
								variant="contained"
								color="primary"
								onClick={() => handleWholesalerChange(false)}>
								{isWholesalerAuthNoCharge
									? "Unassign Wholesaler"
									: "Assign Wholesaler (no charges or fees)"}
								{loading && loadingWholesalerNoCharge && (
									<CircularProgress
										size={30}
										className={classes.spinner}
									/>
								)}
							</SpinnerButton>
						</Grid>
					</>
				)}
				<Grid item xs={10}>
					<DeleteUserButton
						style={{ marginBottom: 20 }}
						fullWidth
						user={user}
						text={`Delete ${user.handle}`}
					/>
				</Grid>
			</Grid>
		</Fragment>
	);
};

SystemAuthorizationForm.propTypes = {
	size: PropTypes.any,
};

export default SystemAuthorizationForm;
