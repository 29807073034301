import React, { Fragment } from "react";
import { objectNotEmpty } from "../../util/UtilityFunctions";
import Form from "./components/Form";
import EditProfileForm from "./profile/EditProfileForm";
//Forms
import SendMessageReplyForm from "./messages/SendMessageReplyForm";
import SendMessageForm from "./messages/SendMessageForm";
import ResetPassWordForm from "./credentials/ResetPassWordForm";
import EditPaymentDetails from "../subscriptions/EditPaymentDetails";
import SystemSubscriptionInfoForm from "./system/SystemSubscriptionInfoForm";
import SystemAuthorizationForm from "./system/SystemAuthorizationForm";
import SystemApplicationForm from "./system/SystemApplicationForm";
import DealForm from "./deals/DealForm";
import Alert from "../alerts/Alert";
//Redux
import { useSelector } from "react-redux";

const FormsLoader = () => {
	const dialog = useSelector((state) => state.UI.dialog);
	const alert = useSelector((state) => state.alert);

	const test = null; //true;
	const testType = null; //"error";

	/*if (dialog.form === "editProfile") {
		require("../../util/rndCropper.css");
	} else {
		require("../../util/sqCropper.css");
	}*/

	//dialog.open && dialog.form && selectForm()
	return (
		<Fragment>
			{dialog.form === "resetPassword" && (
				<Form
					maxWidth="sm"
					titleText="Enter Your Email Address"
					component={<ResetPassWordForm />}
				/>
			)}
			{dialog.form === "editPaymentDetails" && (
				<Form
					maxWidth="sm"
					titleText="Edit Payment Details"
					component={<EditPaymentDetails />}
				/>
			)}
			,
			{dialog.form === "editProfile" && (
				<Form
					titleText="Edit User Details"
					component={<EditProfileForm />}
				/>
			)}
			{objectNotEmpty(dialog.args) ? (
				dialog.form === "deal" && dialog.args.type === "create" ? (
					<Form
						type={dialog.args.type}
						titleText="Create a New Deal"
						component={<DealForm type="create" />}
					/>
				) : (
					dialog.form === "deal" &&
					dialog.args.type === "edit" && (
						<Form
							type={dialog.args.type}
							titleText={`Edit Deal`}
							component={<DealForm type="edit" />}
						/>
					)
				)
			) : null}
			{(alert.open || (test && testType)) && (
				<Alert test={test} testType={testType} />
			)}
			{/*Messages*/}
			{dialog.form === "sendMessage" && (
				<Form
					maxWidth="sm"
					titleText="Contact Deal Maker"
					component={<SendMessageForm />}
				/>
			)}
			{dialog.form === "sendMessageReply" && (
				<Form
					maxWidth="sm"
					titleText="Contact Deal Maker"
					component={
						<SendMessageReplyForm originalMessageId={dialog.args} />
					}
				/>
			)}
			{/*System**/}
			{dialog.form === "subscriptionInfo" && (
				<Form
					titleText="Subscription Information"
					component={<SystemSubscriptionInfoForm />}
				/>
			)}
			{dialog.form === "assignRole" && (
				<Form
					maxWidth="sm"
					titleText="Authorization"
					component={<SystemAuthorizationForm />}
				/>
			)}
			{dialog.form === "application" && (
				<Form
					fullScreen
					titleText="User Verification"
					component={<SystemApplicationForm />}
				/>
			)}
		</Fragment>
	);
};

export default FormsLoader;
