//Notification are retrieved from the user state

import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

//MUI Components
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
//Icons
import NotificationsIcon from "@material-ui/icons/Notifications";
//Redux
import { useDispatch } from "react-redux";
//Dayjs
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.tz.guess();

const Notifications = ({ conversationNotifications, className }) => {
	const allNotifications = useSelector((state) => state.user.notifications);
	const authUser = useSelector((state) => state.user.credentials);
	const [anchorEl, setAnchorEl] = useState(null);
	const dispatch = useDispatch();

	const handleOpen = (e) => {
		setAnchorEl(e.target);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	let notifications;
	if (conversationNotifications) {
		notifications = conversationNotifications;
	} else {
		notifications = allNotifications;
	}

	dayjs.extend(relativeTime);

	let notificationsIcon;
	if (notifications && notifications.length > 0) {
		notifications.filter((not) => not.read === false).length > 0
			? (notificationsIcon = (
					<Badge
						badgeContent={
							notifications.filter((not) => not.read === false)
								.length
						}
						color="secondary">
						<NotificationsIcon />
					</Badge>
			  ))
			: (notificationsIcon = <NotificationsIcon />);
	} else {
		notificationsIcon = <NotificationsIcon />;
	}

	let notificationsMarkup =
		notifications &&
		notifications.filter((not) => not.read === false).length > 0 ? (
			notifications.map((not) => {
				const verb = not.type === "like" ? "liked" : "sent";
				const time = dayjs(not.createdAt).fromNow();
				const read = not.read;

				return (
					!read &&
					not.sender && (
						<MenuItem
							key={not.createdAt}
							onClick={() => handleClose()}>
							<Link to={`/inbox/${authUser.handle}`}>
								<Grid
									container
									justify="center"
									alignItems="center"
									spacing={2}>
									<Grid
										item
										xs={1}
										style={{ marginRight: 10 }}>
										<ListItemAvatar>
											<Avatar src={not.sender.imageUrl} />
										</ListItemAvatar>
									</Grid>
									<Grid item xs={10}>
										<Typography
											color="default"
											variant="body1">
											{not.sender.handle} {verb} you a
											message {time}
										</Typography>
									</Grid>
								</Grid>
							</Link>
						</MenuItem>
					)
				);
			})
		) : (
			<MenuItem onClick={() => handleClose()}>
				You have no notifications
			</MenuItem>
		);
	const notify = notifications &&
		notifications.filter((not) => not.read === false).length > 0 && (
			<Fragment>
				<Tooltip placement="top" title="Notifications">
					<IconButton
						className={className}
						aria-owns={anchorEl ? "simple-menu" : undefined}
						aria-haspopup="true"
						onClick={(e) => handleOpen(e)}>
						{notificationsIcon}
					</IconButton>
				</Tooltip>
				<Menu
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={() => handleClose()}>
					{notificationsMarkup}
				</Menu>
			</Fragment>
		);

	return <Fragment>{notify}</Fragment>;
};

export default Notifications;
