import React, { Fragment } from "react";
import clsx from "clsx";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { clearResponses } from "../../../redux/actions/uiActions";
//Components
import FormMessageArea from "./FormMessageArea";
//MUI
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { CircularProgress } from "@material-ui/core";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	formButton: {
		color: "white",
		marginTop: 0,
		[theme.breakpoints.up("sm")]: {
			marginBottom: 10,
		},
	},
	cancel: {
		color: "#fff",
	},
	grow: {
		flexGrow: 1,
	},
}));
const FormActionArea = ({
	msgTest,
	onClick,
	onCancel,
	hideCancel,
	actionBtnText,
	cancelBtnText,
	loading,
}) => {
	//const loading = useSelector((state) => state.data.loading);
	const errors = useSelector((state) => state.UI.responses.errors);
	const messages = useSelector((state) => state.UI.responses.messages);
	const dispatch = useDispatch();
	const classes = styles();

	const formCancel = () => {
		dispatch(clearResponses());
		onCancel();
	};

	return (
		<Fragment>
			<Grid item xs={12}>
				<Grid container spacing={1} direction="row-reverse">
					<Grid item container xs={12} sm={4} spacing={1}>
						{onCancel && !hideCancel && (
							<Grid item xs={6}>
								<Button
									className={classes.cancel}
									fullWidth
									onClick={() => formCancel()}
									variant="contained"
									color="primary">
									{cancelBtnText}
								</Button>
							</Grid>
						)}
						<div className={classes.grow} />
						<Grid item xs={6}>
							<Button
								fullWidth
								type="submit"
								variant="contained"
								color="primary"
								onClick={onClick}
								className={clsx(
									classes.button,
									classes.formButton
								)}
								disabled={loading}>
								{actionBtnText}
								{loading && (
									<CircularProgress
										size={30}
										className={classes.spinner}
									/>
								)}
							</Button>
						</Grid>
					</Grid>
					<Divider />
					<Grid item xs={12} sm={8}>
						<FormMessageArea
							msgTest={msgTest}
							messages={messages}
							errors={errors}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Fragment>
	);
};
export default FormActionArea;
