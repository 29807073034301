import PropTypes from "prop-types";
import React, { Fragment } from "react";
import iconSuccess from "../../images/logo-icon@177.png";
import iconWarning from "../../images/alert-icon-warning@177.png";
import iconInformation from "../../images/alert-icon-information@177.png";
import clsx from "clsx";
//MUI
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
//Rudux
import { captializeFirstLetter } from "../../util/UtilityFunctions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	badgeProfileImage: {
		maxWidth: 30,
		borderRadius: "100%",
		display: "block",
		padding: theme.spacing(1),
		marginTop: theme.spacing(2),
		marginBottom: 5,
	},
	text: {
		padding: 5,
		textShadow: "1px 1px 2px #fff",
	},
	iconWrapper: {
		margin: 0,
	},
	success: {
		color: theme.palette.primary.dark,
	},
	error: {
		color: theme.palette.warning.dark,
	},
	information: {
		color: theme.palette.information.dark,
	},
}));

const AlertTitle = ({ alert, test, testType }) => {
	const { title, type } = alert;
	const classes = styles();

	const getIcon = (type) => {
		let icon;
		switch (type) {
			case "success":
				icon = iconSuccess;
				break;
			case "error":
				icon = iconWarning;
				break;
			case "information":
				icon = iconInformation;
				break;
			default:
				icon = iconInformation;
				break;
		}
		return icon;
	};

	const getClassName = (type) => {
		let className;
		switch (type) {
			case "success":
				className = classes.success;
				break;
			case "error":
				className = classes.error;
				break;
			case "information":
				className = classes.information;
				break;
			default:
				className = classes.information;
				break;
		}
		return className;
	};
	const icon = (
		<img
			className={clsx(classes.badgeProfileImage, classes.shadow)}
			src={!test ? getIcon(type) : getIcon(testType)}
			alt="alert message icon"
		/>
	);

	return (
		<Fragment>
			<Grid
				className={classes.iconWrapper}
				container
				justify="center"
				alignItems="center">
				{icon}
			</Grid>
			<DialogTitle
				align="center"
				className={clsx(classes.text, getClassName(type))}>
				{test && "Alert Title Test"}
				{title
					? captializeFirstLetter(title)
					: captializeFirstLetter(
							`${type}${type !== "information" ? "!" : null} `
					  )}
			</DialogTitle>
		</Fragment>
	);
};

AlertTitle.propTypes = {
	alert: PropTypes.shape({
		title: PropTypes.string,
		type: PropTypes.string,
	}),
	test: PropTypes.string,
	testType: PropTypes.any,
};

export default AlertTitle;
