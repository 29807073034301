import PropTypes from "prop-types";
import React, { Fragment } from "react";
import clsx from "clsx";
//MUI
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Typography } from "@material-ui/core";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	badge: {
		borderStyle: "solid",
		borderWidth: 1,
		width: "100%",
		padding: "0 5px 0 5px",
		textAlign: "center",
		lineHeight: 0.1,
	},
	pendingVerified: {
		color: "#fff",
		borderColor: theme.palette.primary.main,
		backgroundColor: theme.palette.primary.main,
	},
	verified: {
		color: theme.palette.secondary.main,
		borderColor: theme.palette.accent.main,
		backgroundColor: theme.palette.accent.main,
	},
	support: {
		borderColor: theme.palette.information.dark,
		backgroundColor: theme.palette.information.dark,
	},
	badgeText: {
		lineHeight: 0.1,
	},
	badgeCard: {
		top: 10,
		marginTop: "-10px",
		position: "relative",
	},
}));

const UserTypeBadge = ({
	variant,
	user,
	badgeCard,
	className,
	hideVerified,
}) => {
	//const user = useSelector((state) => state.user.credentials);
	const classes = styles();
	const { verified, type } = user;

	const typeGenerator = () => {
		switch (type) {
			case "investor":
				return "Investor";
			case "wholesaler":
				return "Wholesaler";
			case "support":
				return "Support";
			case "member":
				return "Member";
			default:
				return "Unknown";
		}
	};

	const text = typeGenerator();

	const badge = (verified || type) && (
		<>
			{text != "Unknown" ? (
				<Grid
					elevation={badgeCard ? 0 : 1}
					square={badgeCard}
					item
					className={clsx(
						className,
						badgeCard ? classes.badgeCard : null,
						classes.badge,
						verified ? classes.verified : classes.pendingVerified,
						type === "support" ? classes.support : null
					)}
					component={Paper}>
					<Typography variant={variant}>
						{verified && !hideVerified ? " Premium " : null}
						{typeGenerator()}
					</Typography>
				</Grid>
			) : (
				<div style={{ minHeight: 16 }}></div>
			)}
		</>
	);
	return <Fragment>{badge}</Fragment>;
};

UserTypeBadge.propTypes = {
	badgeCard: PropTypes.any,
	className: PropTypes.any,
	hideVerified: PropTypes.any,
	user: PropTypes.shape({
		type: PropTypes.any,
		verified: PropTypes.any,
	}),
	variant: PropTypes.string,
};

UserTypeBadge.defaultProps = {
	variant: "caption",
};

export default UserTypeBadge;
