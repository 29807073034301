import {
	DELETE_COMMENT,
	SUBMIT_COMMENT,
	SUBMITING_COMMENT,
	EDIT_COMMENT,
	UPDATING_COMMENT,
	DELETING_COMMENT
} from "../types"

import axios from "axios"



//Edit comment
export const editComment = (comment) => (dispatch) => {
	dispatch({
		type: UPDATING_COMMENT,
		payload: comment.commentId
	})
	axios.post('/api/deal/comment/update', comment)

		.then((res) => {

			dispatch({
				type: EDIT_COMMENT,
				payload: res.data
			})

		})
		.catch(err => {
			console.log(err)
		})
}

//Submit Delete Comment
export const deleteComment = (comment) => dispatch => {
	dispatch({
		type: DELETING_COMMENT,
		payload: comment.commentId
	})

	axios.delete(`/api/deal/comment/${comment.commentId}/`, {
			data: {
				commentId: comment.commentId,
				dealId: comment.dealId,
			}
		})
		.then((res) => {
			dispatch({
				type: DELETE_COMMENT,
				payload: comment.commentId
			})

		})
		.catch(err => {
			console.log(err)

		})
}

//Submit comment
export const submitComment = (dealId, commentData) => dispatch => {
	dispatch({
		type: SUBMITING_COMMENT
	})
	axios.post(`/api/deal/${dealId}/comment/`, commentData)
		.then((res) => {
			dispatch({
				type: SUBMIT_COMMENT,
				payload: res.data
			})
			//dispatch(clearErrors());
		})
		.catch(err => {

		})
}

//Submit Block Comment
export const blockComment = (comment) => (dispatch) => {
	dispatch({
		type: UPDATING_COMMENT,
		payload: comment.commentId
	})
	axios.post('/api/deal/comment/block', comment)

		.then((res) => {
			dispatch({
				type: EDIT_COMMENT,
				payload: res.data
			})
		})
		.catch(err => {
			console.log(err)
		})
}
