import React, { Fragment } from "react";
//MUI
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
//Redux
import { useDispatch } from "react-redux";
import { toggleNavDrawer } from "../../../../redux/actions/uiActions";

const MainMenuButton = () => {
	const dispatch = useDispatch();
	const handleClick = () => {
		dispatch(toggleNavDrawer(true, "mainMenu"));
	};

	return (
		<Fragment>
			<IconButton
				onClick={handleClick}
				edge="start"
				color="inherit"
				aria-label="open menu">
				<MenuIcon />
			</IconButton>
		</Fragment>
	);
};

export default MainMenuButton;
