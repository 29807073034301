import React, { Fragment } from "react";
import clsx from "clsx";
//Components

//MUI
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// Redux
import { useSelector } from "react-redux";

//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	main: {
		backgroundColor: "white",

		padding: theme.spacing(3),
	},
	sideProfile: {
		minWidth: 240,
	},
	title: {
		marginBottom: 0,
	},
	subTitle: {
		marginBottom: 10,
	},
	section: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
		fontWeight: 700,
	},
	subSection: {
		fontStyle: "italic",
		fontWeight: 700,
		marginLeft: theme.spacing(-2),
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
	},
	bulletSubSection: {
		fontStyle: "italic",
		fontWeight: 700,
		marginTop: theme.spacing(2),
	},
	sectionIndent: {
		marginLeft: theme.spacing(4),
	},
	subSectionIndent: {
		marginLeft: theme.spacing(6),
	},
}));

const PrivacySection = ({ contractData }) => {
	const user = useSelector((state) => state.user.credentials);
	const classes = styles();
	const { firstName, lastName } = contractData;

	const signeeName = `${firstName || "[First Name]"} ${
		lastName || "[Last Name]"
	}`;

	return (
		<Grid item align="center" xs={12}>
			<Paper className={clsx(classes.main)} component={Card}>
				<CardContent>
					<Typography variant="h5" className={classes.title}>
						Wholesaler Contract
					</Typography>
					<Typography variant="body1" className={classes.subTitle}>
						By signing this document below you signify that you are
						in full agreement and accept all terms of this contract.
					</Typography>

					<Typography
						className={classes.content}
						variant="body2"
						align="left">
						<Typography>
							AGREEMENT between SealDealFinder.dba and/or Kpai
							Real Holdings LLC (&ldquo;Agency&rdquo;), and{" "}
							<Typography
								display="inline"
								className={
									classes.boldText
								}>{` ${signeeName} `}</Typography>{" "}
							(&ldquo;Client&rdquo; print your name) for
							Independent Acquisition Specialist:
						</Typography>
						<Typography variant="h5" className={classes.section}>
							1. Appointment
						</Typography>
						<Typography>
							SealDealFinder.dba appoints
							<Typography
								display="inline"
								className={
									classes.boldText
								}>{` ${signeeName} `}</Typography>
							as an independent acquisition specialist for
							finding, contacting, and engaging owners in real
							estate contracts for the purpose of wholesaling
							described in section 2, attached hereto, for terms
							of this agreement. Commencement of services will
							begin on date signed by client.
						</Typography>
						<Typography variant="h5" className={classes.section}>
							2. Scope of Independent Acquisition Specialist:
						</Typography>
						<Typography
							align="justify"
							className={classes.sectionIndent}>
							<Typography
								paragraph
								className={classes.subSection}
								variant="subtitle">
								General Scope of services for the Independent
								Acquisition Specialist are as follows:
							</Typography>
							<Typography>
								● Identify prospective properties and research
								property information such as: owner, address,
								estimated values, liens, and comparable using
								any method and resource available.
							</Typography>
							<Typography>
								● Make contact with owners of prospective
								properties to ask if they are willing to sale
								and make contracts using KPAI REAL HOLDINGS LLC.
							</Typography>
							<Typography>
								● Share any and all information with the
								partners of SealDealFinder.com and/or Kpai Real
								Holdings LLC to obtain the best possible deal.
							</Typography>
							<Typography>
								● Work with partners to create a system to
								recruit, train, and develop other Independent
								Acquisition Specialists on various levels.
							</Typography>
						</Typography>
						{/*New Section*/}
						<Typography
							align="justify"
							className={classes.sectionIndent}>
							<Typography
								paragraph
								className={classes.subSection}
								variant="subtitle">
								Agency will provide Client with the following
								services:{" "}
							</Typography>{" "}
							● Agency will provide clients with all the necessary
							resources available to Independent Acquisition
							Specialists included but not limited to marketing,
							generating leads, cold calling, and etc.
							<Typography>
								● Agency will provide all the necessary
								paperwork needed for each prospective property
								if needed.
							</Typography>
							<Typography>
								● Agency will produce all invoices with the
								Agency logo and Agency information.
							</Typography>
							<Typography>
								● Clients will be responsible for coordinating
								all aspects of finding prospective properties
								which includes but not limited to: creating a
								team if necessary to ensure success etc..
							</Typography>
							<Typography>
								● Client will be responsible for communicating
								with SealDealFinder.com and/or Kpai Real
								Holdings LLC for strategizing, resourcing, and
								etc. Should SealDealFinder.com and/or Kpai Real
								Holdings LLC request Client to perform
								additional services beyond what is provided in
								section 2, SealDealFinder.com and/or Kpai Real
								Holdings LLC and Client will negotiate in good
								faith with respect to the terms,conditions, and
								compensation for such additional services. Any
								agreement for additional services will be set
								forth in writing and considered an addendum to
								this Agreement.
							</Typography>
						</Typography>
						{/*New Section*/}
						<Typography variant="h5" className={classes.section}>
							3. Ownership
						</Typography>
						<Typography>
							All campaigns, trademarks, service marks, slogans,
							artwork, written materials, drawings, photographs,
							graphic materials, film, music, transcriptions, or
							other materials that are subject to copyright,
							trademark, patent, or similar protection
							(collectively, the &ldquo;Work Product&rdquo;)
							produced by Client are the property of
							SealDealFinder.com and/or Kpai Real Holdings LLC.
							Notwithstanding the foregoing, it is understood that
							Client may, on occasion, license materials from
							third parties for inclusion in Work Product. In such
							circumstances, ownership of such licensed materials
							remains with the licensor at the conclusion of the
							term of the license.
							<Typography
								variant="h5"
								className={classes.section}>
								4. Term{" "}
							</Typography>
							SealDealFinder.com and/or Kpai Real Holdings LLC
							contracts Craig Ridley for services as an
							Independent Acquisition Specialist as (an)
							independent Contractor, and not as (an) employee.
							The term of this Agreement shall commence on the
							date provided when client has signed
							(&ldquo;Commencement Date&rdquo;) and shall continue
							until terminated by either party 14 days&rsquo;
							prior written notice (&ldquo;Notice Period&rdquo;)
							has to be provided. Notice shall be deemed given on
							the day of mailing or, in case of notice by emailed
							the date time stamped. During the Notice Period,
							Client and SealDealFinder.com and/or Kpai Real
							Holdings LLC rights, duties, and responsibilities
							shall continue. Upon termination, Client will
							transfer and/or assign to SealDealFinder.com and/or
							Kpai Real Holdings LLC: (1) all Work Product in
							Clients possession or control belonging to
							SealDealFinder.com and/or Kpai Real Holdings LLC,
							subject, however, to any rights of third parties;
							and (2) all contracts with third parties, including
							advertising media or others, upon being duly
							released by Client and any such third party from any
							further obligations.
						</Typography>
						{/*New Section*/}
						<Typography variant="h5" className={classes.section}>
							5. Compensation and Billing Procedure
						</Typography>
						<Typography>
							&nbsp;SealDealFinder.com and/or Kpai Real Holdings
							LLC will arrange for your assignment fees to be paid
							out using the current payout schedule. Client
							understands that SealDealFinder.com and/or Kpai Real
							Holdings LLC reserves the right to at any time with
							prior notice change the payout schedule for
							assignments, passive income, promotional bonuses,
							and etc.
							<Typography>
								Current Payout Schedule and Promotion Levels on
								assignment fees are as follows: Acquisition
								Specialist Rep. 55% Contract Senior Acquisition
								Specialist Rep. 65% Contract District
								Acquisition Specialist Rep. 75% Contract
								Divisional Acquisition Specialist Rep. 80%
								Contract Regional Vice President 85% Contract
								Current Passive Income based on Promotion Levels
								on assignment fees are as follows: Acquisition
								Specialist Rep. 5% Senior Acquisition Specialist
								Rep 5% District Acquisition Specialist Rep 3%
								Divisional Acquisition Specialist Rep 2%
								Regional Vice President 2.5% + 1% of total
								Quarterly Profit of SealDealFinder.com.
							</Typography>
							<Typography
								paragraph
								className={clsx(
									classes.subSection,
									classes.sectionIndent
								)}
								variant="subtitle">
								Current Promotion Schedule are as follows:
								<Typography
									paragraph
									className={classes.sectionIndent}>
									<Typography>
										● Promotion to Sr. Acquisition
										Specialist Rep.- Get your first
										successful Assignment payout.{" "}
									</Typography>
									<Typography>
										● Promotion to Dis. Acquisition
										Specialist Rep. – Minimum 3 Successful
										Assignment payouts by Team with 1
										personal payout minimum and individually
										recruit 3 team partners.
									</Typography>
									<Typography>
										● Promotion to Div. Acquisition
										Specialist Rep. – Minimum 6 Successful
										Assignment payouts in a 6-month term
										individually or team with 3 personal
										payout minimum, and recruit 6 total team
										partners accumulatively.
									</Typography>
									<Typography>
										● Promotion to RVP – Minimum of 12
										Successful Assignment payouts in a
										12-month term individually or team with
										6 personal payout minimum, and recruited
										12 total team partners accumulatively.
										Client understands all Assignment fees,
										passive income payouts, promotions
										schedules, and bonuses will not be due
										to the client until a successful closing
										and is subject to change each month.
									</Typography>
								</Typography>
							</Typography>
						</Typography>
						{/*New Section*/}
						<Typography variant="h5" className={classes.section}>
							6. Client Representative and Business Standards
						</Typography>
						<Typography
							align="justify"
							className={classes.sectionIndent}>
							<Typography
								paragraph
								className={classes.subSection}
								variant="subtitle">
								Client understands there is a fee associated
								with becoming a representative of
								SealDealFinder.com of $199. This fee helps to
								defray the cost of providing incredible support
								to the client such as the virtual assistant.
								This fee is required immediately after signing
								of the contract which is legally binding. This
								fee covers such expenses in building your
								business such as:
							</Typography>
							<Typography>
								● Criminal Background Checks
							</Typography>
							<Typography>
								● Virtual Assistant for generating leads, skip
								tracing, cold calling, and managing such leads.
							</Typography>
							<Typography>
								● Marketing materials such as Clients polo shirt
								and business cards with SealDealFinder.com
								branding.
							</Typography>
							<Typography className={classes.bulletSubSection}>
								● Training materials, sales tools, books,
								presentations, and etc. There is a fee to the
								Client of $50 a month to help improve and
								maintain the SealDealFinder.com website,
								support, and marketing. (For more information
								get with your Representative) Basic Business
								Standards are as follows:
							</Typography>
							<Typography className={classes.subSectionIndent}>
								<Typography>
									● I will always be honest in my
									SealDealFinder.com dealings with our clients
									and business teams.
								</Typography>
								<Typography>
									● I will always be professional and
									respectful to my business. I will not engage
									in discrimination, on the basis of race,
									color, religion, sex, national origin,
									disability, marital status, veteran status,
									or any other characteristics protected by
									law, and will not engage in any form of
									harassment.
								</Typography>
								<Typography>
									● I understand that SealDealFinder.com
									offers the chance to earn income and
									opportunity for advancement and financial
									growth through building a business, but that
									my actual advancement and financial growth
									are based solely on my own efforts and
									ability. Neither SealDealFinder.com nor any
									SealDealFinder.com representative can
									guarantee my success, advancement, or
									income.
								</Typography>
								<Typography>
									● I understand that I do not make money from
									recruiting and earnings are based on
									successfully closing deals and assignment
									fees.
								</Typography>
								<Typography>
									● I understand I am not a licensed real
									estate agent and we do not sell real estate.
									I will not promote myself or my team as real
									estate agents or brokerages and/or firms.
								</Typography>
								<Typography>
									● I understand that SealDealFinder.com
									and/or Kpai Real Holdings LLC have the
									right, based on my expressed permission, to
									use and publish from time to time my image,
									name, and statements I make to promote and
									market the SealDealFinder.com brand.
								</Typography>
							</Typography>
						</Typography>
						{/*New Section*/}
						<Typography variant="h5" className={classes.section}>
							7. Confidentiality and Safeguard of Property
						</Typography>
						<Typography>
							Client and SealDealFinder.com and/or Kpai Real
							Holdings LLC respectively agree to keep in
							confidence, and not to disclose or use for its own
							respective benefit or for the benefit of any third
							party (except as may be required for the performance
							of services under this Agreement or as may be
							required by law), any information, documents, or
							materials that are reasonably considered
							confidential regarding each other&rsquo;s products,
							business, customers, clients, suppliers, or methods
							of operation; provided, however, that such
							obligation of confidentiality will not extend to
							anything in the public domain or that was in the
							possession of either party prior to disclosure.
							SealDealFinder.com and/or Kpai Real Holdings LLC and
							Client will take reasonable precautions to safeguard
							property of the other entrusted to it, but in the
							absence of negligence or willful disregard, neither
							SealDealFinder.com and/or Kpai Real Holdings LLC nor
							Client will be responsible for any loss or damage.
						</Typography>
						{/*New Section*/}
						<Typography variant="h5" className={classes.section}>
							8. Indemnities
						</Typography>
						<Typography>
							SealDealFinder.com and/or Kpai Real Holdings LLC
							agrees to indemnify and hold Client harmless with
							respect to any claims or actions by third parties
							against Client based upon material prepared by
							Client, involving any claim for libel, slander,
							piracy, plagiarism, invasion of privacy, or
							infringement of copyright, except where any such
							claim or action arises out of material supplied by
							Client to SeaDealFinder.com and/or Kpai Real
							Holdings LLC. Client agrees to indemnify and hold
							SealDealFinder.com and/or Kpai Real Holdings LLC
							harmless with respect to any claims or actions by
							third parties against Client based upon materials
							furnished by Client or where material created by
							Client is substantially changed by
							SealDealFinder.com and/or Kpai Real Holdings LLC.
							Information or data obtained by SealDealFinder.com
							and/or Kpai Real Holdings LLC from Client to
							substantiate claims made in advertising shall be
							deemed to be &ldquo;materials furnished by
							Client.&rdquo; Client further agrees to indemnify
							and hold Agency harmless with respect to any death
							or personal injury claims or actions arising from
							the use of Client&rsquo;s products or services.
						</Typography>
						{/*New Section*/}
						<Typography variant="h5" className={classes.section}>
							9. Amendments
						</Typography>
						<Typography>
							Any amendments to this Agreement must be in writing
							and signed by Agency and Client.
						</Typography>
						{/*New Section*/}
						<Typography variant="h5" className={classes.section}>
							10. Notices
						</Typography>
						<Typography>
							Any notice shall be deemed given on the day of
							mailing or, if notice is by e-mail, on the next day
							following the day notice is for transmission
							e-mailed.
						</Typography>
						{/*New Section*/}
						<Typography variant="h5" className={classes.section}>
							11. Governing Law
						</Typography>
						<Typography>
							This Agreement shall be interpreted in accordance
							with the laws of the State of Texas without regard
							to its principles of conflicts of laws. Jurisdiction
							and venue shall be solely within the State of Texas.
						</Typography>
						<Typography>
							IN WITNESS WHEREOF, SealDealFinder.com and/or Kpai
							Real Holdings LLC and
						</Typography>
						<Typography>
							<Typography
								display="inline"
								className={
									classes.boldText
								}>{` ${signeeName} `}</Typography>
							have executed this Agreement.
						</Typography>
						<Typography variant="h6" className={classes.subSection}>
							<Typography
								variant="h6"
								className={classes.section}>
								Joel N. Usher
							</Typography>
							<Typography>
								Founder of SealDealFinder.com and Kpai Real
								Holdings LLC
							</Typography>
							<br />
							<Typography align="right">
								Updated: 4/6/2021
							</Typography>
						</Typography>
						<Typography>
							<br />
						</Typography>
					</Typography>
				</CardContent>
			</Paper>
		</Grid>
	);
};

export default PrivacySection;
