import PropTypes from "prop-types";
import React from "react";
import { authCreateEditDeals } from "../../../../util/UtilityFunctions";
//MUI
import AddIcon from "@material-ui/icons/Add";
import FormOpenButton from "../forms/FormOpenButton";
//Redux
import { useSelector } from "react-redux";

const defaultProps = {
	btnText: "Create Deal",
	icon: <AddIcon fontSize="small" />,
};

const CreateDealButton = ({
	btnText = defaultProps.btnText,
	icon = defaultProps.icon,
	className,
}) => {
	const authUser = useSelector((state) => state.user.credentials);
	const canCreateEditDeals = authCreateEditDeals(
		authUser.roles,
		authUser.subscriptionPaid
	);

	return (
		<>
			{canCreateEditDeals && (
				<FormOpenButton
					menu
					icon={icon}
					className={className}
					form="deal"
					args={{
						type: "create",
					}}
					btnVariant="outlined"
					btnText={btnText}
				/>
			)}
		</>
	);
};

CreateDealButton.propTypes = {
	args: PropTypes.object.isRequired,
	btnText: PropTypes.string,
	icon: PropTypes.element,
	className: PropTypes.string,
};

export default CreateDealButton;
