import React, { Fragment, useState } from "react";
import backgroundSoft from "../../images/background-soft@177.png";
import backgroundNeutral from "../../images/background-neutral@177.png";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
// Components
import AlertTitle from "./AlertTitle";
import AlertContent from "./AlertContent";
import { DealTextField } from "../../components/controls/DealTextFields";
//MUI
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
//Icons

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
	clearAlerts,
	setAlertConfirmed,
} from "../../redux/actions/alertActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	alert: {
		backgroundColor: "#fff" /* Used if the image is unavailable */,
		backgroundPosition: "center" /* Center the image */,
		backgroundRepeat: "no-repeat" /* Do not repeat the image */,
		backgroundSize:
			"cover" /* Resize the background image to cover the entire container */,
	},
	background: { backgroundImage: `url(${backgroundSoft})` },
	backgroundNeutral: {
		backgroundImage: `url(${backgroundNeutral})`,
	},
	success: {
		color: theme.palette.primary.dark,
	},
	error: {
		color: theme.palette.warning.dark,
	},
	information: {
		color: theme.palette.information.dark,
	},
	btnSuccess: {
		color: "#fff",
		backgroundColor: theme.palette.primary.dark,
	},
	btnError: {
		color: "#fff",
		backgroundColor: theme.palette.warning.dark,
	},
	btnInformation: {
		color: "#fff",
		backgroundColor: theme.palette.information.dark,
	},
}));

export const Alert = () => {
	const alert = useSelector((state) => state.alert);
	const [input, setInput] = useState({});
	//const loading = useSelector((state) => state.UI.loading);
	let history = useHistory(); //redirect after confirm
	const dispatch = useDispatch();
	const classes = styles();

	const handleClose = () => {
		if (alert.redirectTo) {
			history.push(alert.redirectTo);
			history.go(0);
		}
		dispatch(clearAlerts());
	};

	const handleInputChange = (e) => {
		e.preventDefault(e);

		setInput({
			...input,
			[e.currentTarget.name]: e.currentTarget.value,
		});
	};

	const getClassName = (type) => {
		let className;
		switch (type) {
			case "success":
				className = classes.success;
				break;
			case "error":
				className = classes.error;
				break;
			case "information":
				className = classes.information;
				break;
			default:
				className = classes.information;
				break;
		}
		return className;
	};

	const getBtnClassName = (type) => {
		let className;
		switch (type) {
			case "success":
				className = classes.btnSuccess;
				break;
			case "error":
				className = classes.btnError;
				break;
			case "information":
				className = classes.btnInformation;
				break;
			default:
				className = classes.btnInformation;
				break;
		}

		return className;
	};

	const inputField = (
		<DealTextField
			name="inputField"
			type="text"
			className={getClassName(alert.type)}
			label={""}
			value={input.inputField}
			placeholder={
				alert.inputPlaceholder
					? alert.inputPlaceholder
					: "Enter content here..."
			}
			onChange={(e) => handleInputChange(e)}
		/>
	);

	const handleConfirmClick = () => {
		let inputFiledValue = input.inputField;
		dispatch(setAlertConfirmed(inputFiledValue ? inputFiledValue : null));
		//alerts with confirmation need to dispatch the clear alerts reducer after the alert is confirmed.
		//This is handled in the useEffect function of the component that triggered the alert.
	};

	const facebookAdditionalInformation =
		alert.data && alert.data.facebookEnv === "dev" ? (
			<>
				<Grid>
					<Typography
						variant="subtitle2"
						align="left"
						color="primary"
						className={clsx(classes.success, classes.boldText)}>
						Facebook Test Page Account{" "}
						{alert.data &&
							alert.data.facebookPostId &&
							`- Post ID ${alert.data.facebookPostId}`}
					</Typography>
					<Typography
						variant="subtitle2"
						align="left"
						color="primary"
						className={clsx(classes.success)}>
						<Typography
							variant="subtitle2"
							display="inline"
							className={clsx(classes.success, classes.boldText)}>
							User Name:
						</Typography>{" "}
						{alert.data.facebookTestUserCredentials.email}
						<br />
						<Typography
							variant="subtitle2"
							display="inline"
							className={clsx(classes.success, classes.boldText)}>
							Password:
						</Typography>{" "}
						{alert.data.facebookTestUserCredentials.password}
					</Typography>
				</Grid>
			</>
		) : (
			alert.data &&
			alert.data.facebookPostId && (
				<Typography
					variant="subtitle1"
					align="left"
					color="primary"
					className={clsx(classes.success, classes.boldText)}>
					Facebook Post ID: {alert.data.facebookPostId}
				</Typography>
			)
		);

	return alert.text ? (
		<Dialog
			classes={{
				paper: clsx(
					classes.alert,
					alert.type === "error"
						? classes.backgroundNeutral
						: classes.background
				),
			}}
			disableScrollLock={true}
			open={alert.open}
			onClose={() => handleClose()}
			fullWidth
			scroll="body"
			maxWidth={alert.size ? alert.size : "sm"}>
			<Grid>
				{alert.type && (
					<>
						<AlertTitle alert={alert} />
						<AlertContent alert={alert} />
						{facebookAdditionalInformation && (
							<AlertContent
								alert={{
									text: facebookAdditionalInformation,
									type: alert.type,
									abortFirstLetterCap: true,
								}}
							/>
						)}
					</>
				)}

				{alert.inputField && (
					<DialogContent>{inputField}</DialogContent>
				)}
				<DialogActions>
					{alert.required ? (
						<Fragment>
							<Button
								variant="contained"
								onClick={() => handleClose()}
								className={clsx(getBtnClassName(alert.type))}>
								Cancel
							</Button>
							<Button
								variant="contained"
								onClick={() => handleConfirmClick()}
								className={clsx(getBtnClassName(alert.type))}>
								{alert.confirmBtnText
									? alert.confirmBtnText
									: "Confirm"}
							</Button>
						</Fragment>
					) : (
						<>
							{alert.data && alert.data.facebookPageLink && (
								<Link
									href={alert.data.facebookPageLink}
									underline="none"
									rel="noopener"
									target="_blank">
									<Button
										variant="contained"
										component="Link"
										className={clsx(
											getBtnClassName(alert.type)
										)}>
										{alert.data.facebookEnv === "dev" &&
											"(Test Page)"}{" "}
										Vist Facebook Page
									</Button>
								</Link>
							)}
							<Button
								variant="contained"
								onClick={() => handleClose()}
								className={clsx(getBtnClassName(alert.type))}>
								OK
							</Button>
						</>
					)}
				</DialogActions>
			</Grid>
		</Dialog>
	) : (
		<Fragment></Fragment>
	);
};

export default Alert;
