import React from "react";
import clsx from "clsx";
//MUI
import Button from "@material-ui/core/Button";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
}));
const FormUndoCropButton = ({ onClick, className }) => {
	const classes = styles();
	return (
		<Button
			fullWidth
			id="btn-undo-crop"
			className={clsx(className, classes.imageButtons)}
			variant="contained"
			color="primary"
			onClick={onClick}>
			Undo
		</Button>
	);
};

export default FormUndoCropButton;
