import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
//Components
import userImagePlaceholder from "../../images/placeholder-avatar-3d@177.png";

//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	badgeProfileImage: {
		maxWidth: "100%",
		borderRadius: "100%",
		margin: "10px 0 0 0",
		display: "block",
	},
	smallBadgeProfileImage: {
		maxWidth: "60%",
		margin: "10px auto",
		marginBottom: "-5px",

		borderRadius: "100%",
		display: "block",
	},
	scaleUp: {
		transition: "all .2s ease-in-out",
		"&:hover": {
			transform: "scale(1.2)",
		},
	},
}));

const Image = ({
	src,
	fallbackSrc,
	style,
	className,
	smallBadge,
	shadow,
	noScaleUp,
}) => {
	const [state, setState] = useState({
		src: src,
		errored: false,
	});

	const classes = styles();

	const onError = () => {
		setState({
			...state,
			src: userImagePlaceholder,
			errored: true,
		});
	};

	const image = (
		<img
			src={state.src}
			style={style}
			className={clsx(
				className,
				!noScaleUp && classes.scaleUp,
				smallBadge
					? classes.smallBadgeProfileImage
					: classes.badgeProfileImage,
				shadow && classes.shadow
			)}
			onError={() => onError()}
			alt="user"
		/>
	);

	return <div>{image}</div>;
};

Image.propTypes = {
	className: PropTypes.string,
	fallbackSrc: PropTypes.string,
	noScaleUp: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	shadow: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	smallBadge: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	src: PropTypes.string,
	style: PropTypes.string,
};

export default Image;
