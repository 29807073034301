import React, { Fragment } from "react";
import { dealStructuredData } from "../../util/StructuredData";
import { Helmet } from "react-helmet";
//Component
import Deal from "../deals/Deal";
import DealSpinner from "../controls/DealSpinner";
import PublicProfile from "../profile/PublicProfile";
import DealBoardButton from "../controls/buttons/deals/DealBoardButton";
//MUI Components
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Hidden from "@material-ui/core/Hidden";
//Redux
import { useSelector } from "react-redux";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	notFound: {
		backgroundColor: theme.palette.primary.main,
		minHeight: 65,
	},
	notFoundCard: {
		backgroundColor: theme.palette.primary.main,
	},
	notFoundText: {
		color: "white",
	},
	spinner: {
		marginTop: 50,
	},
}));

const DealSection = () => {
	const deal = useSelector((state) => state.data.deal);
	const loading = useSelector((state) => state.data.loading);
	const alert = useSelector((state) => state.alert);

	const classes = styles();

	const helmetHeader = (
		<Helmet>
			<script type="application/ld+json">
				{dealStructuredData({
					title: deal.title,
					description: deal.description,
					imageUrl: deal.imageUrl,
				})}
			</script>
		</Helmet>
	);

	const haveDeal = Object.entries(deal).length > 0;
	const userProfile = (
		<PublicProfile
			ctaText={`View User's Deals`}
			ctaTo={`/profiles/${deal.userHandle}`}
		/>
	);

	return (
		<Fragment>
			<Grid container spacing={2}>
				<Grid item xs={12} lg={8} style={{ height: 100 }}>
					{!loading && haveDeal ? (
						<Deal deal={deal} />
					) : loading ? (
						<DealSpinner
							spinnerParentClassName={classes.spinner}
							loading
							size={75}
						/>
					) : (
						<Grid
							component={Card}
							className={classes.notFoundCard}
							item
							xs={12}>
							{!alert.open && (
								<Grid
									container
									align="center"
									justify="center"
									spacing={2}
									component={CardContent}>
									<Grid item xs={12}>
										<Typography
											className={classes.notFoundText}
											align="center"
											vagrant="h5"
											color="secondary">
											Deal Not Found
										</Typography>
									</Grid>

									<Grid item xs={6}>
										<DealBoardButton
											text={"Return to Deal Board"}
											linkTo={"/"}
										/>
									</Grid>
								</Grid>
							)}
						</Grid>
					)}
				</Grid>
				<Hidden mdDown>
					<Grid item sm={4}>
						{userProfile}
					</Grid>
				</Hidden>
			</Grid>
		</Fragment>
	);
};

export default DealSection;
