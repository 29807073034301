import React from "react";
import clsx from "clsx";
//MUI
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	navBar: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.secondary.main,
		marginBottom: 10,
		padding: 10,
	},
	stickBottom: {
		top: "auto",
		bottom: 0,
	},
	stickTop: {
		top: "auto",
		bottom: 0,
	},
}));

const ActionBar = ({ children, className }) => {
	const classes = styles();
	return (
		<Grid
			item
			container
			xs={12}
			className={clsx(classes.navBar, className)}
			component={Paper}>
			{children}
		</Grid>
	);
};

export default ActionBar;
