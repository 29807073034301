import React from "react";

//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles(theme => ({
	...theme.customStyles
}));
const defaultProps = { backgroundColor: "#404040" };

const BodyFullScreenLayout = ({
	children,
	backgroundColor = defaultProps.backgroundColor
}) => {
	const classes = styles();
	document.body.style.backgroundColor = backgroundColor;

	return <div className={classes.content}>{children}</div>;
};

export default BodyFullScreenLayout;
