import React, { Fragment, useState, useEffect } from "react";

import clsx from "clsx";
import Compressor from "compressorjs";
//Components
import DealSpinner from "../../controls/DealSpinner";
import FormImageControls from "../components/FormImageControls";
import FormActionArea from "../components/FormActionArea";
import FormHiddenFileInput from "../components/FormHiddenFileInput";
import UserImageCropper from "../../../util/UserImageCropper";
import { DealTextField } from "../../controls/DealTextFields";
import DealSelect from "../../controls/DealSelect";
//MUI
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
//Redux
import { useSelector, useDispatch } from "react-redux";
import {
	editUserDetails,
	imageCropping,
} from "../../../redux/actions/userActions";
import { toggleFormDialog } from "../../../redux/actions/uiActions";

//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	root: {
		flexGrow: 1,
	},
	button: {
		float: "right",
	},
	toolTipButton: { color: "white" },
	imageWrapper: { maxWidth: "100%" },
}));

const EditProfileForm = () => {
	const classes = styles();
	const dispatch = useDispatch();
	const credentials = useSelector((state) => state.user.credentials);
	const loading = useSelector((state) => state.user.loading);
	const deals = useSelector((state) => state.data.deals);
	const filteredDeals = useSelector((state) => state.data.filteredDeals);

	const defalutValues = {
		about: credentials.about,
		website: credentials.website,
		location: credentials.location,
		type: `${credentials.type}`,
		imageUrl: credentials.imageUrl,
		imageVersion: credentials.imageVersion,
	};
	const defalutImageValues = {
		name: "",
		blob: null,
		file: null,
	};

	const [sliderDisabled, setSliderDisabled] = useState(true);
	const [undoImageCrop, setUndoImageCrop] = useState(null);
	const [input, setInput] = useState(defalutValues);
	const [image, setImage] = useState(defalutImageValues);

	useEffect(() => {
		setInput(credentials); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [credentials.imageUrl]);

	const handleInputChange = (e) => {
		setInput({
			...input,
			[e.currentTarget.name]: e.currentTarget.value,
		});
	};

	const handleSelectChange = (name) => (e) => {
		e.preventDefault();
		setInput({
			...input,
			[name]: e.target.value,
		});
	};

	const handleImageChange = (event) => {
		const image = event.target.files[0];
		if (image && image !== "undefined") {
			new Compressor(image, {
				quality: 0.3,
				success(result) {
					setUndoImageCrop(null);
					dispatch(imageCropping(true));
					let reader = new FileReader();
					reader.readAsDataURL(result);
					reader.onload = () => {
						setImage({
							...image,
							file: reader.result,
							name: result.name,
						});
					};
				},
				error(err) {
					console.log(err);
				},
			});
		}
	};
	const handleCrop = () => {
		setUndoImageCrop(image.file);
		const newImage = ref.current.cropper.getCroppedCanvas().toDataURL();
		setImage({ ...image, file: newImage });
	};

	const handleImageAdd = () => {
		const fileInput = document.getElementById("dealImageInput");
		fileInput.click();
		setSliderDisabled(false);
	};

	const ref = React.createRef(null);

	const handleSubmit = () => {
		//autoCropOnClose();

		if (ref.current) {
			let canvas = ref.current.cropper.getCroppedCanvas().toDataURL();
			if (canvas) {
				fetch(canvas)
					.then((res) => res.blob())
					.then((blob) => {
						dispatch(
							editUserDetails(
								input,
								blob,
								image.name,
								deals,
								filteredDeals
							)
						);
					})
					.catch((err) => {
						console.log(err);
					});
			}
		} else {
			dispatch(editUserDetails(input));
		}
		setSliderDisabled(true);
	};

	const handleCancel = () => {
		dispatch(toggleFormDialog());
		dispatch(imageCropping(false));
	};

	const theme = useTheme();
	const formPadding = theme.spacing(1);
	const screenSmallUp = useMediaQuery(theme.breakpoints.up("sm"));
	const buttonWidth = input.type === "edit" ? 3 : 6;
	return (
		credentials && (
			<Fragment>
				<FormHiddenFileInput onChange={(e) => handleImageChange(e)} />
				<Grid
					id="form-dialog-content"
					style={{ padding: formPadding }}
					className={clsx(classes.staticForm)}
					item
					container
					spacing={2}>
					<Grid
						item
						container
						justify="space-around"
						alignItems="center"
						xs={12}
						sm={5}>
						{!loading ? (
							<Grid item container justify="center" xs={12}>
								<div className={classes.imageWrapper}>
									<UserImageCropper
										imageFile={image.file}
										ref={ref}
										imageUrl={credentials.imageUrl}
										match={screenSmallUp}
										sliderDisabled={sliderDisabled}
									/>
								</div>
							</Grid>
						) : (
							<Grid
								item
								container
								xs={12}
								style={{ height: 235 }}>
								<DealSpinner loading size={45} />
							</Grid>
						)}
						<FormImageControls
							haveImage={image.file ? true : false}
							buttonWidth={buttonWidth}
							undoImageCrop={undoImageCrop}
							handleImageAdd={() => handleImageAdd()}
							handleCrop={(e) => {
								handleCrop(e);
							}}
							handleUndoCrop={() => {
								setImage({
									...image,
									file: undoImageCrop,
								});
								setUndoImageCrop(null);
							}}
							handleRestoreImage={() => {
								setImage({
									...image,
									file: null,
								});
								setUndoImageCrop(null);
							}}
						/>
					</Grid>
					<Grid item sm={7} xs={12}>
						<Grid id="col1" container justify="center" spacing={2}>
							{/*<DealSelect
								id="type"
								label="User Type"
								value={input.type}
								handleSelectChange={handleSelectChange("type")}>
								<MenuItem value={"wholesaler"}>
									Wholesaler
								</MenuItem>
								<MenuItem value={"investor"}>Investor</MenuItem>
							</DealSelect>*/}
							<DealTextField
								halfWidth
								name="website"
								type="text"
								label="Website"
								placeholder="Enter website address"
								className={classes.textField}
								value={input.website}
								onChange={(e) => handleInputChange(e)}
								fullWidth
							/>
							<DealTextField
								halfWidth
								name="location"
								type="text"
								label="Location"
								placeholder="Enter a location"
								className={classes.textField}
								value={input.location}
								onChange={(e) => handleInputChange(e)}
								fullWidth
							/>
							<DealTextField
								name="about"
								type="text"
								label={
									credentials.type === "member" ||
									credentials.type === "investor"
										? "Tell us about yourself and the properties that interest you"
										: "Tell us about yourself"
								}
								multiline
								rows="3"
								placeholder={
									credentials.type === "member" ||
									credentials.type === "investor"
										? "Tell us about yourself and the properties that interest you."
										: "Tell us about yourself."
								}
								className={classes.textField}
								value={input.about}
								onChange={(e) => handleInputChange(e)}
								fullWidth
							/>
						</Grid>
					</Grid>
					<FormActionArea
						onCancel={() => handleCancel()}
						cancelBtnText={"Cancel"}
						onClick={() => handleSubmit()}
						actionBtnText={"Save"}
					/>
				</Grid>
			</Fragment>
		)
	);
};

export default EditProfileForm;
