import PropTypes from "prop-types";
import React, { useEffect } from "react";
import clsx from "clsx";
//MUI
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
//Icon
import FacebookIcon from "../../../../images/svg/icon-facebook.svg";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { postDealToFacebook } from "../../../../redux/actions/dataActions";
import {
	clearAlerts,
	setAlertWithConfirmation,
} from "../../../../redux/actions/alertActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	icon: {
		marginRight: 5,
		maxHeight: 15,
		marginTop: 3,
	},
	iconWrapper: { width: 15 },
	text: { color: theme.palette.secondary.main },
}));

const defaultProps = {
	text: "FaceBook Driect Post (Admin)",
	color: "primary",
};
const FacebookPostButton = ({
	dealId,
	size,
	menu,
	variant,
	className,
	color = defaultProps.color,
	text = defaultProps.text,
}) => {
	const alert = useSelector((state) => state.alert);
	const deal = useSelector((state) => state.data.deal);
	const classes = styles();
	const dispatch = useDispatch();

	useEffect(() => {
		//confirmation turn off after server after response--> systemAction setUserRole()
		if (alert.confirmationForm === "dealFacebookPost" && alert.confirmed) {
			let message = alert.inputFieldValue ? alert.inputFieldValue : null;
			dispatch(postDealToFacebook(deal, message));
			dispatch(clearAlerts());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [alert.confirmed]);

	const handleClick = () => {
		dispatch(
			setAlertWithConfirmation(
				"dealFacebookPost",
				"information",
				"Post this deal to facebook?",
				"Enter your post message",
				true
			)
		);
	};

	return (
		<Grid item xs={12}>
			<Button
				fullWidth
				classname={className}
				size={clsx(menu ? "small" : size)}
				variant={variant}
				color={color}
				aria-label="edit"
				onClick={() => handleClick()}>
				<span className={classes.iconWrapper}>
					<img
						className={classes.icon}
						src={FacebookIcon}
						alt="facebook icon"
					/>
				</span>
				<span className={classes.text}>{text}</span>
			</Button>
		</Grid>
	);
};

FacebookPostButton.propTypes = {
	className: PropTypes.string,
	dealId: PropTypes.string.isRequired,
	menu: PropTypes.any,
	size: PropTypes.any,
	text: PropTypes.string,
	variant: PropTypes.string,
	color: PropTypes.string,
};

export default FacebookPostButton;
