import React, { useEffect, Fragment } from "react";
import { injectStripe } from "react-stripe-elements";
//Components
import FormGeneral from "../alerts/FormGeneral";
import CardSection from "./CardSection";
import SpinnerButton from "../controls/SpinnerButton";
import BillingInformationForm from "../forms/subscriptions/BillingInformationForm";
import GeneralButton from "../controls/buttons/GeneralButton";
//MUI
import Grid from "@material-ui/core/Grid";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { setErrors } from "../../redux/actions/uiActions";
import {
	createCheckoutPayment,
	setCheckoutVAPackage,
	setCheckoutLoginRedirect,
} from "../../redux/actions/checkoutActions";

import { loadingUI } from "../../redux/actions/uiActions";

//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
import { CircularProgress } from "@material-ui/core";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	responseArea: {
		maxWidth: 494,
	},
}));

const CheckoutBillingCardInformation = (props) => {
	//https://us-central1-rfdprint-101a.cloudfunctions.net/api/subscription/confirm/webhook
	const user = useSelector((state) => state.user.credentials);
	const serverResponse = useSelector((state) => state.subscription.response);
	const loading = useSelector((state) => state.checkout.loading);

	const dispatch = useDispatch();
	const classes = styles();

	useEffect(() => {
		const handleAction = (response) => {
			props.stripe
				.confirmCardSetup(response.payment_intent_client_secret)
				.then((setupIntent) => {
					if (!setupIntent.error) {
						dispatch(
							createCheckoutPayment({
								billing: serverResponse.billing,
								email: serverResponse.email,
								userId: serverResponse.userId,
								assistancePackage:
									serverResponse.assistancePackage,
								newPaymentMethod:
									serverResponse.newPaymentMethod,
								setupIntentConfirmed: true,
							})
						);
					} else {
						dispatch(
							setErrors({
								general:
									"an unknown error occurred, please try again in a bit.",
							})
						);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		};
		async function handleServerResponse(response) {
			if (response.error) {
				console.log(response.error);
				// Show error from server on payment form
			} else if (response.requires_action) {
				// Use Stripe.js to handle required card action
				/*var action = intent.next_action;
				if (action && action.type === "redirect_to_url") {
					window.location = action.redirect_to_url.url;
				}*/
				await handleAction(response);
			} else {
				if (Object.entries(serverResponse) > 0) {
					//dispatch(clearServerResponse());
				}
				// Show success message
			}
		}

		//Start
		if (serverResponse) {
			handleServerResponse(serverResponse);
		}
	}, [serverResponse, props.stripe, dispatch]);

	const getBillingInfo = () => {
		const billing = {};
		billing.firstName = document.getElementById("firstName").value;
		billing.lastName = document.getElementById("lastName").value;
		billing.street = document.getElementById("street").value;
		billing.city = document.getElementById("city").value;
		billing.state = document.getElementById("state").value;
		return billing;
	};

	const handleSubmit = (ev) => {
		const billing = getBillingInfo();
		ev.preventDefault();
		props.stripe
			.createPaymentMethod({
				type: "card",
				card: props.elements.getElement("card"),
				billing_details: {
					address: {
						line1: billing.street,
						city: billing.city,
						state: billing.state,
					},
					email: user.email,
					name: `${billing.firstName} ${billing.lastName}`,
				},
				metadata: {
					userId: user.userId,
				},
			})
			.then((token) => {
				//removed plan from data plan: "plan_H82BM0lA7rqnd3",
				const data = {
					userId: user.userId,
					email: user.email,
					assistancePackage: props.assistancePackage,
					newPaymentMethod: token.paymentMethod.id,
					token, //payment method
					billing,
				};

				dispatch(createCheckoutPayment(data));
			})
			.catch((err) => console.log(err));
	};

	const handelCancel = () => {
		dispatch(setCheckoutVAPackage());
		dispatch(setCheckoutLoginRedirect(""));
	};

	return (
		<Fragment>
			<Grid item container className="checkout">
				<Grid item container xs={12}>
					{user.userId && <BillingInformationForm fullWidth />}
					<CardSection className="MyCardElement" />
					<FormGeneral xs={10} className={classes.responseArea} />
				</Grid>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<GeneralButton
							variant="contained"
							color="primary"
							text="Cancel"
							className={classes.button}
							onClick={() => {
								handelCancel();
							}}></GeneralButton>
					</Grid>
					<Grid item xs={12} sm={6}>
						<SpinnerButton
							fullWidth
							size="medium"
							color="primary"
							onClick={(ev) => {
								handleSubmit(ev);
							}}
							className={classes.button}
							variant="contained"
							disabled={loading}>
							Purchase
							{loading && (
								<CircularProgress
									size={30}
									className={classes.spinner}
								/>
							)}
						</SpinnerButton>
					</Grid>
				</Grid>
			</Grid>
		</Fragment>
	);
};

export default injectStripe(CheckoutBillingCardInformation);
