import {
	SET_SYSTEM_USER_ROLE,
	SET_SYSTEM_USERS,
	SET_SYSTEM_USER,
	DELETE_SYSTEM_USER,
	SET_SYSTEM_FILTERS,
	SET_FILTERED_SYSTEM_USERS,
	CLEAR_SYSTEM_USER,
	SET_VERIFICATION_FILE,
	SET_VERIFIED,
	LOADING_SYSTEM_DATA,
	LOADING_SYSTEM_UI,
	STOP_LOADING_SYSTEM_UI,
	STOP_LOADING_SYSTEM_DATA
} from "../types"

const initialState = {
	users: [],
	user: {},
	loading: false,
	loadingUI: false,
	response: {},
	verificationFile: "",
	forms: {},
	filters: {},
	filteredUsers: []
}

let index;

export default function(state = initialState, action) {
	switch (action.type) {

		case LOADING_SYSTEM_DATA:
			return {
				...state,
				loading: true
			};
		case STOP_LOADING_SYSTEM_DATA:
			return {
				...state,
				loading: false
			};
		case LOADING_SYSTEM_UI:
			return {
				...state,
				loadingUI: true
			};
		case STOP_LOADING_SYSTEM_UI:
			return {
				...state,
				loadingUI: false
			};
		case SET_SYSTEM_USERS:
			return {
				...state,
				users: action.payload,
					loading: false
			};
		case DELETE_SYSTEM_USER:
			return {
				...state,
				users: state.users.filter((user) => user.userId !== action.payload),
					user: {},
					loading: false
			};
		case SET_VERIFIED:
			index = state.users.findIndex(
				(user) => user.userId === action.payload.userId
			);
			return {
				...state,
				users: [
						...state.users.slice(0, index),
						{
							...state.users[index],
							verified: action.payload.verified,
							verificationStatus: action.payload.verificationStatus
						},
						...state.users.slice(index + 1)
					],
					user: {
						...state.user,
						verified: action.payload.verified,
						verificationStatus: action.payload.verificationStatus
					},
					loadingUI: false

			};
		case SET_SYSTEM_USER_ROLE:
			index = state.users.findIndex(
				(user) => user.userId === action.payload.userId
			);
			return {
				...state,
				users: [
						...state.users.slice(0, index),
						{
							...state.user,
							...state.user[index],
							roles: {
								...action.payload.claim
							}

						},
						...state.users.slice(index + 1)
					],
					user: {
						...state.user,
						roles: {
							...action.payload.claim
						}
					},
					loading: false

			};
		case SET_SYSTEM_USER:
			return {
				...state,
				user: action.payload,
					loading: false
			};
		case CLEAR_SYSTEM_USER:
			return {
				...state,
				user: {},
					loading: false
			};
		case SET_VERIFICATION_FILE:
			return {
				...state,
				verificationFile: action.payload,
			};
		case SET_FILTERED_SYSTEM_USERS:
			return {
				...state,
				filteredUsers: action.payload
			};
		case SET_SYSTEM_FILTERS:
			return {
				...state,
				filters: {
					...action.payload
				}
			};

		default:
			return state
	}
}
