import {
	SET_CONVERSATIONS,
	SET_CONVERSATION,
	DELETE_CONVERSATION,
	DELETE_MESSAGE,
	EDIT_MESSAGE,
	UPDATING_MESSAGE,
	SET_DIRECT_MESSAGES,
	SET_DIRECT_MESSAGE,
	SET_MESSAGES, //server response
	SET_ERRORS, //server response
	INBOX_LOADING,
	LOADING_UI,
	CLEAR_RESPONSES,
	SET_ALERT
} from "../types"

import axios from "axios"

//Submit message
export const submitMessage = (messageData, disableSetMessages) => dispatch => {
	dispatch({
		type: CLEAR_RESPONSES,
		payload: {}
	})
	dispatch({
		type: LOADING_UI,
		payload: true
	});
	axios.post(`/api/message/send`, messageData)
		.then((res) => {
			dispatch({
				type: SET_DIRECT_MESSAGE, //inbox user message
				payload: res.data.messageData
			})
			if (!disableSetMessages) {
				dispatch({
					type: SET_MESSAGES, //server response
					payload: res.data.message
				})
			}
			dispatch({
				type: LOADING_UI,
				payload: false
			});
		})
		.catch(err => {
			console.log(err)
			dispatch({
				type: SET_ERRORS,
				payload: err.response.data
			})
			dispatch({
				type: LOADING_UI,
				payload: false
			});
		})
}

//Submit message
export const submitMessageReply = (messageData) => dispatch => {
	dispatch({
		type: LOADING_UI,
		payload: true
	});
	axios.post(`/api/message/send/reply`, messageData)
		.then((res) => {
			dispatch({
				type: SET_DIRECT_MESSAGE, //inbox user message
				payload: res.data.messageData
			})
			dispatch({
				type: SET_MESSAGES, //server response
				payload: res.data.message
			})
			dispatch({
				type: LOADING_UI,
				payload: false
			});
		})
		.catch(err => {
			console.log(err)
			dispatch({
				type: SET_ERRORS,
				payload: err.response.data
			})
			dispatch({
				type: LOADING_UI,
				payload: false
			});
		})
}

//Delete message
export const deleteMessage = (messageId) => dispatch => {
	axios.delete(` /api/message/delete/${messageId}`)
		.then((res) => {
			dispatch({
				type: DELETE_MESSAGE,
				payload: res.data
			})
		})
		.catch(err => console.log(err))
}

//Get message
export const getConversations = () => dispatch => {
	dispatch({
		type: INBOX_LOADING,
		payload: true
	})
	axios.get(`/api/conversations`)
		.then((res) => {
			dispatch({
				type: SET_CONVERSATIONS,
				payload: res.data
			})
		})
		.catch(err => {
			console.log(err)
		})
}

//Set conversation
export const setConversation = (conversation, view) => dispatch => {
	let conversationPayload = {};

	if (!conversation) {
		conversationPayload = {
			conversationId: "",
			recipientHandle: "",
			senderHandle: "",
			senderImage: "",
			senderId: "",
			recipientImage: "",
			recipientId: "",
			participants: [],
			messageCount: null,
		};
	} else {
		conversationPayload = conversation
	}

	const payload = {
		conversation: conversationPayload,
		view
	}

	dispatch({
		type: SET_CONVERSATION,
		payload: payload
	})
}

export const deleteConversation = (conversationId) => dispatch => {
	axios.delete(`/api/conversations/delete/${conversationId}`)
		.then((res) => {
			dispatch({
				type: DELETE_CONVERSATION,
				payload: conversationId
			})
			dispatch({
				type: SET_ALERT,
				payload: {
					type: "success",
					title: "Conversation Deleted Succesfully",
					text: res.data.alert.text,
					open: true,
					abortFirstLetterCap: false,
				}
			})
		})
		.catch((err) => {
			dispatch({
				type: SET_ALERT,
				payload: {
					type: "error",
					title: "Conversation Deletion Error",
					text: "an error occured, could not delete conversation",
					open: true,
					abortFirstLetterCap: false,
				}
			})
		})
}

//Get message
export const getMessagesByConversationId = (conversationId) => dispatch => {
	console.log("attempting to get messages")
	dispatch({
		type: INBOX_LOADING,
		payload: true
	})
	axios.get(`/api/messages/${conversationId}`)
		.then((res) => {
			dispatch({
				type: SET_DIRECT_MESSAGES,
				payload: res.data
			})
		})
		.catch(err => {
			console.log(err)
		})
}

//Edit message
export const editMessage = (message) => (dispatch) => {
	dispatch({
		type: UPDATING_MESSAGE,
		payload: message.messageId
	})
	axios.post('/api/deal/message/update', message)

		.then((res) => {
			dispatch({
				type: EDIT_MESSAGE,
				payload: res.data
			})

		})
		.catch(err => {
			console.log(err)
		})
}


//Submit Block Message
export const blockMessage = (message) => (dispatch) => {
	dispatch({
		type: UPDATING_MESSAGE,
		payload: message.messageId
	})
	axios.post('/api/deal/message/block', message)

		.then((res) => {

			dispatch({
				type: EDIT_MESSAGE,
				payload: res.data
			})

		})
		.catch(err => {
			console.log(err)
		})
}
