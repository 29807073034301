import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
//Components
import NavBarMobile from "../components/navigation/mobile/NavBarMobile";
import DealCardSection from "../components/sections/DealCardSection";
import NavBarMainMenu from "../components/navigation/NavBarMainMenu";
import CreateDealFab from "../components/controls/buttons/deals/CreateDealFab";
import AuthProfile from "../components/profile/AuthProfile";
//MUI
import Hidden from "@material-ui/core/Hidden";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { clearUserProfileData } from "../redux/actions/profileActions";
import {
	getDeals,
	setFilteredDeals,
	clearLocation,
} from "../redux/actions/dataActions";
import { redirect } from "../redux/actions/uiActions";
import { setCheckoutLoginRedirect } from "../redux/actions/checkoutActions";
import MainLayout from "../components/layouts/MainLayout";

const DealBoard = () => {
	const user = useSelector((state) => state.user.credentials);

	const dealsRetrieved = useSelector((state) => state.data.retrieved);
	const deals = useSelector((state) => state.data.deals);
	const data = useSelector((state) => state.data);
	const checkoutRedirect = useSelector((state) => state.checkout.redirect);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(clearLocation());
	}, []);

	useEffect(() => {
		dispatch(clearUserProfileData());
		if (data.filterHandle) {
			dispatch(setFilteredDeals(deals, { handle: null }));
		}
		if (!dealsRetrieved) {
			dispatch(getDeals());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, dealsRetrieved]);
	return !checkoutRedirect ? (
		<MainLayout
			sidebar={
				<AuthProfile
					ctaTo={`/profiles/${user.handle}`}
					ctaText="View My Deals"
				/>
			}>
			<NavBarMainMenu filter />
			<DealCardSection
				deals={data.filteredDeals ? data.filteredDeals : deals}
			/>
			<Hidden smDown>
				<CreateDealFab form="deal" args={{ type: "create" }} />
			</Hidden>
			<Hidden mdUp>
				<NavBarMobile filter />
			</Hidden>
		</MainLayout>
	) : (
		<>
			<Redirect to={checkoutRedirect} />
			{dispatch(setCheckoutLoginRedirect(""))}
		</>
	);
};

export default DealBoard;
