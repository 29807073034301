/*Create a SetupIntent
After the SetupIntent is created, attach a payment method and confirm to collect any required
permissions to charge the payment method later.
https://stripe.com/docs/api/setup_intents/create?lang=node
*/

import PropTypes from "prop-types";
import React, { useEffect, Fragment } from "react";
import { injectStripe } from "react-stripe-elements";
import { objectNotEmpty } from "../../util/UtilityFunctions";
//Components
import FormGeneral from "../alerts/FormGeneral";
import CardSection from "../checkout/CardSection";
import SpinnerButton from "../controls/SpinnerButton";
import GeneralLinkButton from "../controls/buttons/GeneralLinkButton";
import CancelSubscriptionButton from "../controls/buttons/subscription/CancelSubscriptionButton";
//MUI
import BillingInformationForm from "../forms/subscriptions/BillingInformationForm";
import Grid from "@material-ui/core/Grid";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { setErrors } from "../../redux/actions/uiActions";
import {
	clearSetupIntent,
	updatePaymentDetails,
	getSubscription,
	cancelSubscription,
	updateBillingInformationDocument,
} from "../../redux/actions/subcriptionActions";
import { loadingUI, toggleFormDialog } from "../../redux/actions/uiActions";

//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
import { CircularProgress } from "@material-ui/core";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	responseArea: {
		maxWidth: 494,
	},
}));

const ChangePaymentMethod = (props) => {
	//https://us-central1-rfdprint-101a.cloudfunctions.net/api/subscription/confirm/webhook
	const user = useSelector((state) => state.user.credentials);
	const subscription = useSelector((state) => state.subscription.data);
	const loading = useSelector((state) => state.subscription.loading);
	const setupIntentResponse = useSelector(
		(state) => state.subscription.setupIntent
	);
	const dispatch = useDispatch();
	const classes = styles();

	useEffect(() => {
		dispatch(getSubscription(user.userId));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const handleAction = (response) => {
			props.stripe
				.confirmCardSetup(response.setup_intent_client_secret)
				.then((intent) => {
					if (!intent.error) {
						dispatch(
							updateBillingInformationDocument(
								setupIntentResponse.newBillingInformation,
								intent
							)
						);
						dispatch(clearSetupIntent());
					} else {
						dispatch(
							setErrors({
								general:
									"an unknown error occurred, please try again in a bit.",
							})
						);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		};

		async function handleServerResponse(response) {
			if (response.error) {
				// Show error from server on payment form
				alert(response.error);
			} else if (response.requires_action) {
				// Use Stripe.js to handle required card action
				/*var action = intent.next_action;
				if (action && action.type === "redirect_to_url") {
					window.location = action.redirect_to_url.url;
				}*/
				await handleAction(response);
			} else {
				if (Object.entries(setupIntentResponse) > 0) {
					//dispatch(clearServerResponse());
				}
				// Show success message
			}
		}

		if (setupIntentResponse) {
			handleServerResponse(setupIntentResponse);
		}
	}, [setupIntentResponse, props.stripe, dispatch]);

	const getBillingInfo = () => {
		const billing = {};
		billing.firstName = document.getElementById("firstName").value;
		billing.lastName = document.getElementById("lastName").value;
		billing.street = document.getElementById("street").value;
		billing.city = document.getElementById("city").value;
		billing.state = document.getElementById("state").value;
		return billing;
	};

	const handleCancel = (ev) => {
		ev.preventDefault();
		if (subscription.status !== "canceled") {
			dispatch(cancelSubscription);
		}
	};

	const handleSubmit = (ev) => {
		ev.preventDefault();
		const billing = getBillingInfo();
		dispatch(loadingUI);
		props.stripe
			.createPaymentMethod({
				type: "card",
				card: props.elements.getElement("card"),
				billing_details: {
					email: user.email,
					address: {
						line1: billing.street,
						city: billing.city,
						state: billing.state,
					},
					name: `${billing.firstName}, ${billing.lastName}`,
				},
				metadata: {
					userId: user.userId,
				},
			})
			.then((token) => {
				dispatch(updatePaymentDetails(token));
			})
			.catch((err) => console.log(err));
	};

	return (
		<Fragment>
			{subscription && subscription.status !== "canceled" ? (
				<div className="checkout">
					<Grid container xs={12}>
						{user.userId && objectNotEmpty(subscription) && (
							<Fragment>
								{objectNotEmpty(subscription) && (
									<BillingInformationForm />
								)}

								<CardSection className="MyCardElement" />
								<FormGeneral
									xs={10}
									className={classes.responseArea}
								/>
							</Fragment>
						)}
					</Grid>

					<Grid container xs={12}>
						<Grid item xs={12} sm={6}>
							<CancelSubscriptionButton
								user={user}
								text="Cancel Subscription"
								className={classes.spinnerButton}
							/>
						</Grid>

						<Grid item xs={12} sm={6}>
							<SpinnerButton
								color="primary"
								onClick={(ev) => {
									handleSubmit(ev);
								}}
								className={classes.button}
								variant="contained"
								disabled={loading}>
								Update
								{loading && (
									<CircularProgress
										size={30}
										className={classes.spinner}
									/>
								)}
							</SpinnerButton>
						</Grid>
					</Grid>
				</div>
			) : (
				<Grid container xs={12} style={{ marginTop: 10 }}>
					<Grid item spacing={2} xs={12}>
						<GeneralLinkButton
							variant="contained"
							color="primary"
							linkTo="/subscriptions"
							text="Activate a New Subscription"
							onClick={() => {
								dispatch(toggleFormDialog());
							}}
						/>
					</Grid>
				</Grid>
			)}
		</Fragment>
	);
};

ChangePaymentMethod.propTypes = {
	elements: PropTypes.shape({
		getElement: PropTypes.func,
	}),
	stripe: PropTypes.shape({
		confirmCardPayment: PropTypes.func,
		confirmCardSetup: PropTypes.func,
		createPaymentMethod: PropTypes.func,
	}),
};

export default injectStripe(ChangePaymentMethod);
