import PropTypes from "prop-types";
import React from "react";
//Components
import DealSpinner from "../controls/DealSpinner";
//MUI Components
import Typography from "@material-ui/core/Typography";
// Redux
import { useSelector } from "react-redux";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
//Dayjs
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat);
dayjs.extend(timezone);
dayjs.tz.guess(); // America/Chicago

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	comment: { marginBottom: 15 },
	commentUserBadge: {
		maxWidth: 125,
		maxHeight: 125,
	},
	commentBody: {
		width: "100%",
	},
	commentData: {
		marginTop: 10,
		marginLeft: 5,
	},
	ownerComment: {
		backgroundColor: "#a4ecb5",
		color: "#fff",
	},
	ownerLabel: {
		marignBottom: "-40px",
	},
	editCommentSpinnerParent: {
		width: "100%",
		top: 25,
		left: "-10%",
		bottom: 0,
		right: 0,
	},
}));

const CommentBody = ({
	comment,
	matchDealOwnerComment,
	matchLoggedInUserDealOwner,
}) => {
	const { commentId, body, createdAt } = comment;
	const updating = useSelector((state) => state.commentData.updating);
	const classes = styles();

	const commentBody = (
		<Typography className={classes.commentBody} variant="body1">
			{body}
		</Typography>
	);

	const match = updating.commentId === comment.commentId;

	return (
		<div key={commentId} className={classes.commentData}>
			{!updating.active && (
				<Typography variant="body2" color="textSecondary">
					{dayjs(createdAt).format("MMMM D, YYYY h:mm A (z)")}
				</Typography>
			)}

			{updating.active && match ? (
				<DealSpinner
					loading
					size={45}
					color="inherit"
					spinnerParentClassName={classes.editCommentSpinnerParent}
				/>
			) : (
				commentBody
			)}

			{matchDealOwnerComment && !updating.active && (
				<Typography
					className={classes.ownerLabel}
					align="left"
					display="block"
					variant="caption"
					color="inherit">
					(Comment by deal maker)
				</Typography>
			)}
			{comment.blocked && !updating.active && (
				<Typography variant="caption" color="error">
					{matchLoggedInUserDealOwner
						? "Blocked by You (not visible to other users)"
						: "Blocked by Deal Maker (not visible to other users)"}
				</Typography>
			)}
		</div>
	);
};

CommentBody.propTypes = {
	comment: PropTypes.shape({
		blocked: PropTypes.bool,
		commentId: PropTypes.string.isRequired,
		body: PropTypes.string.isRequired,
		createdAt: PropTypes.string.isRequired,
	}),
	matchDealOwnerComment: PropTypes.bool,
	matchLoggedInUserDealOwner: PropTypes.bool,
};

export default CommentBody;
