import React, { useEffect } from "react";
import { secondsToISOString } from "../../../util/UtilityFunctions";
import { captializeFirstLetter } from "../../../util/UtilityFunctions";
import TextTableGrid from "../../../components/controls/TextTableGrid";
import DealSpinner from "../../../components/controls/DealSpinner";
//MUI Components
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
//Redux
import { useSelector } from "react-redux";
import { setGetVerificationFile } from "../../../redux/actions/systemActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
//Dayjs
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);
dayjs.tz.guess();

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	subscriptionDetail: {
		padding: "5px 10px 5px 10px",
		minHeight: 250,
	},
}));

const SubscriptionDetailCard = () => {
	const subscription = useSelector((state) => state.subscription.data);
	const latestInvoice = useSelector(
		(state) => state.subscription.latestInvoice
	);
	const subscriptionLoading = useSelector(
		(state) => state.subscription.loading
	);
	const classes = styles();

	const insertDecimal = (num) => {
		return (num / 100).toFixed(2);
	};

	useEffect(() => {
		setGetVerificationFile(subscription.userId);
	});

	const rows = [
		{
			label: "Subscription Status",
			data:
				subscription.status && subscription.status === "trialing"
					? "Active"
					: captializeFirstLetter(subscription.status),
			alert: subscription.status === "canceled" ? true : false,
		},
		{
			label: "Subscription Date",
			data: dayjs(secondsToISOString(subscription.createdAt)).format(
				"MMM DD YYYY"
			),
		},
		{
			label: "Subscription Time",
			data: dayjs(secondsToISOString(subscription.createdAt)).format(
				"h:mm a"
			),
		},
		{
			seperator: true,
			label: "Last Invoice Created",
			data: dayjs(secondsToISOString(latestInvoice.createdAt)).format(
				"MMM DD YYYY"
			),
		},
		{
			label: "Last Invoice Amount",
			data: `$${insertDecimal(latestInvoice.amountDue)}`,
		},
		{
			label: "Last Invoice Status",
			data: captializeFirstLetter(latestInvoice.invoiceStatus),
		},
		{
			label: "Charge Status",
			data: captializeFirstLetter(latestInvoice.invoiceChargeStatus),
			alert:
				latestInvoice.invoiceChargeStatus === "failed" ? true : false,
		},
		{
			label: "Refunded",
			data: latestInvoice.latestInvoiceChargeRefunded ? "Yes" : "No",
			alert: latestInvoice.latestInvoiceChargeRefunded,
		},
		{
			label: "Payment Atempts",
			data: `${latestInvoice.attemptCount} of 4`,
		},
	];

	let detail =
		Object.entries(subscription).length > 0 ? (
			<TextTableGrid rows={rows} labelWidth={7} dataWidth={5} />
		) : (
			<>
				<Grid container justify="center" alignItems="center">
					<Grid item xs={12} style={{ marginTop: 10 }}>
						<Typography paragraph variant="h6">
							Error Loading Subscription
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography paragraph variant="body2">
							Contact support of this continues.
						</Typography>
					</Grid>
				</Grid>
			</>
		);
	return (
		<Grid
			item
			xs={12}
			className={classes.subscriptionDetail}
			component={Card}>
			{!subscriptionLoading ? (
				<Grid container spacing={0}>
					{detail}
				</Grid>
			) : (
				<Grid item style={{ height: "100%" }}>
					<DealSpinner loading size={75} />
				</Grid>
			)}
		</Grid>
	);
};

export default SubscriptionDetailCard;
