import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";
//MUI
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
//Redux
import { useDispatch } from "react-redux";
import { toggleFormDialog } from "../../../../redux/actions/uiActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	dialogContent: {
		overflow: "hidden",
	},
	toolTipButton: { color: "white" },
	icon: {
		marginRight: 5,
		maxHeight: 19,
		marginTop: "-3px",
	},
}));

const defaultProps = {
	btnVariant: "outlined",
	btnColor: "secondary",
	btnText: "Click",
	btnSize: "medium",
	maxWidth: "md",
};

const FormOpenButton = ({
	form,
	menu,
	args,
	className,
	btnSize = defaultProps.btnSize,
	btnVariant = defaultProps.btnVariant,
	btnColor = defaultProps.btnColor,
	btnText = defaultProps.btnText,
	maxWidth = defaultProps.maxWidth,
	children,
	icon,
}) => {
	const classes = styles();
	const dispatch = useDispatch();

	const handleOpen = () => {
		dispatch(toggleFormDialog(true, form, args));
	};

	return (
		<Grid item>
			<Button
				fullWidth
				size={clsx(menu ? "small" : btnSize)}
				className={clsx(classes.buttonColorWhite, className)}
				variant={btnVariant}
				color={btnColor}
				onClick={() => handleOpen()}>
				<div className={icon ? classes.icon : null}>{icon}</div>{" "}
				{btnText}
			</Button>
		</Grid>
	);
};

FormOpenButton.propTypes = {
	args: PropTypes.any,
	btnColor: PropTypes.any,
	btnSize: PropTypes.any,
	btnText: PropTypes.string.isRequired,
	btnVariant: PropTypes.any,
	children: PropTypes.any,
	className: PropTypes.any,
	form: PropTypes.any,
	icon: PropTypes.any,
	maxWidth: PropTypes.any,
	menu: PropTypes.any,
};

export default FormOpenButton;
