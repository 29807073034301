import React from "react";
import clsx from "clsx";
//MUI Components
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	userId: {
		textAlign: "center",
	},
	adminUser: {
		color: theme.palette.information.dark,
	},
	adminLabel: {
		color: theme.palette.information.dark,
	},
	refferal: {
		color: theme.palette.information.dark,
	},
}));

const SystemCardDetail = ({ user }) => {
	const classes = styles();

	const checkAdminRole = (systemRoles) => {
		let isAdmin = false;
		if (systemRoles) {
			if (systemRoles.admin) {
				isAdmin = systemRoles.admin;
			}
		}
		return isAdmin;
	};

	return (
		<Grid item xs={12}>
			<hr />
			<Grid item>
				<Typography
					className={
						user.verified
							? classes.verifiedText
							: classes.notVerifiedText
					}>
					{checkAdminRole(user.roles) ? (
						<span
							className={clsx(
								classes.adminLabel,
								classes.boldText
							)}>
							Admin User{" "}
						</span>
					) : null}
					{user.verified ? `Premium` : ``}
				</Typography>
				{user.type === "wholesaler" && user.refferedByName ? (
					<>
						<Typography
							display="inline"
							className={clsx(
								classes.refferal,
								classes.boldText
							)}>
							Referred By:
						</Typography>
						<Typography
							display="inline"
							className={classes.refferal}>
							{" "}
							{user.refferedByName}
						</Typography>
					</>
				) : null}
			</Grid>
			<Typography className={classes.userId} variant="caption">
				ID:{user.userId}
			</Typography>
		</Grid>
	);
};

export default SystemCardDetail;
