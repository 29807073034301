import React from "react";
import inlineLogo from "../../images/website-logo@177.png";
import clsx from "clsx";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	logo: {
		maxWidth: "100%",
		display: "block",
		margin: "0 auto",
	},
	loginForms: {
		marginTop: "-20px",
	},
	large: {
		width: 400,
		maxWidth: "100%",
	},
	small: {
		maxWidth: 200,
	},
}));

const LogoInline = () => {
	const classes = styles();
	return (
		<img
			className={clsx(classes.logo, classes.loginForms, classes.large)}
			src={inlineLogo}
			alt="logo"></img>
	);
};

export default LogoInline;
