import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(3),
	},
}));

const GeneralFormControlTextBox = ({ checked, onChange, label, name }) => {
	return (
		<FormControlLabel
			control={
				<Checkbox checked={checked} onChange={onChange} name={name} />
			}
			label={label}
		/>
	);
};
export default GeneralFormControlTextBox;
