import React, { useEffect, Fragment } from "react";
import { injectStripe } from "react-stripe-elements";
//Components
import FormGeneral from "../alerts/FormGeneral";
import CardSection from "../checkout/CardSection";
import SpinnerButton from "../controls/SpinnerButton";
import BillingInformationForm from "../forms/subscriptions/BillingInformationForm";
//MUI
import Grid from "@material-ui/core/Grid";
//Redux
import { useSelector, useDispatch } from "react-redux";

import {
	createSubscription,
	createSubscriptionDocument,
	clearServerResponse,
} from "../../redux/actions/subcriptionActions";
import { loadingUI, setErrors } from "../../redux/actions/uiActions";

//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
import { CircularProgress } from "@material-ui/core";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	responseArea: {
		maxWidth: 494,
	},
}));

const CheckoutFormSubscription = (props) => {
	//https://us-central1-rfdprint-101a.cloudfunctions.net/api/subscription/confirm/webhook
	const user = useSelector((state) => state.user.credentials);
	const serverResponse = useSelector((state) => state.subscription.response);
	const loading = useSelector((state) => state.subscription.loading);

	const dispatch = useDispatch();
	const classes = styles();

	useEffect(() => {
		const handleAction = (response) => {
			props.stripe
				.confirmCardPayment(response.payment_intent_client_secret)
				.then((intent) => {
					if (!intent.error) {
						const updatedSubscription = {
							...serverResponse.newSubscription,
							status: "active",
						};
						dispatch(
							createSubscriptionDocument(
								updatedSubscription,
								user
							)
						);
						dispatch(clearServerResponse());
					} else {
						dispatch(
							setErrors({
								general:
									"an unknown error occurred, please try again in a bit.",
							})
						);
						dispatch(clearServerResponse());
					}
				})
				.catch((err) => {
					console.log(err);
				});
		};
		async function handleServerResponse(response) {
			if (response.error) {
				console.log(response.error);
				// Show error from server on payment form
			} else if (response.requires_action) {
				// Use Stripe.js to handle required card action
				/*var action = intent.next_action;
				if (action && action.type === "redirect_to_url") {
					window.location = action.redirect_to_url.url;
				}*/
				await handleAction(response);
			} else {
				if (Object.entries(serverResponse) > 0) {
					//dispatch(clearServerResponse());
				}
				// Show success message
			}
		}

		//Start
		if (serverResponse) {
			handleServerResponse(serverResponse);
		}
	}, [serverResponse, props.stripe, dispatch]);

	const getBillingInfo = () => {
		const billing = {};
		billing.firstName = document.getElementById("firstName").value;
		billing.lastName = document.getElementById("lastName").value;
		billing.street = document.getElementById("street").value;
		billing.city = document.getElementById("city").value;
		billing.state = document.getElementById("state").value;
		return billing;
	};

	const handleSubmit = (ev) => {
		const billing = getBillingInfo();
		ev.preventDefault();
		dispatch(loadingUI);
		props.stripe
			.createPaymentMethod({
				type: "card",
				card: props.elements.getElement("card"),
				billing_details: {
					address: {
						line1: billing.street,
						city: billing.city,
						state: billing.state,
					},
					email: user.email,
					name: `${billing.firstName} ${billing.lastName}`,
				},
				metadata: {
					userId: user.userId,
				},
			})
			.then((token) => {
				//removed plan from data plan: "plan_H82BM0lA7rqnd3",
				const data = {
					user,
					userId: user.userId,
					email: user.email,
					service: props.service,
					token, //payment method
					billing,
				};

				dispatch(createSubscription(data, user));
			})
			.catch((err) => console.log(err));
	};

	return (
		<Fragment>
			<div className="checkout">
				<Grid container xs={12}>
					{user.userId && <BillingInformationForm />}
					<CardSection className="MyCardElement" />
					<FormGeneral xs={10} className={classes.responseArea} />
				</Grid>

				<SpinnerButton
					color="primary"
					onClick={(ev) => {
						handleSubmit(ev);
					}}
					className={classes.button}
					variant="contained"
					disabled={loading}>
					Purchase
					{loading && (
						<CircularProgress
							size={30}
							className={classes.spinner}
						/>
					)}
				</SpinnerButton>
			</div>
		</Fragment>
	);
};

export default injectStripe(CheckoutFormSubscription);
