import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";
//Component
import ContactDealMakerButton from "../controls/buttons/inbox/ContactDealMakerButton";
import UserBadge from "./UserBadge";
import UserTypeBadge from "./UserTypeBadge";
// Redux
import { useSelector } from "react-redux";
//MUI Components
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
//Dayjs
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);
dayjs.tz.guess();

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	card: {
		position: "relative",
		display: "flex",
	},
	cardReview: {
		backgroundColor: "rgba(81,196,108,0.15)",
	},
	cardCanceled: { backgroundColor: "rgba(255,0,0,0.15)" },
	userBadge: {
		marginTop: 0,
	},
	text: {
		display: "block",
		margin: "0 auto",
		textAlign: "center",
	},
	textBold: {
		fontWeight: 700,
	},
}));

const UserBadgeCard = ({
	user,
	noButtons,
	badgeFullwidth,
	publicUser,
	publicProfile,
	smallBadge,
	showMsgBtn,
	elevation,
}) => {
	const authUser = useSelector((state) => state.user.credentials);
	const classes = styles();

	return (
		<Grid
			item
			elevation={elevation}
			className={classes.formBackground}
			container
			xs={12}
			component={Card}>
			{user.verified && (
				<UserTypeBadge
					badgeCard
					user={user}
					className={classes.typeBadge}
				/>
			)}
			<Grid
				className={clsx(classes.card)}
				component={CardContent}
				container
				spacing={1}>
				<Grid item xs={6}>
					<UserBadge
						shadow
						smallBadge={smallBadge}
						className={classes.userBadge}
						noType
						user={user}
						profileCard
						badgeFullwidth={badgeFullwidth}
						marginTop={0}
					/>
				</Grid>
				<Grid item container xs={6}>
					<Grid item xs={12}>
						<Typography
							classes={{
								root: clsx(classes.text, classes.textBold),
							}}
							variant="caption"
							color="primary">
							User Type:
						</Typography>
						<UserTypeBadge hideVerified user={user} />
					</Grid>
					<Grid item xs={12}>
						<Typography
							classes={{
								root: clsx(classes.text, classes.textBold),
							}}
							variant="caption"
							color="primary">
							Date Joined:
						</Typography>
						<Grid item component={Paper} className={classes.border}>
							<Typography
								classes={{
									root: classes.text,
								}}
								variant="caption"
								color="primary">
								{`${dayjs(user.createdAt).format("MMM YYYY")}`}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				{authUser.userId !== user.userId && showMsgBtn && (
					<ContactDealMakerButton user={user} />
				)}
			</Grid>
		</Grid>
	);
};

UserBadgeCard.propTypes = {
	badgeFullwidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	elevation: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	noButtons: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	publicProfile: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	publicUser: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	showMsgBtn: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	smallBadge: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	user: PropTypes.shape({
		createdAt: PropTypes.string,
		userId: PropTypes.string,
		verified: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	}),
};

export default UserBadgeCard;
