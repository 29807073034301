import React from "react";
//Components
import PrivacySection from "../components/sections/PrivacySection";
import AuthProfile from "../components/profile/AuthProfile";
import MainLayout from "../components/layouts/MainLayout";
import NavBarMainMenu from "../components/navigation/NavBarMainMenu";
import NavBarMobile from "../components/navigation/mobile/NavBarMobile";
//MUI
import Hidden from "@material-ui/core/Hidden";
// Redux
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";

const Privacy = () => {
	const user = useSelector((state) => state.user.credentials);

	const authProfile = (
		<AuthProfile
			user={user}
			ctaTo={`/profiles/${user.handle}`}
			ctaText="View My Deals"
		/>
	);
	document.title = `Page Not Found`;
	return (
		<MainLayout sidebar={authProfile}>
			<Hidden smDown>
				<NavBarMainMenu />
			</Hidden>
			<Typography color="secondary">
				<Typography align="center" variant="h2">
					404
				</Typography>
				<Typography align="center" variant="h1" component="p">
					Page Not Found
				</Typography>
				<hr />
				<Typography align="center" variant="body2" component="p">
					The specified file was not found on this website. Please
					check the URL for mistakes and try again.
				</Typography>
			</Typography>
			<Hidden mdUp>
				<NavBarMobile />
			</Hidden>
		</MainLayout>
	);
};
export default Privacy;
