import React, { Fragment } from "react";
//MUI
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
//Redux
import { useDispatch } from "react-redux";
import { toggleNavDrawer } from "../../../../redux/actions/uiActions";

const ProfileSettingsButton = () => {
	const dispatch = useDispatch();
	const handleClick = () => {
		dispatch(toggleNavDrawer(true, "profileMenu"));
	};

	return (
		<Fragment>
			<IconButton
				onClick={handleClick}
				edge="start"
				color="inherit"
				aria-label="open menu">
				<SettingsIcon />
			</IconButton>
		</Fragment>
	);
};

export default ProfileSettingsButton;
