import React, { useEffect } from "react";
//Components
import InboxNavBar from "../components/navigation/InboxNavBar";
import NavBarMobile from "../components/navigation/mobile/NavBarMobile";
import InboxSection from "../components/sections/InboxSection";
import MainLayout from "../components/layouts/MainLayout";
import AuthProfile from "../components/profile/AuthProfile";
//MUI
import Hidden from "@material-ui/core/Hidden";
// Redux
import { useSelector } from "react-redux";

const Inbox = () => {
	const authUser = useSelector((state) => state.user.credentials);
	const conversations = useSelector((state) => state.inbox.conversations);

	useEffect(() => {
		//dispatch(getConversations());
		// react-hooks/exhaustive-deps
	}, []);

	const authProfile = (
		<AuthProfile
			ctaTo={`/profiles/${authUser.handle}`}
			ctaText="View My Deals"
		/>
	);

	return (
		<MainLayout id="accounts-page" sidebar={authProfile}>
			<Hidden smDown>
				<InboxNavBar />
			</Hidden>
			{authUser ? (
				conversations && <InboxSection conversations={conversations} />
			) : (
				<div>Not Authorized</div>
			)}
			<Hidden mdUp>
				<NavBarMobile />
			</Hidden>
		</MainLayout>
	);
};

export default Inbox;
