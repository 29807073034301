export const assistanceCategories = [
	{
		name: "Real Estate Virtual Assistance Package",
		subtitle: null,
		categoryId: "general_category",
		features: [
			"1 Virtual Assistant",
			"Cold Calling",
			"Appointment Setting",
			"SMS Blasting",
			"Email Blasting",
			"Pulling Out of Property Lists",
			"Processing of Bulk Skip Tracing",
			"Uploading Lists to the Dialer",
			"CRM Management",
			"Run Comparable Market Analysis",
			"Calculate After Repair Value",
			"Calculate Maximum Allowable Offer",
			"Navigate County Tax Record",
		],
	},
	{
		name: "Test Packages",
		subtitle: null,
		categoryId: "test_category",
		features: [
			"1 Virtual Assistant",
			"Cold Calling",
			"Appointment Setting",
			"SMS Blasting",
			"Email Blasting",
			"Pulling Out of Property Lists",
			"Processing of Bulk Skip Tracing",
			"Uploading Lists to the Dialer",
			"CRM Management",
			"Run Comparable Market Analysis",
			"Calculate After Repair Value",
			"Calculate Maximum Allowable Offer",
			"Navigate County Tax Record",
		],
	},
	/*{
		name: "Real Estate Virtual Assistance  Package",
		subtitle: "+ Mojo Triple Dialer + VOIP with selected area code",
		categoryId: "mojo_voip_category",
		features: [
			"1 Virtual Assistant",
			"Cold Calling",
			"Appointment Setting",
			"SMS Blasting",
			"Email Blasting",
			"Pulling Out of Property Lists",
			"Processing of Bulk Skip Tracing",
			"Uploading Lists to the Dialer",
			"CRM Management",
			"Run Comparable Market Analysis",
			"Calculate After Repair Value",
			"Calculate Maximum Allowable Offer",
			"Navigate County Tax Records",
		],
	},*/
	//{
	//	name: "Real Estate Virtual Assistance",
	//	subtitle: "SMS Blasting/Admin Tasks Only Package",
	//	categoryId: "sms_category",
	//	features: [
	//		"1 Virtual Assistant",
	//		"Cold Calling",
	//		"SMS Follow Up",
	//		"SMS Replies",
	//	],
	//},
	//{
	//	name: "Acquisitions  and Dispositions Managers   Packages",
	//	subtitle: "SMS Blasting/Admin Tasks Only Package",
	//	categoryId: "acquisitions_category",
	//	noIcon: true,
	//	features: [
	//		"1 Acquisitions & Dispositions Manager",
	//		"•Coordinating and scheduling conference calls, meetings and conference rooms.",
	//		"• Maintaining manager’s calendar.",
	//		"• Communicating information.",
	//		"• Fulfilling information requests.",
	//		"• Managing detailed checklists.",
	//		"• Printing items, scanning documents, procuring signatures.",
	//		"• Binding information for meetings.",
	//		"• Calling properties for market surveys.",
	//		"• Helping with due diligence when under contract on a deal.",
	//		"• Preparing real estate transaction presentations, packages and analyses for the transaction team, the internal investment committee, investors, partners and lenders.",
	//		"• Gathering market information including sales and rent comparable and other underwriting assumptions.",
	//		"• Coordinating due diligence activities for new acquisitions, including obtaining third-party reports and studies.",
	//		"• Organizing files, planning property inspections and managing checklists.",
	//		"• Assisting the finance team to fulfill information requests.",
	//		"• Assisting with transaction closings, including coordinating legal document preparation.",
	//		"• Coordinating the asset disposition process, including the broker, marketing and due diligence process with buyer, fulfilling information requests.",
	//	],
	//},
];
