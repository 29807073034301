import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
//Components
import PriceFeatureGrid from "../controls/PriceFeatureGrid";
import { assistanceCategories } from "../virtual-assistant/VACategories";
//MUI
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { setCheckoutVAPackage } from "../../redux/actions/checkoutActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	spinner: {
		marginTop: 50,
	},

	productColor: { color: theme.palette.primary.dark },
	cardHeader: {
		color: "#fff",
		textAlign: "center",
		backgroundColor: theme.palette.primary.main,
		paddingBottom: 5,
		marginBottom: 0,
		paddingTop: 5,
		marginTop: 0,
	},
	cardSubheaderText: {
		color: "#fff",
	},
	description: {
		fontSize: 10,
	},
}));
const VirtualAssistantProductCard = ({
	assistancePackage,
	hideCTA,
	showMaxNumberOfFeature,
}) => {
	const dispatch = useDispatch();
	const classes = styles();

	const {
		name,
		price,
		descriptionPrimary,
		descriptionSecondary,
		CTAtext,
		categoryId,
	} = assistancePackage.metadata;

	const { unit_amount } = assistancePackage;

	const handleClick = (selectedPackage) => {
		dispatch(setCheckoutVAPackage(selectedPackage));
	};

	const packageCategory = assistanceCategories.filter(
		(category) => category.categoryId === categoryId
	);

	let features = [];
	if (packageCategory[0].features) {
		features = packageCategory[0].features;
	}

	return (
		<Grid item>
			<Card className={clsx(classes.root, classes.productColor)}>
				<CardActionArea>
					<CardHeader
						classes={{
							root: classes.cardHeader,
							subheader: classes.cardSubheaderText,
						}}
						title={assistancePackage.product.name}
						subheader={`$${unit_amount / 100}`}
					/>
					<CardContent classes={{ root: classes.cardContent }}>
						<Typography className={classes.description}>
							<Typography
								align="center"
								gutterBottom
								variant="subtitle"
								component="h2">
								{descriptionPrimary}
							</Typography>
							<Typography
								align="center"
								gutterBottom
								variant="subtitle"
								component="h2">
								{descriptionSecondary}
							</Typography>
						</Typography>
						<Typography
							variant="body2"
							color="textSecondary"
							component="p">
							{features && (
								<PriceFeatureGrid
									features={features}
									showMaxNumberOfFeature={
										showMaxNumberOfFeature
									}
									labelWidth={7}
									dataWidth={5}
								/>
							)}
						</Typography>
					</CardContent>
				</CardActionArea>
				{!hideCTA && (
					<CardActions>
						<Button
							className={classes.productColor}
							variant="outlined"
							size="small"
							onClick={() => handleClick(assistancePackage)}>
							{CTAtext}
						</Button>
					</CardActions>
				)}
			</Card>
		</Grid>
	);
};

export default VirtualAssistantProductCard;
