import React from "react";
import clsx from "clsx";

//MUI
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
const styles = makeStyles((theme) => ({
	...theme.customStyles,

	addressText: {
		backgroundColor: "rgba(81,196,108,.6)",
		//backgroundColor: "rgba(255,255,255,.5)",
		color: "#fff",
		textShadow: "1px 1px 1px #000000",
		borderRadius: 4,
		padding: "3px 6px",
		marginBottom: 5,
		position: "relative",
		minHeight: 30,
		bottom: 40,
		marginBottom: "-35px",
	},
}));

function DealAddresses({ dealAddress }) {
	const { city, street, state, zipcode } = dealAddress;
	const classes = styles();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<Grid
			container
			justify="center"
			spacing={1}
			elevation={1}
			className={clsx(classes.addressText)}
			component={Paper}>
			<Grid item>
				<Typography variant={isSmallScreen && "caption"}>
					{street}
				</Typography>
			</Grid>
			<Grid item>
				<Typography
					display="inline"
					variant={isSmallScreen && "caption"}>
					{city ? city : null}
				</Typography>
				<Typography
					display="inline"
					variant={isSmallScreen && "caption"}>
					{state ? `, ${state}` : null}
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant={isSmallScreen && "caption"}>
					{zipcode ? zipcode : null}
				</Typography>
			</Grid>
		</Grid>
	);
}

export default DealAddresses;
