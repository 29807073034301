import React, { Fragment } from "react";
import clsx from "clsx";

//Icons
import userIcon from "../../images/svg/icon-user.svg";
import locationIcon from "../../images/svg/icon-location.svg";
import websiteIcon from "../../images/svg/icon-website.svg";
//MUI
import Paper from "@material-ui/core/Paper";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

const styles = makeStyles((theme) => ({
	...theme.customStyles,

	privateProfilePaper: {
		padding: 10,
		backgroundColor: theme.palette.primary.main,
	},
	privateProfileFields: {
		color: theme.palette.accent.main,
	},
	publicProfilePaper: {
		padding: 10,
		backgroundColor: theme.palette.secondary.main,
	},
	publicProfileFields: {
		color: theme.palette.primary.main,
	},
	viewingPublicProfileText: {
		textAlign: "center",
		padding: 5,
	},
	sidebarButtonPublic: {
		color: theme.palette.primary.main,
	},
	profileInnerBorder: {
		borderColor: theme.palette.primary.main,
		boderWidth: 1,
		borderStyle: "solid",
		borderRadius: 4,
		padding: 3,
	},
	removeBoxShadow: { boxShadow: "none" },
	profileDetails: {
		textAlgin: "right",
	},
	profileFields: {
		padding: 10,
	},
	profileFieldText: {
		color: theme.palette.accent.main,
	},
	toolTipButton: { color: "white" },
	textBackground: {
		backgroundColor: "white",
		width: "100%",
	},
	profileFieldSeparator: {
		width: "100%",
		borderWidth: 0.1,
		border: "dotted",
		borderBottom: "1px solid rgba(0,0,0,0.2)",
		marginBottom: 10,
	},
	imageWrapper: {
		marginBottom: 20,
	},
	icon: {
		verticalAlign: "middle",
		marginRight: 10,
	},
	userIcon: {
		borderRadius: "50%",
		width: 25,
	},
	infoIcon: {
		margin: "0 auto",
		textAlign: "center",
		display: "block",
	},
}));

const ProfileDetails = ({ user, publicProfile }) => {
	const classes = styles();
	const { handle, location, website } = user;
	const getIcon = (icon) => (
		<img
			src={icon}
			className={clsx(classes.icon, classes.userIcon)}
			alt="profile icon"
		/>
	);

	return (
		<div className={classes.profileDetails}>
			<Paper
				className={clsx(
					classes.profileFields,
					publicProfile ? classes.removeBoxShadow : null
				)}>
				<Typography
					variant="body2"
					className={classes.profileFieldText}>
					{/*<AccountCircleIcon className={classes.icon} />*/}
					{getIcon(userIcon)}
					{handle}
				</Typography>

				<hr className={classes.profileFieldSeparator} />
				{location && (
					<Fragment>
						<Typography className={classes.profileFieldText}>
							{getIcon(locationIcon)}
							{location}
						</Typography>
						<hr className={classes.profileFieldSeparator} />
					</Fragment>
				)}
				{website && (
					<Fragment>
						<Typography className={classes.profileFieldText}>
							{getIcon(websiteIcon)}
							{website}
						</Typography>
						<hr className={classes.profileFieldSeparator} />
					</Fragment>
				)}
			</Paper>
		</div>
	);
};

export default ProfileDetails;
