import React, { useState, useEffect } from "react";
import clsx from "clsx";
//Components
import { DealTextField } from "../../controls/DealTextFields";
import UserBadgeCard from "../../badges/UserBadgeCard";
//Form Components
import FormActionArea from "../components/FormActionArea";
//MUI
import Grid from "@material-ui/core/Grid";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { submitMessage } from "../../../redux/actions/inboxActions";
import {
	toggleFormDialog,
	clearResponses,
} from "../../../redux/actions/uiActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	button: {
		float: "right",
	},
	toolTipButton: { color: "white" },
}));

const SendMessageForm = () => {
	//dialog.args set by ContactDealMakerButton
	const dialog = useSelector((state) => state.UI.dialog);
	const loading = useSelector((state) => state.UI.loading);
	const deal = useSelector((state) => state.data.deal);
	const errors = useSelector((state) => state.UI.responses.errors);

	const [input, setInput] = useState({
		imageUrl: dialog.args.imageUrl,
		handle: dialog.args.handle,
		userId: dialog.args.userId,
		type: dialog.args.type,
		verified: dialog.args.verified,
		checked: true,
		body: "",
	});
	const classes = styles();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(clearResponses());
	}, []);

	const handleInputChange = (e) => {
		setInput({
			...input,
			[e.currentTarget.name]: e.currentTarget.value,
		});
	};

	const handleCancel = () => {
		dispatch(toggleFormDialog(false, null));
	};

	const handleSubmit = () => {
		dispatch(clearResponses());
		if (input.checked) {
			input.deal = {
				dealId: deal.dealId,
				title: deal.title,
				imageUrl: deal.imageUrl,
			};
		}
		dispatch(submitMessage(input));
		setInput({ ...input, body: "" });
	};

	return (
		<Grid container alignItems="center" justify="center" spacing={2}>
			<Grid item xs={8}>
				<UserBadgeCard
					elevation={10}
					user={{
						imageUrl: dialog.args.imageUrl,
						handle: dialog.args.handle,
						userId: dialog.args.userId,
						type: dialog.args.type,
						verified: dialog.args.verified,
					}}
				/>
			</Grid>
			<Grid id="col1" item container spacing={2}>
				{deal.dealId && (
					<Grid item>
						<input
							type="checkbox"
							name="checked"
							value={input.checked}
							checked={input.checked}
							className={clsx(classes.chipCheckBox)}
							onChange={() => {
								setInput({
									...input,
									checked: !input.checked,
								});
							}}
						/>
						<Typography display="inline">
							Include a link to the deal in the note?
						</Typography>
					</Grid>
				)}
				<DealTextField
					fullWidth
					name="body"
					type="text"
					label="Note"
					multiline
					rows="3"
					placeholder={`Leave a short note for ${input.handle}`}
					className={classes.textField}
					value={input.body}
					onChange={(e) => handleInputChange(e)}
					helperText={errors.body ? errors.body : null}
					error={errors.body ? true : false}
				/>
			</Grid>
			{/*<Grid item xs={12}>
				<SpinnerButton
					loading={loading}
					fullWidth
					variant="contained"
					color="primary"
					onClick={() => handleSubmit()}>
					Submit
				</SpinnerButton>
	</Grid>*/}
			<Grid item xs={12}>
				<FormActionArea
					loading={loading}
					hideCancel
					onCancel={() => handleCancel()}
					cancelBtnText={"Cancel"}
					onClick={() => handleSubmit()}
					actionBtnText={"Send"}
				/>
			</Grid>
		</Grid>
	);
};

export default SendMessageForm;
