import React, { useState, useEffect } from "react";
// Components
import SpinnerButton from "../controls/SpinnerButton";
//MUI Component
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { submitComment } from "../../redux/actions/commentActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	messageArea: { textAlign: "center", marginBottom: 20 },
	submitButton: {
		textAlign: "center",
		display: "block",
		margin: "0 auto",
		width: "100%",
	},
	commentField: {
		marginBottom: theme.spacing(2),
	},
}));

const CommentSubmitForm = ({ documentType, documentId }) => {
	const deal = useSelector((state) => state.data.deal);
	const errors = useSelector((state) => state.UI.responses.errors);
	const messages = useSelector((state) => state.UI.responses.messages);
	const submiting = useSelector((state) => state.commentData.submiting);
	const dispatch = useDispatch();
	const classes = styles();

	const defaultFormValues = {
		body: "",
		documentType: documentType,
		documentId: documentId,
	};

	const [input, setInput] = useState(defaultFormValues);

	useEffect(() => {
		if (!submiting && input.body) {
			setInput(defaultFormValues);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submiting]);

	const handleInputChange = (e) => {
		e.preventDefault();
		setInput({
			...input,
			[e.currentTarget.name]: e.currentTarget.value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(submitComment(deal.dealId, input));
	};

	return (
		<Grid item>
			<Grid container>
				<Grid item xs={12}>
					<TextField
						variant="outlined"
						name="body"
						type="text"
						label="Leave a comment"
						value={input.body}
						placeholder="Enter notes here"
						helpertext={errors.body ? errors.body : null}
						error={errors.body ? true : false}
						className={classes.commentField}
						onChange={(e) => handleInputChange(e)}
						multiline
						rows={4}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12}>
					<SpinnerButton
						size="small"
						fullWidth
						loading={submiting}
						disabled={submiting}
						type="submit"
						variant="contained"
						color="primary"
						className={classes.submitButton}
						onClick={(e) => handleSubmit(e)}>
						Submit Comment
					</SpinnerButton>
				</Grid>
			</Grid>
			<div className={classes.messageArea}>
				{errors.general ||
					(errors.error && (
						<Typography variant="body2" style={{ color: "red" }}>
							{errors.error}
							{errors.general}
						</Typography>
					))}
				{messages.success && (
					<Typography variant="body2" className={classes.success}>
						{messages.success}
					</Typography>
				)}
			</div>
		</Grid>
	);
};

export default CommentSubmitForm;
