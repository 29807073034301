import React from "react";
//Component
//MUI
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
//Icons
import IconButton from "@material-ui/core/IconButton";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { toggleNavDrawer } from "../../../../redux/actions/uiActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	root: {
		display: "flex",
	},
	appBar: {
		width: "100%",
	},
	drawer: {
		width: "100%",
		flexShrink: 0,
	},
	drawerPaper: {
		width: "100%",
		backgroundColor: theme.palette.primary.main,
	},
	// necessary for content to be below app bar
	toolbar: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.default,
		padding: theme.spacing(3),
	},
	drawerHeader: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: "flex-end",
	},
	drawerFooter: {
		top: "auto",
		bottom: 0,
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
}));

const MainDrawer = ({ children }) => {
	const drawer = useSelector((state) => state.UI.drawer);
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(toggleNavDrawer(false, null));
	};

	const classes = styles();
	return (
		drawer.open && (
			<Drawer
				open={drawer.open}
				onClose={() => handleClose()}
				className={classes.drawer}
				variant="permanent"
				classes={{
					paper: classes.drawerPaper,
				}}
				anchor="left">
				<div className={classes.drawerHeader}>
					<IconButton onClick={() => handleClose()}>X</IconButton>
				</div>
				<Divider />
				{children}
				<Divider />
				<div className={classes.drawerFooter}>
					<Grid item xs={12}>
						<Button
							fullWidth
							variant="outlined"
							size="small"
							color="secondary"
							onClick={() => handleClose()}>
							Close
						</Button>
					</Grid>
				</div>
			</Drawer>
		)
	);
};

export default MainDrawer;
