import React from "react";
import PropTypes from "prop-types";
//MUI
import Grid from "@material-ui/core/Grid";
//Components
import DealCardCompact from "../deals/DealCardCompact";
import DealSpinner from "../controls/DealSpinner";
// Redux
import { useSelector } from "react-redux";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Typography } from "@material-ui/core";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	spinner: {
		marginTop: 50,
	},
}));

export const DealCardSection = ({ deals }) => {
	const loading = useSelector((state) => state.data.loading);

	//const dispatch = useDispatch();
	const classes = styles();
	let dealCards = !loading ? (
		deals ? (
			deals.map((deal) => (
				<Grid
					key={deal.dealId}
					item
					container
					alignItems="stretch"
					xs={12}
					sm={4}>
					{/*<DealCard publicDeal={publicDeal} deal={deal} />*/}
					<DealCardCompact deal={deal} />
					<Typography noWrap variant="caption" color="secondary">
						{deal.title}
					</Typography>
					{/* <Typography noWrap variant="caption" color="secondary">
						{deal.city}, {deal.state} {deal.zipcode}
			</Typography>*/}
				</Grid>
			))
		) : null
	) : (
		<DealSpinner
			spinnerParentClassName={classes.spinner}
			loading
			size={75}
		/>
	);

	const sectionJustify = !dealCards ? "center" : "flex-start";

	return (
		<Grid container justify={sectionJustify} spacing={2} item xs={12}>
			{dealCards ? (
				dealCards
			) : (
				<Typography justify="center" variant="body2" color="secondary">
					There are no deals to display that meet the search criteria.
				</Typography>
			)}
		</Grid>
	);
};

DealCardSection.propTypes = {
	deals: PropTypes.array,
};

export default DealCardSection;
