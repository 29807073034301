import {
	LOADING_COMMENT,
	UPDATING_COMMENT,
	SUBMITING_COMMENT,
	DELETING_COMMENT,
	SET_COMMENTS,
	SET_COMMENT,
	SUBMIT_COMMENT,
	EDIT_COMMENT,
	DELETE_COMMENT,
} from "../types"

const initialState = {
	comments: [],
	comment: {},
	submiting: false,
	updating: {
		active: false,
		commentId: ''
	},
	deleting: {
		active: false,
		commentId: ''
	},
	loading: false
}
let index;

export default function(state = initialState, action) {
	switch (action.type) {
		case LOADING_COMMENT:
			return {
				...state,
				loading: true
			};

		case UPDATING_COMMENT:
			return {
				...state,
				updating: {
					active: true,
					commentId: action.payload
				}
			};

		case SUBMITING_COMMENT:
			return {
				...state,
				submiting: true
			};

		case DELETING_COMMENT:
			return {
				...state,
				deleting: {
					active: true,
					commentId: action.payload
				}
			};

		case SET_COMMENTS:
			return {
				...state,
				comments: action.payload,
					loading: false
			};

		case SET_COMMENT:
			return {
				...state,
				comment: {
						...state.comment,
						...action.payload
					},
					loading: false
			};

		case SUBMIT_COMMENT:
			return {
				...state,

				comments: [action.payload, ...state.comments],
					commentCount: state.commentCount + 1,
					comment: action.payload,
					submiting: false
			};

		case DELETE_COMMENT:
			console.log(action.payload)
			return {
				...state,
				comments: [...state.comments.filter((comment) => comment.commentId !== action.payload)],
					commentCount: state.commentCount - 1,
					deleting: {
						active: false,
						commentId: "",
					}

			};

		case EDIT_COMMENT:
			index = state.comments.findIndex((comment) => comment.commentId === action.payload.commentId);
			return {
				...state,
				comments: [
						...state.comments.slice(0, index),
						{
							...state.comments[index],
							...action.payload
						},
						...state.comments.slice(index + 1)
					],
					comment: action.payload,
					updating: {
						active: false,
						commentId: ""
					}
			};

		default:
			return state;
	}
}
