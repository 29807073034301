import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
//MUI Components
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	shareArea: {
		margin: theme.spacing(1),
	},
	buttonText: {
		marginRight: 10,
	},
	buttonIcon: {
		maxWidth: 25,
		maxHeight: 20,
		margin: "0 10px",
	},
}));

const ShareButton = ({ icon, text }) => {
	const classes = styles();
	return (
		<Button
			fullWidth
			component="div"
			variant="contained"
			color="primary"
			className={clsx(classes.shareButton, classes.buttonColorWhite)}>
			<img className={classes.buttonIcon} src={icon} alt={text} />
			<Typography variant="caption" className={classes.buttonText}>
				{text}
			</Typography>
		</Button>
	);
};

ShareButton.propTypes = {
	icon: PropTypes.string,
	text: PropTypes.string,
};

export default ShareButton;
