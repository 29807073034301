import PropTypes from "prop-types";
import React from "react";
import FormCloseTipButton from "../../controls/buttons/forms/FormCloseTipButton";
import FormTitle from "./FormTitle";
import DialogTitle from "@material-ui/core/DialogTitle";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
}));
const FormHeader = ({ titleText, handleClose }) => {
	const classes = styles();
	return (
		<DialogTitle className={classes.header}>
			<FormCloseTipButton handleClose={handleClose} />
			<FormTitle title={titleText} />
		</DialogTitle>
	);
};

FormHeader.propTypes = {
	handleClose: PropTypes.func.isRequired,
	titleText: PropTypes.string.isRequired,
};

export default FormHeader;
