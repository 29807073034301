import React from "react";
import clsx from "clsx";
//MUI
import Button from "@material-ui/core/Button";
//Redux
import { useDispatch } from "react-redux";
import { getConversations } from "../../../../redux/actions/inboxActions";
import { getNotifications } from "../../../../redux/actions/userActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	root: {
		color: "#fff",
	},
}));

const InboxRefreshButton = ({ size, menu, variant, className, userHandle }) => {
	const dispatch = useDispatch();
	const classes = styles();

	const handleInboxRefreshClick = () => {
		dispatch(getConversations());
		dispatch(getNotifications());
	};
	return (
		<Button
			underline="none"
			size={clsx(menu ? "small" : size)}
			fullWidth
			className={clsx(classes.root, className)}
			variant={variant}
			color={"secondary"}
			onClick={() => {
				handleInboxRefreshClick();
			}}>
			Refresh Inbox
		</Button>
	);
};

export default InboxRefreshButton;
