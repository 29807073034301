import {
	PURGE_USER
} from "../types"

import {
	combineReducers
} from 'redux'

import dataReducer from './dataReducer';
import commentReducer from './commentReducer';
import uiReducer from './uiReducer';
import userReducer from './userReducer';
import profileReducer from './profileReducer';
import subcriptionReducer from './subcriptionReducer';
import checkoutReducer from "./checkoutReducer"
import alertReducer from './alertReducer';
import systemReducer from "./systemReducer";
import inboxReducer from "./inboxReducer"

const appReducer = combineReducers({
	user: userReducer,
	data: dataReducer,
	commentData: commentReducer,
	profile: profileReducer,
	inbox: inboxReducer,
	UI: uiReducer,
	subscription: subcriptionReducer,
	checkout: checkoutReducer,
	system: systemReducer,
	alert: alertReducer
})

//https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
/*In case you are using redux-persist, you may also need to clean your storage. Redux-persist keeps a copy of your state in a storage engine, and the state copy will be loaded from there on refresh.*/
const rootReducer = (state, action) => {
	if (action.type === PURGE_USER) {
		state = undefined
	}

	return appReducer(state, action)
}


export default rootReducer;
