import PropTypes from "prop-types";
import React, { Fragment } from "react";
import placeholder from "../../images/deal-placeholder.jpg";
import { getTagLabel } from "../../util/UtilityFunctions";

//Component
import Prices from "../deals/Prices";
import DealBadge from "../badges/DealBadge";
import UserBadge from "../badges/UserBadge";
import UserBadgeCard from "../badges/UserBadgeCard";
import DealDescription from "../deals/DealDescription";
import CommentSubmitForm from "../comments/CommentSubmitForm";
import CommentSection from "../sections/CommentSection";
import DealShare from "../share/DealShare";
import ContactDealMakerButton from "../controls/buttons/inbox/ContactDealMakerButton";
import DealImageWithErrorChecking from "../image/DealImageWithErrorChecking";
import DealAddresses from "./DealAddresses";
//MUI Components
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
//Redux
import { useSelector } from "react-redux";
//Styles
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	deal: {
		backgroundColor: theme.palette.information.main,
	},
	dealDescription: {
		minHeight: 144,
	},
	card: {
		position: "absolute",
		marginBottom: 20,
	},
	cardContent: {
		width: "100%",
		flexDirection: "row",
		padding: 25,
	},
	cover: {
		width: "100%",
		height: 250,
		objectFit: "contain",
		/*minWidth: 200,

		objectFit: "cover"*/
	},
	contactButton: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	chipSecction: { position: "relative" },
	customChipDeactivated: {
		backgroundColor: "lightGrey",
		color: "grey",
	},
	customChipActivated: {
		backgroundColor: theme.palette.primary.main,
		color: "#ffffff",
	},
	customChip: {
		position: "relative",
		width: "100%",
		borderRadius: 50,
		margin: 0,
	},
	chipLabel: {
		textAlign: "center",
	},
	chipCheckBox: {
		visibility: "hidden",
		padding: "5px",
		color: "grey",
	},
	chipText: {
		padding: "2.5px",
		margin: "3px auto",
	},
	text: {
		fontSize: 11.99,
	},
	userBadgeCard: {
		marginBottom: 15,
	},
}));

const Deal = ({ deal }) => {
	const {
		imageUrl,
		title,
		description,
		price,
		arv,
		rehab,
		assignmentFee,
		tags,
		userHandle,
		userId,
		dealId,
		status,
		city,
		street,
		zipcode,
		state,
	} = deal;

	const dealAddress = { city, street, zipcode, state };

	const classes = styles();
	const theme = useTheme();
	const screenSmallUp = useMediaQuery(theme.breakpoints.up("sm"));
	const authenticated = useSelector((state) => state.user.authenticated);
	const authUser = useSelector((state) => state.user.credentials);
	let hasTags = false;

	const userBadge = (
		<UserBadge
			user={{
				userId: deal.userId,
				handle: deal.userHandle,
				imageUrl: deal.userImage,
				verified: deal.userVerified,
				type: deal.userType,
			}}
		/>
	);

	const userCard = (
		<UserBadgeCard
			showMsgBtn
			smallBadge
			user={{
				userId: deal.userId,
				handle: deal.userHandle,
				imageUrl: deal.userImage,
				verified: deal.userVerified,
				type: deal.userType,
			}}
		/>
	);

	const tagChips = Object.entries(tags).map((tag, index) => {
		let dealTag = tag[1];
		if (dealTag.checked) {
			hasTags = true;
		}
		return (
			dealTag.checked && (
				<Grid item key={index} xs={6}>
					<Paper
						className={`${
							dealTag.checked
								? classes.customChipActivated
								: classes.customChipDeactivated
						} ${classes.customChip}`}>
						<Typography className={classes.chipLabel}>
							{dealTag.tagId
								? getTagLabel(dealTag.tagId)
								: dealTag.label
								? `${dealTag.label} (missing id)`
								: "tag error"}
						</Typography>
					</Paper>
				</Grid>
			)
		);
	});

	const dealCard = (
		<Grid container>
			{!screenSmallUp && (
				<Grid className={classes.userBadgeCard} item xs={12} sm={3}>
					{userCard}
				</Grid>
			)}
			<Hidden only={["xs", "lg", "xl"]}>
				{authUser.userId !== deal.userId && (
					<Grid item container xs={12}>
						<Grid item xs={1}></Grid>
						<Grid item xs={10}>
							<ContactDealMakerButton
								className={classes.contactButton}
								user={{
									userId: deal.userId,
									handle: deal.userHandle,
									imageUrl: deal.userImage,
									type: deal.userType,
									verified: deal.userVerified,
								}}
							/>
						</Grid>
						<Grid item xs={1}></Grid>
					</Grid>
				)}
			</Hidden>

			<Grid className={classes.deal} item component={Card}>
				<Grid item xs={12}>
					{status === "sold" && <DealBadge large type={status} />}
				</Grid>
				<DealImageWithErrorChecking
					className={classes.cover}
					src={imageUrl}
				/>
				<DealAddresses dealAddress={dealAddress} />
				<CardContent>
					<Grid spacing={2} container>
						<Grid spacing={2} item container>
							<Prices
								priceFields={[
									{ label: "Price", value: price },
									{ label: "ARV", value: arv },
									{ label: "Rehab Cost", value: rehab },
									{
										label: "Assignment Fee",
										value: assignmentFee,
									},
								]}
							/>
							{/*Content Body*/}
							<Grid item container spacing={2}>
								{screenSmallUp && (
									<Grid item xs={12} sm={3}>
										{userBadge}
									</Grid>
								)}
								<Grid item xs={12} sm={9}>
									<Typography variant="h6">
										{title}
									</Typography>
									<Typography
										className={classes.dealDescription}
										classes={{
											root: classes.text,
										}}>
										<DealDescription
											title={title}
											description={
												description
											}></DealDescription>
									</Typography>
								</Grid>
								{hasTags && (
									<Grid item xs={12}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<Divider />
											</Grid>
											<Grid
												alignItems="center"
												justify="center"
												className={classes.chipSecction}
												spacing={2}
												item
												container>
												{tagChips}
											</Grid>
										</Grid>
									</Grid>
								)}
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid item xs={12}>
							<DealShare />
						</Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						{authenticated && (
							<Grid item xs={12}>
								<CommentSubmitForm />
								<CommentSection
									dealId={dealId}
									dealOwnerHandle={userHandle}
									dealOwnerUserId={userId}
								/>
							</Grid>
						)}
					</Grid>
				</CardContent>
			</Grid>
		</Grid>
	);

	return <Fragment>{dealCard}</Fragment>;
};

Deal.propTypes = {
	deal: PropTypes.shape({
		dealId: PropTypes.string.isRequired,
		userHandle: PropTypes.string.isRequired,
		userId: PropTypes.string.isRequired,
		userImage: PropTypes.string,
		userType: PropTypes.string.isRequired,
		userVerified: PropTypes.bool.isRequired,
		imageUrl: PropTypes.string,
		title: PropTypes.string.isRequired,
		description: PropTypes.string,
		price: PropTypes.number.isRequired,
		arv: PropTypes.number,
		tags: PropTypes.object,
		rehab: PropTypes.number,
		assignmentFee: PropTypes.number,
		status: PropTypes.string.isRequired,
	}),
};

export default Deal;
