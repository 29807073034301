import React from "react";
import PropTypes from "prop-types";
import { LinkedinShareButton } from "react-share";
import linkedInIcon from "../../../../images/svg/icon-linkedIn.svg";
//Components
import ShareButton from "../ShareButton";
//MUI Components
import Grid from "@material-ui/core/Grid";

const defaultProps = {
	description: "I found this great deal on sealdealfinder.com! ",
};

const LinkedInDealShareButton = ({
	shareUrl,
	subject,
	description = defaultProps.description,
}) => {
	return (
		<Grid item xs={12} sm={3}>
			<LinkedinShareButton
				component="div"
				style={{ width: "100%" }}
				variant="contained"
				color="primary"
				url={shareUrl}
				title={subject}
				description={description}>
				<ShareButton icon={linkedInIcon} text="LinkedIn" />
			</LinkedinShareButton>
		</Grid>
	);
};

LinkedInDealShareButton.propTypes = {
	description: PropTypes.string,
	shareUrl: PropTypes.string,
	subject: PropTypes.string,
};

export default LinkedInDealShareButton;
