import PropTypes from "prop-types";
import React, { useState } from "react";
import clsx from "clsx";

//Components
//import CommentInnerContent from "./CommentInnerContent";
import MessageDeleteButton from "../controls/buttons/inbox/MessageDeleteButton";
import GeneralLinkButton from "../controls/buttons/GeneralLinkButton";
//MUI Components
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
// Redux
import { useSelector } from "react-redux";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Typography } from "@material-ui/core";
//Dayjs
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat);
dayjs.extend(timezone);
dayjs.tz.guess();

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	bubble: { padding: 5 },
	bubbleUserBadge: {
		maxWidth: 125,
		maxHeight: 125,
	},
	authUser: {
		backgroundColor: theme.palette.information.main,
		color: theme.palette.information.dark,
	},
	participant: {
		backgroundColor: theme.palette.information.dark,
		color: "#fff",
	},
	ownerLabel: {
		marignBottom: "-40px",
	},
	deleting: {
		backgroundColor: "red",
	},
	blocked: {
		backgroundColor: "lightGrey",
	},
	buttonColorInformation: {
		color: theme.palette.information.dark,
	},
}));

const MessageCard = ({ message }) => {
	const authUser = useSelector((state) => state.user.credentials);
	const conversation = useSelector((state) => state.inbox.conversation);
	const classes = styles();
	//const { userHandle, userImage } = comment;

	const getUserData = () => {
		if (conversation.firstParticipant) {
			if (conversation.firstParticipant.id === message.senderId) {
				return {
					userId: conversation.firstParticipant.id,
					handle: conversation.firstParticipant.handle,
					imageUrl: conversation.firstParticipant.imageUrl,
				};
			} else {
				return {
					userId: conversation.secondParticipant.id,
					handle: conversation.secondParticipant.handle,
					imageUrl: conversation.secondParticipant.imageUrl,
				};
			}
		} else {
			return {
				handle: "",
				imageUrl: "",
			};
		}
	};
	const [userData] = useState(getUserData());

	let bubbleUserClassName;
	if (message.senderId === authUser.userId) {
		bubbleUserClassName = classes.authUser;
	} else {
		bubbleUserClassName = classes.participant;
	}

	const buttonText = message.deal && (
		<Typography noWrap>{`Deal: ${message.deal.title}`}</Typography>
	);

	const sentUserInformation = (
		<Typography variant="caption">
			(sent by
			{userData.userId === authUser.userId
				? " you"
				: ` @${userData.handle}`}
			{` on ${dayjs(message.createdAt).format("M/D/YYYY [at] h:mm A z")}`}
			)
		</Typography>
	);

	const dealLink =
		message.deal && message.deal.dealId ? (
			<Grid item xs={12} sm={4}>
				<GeneralLinkButton
					className={classes.buttonColorInformation}
					size="small"
					menu
					variant="contained"
					text={buttonText}
					linkTo={`/deals/${message.deal.dealId}`}
				/>
			</Grid>
		) : null;

	const card = (
		<Grid
			item
			container
			className={clsx(bubbleUserClassName, classes.bubble)}
			component={Card}
			spacing={1}>
			<Grid className={classes.bubbleUserBadge} item xs={12} sm={1}>
				<Avatar src={userData.imageUrl} />
			</Grid>

			<Grid item container xs={11}>
				<Grid item container>
					<Grid item xs={11}>
						<Typography>{message.body}</Typography>
					</Grid>
					<Grid item xs={11}>
						{sentUserInformation}
					</Grid>
					<Grid item xs={1}>
						<MessageDeleteButton messageId={message.messageId} />
					</Grid>
				</Grid>
			</Grid>
			{dealLink}
		</Grid>
	);

	return (
		<Grid item xs={12}>
			{card}
		</Grid>
	);
};

MessageCard.propTypes = {
	message: PropTypes.shape({
		body: PropTypes.string,
		createdAt: PropTypes.string,
		deal: PropTypes.shape({
			dealId: PropTypes.string,
			title: PropTypes.string,
		}),
		messageId: PropTypes.string,
		senderId: PropTypes.string,
	}).isRequired,
};

export default MessageCard;
