import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import placeholder from "../../images/deal-placeholder.jpg";
import clsx from "clsx";

//Component
import Prices from "./Prices";
import DealBadge from "../badges/DealBadge";
import UserBadge from "../badges/UserBadge";
import DealImageWithErrorChecking from "../image/DealImageWithErrorChecking";
//MUI Components
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	deal: {
		flexGrow: 1,
	},
	dealDescription: {
		minHeight: 144,
	},
	publicCard: {
		backgroundColor: theme.palette.information.main,
	},
	compactCard: {
		transition: "all .2s ease-in-out",
		"&:hover": {
			transform: "scale(1.05)",
		},
	},
	card: {
		position: "absolute",
		marginBottom: 20,
	},
	cardContent: {
		width: "100%",
		flexDirection: "row",
		padding: 25,
	},
	cover: {
		width: "100%",
		height: 150,
		objectFit: "contain",
		/*minWidth: 200,

		objectFit: "cover"*/
	},
}));

const DealCardCompact = ({ deal }) => {
	const {
		imageUrl,
		price,
		arv,
		rehab,
		userImage,
		userHandle,
		userVerified,
		userType,
		dealId,
		status,
	} = deal;
	const classes = styles();

	return (
		<Grid
			className={clsx(classes.shadow, classes.compactCard)}
			item
			xs={12}
			component={Card}
			style={{ position: "relative" }}>
			<Grid item xs={12}>
				{status === "sold" && <DealBadge type={status} />}
			</Grid>
			<div style={{ position: "absolute", left: 10, top: 0, width: 50 }}>
				<UserBadge
					noType
					noHandle
					shadow
					user={{
						handle: userHandle,
						imageUrl: userImage,
						verified: userVerified,
						type: userType,
					}}
				/>
			</div>
			<Link to={`/deals/${dealId}`}>
				<DealImageWithErrorChecking
					className={classes.cover}
					src={imageUrl}
				/>
			</Link>
			<Prices
				linkTo={`/deals/${dealId}`}
				compact
				priceFields={[
					{ label: "Price", value: price },
					{ label: "ARV", value: arv },
					{ label: "Rehab", value: rehab },
				]}
			/>
		</Grid>
	);
};

DealCardCompact.propTypes = {
	deal: PropTypes.object,
};

export default DealCardCompact;
