import {
	LOADING_PROFILE,
	SET_PROFILE,
	SET_PROFILE_IMAGE

} from "../types"

const initialState = {
	user: {},
	deals: [],
	loading: false,
}

export default function(state = initialState, action) {
	switch (action.type) {
		case SET_PROFILE:
			return {
				...state,
				loading: false,
					...action.payload
			};
		case SET_PROFILE_IMAGE:
			return {
				...state,
				user: {
					imageUrl: action.payload
				},
			};
		case LOADING_PROFILE:
			return {
				...state,
				loading: true
			};
		default:
			return state

	}
}
