import PropTypes from "prop-types";
import React from "react";
//Commponents
import CommentCard from "../comments/CommentCard";
//MUI Components
import Grid from "@material-ui/core/Grid";
// Redux
import { useSelector } from "react-redux";

const CommentSection = ({ dealOwnerUserId }) => {
	const comments = useSelector((state) => state.commentData.comments);
	const user = useSelector((state) => state.user.credentials);
	const deleting = useSelector((state) => state.commentData.deleting);

	const matchLoggedInUserDealOwner = user.userId === dealOwnerUserId;

	const commentCards = comments.map((comment) => {
		const matchDealOwnerComment = comment.userId === dealOwnerUserId;
		const matchLoggedInUserComment = user.userId === comment.userId;
		const deletingCommentMatch = deleting.commentId === comment.commentId;

		return (
			(!comment.blocked ||
				matchLoggedInUserComment ||
				matchLoggedInUserDealOwner) && (
				<CommentCard
					key={comment.commentId}
					comment={comment}
					matchLoggedInUserDealOwner={matchLoggedInUserDealOwner}
					matchDealOwnerComment={matchDealOwnerComment}
					matchLoggedInUserComment={matchLoggedInUserComment}
					deletingCommentMatch={deletingCommentMatch}
				/>
			)
		);
	});

	return <Grid container>{commentCards}</Grid>;
};

CommentSection.propTypes = {
	dealOwnerUserId: PropTypes.string.isRequired,
};

export default CommentSection;
