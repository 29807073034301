import PropTypes from "prop-types";
import React from "react";
//Component
import SystemAuthRoleButton from "../../controls/buttons/system/form/open/SystemAuthRoleButton";
import SystemApplicationButton from "../../controls/buttons/system/form/open/SystemApplicationButton";
import SystemSubscriptionButton from "../../controls/buttons/system/form/open/SystemSubscriptionButton";
//MUI Components
import Grid from "@material-ui/core/Grid";

const SystemCardButtons = ({ user, canceled }) => {
	return (
		<Grid
			style={{ marginTop: 5 }}
			item
			container
			alignItems="center"
			spacing={1}
			xs={12}
			sm={12}>
			<Grid></Grid>
			{user.verified ||
			user.type === "wholesaler" ||
			user.type === "support" ? (
				<SystemSubscriptionButton user={user} canceled={canceled} />
			) : (
				<SystemApplicationButton user={user} />
			)}
			<SystemAuthRoleButton user={user} />
		</Grid>
	);
};

SystemCardButtons.propTypes = {
	canceled: PropTypes.any,
	user: PropTypes.shape({
		verified: PropTypes.bool,
	}),
};

export default SystemCardButtons;
