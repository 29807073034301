//Packages
import React, { useEffect, Fragment } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
//Components
import AuthRoute from "../util/AuthRoute";
import FormsLoader from "../components/forms/FormsLoader";
import DrawerLoader from "../components/navigation/mobile/drawers/DrawerLoader";
import Success from "../components/vaCheckout/Success";
import Canceled from "../components/vaCheckout/Canceled";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../redux/actions/userActions";
import { clearDeal } from "../redux/actions/dataActions";
import { setHeaderData, toggleNavDrawer } from "../redux/actions/uiActions";
//Pages
import DealBoard from "./DealBoard";
import Deal from "./Deal";
import Profile from "./Profile";
import Settings from "./Settings";
import Inbox from "./Inbox";
import Login from "./Login";
import Signup from "./Signup";
import SignupWholesaler from "./SignupWholesaler";
import System from "./System";
import Subscriptions from "./Subscriptions";
import Verification from "./Verification";
import VirtualAssistant from "./VirtualAssistant";
import Teams from "./Teams";
import Privacy from "./Privacy";
import Terms from "./Terms";
import NotFound from "./NotFound";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	mobileBottomSpace: {
		[theme.breakpoints.down("sm")]: {
			paddingBottom: 100,
		},
	},
}));
const Routes = () => {
	const authUser = useSelector((state) => state.user.credentials);
	const deal = useSelector((state) => state.data.deal);
	const redirectTo = useSelector((state) => state.UI.redirectTo);
	const delay = useSelector((state) => state.UI.timeDelay);
	let dispatch = useDispatch();
	let location = useLocation();
	const classes = styles();

	useEffect(() => {
		//if (currentLocation !== "/login" || currentLocation !== "/signup") {
		dispatch(getUserData());
		//	}
	}, [dispatch]);

	useEffect(() => {
		if (deal.title) {
			document.title = `${deal.title}`;
		}
	}, [deal]);
	useEffect(() => {
		const baseLocationDeals = location.pathname.startsWith("/deals/");
		if (!baseLocationDeals) {
			dispatch(clearDeal());
		}
		dispatch(toggleNavDrawer(false, null));
		let currentLocation = location.pathname;
		let dealsPage = currentLocation.includes("deals");
		if (!dealsPage) {
			let title = "Seal Deal Finder";
			let description =
				"Helping Wholesalers and Investors Seal the Deal!";
			dispatch(
				setHeaderData({
					active: true,
					title: title,
					description: description,
				})
			);
			document.title = "Seal Deal Finder";
		} else {
			dispatch(
				setHeaderData({
					active: false,
				})
			);
		}
	}, [location, dispatch]);

	if (redirectTo) {
		return <Redirect to={redirectTo} />;
	}

	return (
		<Fragment>
			{/*headers.active && headerData*/}
			<div id="content" className="content">
				<Switch>
					<Route
						exact
						path="/"
						//component={authenticated ? DealBoard : Maintenance}
						component={DealBoard}
					/>

					<Route path="/profiles/:userHandle" component={Profile} />
					<Route path="/settings/:userHandle" component={Settings} />
					<Route path="/inbox/:userHandle" component={Inbox} />
					<Route path="/deals/:dealId" component={Deal} />

					<Route exact path="/profiles" component={DealBoard} />

					<AuthRoute exact path="/login" component={Login} />
					<AuthRoute exact path="/signup" component={Signup} />
					<AuthRoute
						exact
						path="/wholesaler/signup"
						component={SignupWholesaler}
					/>
					<AuthRoute
						path="/wholesaler/referral/:referredBy"
						component={SignupWholesaler}
					/>
					<Route
						path="/system/:systemUserId/:showAllPending"
						component={System}
					/>
					<Route exact path="/system/" component={System} />
					<Route
						exact
						path="/subscriptions"
						render={() => (
							<>
								{authUser.type === "wholesaler" && (
									<Subscriptions service="wholesaler_premium" />
								)}

								{(authUser.type === "member" ||
									authUser.type === "investor") && (
									<Subscriptions service="investor_premium" />
								)}

								{authUser.type === "support" && (
									<Subscriptions service="tech_support" />
								)}
							</>
						)}
					/>

					<Route
						exact
						path="/verification"
						component={Verification}
					/>
					<Route exact path="/privacy" component={Privacy} />
					<Route exact path="/terms" component={Terms} />
					<Route
						exact
						path="/virtual-assistant"
						component={VirtualAssistant}
					/>
					<Route path="/success/:sessionId">
						<Success />
					</Route>
					<Route path="/canceled">
						<Canceled />
					</Route>
					<Route path="*" component={NotFound} />
				</Switch>
				<div className={classes.mobileBottomSpace} />
				<FormsLoader />
				<DrawerLoader />
			</div>
		</Fragment>
	);
};

export default Routes;
