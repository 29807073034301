import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";
import CurrencyFormat from "react-currency-format";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	customChipDeactivated: {
		backgroundColor: "lightGrey",
	},
	customChipActivated: {
		backgroundColor: theme.palette.primary.main,
	},
	customChip: {
		maxHeight: 40,
		color: "#ffffff",
		textAlign: "center",
		borderRadius: 50,
		padding: "0 12px 5px 10px",
	},
	textColor: { color: `${theme.palette.primary.main}!important` },
}));
export const DealTextField = (props) => {
	return (
		<Grid item xs={props.halfWidth ? 6 : 12}>
			<TextField
				autoFocus={props.autoFocus}
				color={props.color}
				readOnly={props.readOnly}
				variant="outlined"
				size="small"
				fullWidth
				disabled={props.disabled}
				placeholder={props.placeholder}
				id={props.name}
				name={props.name}
				type={props.type}
				label={props.label}
				value={props.value}
				onBlur={props.onBlur}
				onChange={props.onChange}
				onClick={props.onClick}
				className={clsx(props.className)}
				key={props.name}
				multiline={props.multiline}
				rows={props.rows}
				helperText={props.helperText}
				onKeyDown={props.onKeyDown}
				error={props.error}
				InputProps={
					props.price
						? {
								startAdornment: (
									<InputAdornment position="start">
										$
									</InputAdornment>
								),
						  } // eslint-disable-line no-mixed-spaces-and-tabs
						: props.InputProps
				}
			/>
		</Grid>
	);
};

DealTextField.propTypes = {
	InputProps: PropTypes.any,
	autoFocus: PropTypes.any,
	className: PropTypes.any,
	disabled: PropTypes.any,
	error: PropTypes.any,
	halfWidth: PropTypes.any,
	helperText: PropTypes.any,
	label: PropTypes.any,
	multiline: PropTypes.any,
	name: PropTypes.any,
	onChange: PropTypes.any,
	onClick: PropTypes.any,
	onKeyDown: PropTypes.any,
	placeholder: PropTypes.any,
	price: PropTypes.any,
	readOnly: PropTypes.any,
	rows: PropTypes.any,
	type: PropTypes.any,
	value: PropTypes.any,
};

export const DealCurrencyTextField = (props) => {
	return (
		<CurrencyFormat
			value={props.value ? props.value : ""}
			customInput={DealTextField}
			thousandSeparator={true}
			thousandSpacing="3"
			label={props.label}
			name={props.name}
			small={props.small}
			onChange={(e) => props.onChange(e)}
			price={true}
			halfWidth={props.halfWidth}
			helperText={props.helperText}
			error={props.error}
		/>
	);
};

DealCurrencyTextField.propTypes = {
	error: PropTypes.any,
	halfWidth: PropTypes.any,
	helperText: PropTypes.any,
	label: PropTypes.any,
	name: PropTypes.any,
	onChange: PropTypes.func,
	small: PropTypes.any,
	value: PropTypes.any,
};

export const DealLockedField = (props) => {
	return (
		<Grid item xs={props.halfWidth ? 6 : 12}>
			<FormControl
				variant="outlined"
				size="small"
				className={props.className}>
				<InputLabel
					style={{ color: "grey" }}
					shrink={props.shrink}
					htmlFor={props.name}>
					{props.label}
				</InputLabel>

				<OutlinedInput
					style={{ backgroundColor: "#deffde" }}
					disabled={true}
					id={props.name}
					name={props.name}
					type={props.type}
					label={props.label}
					value={props.value}
					onChange={props.onChange}
				/>
			</FormControl>
		</Grid>
	);
};

DealLockedField.propTypes = {
	className: PropTypes.any,
	halfWidth: PropTypes.any,
	label: PropTypes.any,
	name: PropTypes.any,
	onChange: PropTypes.func,
	shrink: PropTypes.any,
	type: PropTypes.any,
	value: PropTypes.any,
};

export const DealCheckbox = (props) => (
	<Grid item xs={props.halfWidth ? 6 : 12}>
		<FormControlLabel
			control={
				<Checkbox
					name={props.name}
					value={props.value}
					checked={props.checked}
					className={props.className}
					onChange={props.onChange}
					color="primary"
				/>
			}
			label={props.label}
			labelPlacement="end"
		/>
	</Grid>
);

DealCheckbox.propTypes = {
	checked: PropTypes.any,
	className: PropTypes.any,
	halfWidth: PropTypes.any,
	label: PropTypes.any,
	name: PropTypes.any,
	onChange: PropTypes.any,
	value: PropTypes.any,
};
Checkbox.defaultProps = {
	className: "form__fields__checkbox",
};

export const DealChip = (props) => {
	const classes = styles();

	return (
		<Grid item xs={props.halfWidth ? 6 : 12}>
			<Paper
				className={clsx(
					props.checked
						? classes.customChipActivated
						: classes.customChipDeactivated,
					classes.customChip
				)}>
				<FormControlLabel
					control={
						<Checkbox
							name={props.name}
							value={props.value}
							checked={props.checked}
							className={props.className}
							onChange={props.onChange}
							color="secondary"
						/>
					}
					label={props.label}
					labelPlacement="end"
				/>
			</Paper>
		</Grid>
	);
};

DealChip.propTypes = {
	checked: PropTypes.any,
	className: PropTypes.any,
	halfWidth: PropTypes.any,
	label: PropTypes.any,
	name: PropTypes.any,
	onChange: PropTypes.any,
	value: PropTypes.any,
};
