import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
//MUI
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { deleteDeal } from "../../../../redux/actions/dataActions";
import {
	setAlertWithConfirmation,
	clearAlerts,
} from "../../../../redux/actions/alertActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	deleteButton: {
		//color: "#fff",
		//backgroundColor: "rgba(255,0,0,.7)",
	},
	icon: {
		marginRight: 0,
		maxHeight: 19,
		marginTop: "-3px",
	},
}));

const defaultProps = {
	text: "Delete Deal",
};
const DeleteDealButton = ({
	dealId,
	size,
	menu,
	variant,
	text = defaultProps.text,
}) => {
	const alert = useSelector((state) => state.alert);
	const classes = styles();
	const dispatch = useDispatch();

	useEffect(() => {
		//confirmation turn off after server after response--> systemAction setUserRole()

		if (alert.confirmationForm === "dealDelete" && alert.confirmed) {
			dispatch(deleteDeal(dealId));
			dispatch(clearAlerts());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [alert.confirmed, dealId, dispatch]);

	const handleDelete = () => {
		dispatch(
			setAlertWithConfirmation(
				"dealDelete",
				"error",
				"Confirm Deal Deletion?",
				"This action cannot be reversed. The deal will be permanently deleted."
			)
		);
	};

	return (
		<Grid item>
			<Fragment>
				<Button
					fullWidth
					className={classes.deleteButton}
					size={clsx(menu ? "small" : size)}
					variant={variant}
					color={"secondary"}
					aria-label="edit"
					onClick={() => handleDelete()}>
					<DeleteIcon className={classes.icon} /> {text}
				</Button>
			</Fragment>
		</Grid>
	);
};

DeleteDealButton.propTypes = {
	className: PropTypes.string,
	dealId: PropTypes.string,
	menu: PropTypes.any,
	size: PropTypes.string,
	text: PropTypes.string,
	variant: PropTypes.string,
};

export default DeleteDealButton;
