import { createStore, applyMiddleware, compose } from "redux";
//import { composeWithDevTools } from "remote-redux-devtools";
import thunk from "redux-thunk";
import rootReducer from "./reducers/index";

//import { getUserDataMiddleware } from "./customMiddleware";

/*const initialState = {};
const reducers = combineReducers({
	user: userReducer,
	data: dataReducer,
	UI: uiReducer,
	system: systemReducer
});
*/

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, storeEnhancers(applyMiddleware(thunk)));

//ngrok.cmd http 3000 -host-header="localhost:3000"
//ngrok.cmd http --host-header=rewrite 3000

//const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
