// Could be using react components, but string templates ain't
// all bad either: they're arguably easier to edit.
export function dealStructuredData({ title, imageUrl, description }) {
	return JSON.stringify({
		"@context": "http://schema.org/",
		"@type": "Thing",
		name: `"${title}"`,
		description: `"${description}"`,
		image: `"${imageUrl}"`,
	});
}
