import PropTypes from "prop-types";
import React, { Fragment } from "react";
import clsx from "clsx";
import { authCreateEditDeals } from "../../../../util/UtilityFunctions";
//MUI
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { toggleFormDialog } from "../../../../redux/actions/uiActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	fabRight: {
		position: "fixed",
		float: "right",
		bottom: 20,
		right: 10,
	},
	fabCenter: {
		position: "absolute",
		zIndex: 1,
		top: -30,
		left: 0,
		right: 0,
		margin: "0 auto",
	},
}));

const CreateDealFab = ({ form, args, className, center }) => {
	const authUser = useSelector((state) => state.user.credentials);
	const dispatch = useDispatch();
	const classes = styles();

	const canCreateEditDeals = authCreateEditDeals(
		authUser.roles,
		authUser.subscriptionPaid
	);

	const handleOpen = () => {
		dispatch(toggleFormDialog(true, form, args));
	};

	return (
		<>
			{canCreateEditDeals && (
				<Fragment>
					{authUser.type === "wholesaler" ||
					(authUser.type === "support" && authUser.verified) ? (
						<Fab
							className={clsx(
								className,
								center ? classes.fabCenter : classes.fabRight
							)}
							color="primary"
							aria-label="add"
							onClick={() => handleOpen()}>
							<AddIcon color="secondary" />
						</Fab>
					) : null}
				</Fragment>
			)}
		</>
	);
};

CreateDealFab.propTypes = {
	args: PropTypes.object,
	center: PropTypes.any,
	className: PropTypes.string,
	form: PropTypes.any,
};

export default CreateDealFab;
