import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	customChipDeactivated: {
		backgroundColor: "lightGrey",
		color: "grey",
	},
	customChipActivated: {
		backgroundColor: theme.palette.primary.main,
		color: "#ffffff",
	},
	customChip: {
		cursor: "pointer",
		position: "relative",
		width: "100%",
		borderRadius: 50,
		margin: 0,
		marginTop: "-20px",
	},
	chipLabel: {
		textAlign: "center",
	},
	chipCheckBox: {
		visibility: "hidden",
		padding: "5px",
		color: "grey",
	},
	chipText: {
		padding: "2.5px",
		margin: "3px auto",
	},
}));

const DealChip = ({
	id,
	halfWidth,
	name,
	label,
	value,
	checked,
	activatedClassName,
	deactivatedClassName,
	className,
	onClick,
}) => {
	const classes = styles();

	const handleCheckBoxCLicked = () => {
		const checkBox = document.getElementById(id);
		checkBox.click();
	};

	return (
		<Grid item xs={8} sm={12} md={halfWidth ? 5 : 12}>
			<input
				id={id}
				type="checkbox"
				label={label}
				name={name}
				value={value}
				className={clsx(className, classes.chipCheckBox)}
				onClick={onClick}
			/>
			<Paper
				label={label}
				className={clsx(
					className,
					checked
						? activatedClassName
							? activatedClassName
							: classes.customChipActivated
						: deactivatedClassName
						? deactivatedClassName
						: classes.customChipDeactivated,
					classes.customChip
				)}
				onClick={() => handleCheckBoxCLicked()}>
				<Typography className={classes.chipLabel}>{label}</Typography>
			</Paper>
		</Grid>
	);
};

DealChip.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.bool,
	halfWidth: PropTypes.bool,
	className: PropTypes.string,
	checked: PropTypes.bool,
	activatedClassName: PropTypes.string,
	deactivatedClassName: PropTypes.string,
	onClick: PropTypes.func,
};

export default DealChip;
