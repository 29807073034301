import React from "react";
import clsx from "clsx";
//MUI Components
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
}));

const DealSelect = (props) => {
	const classes = styles();

	return (
		<Grid item xs={props.halfWidth ? 6 : 12}>
			<FormControl
				fullWidth
				size="small"
				variant="outlined"
				error={props.error}
				className={clsx(props.className, classes.formControl)}>
				<InputLabel id={`${props.id}-label`}>{props.label}</InputLabel>
				<Select
					labelId={`${props.id}-label`}
					id={props.id}
					value={props.value}
					onChange={(e) => props.handleSelectChange(e)}>
					{props.children}
				</Select>
				{props.helperText && (
					<FormHelperText>{props.helperText}</FormHelperText>
				)}
			</FormControl>
		</Grid>
	);
};

export default DealSelect;
