import React from "react";

//Components
import ActionBar from "./ActionBar";
import DealToolBar from "./toolbars/DealToolBar";

const defaultProps = { color: "secondary" };

const DealNavBar = ({ color = defaultProps.color, position }) => {
	return (
		<ActionBar position={position}>
			<DealToolBar color={color} />
		</ActionBar>
	);
};

export default DealNavBar;
