import React from "react";
import clsx from "clsx";
// Redux
import { useDispatch } from "react-redux";
import { systemSetUser } from "../../../../../../redux/actions/systemActions";
import { toggleFormDialog } from "../../../../../../redux/actions/uiActions";
//MUI Components
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	button: {
		width: "100%",
		color: "#fff",
	},
	verified: {
		backgroundColor: theme.palette.accent.main,
		color: "#fff",
	},
}));

const SystemAuthRoleButton = ({ user, className }) => {
	const dispatch = useDispatch();
	const classes = styles();

	const handleAssignAdminClick = () => {
		dispatch(systemSetUser(user));
		dispatch(toggleFormDialog(true, "assignRole", null));
	};

	return (
		<Grid item xs={12}>
			<Button
				fullWidth
				id="assignSystemRole"
				className={clsx(className, classes.button)}
				variant="contained"
				color="primary"
				onClick={() => handleAssignAdminClick()}>
				Authorization
			</Button>
		</Grid>
	);
};

export default SystemAuthRoleButton;
