import axios from 'axios'
import {
	SET_PROFILE,
	LOADING_PROFILE

} from "../types"



export const getUserProfileData = (params) => (dispatch) => {
	dispatch({
		type: LOADING_PROFILE,
	});

	axios.get(`/api/user/${params.userHandle}`)
		.then(res => {
			dispatch({
				type: SET_PROFILE,
				payload: res.data.profile
			})
		})
		.catch(err => {
			console.error(err);
		})
}

export const clearUserProfileData = () => (dispatch) => {
	dispatch({
		type: SET_PROFILE,
		payload: {
			user: {}
		}
	})
}
