import React, { Fragment, useState, useEffect } from "react";
// Components
import DealSelect from "../../controls/DealSelect";
//MUI Components
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
//Redux
import { useSelector, useDispatch } from "react-redux";
import {
	setSystemFilters,
	setSystemFilteredUsers,
} from "../../../redux/actions/systemActions";

const styles = makeStyles((theme) => ({
	...theme.customStyles,

	sideProfile: {
		minWidth: 240,
	},
	actionitems: {
		backgroundColor: "rgba(255, 255, 255, 1)",
		color: `${theme.palette.primary.main} !important`,
		borderRadius: 6,
	},
	search: {
		maxWidth: 200,
	},
	placeholder: {
		color: "blue",
	},
	filterExpandIcon: {
		marginRight: theme.spacing(2),
		float: "right",
		flexGrow: 1,
	},
	locationSpinner: {
		color: theme.palette.secondary.main,
	},
	filterLabelText: {
		padding: 5,
	},
}));

const SystemFilterToolbar = () => {
	const systemFilters = useSelector((state) => state.system.filters);
	const users = useSelector((state) => state.system.users);
	const navFilter = useSelector((state) => state.UI.navFilter);
	const [input, setInput] = useState({});

	const classes = styles();
	const dispatch = useDispatch();

	const handleSelectChange = (field) => (e) => {
		e.preventDefault();
		setInput({
			...input,
			[field]: e.target.value,
		});
	};

	useEffect(() => {
		setInput(systemFilters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
		dispatch(setSystemFilters(input));
		dispatch(setSystemFilteredUsers(users, input));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [input]);

	return (
		<Fragment>
			<Grid container direction="row" spacing={2}>
				<Grid
					item
					container
					direction="row"
					spacing={2}
					justify="space-between"
					alignItems="center">
					<Grid item xs={12} sm={4}>
						<Grid container justify="center" spacing={2}>
							<Hidden mdUp>
								<Grid item xs={12}>
									<Typography
										align="center"
										justify="center"
										variant="body1"
										className={classes.filterLabelText}>
										Seal Deal Finder Users
									</Typography>
								</Grid>
							</Hidden>
						</Grid>
					</Grid>
				</Grid>
				{navFilter.show && systemFilters ? (
					<Grid
						item
						container
						direction="row"
						spacing={2}
						justify="space-between"
						alignItems="center">
						<Grid item xs={12} sm={3}>
							<DealSelect
								id="type"
								label="User Type"
								value={input.type}
								handleSelectChange={handleSelectChange("type")}>
								<MenuItem value="">
									<em>All</em>
								</MenuItem>
								<MenuItem value="member">
									<em>Members</em>
								</MenuItem>
								<MenuItem value="investor">
									<em>Investors</em>
								</MenuItem>
								<MenuItem value="wholesaler">
									<em>Wholesalers</em>
								</MenuItem>
							</DealSelect>
						</Grid>
						<Grid item xs={12} sm={3}>
							<DealSelect
								id="roles"
								label="Authorization"
								value={input.type}
								handleSelectChange={handleSelectChange(
									"roles"
								)}>
								<MenuItem value="">
									<em>All</em>
								</MenuItem>
								<MenuItem value="admin">
									<em>Admin</em>
								</MenuItem>
							</DealSelect>
						</Grid>
						<Grid item xs={12} sm={3}>
							<DealSelect
								id="type"
								label="Verified"
								value={input.verified}
								handleSelectChange={handleSelectChange(
									"verified"
								)}>
								<MenuItem value="">
									<em>All</em>
								</MenuItem>
								<MenuItem value="showTrue">
									<em>Verified</em>
								</MenuItem>
								<MenuItem value="showFalse">
									<em>Not Verified</em>
								</MenuItem>
							</DealSelect>
						</Grid>
						<Grid item xs={12} sm={3}>
							<DealSelect
								id="subscriptionPaid"
								label="Subscription"
								value={input.subscriptionPaid}
								handleSelectChange={handleSelectChange(
									"subscriptionPaid"
								)}>
								<MenuItem value={null}>
									<em>All</em>
								</MenuItem>
								<MenuItem value="showTrue">
									<em>Paid</em>
								</MenuItem>
								<MenuItem value="showFalse">
									<em>Not Paid</em>
								</MenuItem>
							</DealSelect>
						</Grid>
					</Grid>
				) : null}
			</Grid>
		</Fragment>
	);
};

export default SystemFilterToolbar;
