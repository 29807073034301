import PropTypes from "prop-types";
import React, { useState } from "react";
import clsx from "clsx";
//Components
import CenterFormsLayout from "../components/layouts/CenterFormsLayout";
import SpinnerButton from "../components/controls/SpinnerButton";
import { DealTextField } from "../components/controls/DealTextFields";
//MUI Components
import Typography from "@material-ui/core/Typography";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { loginUser } from "../redux/actions/userActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
}));

const Login = (props) => {
	const classes = styles();
	const dispatch = useDispatch();
	const loading = useSelector((state) => state.UI.loading);
	const errors = useSelector((state) => state.UI.responses.errors);

	const [input, setInput] = useState({
		email: "",
		password: "",
	});

	const handleSubmit = (e) => {
		e.preventDefault();
		const userData = {
			email: input.email,
			password: input.password,
		};

		dispatch(loginUser(userData, props.history));
	};

	const handleInputChange = (e) => {
		setInput({
			...input,
			[e.currentTarget.name]: e.currentTarget.value,
		});
	};

	return (
		<CenterFormsLayout
			title="Login"
			linkTo="/signup"
			linkInfoText="dont have an account? signup"
			linkText="here"
			pngLogo>
			<form className={clsx(classes.form)} noValidate>
				<DealTextField
					id="email"
					name="email"
					type="email"
					label="Email"
					className={clsx(classes.textField)}
					helperText={errors.email ? errors.email : null}
					error={errors.email ? true : false}
					InputProps={{
						classes: {
							text: classes.text,
							error: classes.error,
						},
					}}
					value={input.email}
					onChange={handleInputChange}
					fullWidth
				/>
				<DealTextField
					id="password"
					name="password"
					type="password"
					label="Password"
					className={classes.textField}
					helperText={errors.password ? errors.password : null}
					error={errors.password ? true : false}
					value={input.password}
					onChange={handleInputChange}
					fullWidth
				/>
				{errors.general && (
					<Typography
						variant="body2"
						className={classes.generalError}>
						{errors.general}
					</Typography>
				)}
				<SpinnerButton
					className={classes.button}
					color="primary"
					type="submit"
					variant="contained"
					disabled={loading}
					loading={loading}
					onClick={handleSubmit}>
					Login
				</SpinnerButton>
			</form>
		</CenterFormsLayout>
	);
};

Login.propTypes = {
	history: PropTypes.object.isRequired,
};

export default Login;
