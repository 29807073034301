//verificationUploaded
import React, { useState, useEffect } from "react";
import clsx from "clsx";
//Component
import SpinnerButton from "../../controls/SpinnerButton";
//Functions
import { CheckFileSize } from "../../../util/UtilityFunctions";
//MUI
import Grid from "@material-ui/core/Grid";
import GeneralButton from "../../controls/buttons/GeneralButton";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { uploadVerificationFile } from "../../../redux/actions/userActions";
import { setAlert, clearAlert } from "../../../redux/actions/alertActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
import { DealTextField } from "../../controls/DealTextFields";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	main: {
		backgroundColor: "white",
		minHeight: 400,
	},
	sideProfile: {
		minWidth: 240,
	},
	title: {
		marginBottom: 0,
	},
	subTitle: {
		marginBottom: 10,
	},
}));

const VerificationFileUploadForm = (props) => {
	const user = useSelector((state) => state.user.credentials);
	const alert = useSelector((state) => state.alert);
	const loading = useSelector((state) => state.UI.loading);
	const [file, setFile] = useState({
		data: null,
		name: "",
	});
	const dispatch = useDispatch();
	const classes = styles();

	useEffect(() => {
		//window.history.pushState(null, null, newPath);
		if (user.verificationUploaded && !alert.open) {
			//window.location = "/";
			dispatch(clearAlert());
		}
	}, [user.verificationUploaded, alert.open, dispatch]);

	const handleFileAdd = () => {
		const fileInput = document.getElementById("verificationFileInput");
		fileInput.click();
	};

	const handleFileChange = (event) => {
		const data = event.target.files[0];
		const fileSizeTooLarge = CheckFileSize(data.size);

		const fileName = data.name;
		const lastDot = fileName.lastIndexOf(".");
		const ext = fileName.substring(lastDot + 1);

		if (ext !== "pdf") {
			dispatch(
				setAlert(
					"alert",
					"error",
					"File Type Not Accepted",
					`Only PDF files can be accepted.  Please upload a pdf file.`,
					false
				)
			);
		} else {
			if (fileSizeTooLarge) {
				dispatch(
					setAlert(
						"alert",
						"error",
						"File Size Too Large",
						`Files cannot be larger than 5mb. The file ${data.name} is too large. Please upload a smaller file size.`,
						false
					)
				);
			} else {
				setFile({
					data: data,
					name: data.name,
				});
			}
		}
	};

	const handleSubmit = () => {
		const formData = new FormData();
		formData.append("file", file.data, file.name);
		dispatch(uploadVerificationFile(formData));
	};
	console.log(file);
	return (
		<Grid
			item
			xs={12}
			className={clsx(classes.formBackground, classes.main)}
			component={Card}>
			<input
				type="file"
				id="verificationFileInput"
				accept="application/pdf"
				onChange={(e) => handleFileChange(e)}
				hidden="hidden"
			/>
			<Grid container component={CardContent} align="center">
				<CardContent>
					<Grid item xs={12}>
						<Typography variant="h4" className={classes.title}>
							Verification Form
						</Typography>
						<Typography
							variant="body1"
							align="left"
							className={classes.subTitle}>
							Complete your subscription, upload [document] that
							will allow the sealdealfinder.com team to determine
							if you are a legitimate Investor.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Grid item xs={12} sm={3} />
						<Grid item xs={12} sm={6} container spacing={2}>
							<Grid item xs={12}>
								<DealTextField readOnly value={file.name} />
							</Grid>
							<Grid item xs={12}>
								<GeneralButton
									fullWidth
									variant="contained"
									color="primary"
									text="Upload Verification File"
									onClick={() =>
										handleFileAdd()
									}></GeneralButton>
							</Grid>
							<Grid item xs={12}>
								<SpinnerButton
									loading={loading}
									fullWidth
									variant="contained"
									color="primary"
									onClick={() => handleSubmit()}>
									Submit
								</SpinnerButton>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={3} />
					</Grid>
				</CardContent>
			</Grid>
		</Grid>
	);
};

export default VerificationFileUploadForm;
