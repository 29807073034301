import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
//MUI
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import ToolTip from "@material-ui/core/Tooltip";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { deleteConversation } from "../../../../redux/actions/inboxActions";
import {
	setAlertWithConfirmation,
	clearAlerts,
} from "../../../../redux/actions/alertActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
import { user } from "firebase-functions/lib/providers/auth";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
}));

const defaultProps = {
	text: "",
	color: "secondary",
};
const DeleteConversationButton = ({
	className,
	conversationId,
	user,
	size,
	menu,
	variant,
	fullWidth,
	toolTipClassName,
	toolTip,
	color = defaultProps.color,
	text = defaultProps.text,
}) => {
	const alert = useSelector((state) => state.alert);
	const classes = styles();
	const dispatch = useDispatch();

	useEffect(() => {
		//confirmation turn off after server after response--> systemAction setUserRole()

		if (
			alert.confirmationForm === "conversationDelete" &&
			alert.confirmed &&
			alert.data === conversationId
		) {
			dispatch(deleteConversation(conversationId));
			dispatch(clearAlerts());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [alert.confirmed, conversationId, dispatch]);

	const confirmationMsg = (
		<Typography component="div" paragraph variant="body1">
			This action cannot be reversed. The conversation and all notes to
			and from{" "}
			<Typography
				className={classes.boldText}
				display="inline"
				variant="body1">
				@{user.handle}{" "}
			</Typography>
			will be perminately deleted.
		</Typography>
	);

	const handleDelete = () => {
		dispatch(
			setAlertWithConfirmation(
				"conversationDelete",
				"error",
				"Confirm Conversation Deletion?",
				confirmationMsg,
				null,
				null,
				conversationId
			)
		);
	};

	return (
		<Grid item>
			<ToolTip
				className={toolTipClassName}
				title={toolTip}
				placement="top">
				<IconButton
					fullWidth={fullWidth}
					className={clsx(classes.deleteButton, className)}
					size={clsx(menu ? "small" : size)}
					variant={variant}
					aria-label="delete"
					onClick={() => handleDelete()}>
					<DeleteIcon className={classes.icon} /> {text}
				</IconButton>
			</ToolTip>
		</Grid>
	);
};

DeleteConversationButton.propTypes = {
	className: PropTypes.string,
	color: PropTypes.string,
	conversationId: PropTypes.string.isRequired,
	fullWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	menu: PropTypes.any,
	size: PropTypes.string,
	text: PropTypes.string,
	toolTip: PropTypes.string,
	toolTipClassName: PropTypes.string,
	user: PropTypes.shape({
		handle: PropTypes.string.isRequired,
	}),
	variant: PropTypes.string,
};

export default DeleteConversationButton;
