import {
	SET_VA_PACKAGES,
	SET_VA_PACKAGE,
	SET_PAYMENT_METHOD,
	SET_ALERT,
	SET_ERRORS,
	SET_PAYMENT_RESPONSE,
	LOADING_CHECKOUT,
	STOP_LOADING_CHECKOUT,
	SET_CHECKOUT_LOGIN_REDIRECT

} from "../types"
import axios from "axios"

export const createCheckoutPayment = (data) => dispatch => {
	dispatch({
		type: LOADING_CHECKOUT
	})
	axios.post("/api/checkout/charge/payment", {
			headers: {
				"Content-Type": "text/plain"
			},
			data
		})
		.then((res) => {
			if (!res.data.requires_action) {
				dispatch({
					type: SET_ALERT,
					payload: {
						type: "success",
						title: "Success!",
						text: res.data.message.success,
						redirectTo: '/'
					}
				});

			} else {
				dispatch({
					type: SET_PAYMENT_RESPONSE,
					payload: res.data
				})
			}

			dispatch({
				type: STOP_LOADING_CHECKOUT
			})
		}).catch(err => {
			console.log(err)
			dispatch({
				type: SET_ERRORS,
				payload: err.response.data
			})
			dispatch({
				type: STOP_LOADING_CHECKOUT
			})
		})
}

export const getPaymentMethod = (paymentMethod) => dispatch => {
	axios.post("/api/subscription/payment/method", {
			paymentMethod
		})
		.then((currentPaymentMethod) => {
			dispatch({
				type: SET_PAYMENT_METHOD,
				payload: currentPaymentMethod.data
			})
		})
		.catch((err) => {
			console.error(err)
		})
}

export const setCheckoutVAPackage = (virtualAssistantPackage) => dispatch => {
	dispatch({
		type: SET_VA_PACKAGE,
		payload: virtualAssistantPackage
	})
}

export const getPackages = () => dispatch => {
	axios.get("/api/product/list")
		.then((res) => {
			dispatch({
				type: SET_VA_PACKAGES,
				payload: res.data

			})
		})
		.catch = (err) => {
			console.log(err)
		}
}


export const setCheckoutLoginRedirect = (redirectTo) => dispatch => {

	dispatch({
		type: SET_CHECKOUT_LOGIN_REDIRECT,
		payload: redirectTo

	})
}
