import PropTypes from "prop-types";
import React, { useEffect } from "react";
//Components
import SystemUserCard from "../system/cards/SystemUserCard";
import DealSpinner from "../controls/DealSpinner";
//MUI
import Grid from "@material-ui/core/Grid";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { systemGetUsers } from "../../redux/actions/systemActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	spinner: {
		marginTop: 50,
	},
}));

export const SystemSection = ({ systemUsers }) => {
	const loading = useSelector((state) => state.system.loading);

	const dispatch = useDispatch();
	const classes = styles();

	useEffect(() => {
		//ComponentDidMount
		dispatch(systemGetUsers());
	}, [dispatch]);

	let userCards = !loading ? (
		systemUsers.map((user, index) => (
			<Grid key={user.userId} item container xs={12} sm={6} lg={4}>
				<SystemUserCard index={index} user={user} />
			</Grid>
		))
	) : (
		<DealSpinner
			spinnerParentClassName={classes.spinner}
			loading
			size={75}
		/>
	);

	return (
		<Grid item container direction="row" spacing={2} xs={12}>
			{userCards}
		</Grid>
	);
};

SystemSection.propTypes = {
	systemUsers: PropTypes.array.isRequired,
};

export default SystemSection;
