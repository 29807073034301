import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
//Components
import LoginSignupActions from "../../../profile/LoginSignupActions";
//MUI Components
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	toolBar: {
		padding: theme.spacing(1),
	},
	menuButtonRight: {
		marginRight: theme.spacing(2),
		float: "right",
		flexGrow: 1,
	},
	menuButtonCenter: {},
	verifiedText: {
		color: theme.palette.accent.main,
	},
	verifiedButton: {
		backgroundColor: theme.palette.accent.main,
		color: "#fff",
	},
	grow: {
		flexGrow: 1,
	},
	fullwidth: {
		width: "100%",
	},
	dividerColor: {
		color: "white",
	},
	firstButton: { marginTop: theme.spacing(1) },
}));

const PublicToolbar = ({ color, center, filter }) => {
	const classes = styles();

	return (
		<Grid item xs={12}>
			<Grid
				align="center"
				justify="center"
				container
				spacing={2}
				className={classes.toolBar}>
				<Grid
					item
					className={clsx(
						center ? classes.firstButton : null,
						center ? classes.fullwidth : null
					)}>
					<Button
						fullWidth
						variant={center ? "outlined" : "text"}
						size="small"
						color={color}
						component={Link}
						to={`/`}>
						Deal Board
					</Button>
				</Grid>

				<Grid item className={center ? classes.fullwidth : null}>
					<LoginSignupActions />
				</Grid>
			</Grid>
		</Grid>
	);
};

export default PublicToolbar;
