import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";

//MUI
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
//MUI Icons
import FilterListIcon from "@material-ui/icons/FilterList";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { toggleNavFilter } from "../../../redux/actions/uiActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	button: {
		color: theme.palette.primary.main,
		"&:hover": {
			backgroundColor: theme.palette.accent.main,
			color: "#fff",
			"& .icon": {
				color: "#fff",
			},
		},
	},
	active: {
		backgroundColor: theme.palette.accent.main,
		color: "#fff",
	},
}));
const ToggleFiltersButton = ({ scrollUp, className }) => {
	const navFilter = useSelector((state) => state.UI.navFilter);
	const classes = styles();
	const dispacth = useDispatch();

	const handleToggleShowFilters = () => {
		if (scrollUp && !navFilter.show) {
			window.scrollTo(0, 0);
		}
		dispacth(toggleNavFilter());
	};

	return (
		<Grid item>
			<Button
				size="small"
				className={clsx(
					className,
					classes.button,
					navFilter.show ? classes.active : null
				)}
				variant="contained"
				color="secondary"
				onClick={() => {
					handleToggleShowFilters();
				}}>
				<FilterListIcon
					className={clsx(
						"icon",
						navFilter.show ? classes.active : null
					)}
				/>
			</Button>
		</Grid>
	);
};

ToggleFiltersButton.propTypes = {
	className: PropTypes.string,
	scrollUp: PropTypes.any,
};
export default ToggleFiltersButton;
