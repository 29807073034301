import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect } from "react";
import { getTagLabel } from "../../../util/UtilityFunctions";
import "cropperjs/dist/cropper.css";
import Compressor from "compressorjs";
//Components
import ImageCropper from "../../../util/ImageCropper";
import FormHiddenFileInput from "../components/FormHiddenFileInput";
import FormDealActionArea from "../components/FormDealActionArea";
import FormImageControls from "../components/FormImageControls";
import {
	DealTextField,
	DealCurrencyTextField,
} from "../../controls/DealTextFields";

import DealSelect from "../../controls/DealSelect";
import DealChip from "../../controls/DealChip";
//MUI
import { useTheme } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";

//MUI Icons
import Typography from "@material-ui/core/Typography";
//Redux
import { toggleFormDialog } from "../../../redux/actions/uiActions";
import { useSelector, useDispatch } from "react-redux";
import {
	postDeal,
	editDeal,
	setDealLocation,
	findAddressByZicode,
} from "../../../redux/actions/dataActions";
import { clearResponses } from "../../../redux/actions/uiActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	search: {
		textAlign: "center",
		display: "block",
		margin: "0 auto",
		maxWidth: 300,
		color: theme.palette.secondary.main,
	},
	priceFieldPaper: {
		backgroundColor: theme.palette.primary.main,
		width: "100%",
		padding: 10,
	},
	description: {
		whiteSpace: "pre-line",
	},
}));

const CreateEditDealForm = ({ type }) => {
	const deal = useSelector((state) => state.data.deal);
	const location = useSelector((state) => state.data.location);

	const loading = useSelector((state) => state.data.loading);
	const errors = useSelector((state) => state.UI.responses.errors);
	const response = useSelector((state) => state.UI.responses);
	const messages = response.messages;

	let defaultFields;
	const defaultCreateFields = {
		title: "",
		city: "",
		state: "",
		street: "",
		zipcode: "",
		status: "published",
		price: "",
		arv: "",
		rehab: "",
		agreementFee: "",
		tags: {
			incomeProducing: {
				checked: false,
				label: "",
			},
			singleFamily: {
				checked: false,
				label: "",
			},
		},
	};

	const [image, setImage] = useState({
		name: "",
		blob: null,
		file: null,
	});

	const setDefaults = () => {
		switch (type) {
			case "create":
				//Requied for API
				defaultFields = defaultCreateFields;
				break;
			case "edit":
				defaultFields = {
					...deal,
				};
				break;
			default:
				defaultFields = defaultCreateFields;
		}
		return defaultFields;
	};
	const [input, setInput] = useState(setDefaults());
	const [undoImageCrop, setUndoImageCrop] = useState(null);
	const [sliderDisabled, setSliderDisabled] = useState(true);

	useEffect(() => {
		const setLocation = () => {
			if (location.city) {
				setInput({
					...input,
					city: location.city,
					state: location.state,
				});
			}
		};

		if (location) {
			setLocation();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, setInput]);

	useEffect(() => {
		let formType = type;
		if (formType === "edit") {
			if (messages.dealId) {
				let newPath = `/deals/${messages.dealId}`;
				window.history.pushState(null, null, newPath);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [messages.success]);

	useEffect(() => {
		if (type === "edit") {
			setInput({ ...input, ...deal });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deal.imageUrl]);

	useEffect(() => {
		if (type === "create") {
			dispatch(
				setDealLocation({
					city: "",
					state: "",
					street: "",
				})
			);
			setInput({ ...input, city: "", state: "", street: "" });
		} else {
			dispatch(
				setDealLocation({
					city: deal.city,
					state: deal.state,
					street: deal.street,
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const classes = styles();
	const dispatch = useDispatch();

	const handleSearchClick = () => {
		dispatch(findAddressByZicode(input.zipcode));
	};

	const handleClose = () => {
		setInput(defaultFields);
		dispatch(toggleFormDialog(false, null, null));
		dispatch(clearResponses());
	};

	const handleSelectChange = (name) => (e) => {
		e.preventDefault();
		setInput({
			...input,
			[name]: e.target.value,
		});
	};
	const handleInputChange = (e) => {
		e.preventDefault(e);
		setInput({
			...input,
			[e.currentTarget.name]: e.currentTarget.value,
		});
		//console.log(input);
	};

	const handleCurrencyChange = (e, c) => {
		e.preventDefault(e);

		setInput({
			...input,
			[e.target.name]: e.target.value,
		});
		//console.log(input);
	};

	const handletagChange = (e) => {
		e.preventDefault();
		setInput({
			...input,
			tags: {
				...input.tags,
				[e.currentTarget.name]: {
					checked: !input.tags[e.currentTarget.name].checked,
					tagId: e.currentTarget.name,
				},
			},
		});
	};

	const handleImageAdd = () => {
		const fileInput = document.getElementById("dealImageInput");
		fileInput.click();
		setSliderDisabled(false);
	};

	const handleImageChange = (event) => {
		const image = event.target.files[0];
		new Compressor(image, {
			quality: 0.5,
			success(result) {
				setUndoImageCrop(null);
				let reader = new FileReader();
				reader.readAsDataURL(result);
				reader.onload = () => {
					setImage({
						...image,
						file: reader.result,
						name: result.name,
					});
				};
			},
			error(err) {
				console.log(err);
			},
		});
	};

	const ref = React.createRef(null);

	///Easy-React-Crop
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up("sm"));

	const handleCrop = () => {
		setUndoImageCrop(image.file);
		const newImage = ref.current.cropper.getCroppedCanvas().toDataURL();
		setImage({ ...image, file: newImage });
	};

	const handleEnterKeyPressed = (e) => {
		if (e.keyCode === 13) {
			handleSearchClick();
		}
	};

	const handleSubmit = (e) => {
		if (ref.current) {
			let canvas = ref.current.cropper.getCroppedCanvas().toDataURL();

			if (canvas) {
				fetch(canvas)
					.then((res) => res.blob())
					.then((blob) => {
						if (type === "create") {
							handleCrop(e);

							dispatch(postDeal(input, blob, image.name));
						} else {
							dispatch(editDeal(input, blob, image.name));
						}
					})
					.catch((err) => {
						console.log(err);
					});
			}
		} else {
			if (type === "create") {
				dispatch(postDeal(input, null, null));
			} else {
				dispatch(editDeal(input, null, null));
			}
		}
	};

	const buttonWidth = type === "edit" ? 3 : 6;

	return (
		<Fragment>
			<FormHiddenFileInput onChange={(e) => handleImageChange(e)} />

			<Grid container spacing={1}>
				{/*Column#1*/}
				{/*Field: title*/}
				<Grid item xs={12} sm={12} md={6}>
					<Grid
						container
						item
						justify="space-around"
						direction="row"
						spacing={2}
						xs={12}>
						<Grid item xs={12}>
							<div className={classes.imageWrapper}>
								<ImageCropper
									imageFile={image.file}
									ref={ref}
									dealImageUrl={deal.imageUrl}
									matche={matches}
									type={type}
									sliderDisabled={sliderDisabled}
								/>
								<FormImageControls
									haveImage={image.file ? true : false}
									type={type}
									buttonWidth={buttonWidth}
									undoImageCrop={undoImageCrop}
									handleImageAdd={() => handleImageAdd()}
									handleCrop={(e) => {
										handleCrop(e);
									}}
									handleUndoCrop={() => {
										setImage({
											...image,
											file: undoImageCrop,
										});
										setUndoImageCrop(null);
									}}
									handleRestoreImage={() => {
										setImage({
											...image,
											file: null,
										});
										setUndoImageCrop(null);
									}}
								/>
							</div>
						</Grid>

						<DealTextField
							name="title"
							color="primary"
							type="text"
							label="Deal Title"
							value={input.title}
							placeholder="Enter Title Name"
							helperText={errors.title ? errors.title : null}
							error={errors.title ? true : false}
							onBlur={(e) => handleInputChange(e)}
							onChange={(e) => handleInputChange(e)}
						/>

						<DealTextField
							className={classes.description}
							name="description"
							type="text"
							label="Deal Description"
							value={input.description}
							placeholder="Enter Data"
							onBlur={(e) => handleInputChange(e)}
							onChange={(e) => handleInputChange(e)}
							multiline
							rows={5}
						/>
					</Grid>
					<hr className={classes.invisibleSeparator} />
				</Grid>

				{/*Column#2*/}
				<Grid item xs={12} sm={12} md={6}>
					<Grid
						container
						spacing={2}
						justify="space-around"
						direction="row">
						{/*Price Fields*/}
						<Grid item container>
							<Paper className={classes.priceFieldPaper}>
								<Grid container spacing={2}>
									{type === "edit" && (
										<DealSelect
											id="status"
											label="Deal Status"
											value={input.status}
											handleSelectChange={handleSelectChange(
												"status"
											)}>
											<MenuItem value={"published"}>
												Published
											</MenuItem>
											<MenuItem value={"sold"}>
												Sold
											</MenuItem>
										</DealSelect>
									)}
									<DealCurrencyTextField
										halfWidth
										value={input.price}
										label="Price"
										name="price"
										helperText={
											errors.price ? errors.price : null
										}
										error={errors.price ? true : false}
										onChange={(e) => {
											handleCurrencyChange(e);
										}}
									/>
									<DealCurrencyTextField
										value={input.arv}
										halfWidth
										label="ARV"
										name="arv"
										onChange={(e) =>
											handleCurrencyChange(e)
										}
									/>
									<DealCurrencyTextField
										value={input.rehab}
										halfWidth
										label="Rehab Cost"
										name="rehab"
										onChange={(e) =>
											handleCurrencyChange(e)
										}
									/>
									<DealCurrencyTextField
										value={input.assignmentFee}
										halfWidth
										label="Agreement Fee"
										name="assignmentFee"
										onChange={(e) =>
											handleCurrencyChange(e)
										}
									/>
								</Grid>
							</Paper>

							<hr className={classes.invisibleSeparator} />
						</Grid>

						{/*Address Fields*/}
						<div className="preview"></div>
						<Button
							fullWidth
							className={classes.search}
							align="center"
							variant="contained"
							color="primary"
							onClick={() => {
								handleSearchClick();
							}}>
							Find Location by Zipcode
						</Button>
						<DealTextField
							name="zipcode"
							type="number"
							label="Zipcode"
							value={input.zipcode || ""}
							helperText={errors.zipcode ? errors.zipcode : null}
							error={errors.zipcode ? true : false}
							onChange={(e) => handleInputChange(e)}
							onKeyDown={(e) => handleEnterKeyPressed(e)}
						/>
						<DealTextField
							disabled={
								location
									? location.city
										? false
										: type === "edit"
										? false
										: true
									: null
							}
							variant="outlined"
							name="street"
							type="text"
							label="Street Address"
							value={input.street || ""}
							onChange={(e) => handleInputChange(e)}
						/>
						{/*Disable text field: city*/}
						<Grid item xs={6}>
							<FormControl variant="outlined" size="small">
								<InputLabel
									style={{ color: "grey" }}
									shrink={
										input.city || location.city
											? true
											: false
									}
									htmlFor="city">
									City
								</InputLabel>

								<OutlinedInput
									style={{ backgroundColor: "#deffde" }}
									disabled={true}
									id="city"
									name="city"
									type="text"
									label="city"
									value={input.city || location.city}
								/>
							</FormControl>
						</Grid>
						{/*Disable text field: state*/}
						<Grid item xs={6}>
							<FormControl variant="outlined" size="small">
								<InputLabel
									style={{ color: "grey" }}
									shrink={
										input.state || location.state
											? true
											: false
									}
									htmlFor="state">
									State
								</InputLabel>

								<OutlinedInput
									style={{ backgroundColor: "#deffde" }}
									disabled={true}
									id="state"
									name="state"
									type="text"
									label="state"
									value={input.state || location.state}
								/>
							</FormControl>
						</Grid>

						{/*<hr
									style={{ width: "95%" }}
									className={classes.visibleSeparator}
								/>*/}
						{/*End Price Fields*/}
						{/*Deal Chips*/}
						<Grid item xs={12}>
							<Typography variant="body2">
								Optional Tags:
							</Typography>
						</Grid>

						<DealChip
							halfWidth
							id="incomeProducing"
							name="incomeProducing"
							checked={input.tags.incomeProducing.checked}
							value={input.tags.incomeProducing.checked}
							label={getTagLabel("incomeProducing")}
							onClick={(e) => handletagChange(e)}
						/>
						<DealChip
							halfWidth
							id="singleFamily"
							name="singleFamily"
							checked={input.tags.singleFamily.checked}
							value={input.tags.singleFamily.checked}
							label={getTagLabel("singleFamily")}
							onClick={(e) => handletagChange(e)}
						/>
					</Grid>
				</Grid>
				{/*End Column #2*/}
				<hr className={classes.visibleSeparator} />
				<FormDealActionArea
					messages={messages}
					errors={errors}
					onClick={() => handleSubmit()}
					handleClose={() => handleClose()}
					type={type}
					loading={loading}
				/>
			</Grid>
		</Fragment>
	);
};

CreateEditDealForm.propTypes = {
	type: PropTypes.string.isRequired,
};
export default CreateEditDealForm;
