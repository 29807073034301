import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
//Components
import Form from "../components/forms/components/Form";
import SpinnerButton from "../components/controls/SpinnerButton";
import { DealTextField } from "../components/controls/DealTextFields";
import CenterFormsLayout from "../components/layouts/CenterFormsLayout";
import DealSelect from "../components/controls/DealSelect";
import GeneralFormGroup from "../components/controls/selectors/GeneralFormGroup";
import GeneralFormControlTextBox from "../components/controls/selectors/GeneralFormControlTextBox";
import GeneralButton from "../components/controls/buttons/GeneralButton";
//MUI Components
import WholesalerContractSection from "../components/sections/WholesalerContractSection";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DialogActions, Button } from "@material-ui/core";
//Redux
import { useSelector, useDispatch } from "react-redux";
import {
	setAlertWithConfirmation,
	setAlert,
	clearAlerts,
} from "../redux/actions/alertActions";
import { signupUser, setSignupReferral } from "../redux/actions/userActions";
import { toggleFormDialog } from "../redux/actions/uiActions";
//Styles
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	signupForm: { marginTop: 10 },
	textField: { marginTop: 0 },
	link: {
		color: "#fff",
		fontWeight: 700,
		textDecoration: "underline",
		textShadow: "2px 2px 4px #000000",
	},
	shadow: {
		fontWeight: 700,
		textShadow: "2px 2px 4px #000000",
	},
}));

const Signup = (props) => {
	const alert = useSelector((state) => state.alert);
	const dialog = useSelector((state) => state.UI.dialog);
	const signup = useSelector((state) => state.user.signup);
	const loading = useSelector((state) => state.UI.loading);
	const errors = useSelector((state) => state.UI.responses.errors);
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const classes = styles(props);

	let params = useParams();

	useEffect(() => {
		if (params) {
			setInput({
				...input,
				referredBy: params.referredBy,
			});
		}
	}, [params]);

	useEffect(() => {
		//confirmation turn off after server after response--> systemAction setUserRole()

		if (alert.confirmationForm === "signupWholesaler" && alert.confirmed) {
			setInput({
				...input,
				wholesalerAgreementSigned: true,
			});
			dispatch(clearAlerts());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [alert.confirmed, dispatch]);

	const [input, setInput] = useState({
		type: "wholesaler",
		firstName: "",
		lastName: "",
		referredBy: "",
		email: "",
		password: "",
		terms: false,
		privacy: false,
		wholesalerAgreementSigned: false,
	});
	const handleSubmit = (e) => {
		e.preventDefault();

		//dispatch(toggleFormDialog(true, "wholesalerContract"));

		dispatch(signupUser(input, props.history));
	};

	const handleTextFormat = (name, value) => {
		let handleLetter = value.toLowerCase();
		if (/\s/.test(handleLetter)) {
			//console.log("no spaces in handle allowed");
		} else {
			setInput({
				...input,
				[name]: handleLetter,
			});
		}
	};

	const handleCheckBoxChange = (event) => {
		setInput({ ...input, [event.target.name]: event.target.checked });
	};

	const contractData = {
		firstName: input.firstName,
		lastName: input.lastName,
	};
	const handleWholesalerContractButtonClick = () => {
		dispatch(
			setAlertWithConfirmation(
				"signupWholesaler",
				"success",
				"Contract",
				<WholesalerContractSection contractData={contractData} />,
				null,
				null,
				null,
				"md",
				null,
				"Sign Contract"
			)
		);
	};

	const contractForm = (
		<>
			<DealTextField
				id="confirmPassword"
				name="confirmPassword"
				type="text"
				label="Confirm Password"
				className={classes.textField}
				fullWidth
			/>
		</>
	);

	const formGroupCheckBoxesLabel = (
		<Typography variant="caption" display="inline">
			Check the boxes below if you accept the{" "}
			<Link to="/terms" target="_blank" className={classes.link}>
				Terms and Conditions
			</Link>{" "}
			and{" "}
			<Link to="/terms" target="_blank" className={classes.link}>
				Privacy Policy
			</Link>
		</Typography>
	);
	const agreementSignedCheckBoxHeader = (
		<Typography style={{ width: "100%" }} display="block" variant="caption">
			Check the box to sign the wholesaler contract:
		</Typography>
	);
	const handleSelectChange = (name) => (e) => {
		e.preventDefault();
		setInput({
			...input,
			[name]: e.target.value,
		});
	};

	const handleInputChange = (e) => {
		if (e.currentTarget.name === "handle") {
			handleTextFormat(e.currentTarget.name, e.currentTarget.value);
		} else {
			setInput({
				...input,
				[e.currentTarget.name]: e.currentTarget.value,
			});
		}
	};

	return (
		<CenterFormsLayout
			title="Signup"
			linkTo="/login"
			linkInfoText="have an account? login"
			linkText="here"
			pngLogo>
			<form noValidate>
				{dialog.open && dialog.form === "wholesalerContract" && (
					<Form
						maxWidth="md"
						titleText="Wholesaler Agreement"
						component={
							<WholesalerContractSection
								contractData={contractData}
							/>
						}
					/>
				)}
				<Grid container className={classes.signupForm} spacing={2}>
					<DealTextField
						halfWidth
						id="firstName"
						name="firstName"
						type="text"
						label="First Name"
						className={classes.textField}
						helperText={errors.firstName}
						error={errors.firstName ? true : false}
						value={input.firstName}
						onChange={handleInputChange}
					/>
					<DealTextField
						halfWidth
						id="lastName"
						name="lastName"
						type="text"
						label="Last Name"
						className={classes.textField}
						helperText={errors.lastName}
						error={errors.lastName ? true : false}
						value={input.lastName}
						onChange={handleInputChange}
					/>

					<DealTextField
						halfWidth
						id="handle"
						name="handle"
						type="text"
						label="Username"
						className={classes.textField}
						helperText={errors.handle}
						error={errors.handle ? true : false}
						value={input.handle}
						onChange={handleInputChange}
					/>
					<DealTextField
						halfWidth
						id="email"
						name="email"
						type="email"
						label="Email"
						className={classes.textField}
						helperText={errors.email}
						error={errors.email ? true : false}
						value={input.email}
						onChange={handleInputChange}
						fullWidth
					/>
					<DealTextField
						id="referredBy"
						name="referredBy"
						type="text"
						label="Referral Code"
						className={classes.referredBy}
						helperText={errors.referredBy}
						error={errors.referredBy ? true : false}
						value={input.referredBy}
						onChange={handleInputChange}
					/>

					<DealTextField
						halfWidth
						id="password"
						name="password"
						type="password"
						label="Password"
						className={classes.textField}
						helperText={errors.password}
						error={errors.password ? true : false}
						value={input.password}
						onChange={handleInputChange}
						fullWidth
					/>
					<DealTextField
						halfWidth
						id="confirmPassword"
						name="confirmPassword"
						type="password"
						label="Confirm Password"
						className={classes.textField}
						helperText={errors.confirmPassword}
						error={errors.confirmPassword ? true : false}
						value={input.confirmPassword}
						onChange={handleInputChange}
						fullWidth
					/>
					<Grid item container xs={12} spacing={12}>
						<Grid item xs={12}>
							<GeneralFormGroup
								error={errors.termsPrivacyError}
								groupLabel={formGroupCheckBoxesLabel}>
								<GeneralFormControlTextBox
									name="terms"
									checked={input.terms}
									label={
										<Typography variant="caption">
											Terms and Conditions
										</Typography>
									}
									onChange={handleCheckBoxChange}
								/>
								<GeneralFormControlTextBox
									name="privacy"
									checked={input.privacy}
									label={
										<Typography variant="caption">
											Privacy Policy
										</Typography>
									}
									onChange={handleCheckBoxChange}
								/>
							</GeneralFormGroup>
							{errors.general && (
								<Typography
									variant="body2"
									className={classes.generalError}>
									{errors.general}
								</Typography>
							)}
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item container alignItems="center" xs={12}>
						{input.firstName !== "" &&
							input.lastName !== "" &&
							(!input.wholesalerAgreementSigned ? (
								<>
									<Grid item xs={12}>
										<GeneralButton
											text="Sign Wholesaler Contract"
											variant="contained"
											color="primary"
											onClick={() =>
												handleWholesalerContractButtonClick()
											}></GeneralButton>
									</Grid>
									<Grid item xs={12}>
										{errors.wholesalerAgreementSigned && (
											<Typography
												variant="body2"
												className={
													classes.generalError
												}>
												{
													errors.wholesalerAgreementSigned
												}
											</Typography>
										)}
									</Grid>
								</>
							) : (
								<>
									<Grid item xs={12}>
										<Typography
											className={classes.shadow}
											variant="subtitle1"
											align="center"
											color="primary">
											Wholesaler Contract Signed
										</Typography>
									</Grid>
								</>
							))}
					</Grid>
					<SpinnerButton
						className={classes.button}
						type="submit"
						color="primary"
						variant="contained"
						disabled={!input.wholesalerAgreementSigned || loading}
						loading={loading}
						onClick={handleSubmit}>
						Signup
					</SpinnerButton>
				</Grid>
			</form>
		</CenterFormsLayout>
	);
};

Signup.propTypes = {
	history: PropTypes.any,
};

export default Signup;
