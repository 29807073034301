import React from "react";
import clsx from "clsx";
//Component
import UserTypeBadge from "../../badges/UserTypeBadge";
import SystemCardButtons from "./SystemCardButtons";
import SystemCardDetail from "./SystemCardDetail";
import SystemUserBadge from "./SystemUserBadge";
//MUI Components
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	systemCard: {},
	card: {
		position: "relative",
		display: "flex",
	},
	cardReview: {
		backgroundColor: "rgba(81,196,108,0.15)",
	},
	cardContent: { marginTop: 0, paddingTop: 0 },
	cardCanceled: { backgroundColor: "rgba(255,0,0,0.15)" },
	userName: {
		margin: 0,
	},
}));

const SystemUserCard = ({ user, noButtons, badgeFullwidth }) => {
	const classes = styles();

	const setStatusClassName = () => {
		let verificationClassName;
		let subscriptionClassName;
		let statusClassName;

		user.verificationStatus === "review" && !user.verified
			? (verificationClassName = classes.cardReview)
			: (verificationClassName = null);

		user.subscriptionStatus === "canceled"
			? (subscriptionClassName = null) ///classes.cardCanceled
			: (subscriptionClassName = null);

		statusClassName = clsx(subscriptionClassName);

		return statusClassName;
	};

	return (
		<Grid
			className={clsx(classes.systemCard, classes.formBackground)}
			item
			container
			xs={12}
			component={Card}>
			<Grid item xs={12} align="center">
				<Typography classes={classes.userName}>
					{user.lastName}, {user.firstName}
				</Typography>
			</Grid>
			{user.verified ? (
				<UserTypeBadge
					badgeCard
					user={user}
					className={classes.typeBadge}
				/>
			) : (
				<Grid
					item
					xs={12}
					id="verified-badge-placeholder"
					style={{ marginTop: 5 }}></Grid>
			)}
			<Grid
				className={clsx(
					classes.card,
					classes.cardContent,
					setStatusClassName()
				)}
				component={CardContent}
				container
				spacing={1}>
				<Grid item xs={4}>
					<SystemUserBadge user={user} badgeFullwidth={true} />
				</Grid>
				<Grid item xs={8}>
					{!noButtons && <SystemCardButtons user={user} />}
				</Grid>
				<SystemCardDetail user={user} />
			</Grid>
		</Grid>
	);
};

export default SystemUserCard;
