import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";

//Components
import LogoutButton from "../../controls/buttons/LogoutButton";
import DealBoardButton from "../../controls/buttons/deals/DealBoardButton";
import GetVerifiedButton from "../../controls/buttons/user/GetVerifiedButton";
import InboxLinkButton from "../../controls/buttons/inbox/InboxLinkButton";
import InboxRefreshButton from "../../controls/buttons/inbox/InboxRefreshButton";
import MessagesRefreshButton from "../../controls/buttons/inbox/MessagesRefreshButton";
import Notifications from "../Notifications";
//MUI Components
import Grid from "@material-ui/core/Grid";
// Redux
import { useSelector } from "react-redux";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	toolBar: {
		padding: theme.spacing(1),
	},
	menuButtonRight: {
		marginRight: theme.spacing(2),
		float: "right",
		flexGrow: 1,
	},
	editButton: {
		//color: "#fff",
		//backgroundColor: theme.palette.information.dark,
	},
	fullwidth: {
		width: "100%",
	},
	firstButton: { marginTop: theme.spacing(1) },
	grow: {
		flexGrow: 1,
	},
	notification: {
		marginTop: "-3px",
		marginBottom: "-10px",
	},
}));

const DealToolBar = ({ center }) => {
	const view = useSelector((state) => state.inbox.view);
	const authUser = useSelector((state) => state.user.credentials);
	const conversation = useSelector((state) => state.inbox.conversation);
	const classes = styles();

	return (
		<Grid item xs={12}>
			<Grid
				align="center"
				justify="center"
				container
				spacing={2}
				className={classes.toolBar}>
				<Grid
					item
					className={clsx(
						center ? classes.firstButton : null,
						center ? classes.fullwidth : null
					)}>
					<DealBoardButton variant="outlined" menu />
				</Grid>
				{(!view || view === "messages") && (
					<Grid item className={center ? classes.fullwidth : null}>
						<InboxLinkButton
							menu
							variant="outlined"
							userHandle={authUser.handle}
						/>
					</Grid>
				)}
				{view === "conversations" && (
					<Grid item className={center ? classes.fullwidth : null}>
						<InboxRefreshButton menu variant="outlined" />
					</Grid>
				)}
				{view === "messages" && (
					<Grid item className={center ? classes.fullwidth : null}>
						{/*<MessagesRefreshButton
							conversationId={conversation.conversationId}
							menu
							variant="outlined"
						/>*/}
					</Grid>
				)}
				<Grid className={classes.notification}>
					<Notifications />
				</Grid>
				<div className={classes.grow} />

				<Grid item className={center ? classes.fullwidth : null}>
					<LogoutButton menu fullWidth />
				</Grid>
				<Grid item className={center ? classes.fullwidth : null}>
					<GetVerifiedButton menu menuButtonRight />
				</Grid>
			</Grid>
		</Grid>
	);
};

DealToolBar.propTypes = {
	center: PropTypes.any,
};

export default DealToolBar;
