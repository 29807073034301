import PropTypes from "prop-types";
import React from "react";
//Components
import ToolTipButton from "../ToolTipButton";
//MUI Components
import Grid from "@material-ui/core/Grid";
//MUI Icons
import DeleteIcon from "@material-ui/icons/Delete";
// Redux
import { useDispatch } from "react-redux";
import { deleteMessage } from "../../../../redux/actions/inboxActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	comment: { marginBottom: 15 },
	commentUserBadge: {
		maxWidth: 125,
		maxHeight: 125,
	},
	commentData: {
		marginLeft: 5,
	},
	ownerComment: {
		backgroundColor: "#a4ecb5",
		color: "#fff",
	},
	ownerLabel: {
		marignBottom: "-40px",
	},
}));

const MessageDeleteButton = ({ messageId }) => {
	const classes = styles();
	const dispatch = useDispatch();

	const handleDeleteComment = () => {
		dispatch(deleteMessage(messageId));
	};

	return (
		<Grid item>
			<ToolTipButton
				edge="end"
				titleClassName={classes.toolTipButton}
				title="Delete Note"
				btnClassName="button"
				onClick={() => {
					handleDeleteComment();
				}}>
				<DeleteIcon />
			</ToolTipButton>
		</Grid>
	);
};

MessageDeleteButton.propTypes = {
	messageId: PropTypes.string.isRequired,
};

export default MessageDeleteButton;
