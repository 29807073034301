import React from "react";
import clsx from "clsx";
//Functions
import { addCommasToNumber } from "../../util/UtilityFunctions";

//MUI
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
//import { CLEAR_ERRORS } from "../redux/types";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	priceFieldPaper: {
		backgroundColor: "#fff",
		color: theme.palette.primary.main,
		borderColor: theme.palette.primary.main,
		borderWidth: 1,
		borderStyle: "solid",
		width: "100%",
		padding: 3,
		minHeight: 45,
	},
	priceFieldPaperCompact: {
		backgroundColor: "rgba(81,196,108,0)",
		//color: "#fff",
		//backgroundColor: "rgba(255,255,255,.5)",
		//color: theme.palette.primary.main,
		//textShadow: "1px 1px 1px #000000",
		width: "100%",
		padding: 3,
		maxHeight: 40,
		position: "absolute",
		bottom: 10,
		[theme.breakpoints.down("sm")]: {
			marginBottom: 5,
		},
	},
	priceText: {
		backgroundColor: "rgba(255,255,255,0)",
		color: theme.palette.primary.main,
	},
	priceTextCompact: {
		backgroundColor: "rgba(81,196,108,.7)",
		//backgroundColor: "rgba(255,255,255,.5)",
		color: "#fff",
		textShadow: "1px 1px 1px #000000",
		borderRadius: 4,
		padding: "3px 6px",
		marginBottom: 5,
	},

	priceLabel: {
		fontWeight: 700,
	},
}));

const Prices = ({ priceFields, compact, linkTo }) => {
	const classes = styles();

	return (
		<Paper
			elevation={compact ? 0 : 1}
			className={clsx(
				!compact
					? classes.priceFieldPaper
					: classes.priceFieldPaperCompact
			)}>
			<Grid container justify="space-evenly" spacing={2}>
				{priceFields[0].value && priceFields[0].value !== 0 ? (
					priceFields.map(
						(field, index) =>
							field.value && (
								<Grid key={index} item>
									<Grid
										direction="column"
										justify="space-evenly"
										className={clsx(
											!compact
												? classes.priceText
												: classes.priceTextCompact
										)}
										container>
										<Grid item>
											<Typography
												className={classes.priceLabel}
												variant="body2"
												align="center">
												{field.label}
											</Typography>
										</Grid>
										<Grid item>
											<Typography
												variant="body2"
												align="center">
												{addCommasToNumber(field.value)}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							)
					)
				) : (
					<Grid item>
						<Typography variant="body2" align="center">
							<Grid
								direction="column"
								justify="space-evenly"
								container>
								<Typography>
									Pricing Information Not Avaiable
								</Typography>
							</Grid>
						</Typography>
					</Grid>
				)}
			</Grid>
		</Paper>
	);
};

export default Prices;
