import React from "react";

//Components
import ActionBar from "./ActionBar";
import InboxToolBar from "./toolbars/InboxToolBar";

const defaultProps = { color: "secondary" };

const DealNavBar = ({ color = defaultProps.color, position }) => {
	return (
		<ActionBar position={position}>
			<InboxToolBar color={color} />
		</ActionBar>
	);
};

export default DealNavBar;
