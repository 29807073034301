import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
//Redux
import { useSelector, useDispatch } from "react-redux";
//Components
import FormMessageArea from "./FormMessageArea";
//MUI
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
import { clearResponses } from "../../../redux/actions/uiActions";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	formButton: {
		color: "white",
		marginTop: 0,
		[theme.breakpoints.up("sm")]: {
			marginBottom: 5,
		},
	},
}));
const FormDealActionArea = ({ handleClose, type, onClick }) => {
	const loading = useSelector((state) => state.data.loading);
	const errors = useSelector((state) => state.UI.responses.errors);
	const messages = useSelector((state) => state.UI.responses.messages);
	const classes = styles();

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(clearResponses());
	}, []);

	return (
		<Grid item container direction="row-reverse" xs={12}>
			<Grid item container spacing={1} xs={12} sm={3}>
				{type === "create" && messages.success ? (
					<Button
						type="button"
						fullWidth
						variant="contained"
						color="primary"
						component={Link}
						className={clsx(classes.button, classes.formButton)}
						to={`/deals/${messages.dealId}`}
						onClick={handleClose}>
						View Deal
					</Button>
				) : (
					<Button
						fullWidth
						type="submit"
						variant="contained"
						color="primary"
						onClick={onClick}
						className={clsx(classes.button, classes.formButton)}
						disabled={
							loading || (type === "create" && messages.success)
						}>
						{type === "create" ? "Publish Deal" : "Update Deal"}
						{loading && (
							<CircularProgress
								size={30}
								className={classes.spinner}
							/>
						)}
					</Button>
				)}
			</Grid>
			<Grid item xs={12} sm={9}>
				<FormMessageArea messages={messages} errors={errors} />
			</Grid>
		</Grid>
	);
};

FormDealActionArea.propTypes = {
	handleClose: PropTypes.func.isRequired,
	onClick: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
};

export default FormDealActionArea;
