import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const FormUploadFileButton = ({ onClick, fullWidth, title, className }) => {
	return (
		<Grid item xs={12} sm={!fullWidth ? 6 : 12}>
			<Button
				className={className}
				fullWidth
				variant="contained"
				color="primary"
				onClick={onClick}>
				{title}
			</Button>
		</Grid>
	);
};

export default FormUploadFileButton;
