import {
	LOADING_UI,
	CLEAR_RESPONSES,
	SET_ERRORS,
	TOGGLE_FORM_DIALOG,
	TOGGLE_NAV_DRAWER,
	TOGGLE_NAV_FILTER,
	SET_HEADERS,
	SET_RESPONSIVE,
	REDIRECT,
	TIME_DELAY

} from "../types"

// action creators
export const redirect = link => dispatch => {
	console.log("=== REDIRECT ACTION DISPATCHED ===");
	dispatch({
		type: REDIRECT,
		payload: link
	});
};

export const setHeaderData = header => dispatch => {
	dispatch({
		type: SET_HEADERS,
		payload: header
	});
}

export const loadingUI = loading => dispatch => {
	dispatch({
		type: LOADING_UI,
		payload: loading
	});
}

export const clearResponses = () => dispatch => {
	dispatch({
		type: CLEAR_RESPONSES,
	});
}

export const toggleFormDialog = (open, form, args) => dispatch => {
	let payload = {
		open,
		form,
		args
	}

	dispatch({
		type: TOGGLE_FORM_DIALOG,
		payload: payload
	})
}

export const toggleNavDrawer = (open, toolbar) => dispatch => {
	let payload = {
		open,
		toolbar
	}

	dispatch({
		type: TOGGLE_NAV_DRAWER,
		payload: payload
	})
}
export const setResponsive = (screenSize) => dispatch => {
	dispatch({
		type: SET_RESPONSIVE,
		payload: screenSize
	})
}

export const toggleNavFilter = () => dispatch => {
	dispatch({
		type: TOGGLE_NAV_FILTER,
	})
}

export const setErrors = (errorData) => dispatch => {
	dispatch({
		type: SET_ERRORS,
		payload: errorData
	})
}
