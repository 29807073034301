import PropTypes from "prop-types";
import React, { useState } from "react";
import clsx from "clsx";
//MUI Component
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { editComment } from "../../redux/actions/commentActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	messageArea: { textAlign: "center", marginBottom: 20 },
	submitButton: {
		textAlign: "center",
		display: "block",
		margin: "0 auto",
		width: "100%",
	},
	editForm: {
		display: "block",
	},
	hidden: {
		display: "hidden",
	},
	editButton: { margin: 0, position: "absolute", right: 0 },
	comment: {
		position: "relative",
	},

	editTextArea: {
		width: "100%",
		maxWidth: "100%",
		font: "inherit",
		borderColor: theme.palette.primary.main,
		borderWidth: 1,
		borderRadius: 4,
		color: theme.palette.primary.main,
		outline: "none",
	},
}));

const CommentEdit = ({ comment, onClose }) => {
	const form = useSelector((state) => state.UI.forms.comment);
	const errors = useSelector((state) => state.UI.responses.errors);
	const messages = useSelector((state) => state.UI.responses.messages);

	const dispatch = useDispatch();
	const classes = styles();

	const defaultValues = comment ? comment : { body: {} };

	const [input, setInput] = useState(defaultValues);

	const handleInputChange = (e) => {
		e.preventDefault();
		setInput({
			...input,
			[e.currentTarget.name]: e.currentTarget.value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		/*dispatch(setCommentEdit(input))*/
		dispatch(editComment(input));
		onClose();
	};

	return (
		<form>
			<Grid container>
				<Grid item xs={11} sm={12}>
					<TextareaAutosize
						rowsMin={3}
						variant="outlined"
						name="body"
						type="text"
						label="Leave a comment"
						value={input.body}
						placeholder="Enter comment here"
						className={clsx(
							classes.textField,
							classes.editTextArea
						)}
						onChange={(e) => handleInputChange(e)}
					/>
				</Grid>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Button
							size="small"
							fullWidth
							disabled={messages.success}
							type="submit"
							variant="contained"
							color="primary"
							className={classes.submitButton}
							onClick={(e) => {
								handleSubmit(e);
							}}>
							Update
						</Button>
					</Grid>
					<Grid item xs={6}>
						<Button
							size="small"
							fullWidth
							disabled={messages.success}
							type="button"
							variant="contained"
							color="primary"
							className={classes.submitButton}
							onClick={onClose}>
							Cancel
						</Button>
					</Grid>
				</Grid>
			</Grid>
			<div className={classes.messageArea}>
				{errors.general ||
					(errors.error && (
						<Typography variant="body2" style={{ color: "red" }}>
							{errors.error}
							{errors.general}
						</Typography>
					))}
				{messages.success && (
					<Typography variant="body2" className={classes.success}>
						{messages.success}
					</Typography>
				)}
			</div>
		</form>
	);
};

CommentEdit.propTypes = {
	comment: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default CommentEdit;
