import React from "react";
//Components
import VerificationFileUploadForm from "../forms/system/VerificationFileUploadForm";
//MUI
import Grid from "@material-ui/core/Grid";

const Verification = () => {
	return (
		<Grid item xs={12}>
			<VerificationFileUploadForm />
		</Grid>
	);
};

export default Verification;
