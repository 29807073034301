import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
//MUI
import Button from "@material-ui/core/Button";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
}));
const defaultProps = {
	text: "Button",
	size: "medium",
	color: "secondary",
	fullWidth: true,
};

const GeneralButton = ({
	menu,
	variant,
	className,
	onClick,
	size = defaultProps.size,
	color = defaultProps.color,
	text = defaultProps.text,
	fullWidth = defaultProps.fullWidth,
}) => {
	const classes = styles();
	return (
		<Button
			size={clsx(menu ? "small" : size)}
			fullWidth={fullWidth}
			className={clsx(classes.buttonColorWhite, className)}
			variant={variant}
			color={color}
			onClick={onClick}>
			{text}
		</Button>
	);
};

GeneralButton.propTypes = {
	className: PropTypes.string,
	color: PropTypes.any,
	linkTo: PropTypes.string,
	menu: PropTypes.bool,
	onClick: PropTypes.any,
	size: PropTypes.string,
	text: PropTypes.any.isRequired,
	variant: PropTypes.string,
};

export default GeneralButton;
