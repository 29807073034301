import React, { useState } from "react";
//Components
import BasicLayout from "../components/layouts/BasicLayout";
//MUI
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
//Redux
import { uploadVerificationFile } from "../redux/actions/userActions";
import { useDispatch } from "react-redux";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
import { DealTextField } from "../components/controls/DealTextFields";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	main: {
		backgroundColor: "white",
		height: "90vh",
	},
	sideProfile: {
		minWidth: 240,
	},
}));

const Verification = (props) => {
	const [file, setFile] = useState({
		data: null,
		name: "",
	});
	const dispatch = useDispatch();
	const classes = styles();

	const handleFileAdd = () => {
		const fileInput = document.getElementById("verificationFileInput");
		fileInput.click();
	};

	const handleFileChange = (event) => {
		const data = event.target.files[0];
		setFile({
			data: data,
			name: data.name,
		});
	};

	const handleSubmit = () => {
		const formData = new FormData();
		formData.append("file", file.data, file.name);
		dispatch(uploadVerificationFile(formData));
	};
	return (
		<BasicLayout>
			<Paper className={classes.main}>
				<input
					type="file"
					id="verificationFileInput"
					accept="application/pdf"
					onChange={(e) => handleFileChange(e)}
					hidden="hidden"
				/>
				<Grid
					container
					spacing={2}
					direction="row"
					justify="center"
					alignItems="center">
					<Grid item xs={12} sm={4}></Grid>

					<Grid item></Grid>

					<Grid
						container
						direction="column"
						align="center"
						justify="center"
						spacing={2}
						xs={12}
						sm={4}>
						<Grid>
							<br />
							<Typography
								variant="h4"
								className={classes.pageTitle}>
								Verification Document
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<DealTextField readOnly value={file.name} />
						</Grid>
						<Grid item xs={12}>
							<Button
								fullWidth
								variant="contained"
								color="primary"
								onClick={() => handleFileAdd()}>
								Upload Verification File
							</Button>
						</Grid>
						<Grid item xs={12}>
							<Button
								fullWidth
								variant="contained"
								color="primary"
								onClick={() => handleSubmit()}>
								Submit
							</Button>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={4}></Grid>
				</Grid>
			</Paper>
		</BasicLayout>
	);
};

export default Verification;
