import React from "react";
//Components
import ContentFullScreen from "./body/ContentFullScreen";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	dealCard: {
		backgroundColor: "#51C46C",
	},
	root: {
		flexGrow: 1,
	},
	sideProfile: {
		minWidth: 240,
	},
}));

const FullScreenLayout = ({ children, backgroundColor }) => {
	const classes = styles();
	return (
		<ContentFullScreen backgroundColor={backgroundColor}>
			<div className={classes.root}>{children}</div>
		</ContentFullScreen>
	);
};

export default FullScreenLayout;
