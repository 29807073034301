import {
	SET_VA_PACKAGE,
	SET_VA_PACKAGES,
	LOADING_CHECKOUT,
	STOP_LOADING_CHECKOUT,
	SET_CHECKOUT_LOGIN_REDIRECT
} from "../types"

const initialState = {
	data: {},
	loading: false,
	packages: [],
	virtualAssistant: {
		package: null
	},
	redirect: ""
}

export default function(state = initialState, action) {
	switch (action.type) {
		case SET_CHECKOUT_LOGIN_REDIRECT:
			return {
				...state,
				redirect: action.payload
			}
			case SET_VA_PACKAGE:
				return {
					...state,
					virtualAssistant: {
						...state.oneTimePayment,
						package: action.payload
					}

				};
			case SET_VA_PACKAGES:
				return {
					...state,
					packages: [
						...action.payload
					]
				};
			case LOADING_CHECKOUT:
				return {
					...state,
					loading: true
				};
			case STOP_LOADING_CHECKOUT:
				return {
					...state,
					loading: false
				}
				default:
					return state
	}
}
