import React, { useState, useEffect } from "react";
//Components

import { DealTextField } from "../../controls/DealTextFields";
import FormActionArea from "../components/FormActionArea";
//MUI
import Grid from "@material-ui/core/Grid";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { submitMessage } from "../../../redux/actions/inboxActions";
import {
	toggleFormDialog,
	clearResponses,
} from "../../../redux/actions/uiActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	button: {
		float: "right",
	},
	toolTipButton: { color: "white" },
}));

const SendMessageReplyForm = () => {
	const authUser = useSelector((state) => state.user.credentials);
	const conversation = useSelector((state) => state.inbox.conversation);
	const loading = useSelector((state) => state.UI.loading);
	const errors = useSelector((state) => state.UI.responses.errors);

	let userData;
	if (conversation.firstParticipant) {
		if (conversation.firstParticipant.id === authUser.userId) {
			userData = {
				userId: conversation.secondParticipant.id,
				handle: conversation.secondParticipant.handle,
				imageUrl: conversation.secondParticipant.imageUrl,
			};
		} else {
			userData = {
				userId: conversation.firstParticipant.id,
				handle: conversation.firstParticipant.handle,
				imageUrl: conversation.firstParticipant.imageUrl,
			};
		}
	}

	const [input, setInput] = useState({
		...userData,
		body: "",
	});
	const classes = styles();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(clearResponses());
	}, []);

	const handleCancel = () => {
		dispatch(toggleFormDialog(false, null));
	};

	const handleInputChange = (e) => {
		setInput({
			...input,
			[e.currentTarget.name]: e.currentTarget.value,
		});
	};

	const handleSubmit = () => {
		dispatch(submitMessage(input, true));
		setInput({ ...input, body: "" });
	};

	return (
		<Grid container>
			<Grid id="col1" item container spacing={2}>
				<DealTextField
					fullWidth
					name="body"
					type="text"
					label="Note"
					multiline
					rows="3"
					placeholder={`Leave a short note for ${input.handle}`}
					className={classes.textField}
					value={input.body}
					onChange={(e) => handleInputChange(e)}
					helperText={errors.body ? errors.body : null}
					error={errors.body ? true : false}
				/>
			</Grid>
			<Grid item xs={12}>
				<FormActionArea
					loading={loading}
					hideCancel
					onCancel={() => handleCancel()}
					cancelBtnText={"Cancel"}
					onClick={() => handleSubmit()}
					actionBtnText={"Send"}
				/>
			</Grid>
		</Grid>
	);
};

export default SendMessageReplyForm;
