import React, { useEffect } from "react";
//Components
import NavBarMobile from "../components/navigation/mobile/NavBarMobile";
import VirtualAssistantSection from "../components/sections/VirtualAssistantSection";
import NavBarMainMenu from "../components/navigation/NavBarMainMenu";
import CreateDealFab from "../components/controls/buttons/deals/CreateDealFab";
import AuthProfile from "../components/profile/AuthProfile";
//MUI
import Hidden from "@material-ui/core/Hidden";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { getDeals } from "../redux/actions/dataActions";
import MainLayout from "../components/layouts/MainLayout";

const VirtualAssistant = () => {
	const user = useSelector((state) => state.user.credentials);
	const deals = useSelector((state) => state.data.deals);
	const data = useSelector((state) => state.data);
	const dispatch = useDispatch();

	return (
		<MainLayout
			sidebar={
				<AuthProfile
					ctaTo={`/profiles/${user.handle}`}
					ctaText="View My Deals"
				/>
			}>
			<NavBarMainMenu filter />
			<VirtualAssistantSection />
			<Hidden smDown>
				<CreateDealFab form="deal" args={{ type: "create" }} />
			</Hidden>
			<Hidden mdUp>
				<NavBarMobile filter />
			</Hidden>
		</MainLayout>
	);
};

export default VirtualAssistant;
