import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";
//MUI
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
//Redux
import { useSelector } from "react-redux";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	menuButtonRight: {
		marginRight: theme.spacing(2),
		float: "right",
	},
	verifiedButton: {
		backgroundColor: theme.palette.accent.main,
		color: "#fff",
	},
}));

const defaultProps = {
	text: "Upgrade to Premium",
	variant: "outlined",
};

const GetVerifiedButton = ({
	size,
	menu,
	className,
	menuButtonRight,
	text = defaultProps.text,
	variant = defaultProps.variant,
}) => {
	const authUser = useSelector((state) => state.user.credentials);
	const classes = styles();

	const button = (callToAction) => (
		<Button
			size={clsx(menu ? "small" : size)}
			className={clsx(
				classes.verifiedButton,
				menuButtonRight ? classes.menuButtonRight : null,
				className
			)}
			variant={variant}
			color={"secondary"}
			component={Link}
			to={"/subscriptions"}>
			{callToAction}
		</Button>
	);

	const buttonTextSelector = () => {
		switch (authUser.type) {
			case "wholesaler":
				if (!authUser.roles.subscriptionPaid) {
					return button(
						<Typography variant="caption">Finish Setup</Typography>
					);
				}
				break;
			case "investor":
				if (authUser.roles.subscriptionPaid && !authUser.verified) {
					return button(
						<Typography variant="caption">
							Verify Membership
						</Typography>
					);
				}
				break;
			case "member":
				return button(
					<Typography variant="caption">{text}</Typography>
				);

			default:
				break;
		}
	};

	const cta = buttonTextSelector();

	return <Grid item>{cta}</Grid>;
};

GetVerifiedButton.propTypes = {
	className: PropTypes.string,
	menu: PropTypes.any,
	menuButtonRight: PropTypes.any,
	size: PropTypes.any,
	text: PropTypes.string,
	variant: PropTypes.string,
};

export default GetVerifiedButton;
