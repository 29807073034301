import React, { Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

//Components
import DeleteDealButton from "../../controls/buttons/deals/DeleteDealButton";
import EditDealButton from "../../controls/buttons/deals/EditDealButton";
import LogoutButton from "../../controls/buttons/LogoutButton";
import DealBoardButton from "../../controls/buttons/deals/DealBoardButton";
import GetVerifiedButton from "../../controls/buttons/user/GetVerifiedButton";
import RightButtonGroup from "../toolbars/groups/RightButtonGroup";
import Notifications from "../../navigation/Notifications";
import CreateDealButton from "../../controls/buttons/deals/CreateDealButton";
//MUI Components
import Grid from "@material-ui/core/Grid";
// Redux
import { useSelector } from "react-redux";

//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
import Hidden from "@material-ui/core/Hidden";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	toolBar: {
		padding: theme.spacing(1),
	},
	menuButtonRight: {
		marginRight: theme.spacing(2),
		float: "right",
		flexGrow: 1,
	},
	editButton: {
		//color: "#fff",
		//backgroundColor: theme.palette.information.dark,
	},
	fullwidth: {
		width: "100%",
	},
	notification: {
		marginTop: "-7px",
		marginBottom: "-10px",
	},
	firstButton: { marginTop: theme.spacing(1) },
}));

const DealToolBar = ({ center }) => {
	const deal = useSelector((state) => state.data.deal);
	const authUser = useSelector((state) => state.user.credentials);

	const classes = styles();

	return (
		<Grid item xs={12}>
			<Grid
				align="center"
				justify="center"
				container
				spacing={2}
				className={classes.toolBar}>
				{deal.dealId && (
					<Fragment>
						<Grid
							item
							className={clsx(
								center ? classes.firstButton : null,
								center ? classes.fullwidth : null
							)}>
							<DealBoardButton variant="outlined" menu />
						</Grid>

						{authUser.userId === deal.userId ? (
							<Fragment>
								<Grid
									item
									className={
										center ? classes.fullwidth : null
									}>
									<EditDealButton />
								</Grid>
								<Grid
									item
									className={
										center ? classes.fullwidth : null
									}>
									<DeleteDealButton
										menu
										className={clsx(
											center ? classes.fullwidth : null
										)}
										variant="outlined"
										dealId={deal.dealId}
									/>
								</Grid>
							</Fragment>
						) : null}

						<CreateDealButton
							btnText="Create New Deal"
							variant="outlined"
							menu
							aria-label="create deal"
							form="deal"
							args={{ type: "create" }}
						/>

						{/*<Grid
							item
							className={center ? classes.fullwidth : null}>
							{authorized && (
								<FacebookPostButton
									menu
									color="secondary"
									variant="outlined"
								/>
							)}
							</Grid>*/}
					</Fragment>
				)}
				<Grid item className={center ? classes.fullwidth : null}>
					<Grid container>
						{/*<Grid>
							<InboxLinkButton
								small
								menu
								variant="outlined"
								userHandle={authUser.handle}
							/>
						</Grid>*/}
						<Grid className={classes.notification}>
							<Notifications />
						</Grid>
					</Grid>
				</Grid>
				<Hidden smDown>
					<RightButtonGroup>
						<LogoutButton menu menuButtonRight />
						<GetVerifiedButton menu menuButtonRight />
					</RightButtonGroup>
				</Hidden>
			</Grid>
		</Grid>
	);
};

DealToolBar.propTypes = {
	center: PropTypes.bool,
};

export default DealToolBar;
