import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
// Redux
import { useDispatch } from "react-redux";
//MUI Components
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { toggleFormDialog } from "../../../../redux/actions/uiActions";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	root: { marginTop: 10 },
	resetPasswordLinkText: {
		cursor: "pointer",
		marginTop: 10,
	},
	link: {
		color: "#fff",
		textShadow: "2px 2px 4px #000000",
		fontWeight: 700,
		textDecoration: "underline",
	},
}));

const OpenResetPasswordLink = ({ className }) => {
	const dispatch = useDispatch();
	const classes = styles();

	const handleLinkClick = () => {
		dispatch(toggleFormDialog(true, "resetPassword", null));
	};

	return (
		<Grid item className={classes.root} xs={12}>
			<Typography
				component="span"
				className={clsx(className, classes.linkInfoText)}
				variant="body2"
				color="secondary">
				forgot your password?{" "}
				<Link
					onClick={() => handleLinkClick()}
					className={classes.link}>
					here
				</Link>{" "}
			</Typography>
		</Grid>
	);
};

OpenResetPasswordLink.propTypes = {
	className: PropTypes.string,
};

export default OpenResetPasswordLink;
