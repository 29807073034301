import React, { Fragment } from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import clsx from "clsx";

//Components
import CheckoutBillingCardInformation from "./CheckoutBillingCardInformation";
import UserTypeBadge from "../badges/UserTypeBadge";
import VirtualAssistantProductCard from "../virtual-assistant/VirtualAssistantProductCard";
import CheckoutTable from "./CheckoutTable";
import LoginSignupActions from "../profile/LoginSignupActions";
//MUI
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { setCheckoutLoginRedirect } from "../../redux/actions/checkoutActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	main: {
		backgroundColor: "white",

		padding: theme.spacing(3),
	},
	sideProfile: {
		minWidth: 240,
	},
	title: {
		marginBottom: 0,
	},
	subTitle: {
		marginBottom: 10,
	},
}));

const CheckoutForm = () => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user.credentials);
	const assistancePackage = useSelector(
		(state) => state.checkout.virtualAssistant.package
	);

	const classes = styles();
	const env = process.env.REACT_APP_ENV;
	const bull = <span className={classes.bullet}>•</span>;

	let publicKey;
	switch (env) {
		case "production":
			publicKey = process.env.REACT_APP_STRIPE_LIVE_PUBLIC_KEY; //live
			break;
		case "staging":
			publicKey = process.env.REACT_APP_STRIPE_LIVE_PUBLIC_KEY; //live
			break;
		case "development":
			publicKey = process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY; //test
			break;
		default:
			publicKey = process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY;
	}

	const redirect = (redirectTo) => {
		dispatch(setCheckoutLoginRedirect(redirectTo));
	};

	const product = () => (
		<>
			<CheckoutTable
				assistancePackage={assistancePackage}
				price={`$${assistancePackage.unit_amount / 100}`}
			/>
			<Elements
				style={{
					borderColor: "green",
					borderStyle: "solid",
					borderWidth: 1,
				}}>
				<CheckoutBillingCardInformation
					assistancePackage={assistancePackage}
				/>
			</Elements>
			<hr />
		</>
	);

	return (
		<>
			{assistancePackage ? (
				<Paper className={clsx(classes.main, classes.formBackground)}>
					<Grid align="center" item container xs={12} spacing={2}>
						<Grid item sm={6}>
							<VirtualAssistantProductCard
								assistancePackage={assistancePackage}
								showMaxNumberOfFeature={7}
								hideCTA
							/>
						</Grid>
						<Grid item align="center" sm={6}>
							<StripeProvider apiKey={publicKey}>
								{user.userId ? (
									<>
										<div className="example">
											<Typography
												align="center"
												variant="body1"
												className={
													classes.subTitle
												}></Typography>
										</div>
										{product()}
									</>
								) : (
									<Fragment>
										<Typography>
											sign up or login to purchase
										</Typography>
										<LoginSignupActions
											cancelRedirectButton
										/>
										{redirect("/virtual-assistant")}
									</Fragment>
								)}
							</StripeProvider>
						</Grid>
					</Grid>
				</Paper>
			) : null}
		</>
	);
};

export default CheckoutForm;
