import PropTypes from "prop-types";
import React from "react";
import ToolTipButton from "../../../controls/buttons/ToolTipButton";
//MUI
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	closeButton: {
		position: "absolute",
		right: 15,
		top: "1.5%",
	},
}));

const DealButtonOutlined = ({ handleClose }) => {
	const classes = styles();
	return (
		<Grid item>
			<ToolTipButton
				className={classes.closeButton}
				title="Close"
				onClick={handleClose}
				titleClassName={classes.closeButton}>
				<CloseIcon />
			</ToolTipButton>
		</Grid>
	);
};

DealButtonOutlined.propTypes = {
	handleClose: PropTypes.func.isRequired,
};

export default DealButtonOutlined;
