import PropTypes from "prop-types";
import React from "react";
//MUI
import EditIcon from "@material-ui/icons/Edit";
import FormOpenButton from "../forms/FormOpenButton";
//Redux
import { useSelector } from "react-redux";

const defaultProps = {
	btnText: "Edit Deal",
	icon: <EditIcon fontSize="small" />,
};

const EditDealButton = ({
	btnText = defaultProps.btnText,
	icon = defaultProps.icon,
	className,
}) => {
	const deal = useSelector((state) => state.data.deal);
	return (
		<FormOpenButton
			menu
			icon={icon}
			className={className}
			form="deal"
			args={{
				type: "edit",
				dealId: deal.dealId,
			}}
			btnVariant="outlined"
			btnText={btnText}
		/>
	);
};

EditDealButton.propTypes = {
	args: PropTypes.object.isRequired,
	btnText: PropTypes.string,
	className: PropTypes.string,
	icon: PropTypes.element,
};

export default EditDealButton;
