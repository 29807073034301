import React from "react";
import PropTypes from "prop-types";
//Components
import AuthProfile from "./AuthProfile";
import PublicProfile from "./PublicProfile";

const defaultProps = {
	authUserMatchesParams: false,
};

const SideProfile = ({
	authUserMatchesParams = defaultProps.authUserMatchesParams,
	showLoginSignupActions,
}) => {
	return authUserMatchesParams ? (
		<AuthProfile ctaTo={"/"} ctaText={"View Dealboard"} />
	) : (
		<PublicProfile
			ctaTo={"/"}
			ctaText={"View Dealboard"}
			showLoginSignupActions={showLoginSignupActions}
		/>
	);
};

SideProfile.propTypes = {
	authUserMatchesParams: PropTypes.bool,
	showLoginSignupActions: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.bool,
	]),
};

export default SideProfile;
