import React, { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
//Components
import { DealTextField } from "../../controls/DealTextFields";
import { objectNotEmpty } from "../../../util/UtilityFunctions";
//MUI Components
import Grid from "@material-ui/core/Grid";
//Redux
import { useSelector } from "react-redux";
//Styles
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	textfields: { padding: theme.spacing(2) },
}));

const BillingInformationForm = ({ fullWidth }) => {
	const user = useSelector((state) => state.user.credentials);
	const subscription = useSelector((state) => state.subscription.data);
	const dialog = useSelector((state) => state.UI.dialog);
	const errors = useSelector((state) => state.UI.responses.errors);

	let isPaymentDetailsForm = dialog.form === "editPaymentDetails";

	let setFullWidth;

	if (fullWidth || isPaymentDetailsForm) {
		setFullWidth = true;
	}

	let defaultValues;

	if (!objectNotEmpty(subscription)) {
		defaultValues = {
			firstName: user.firstName,
			lastName: user.lastName,
		};
	} else {
		defaultValues = subscription.billing;
	}
	const [input, setInput] = useState({ ...defaultValues });

	const handleInputChange = (e) => {
		setInput({
			...input,
			[e.currentTarget.name]: e.currentTarget.value,
		});
	};
	const classes = styles();

	return (
		<Grid item xs={12}>
			{!setFullWidth && <Grid item></Grid>}
			<Grid
				className={classes.textfields}
				item
				xs={12}
				sm={!setFullWidth ? 8 : 12}
				container
				spacing={2}>
				<DealTextField
					halfWidth
					id="firstName"
					name="firstName"
					type="text"
					label="First Name"
					helperText={errors.firstName}
					error={errors.firstName ? true : false}
					value={input.firstName}
					onChange={handleInputChange}
				/>
				<DealTextField
					halfWidth
					id="lastName"
					name="lastName"
					type="text"
					label="Last Name"
					helperText={errors.lastName}
					error={errors.lastName ? true : false}
					value={input.lastName}
					onChange={handleInputChange}
				/>
				<DealTextField
					id="street"
					variant="outlined"
					name="street"
					type="text"
					label="Street Address"
					value={input.street}
					onChange={(e) => handleInputChange(e)}
				/>
				<DealTextField
					id="billing-city"
					halfWidth
					name="city"
					type="text"
					label="City"
					value={input.city}
					onChange={handleInputChange}
				/>
				<DealTextField
					id="billing-state"
					halfWidth
					name="state"
					type="text"
					label="State"
					value={input.state}
					onChange={handleInputChange}
				/>
			</Grid>

			{!setFullWidth && <Grid item></Grid>}
		</Grid>
	);
};

export default BillingInformationForm;
