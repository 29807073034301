import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";
//Components

//MUI
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
//Styles
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	responseArea: {
		padding: 10,
		borderWidth: 1,
		borderRadius: 4,
		borderStyle: "solid",
	},
	responseAreaSuccess: {
		color: theme.palette.primary.main,
		borderColor: theme.palette.primary.main,
	},
	responseAreaError: {
		color: "red",
		borderColor: "red",
	},
}));

const defaultProps = {
	msgOffsetLeft: "-8px",
};

const FormMessageArea = ({
	messages,
	errors,
	msgTest,
	msgOffsetLeft = defaultProps.msgOffsetLeft,
}) => {
	const classes = styles();
	const theme = useTheme();

	const screenSmallUp = useMediaQuery(theme.breakpoints.up("sm"));
	return (
		<Grid
			item
			style={{ marginLeft: screenSmallUp ? msgOffsetLeft : "inherant" }}
			xs={12}>
			<Grid container>
				{errors.general && (
					<Grid
						item
						xs={12}
						className={clsx(
							classes.responseArea,
							classes.responseAreaError
						)}>
						<Typography
							variant="body2"
							style={{
								color: "red",
							}}>
							{errors.general}
						</Typography>
					</Grid>
				)}

				{(messages.success || msgTest) && (
					<Grid
						item
						id="response-text"
						xs={12}
						className={clsx(
							classes.responseArea,
							classes.responseAreaSuccess
						)}>
						<Typography variant="body2">
							{messages.success}
							{msgTest &&
								"Ut id nisl quis enim dignissim sagittis. Aenean tel"}
						</Typography>
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};

FormMessageArea.propTypes = {
	errors: PropTypes.shape({
		general: PropTypes.string,
	}),
	messages: PropTypes.shape({
		success: PropTypes.string,
	}),
	msgOffsetLeft: PropTypes.string,
	msgTest: PropTypes.string,
};

export default FormMessageArea;
