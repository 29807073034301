import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		marginTop: "-20px",
		marginBottom: "-40px",
	},
	formControl: {
		margin: theme.spacing(3),
	},
}));

const GeneralFormGroup = ({ children, groupLabel, error }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<FormControl
				error={error}
				component="fieldset"
				className={classes.formControl}>
				<FormLabel component="legend">
					<Typography style={{ color: "#000" }}>
						{groupLabel}
					</Typography>
				</FormLabel>
				<FormGroup row={true}>{children}</FormGroup>
				<FormHelperText>{error}</FormHelperText>
			</FormControl>
		</div>
	);
};

export default GeneralFormGroup;
