import React from "react";
import noImg from "../images/placeholder-avatar-3d@177.png";
import clsx from "clsx";
//MUI
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import WebIcon from "@material-ui/icons/Web";
import CircularProgress from "@material-ui/core/CircularProgress";
//Icons
import LocationOn from "@material-ui/icons/LocationOn";
import CalendarToday from "@material-ui/icons/CalendarToday";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import InfoIcon from "@material-ui/icons/Info";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	spinner: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	},
	placeholderImage: {
		maxWidth: 95,
		borderRadius: "100%",
		objectFit: "cover",
	},
	imageWrapper: {
		width: 95,
		borderRaduis: "50%",
	},
	card: {
		display: "flex",
		marginBottom: 20,
	},
	cardContent: {
		width: "100%",
		flexDirection: "coloumn",
		padding: 25,
	},
	cover: {
		minWidth: 200,
		objectFit: "cover",
	},
	handle: {
		width: 60,
		height: 20,
		backgroundColor: theme.palette.primary.main,
		marginBottom: 7,
	},
	date: {
		height: 14,
		width: 100,
		backgroundColor: "FFF",
		marginBottom: 10,
	},
	profileDetails: { color: "white", textAlgin: "right" },
	fullLine: {
		height: 15,
		width: "100%",
		marginBottom: 10,
		backgroundColor: "grey",
	},
	halfline: {
		height: 15,
		width: "50%",
		marginBottom: 10,
		backgroundColor: "grey",
	},
	profileFields: {
		margin: 10,
		padding: 10,
	},
	toolTipButton: { color: "white" },
	textBackground: {
		backgroundColor: "white",
		width: "100%",
	},
	profileField: {
		color: "#51C46C !important",
	},
	profileFieldSeparator: {
		width: "100%",
		borderWidth: 0.1,
		border: "dotted",
		borderBottom: "1px solid rgba(0,0,0,0.2)",
		marginBottom: 10,
	},
	icon: {
		verticalAlign: "middle",
		marginRight: 10,
	},
	infoIcon: {
		margin: "0 auto",
		textAlign: "center",
		display: "block",
	},
	text: {
		display: "block",
		margin: "0 auto",
		textAlign: "center",
	},
	textBold: {
		fontWeight: 700,
	},
}));

const ProfileSkeleton = (props) => {
	const classes = styles();

	const content = (
		<Grid item>
			<Paper
				className={classes.paper}
				style={{
					minHeight: 410,
					height: " 70vh",
					backgroundColor: "#51C46C",
				}}>
				<Grid
					item
					className={classes.formBackground}
					container
					xs={12}
					component={Card}>
					<Grid
						className={clsx(classes.card)}
						component={CardContent}
						container
						spacing={1}>
						<Grid item xs={6}>
							<div
								className={classes.imageWrapper}
								style={{ position: "relative" }}>
								<div className={classes.spinner}>
									<CircularProgress
										style={{ color: "white" }}
									/>
								</div>
								<img
									src={noImg}
									alt="profile"
									className={classes.placeholderImage}
								/>
							</div>
						</Grid>
						<Grid item container xs={6}>
							<Grid item xs={12}>
								<Typography
									classes={{
										root: clsx(
											classes.text,
											classes.textBold
										),
									}}
									variant="caption"
									color="primary">
									User Type:
								</Typography>
								{/*<UserTypeBadge />*/}
							</Grid>
							<Grid item xs={12}>
								<Typography
									classes={{
										root: clsx(
											classes.text,
											classes.textBold
										),
									}}
									variant="caption"
									color="primary">
									Date Joined:
								</Typography>
								<Grid
									item
									component={Paper}
									className={classes.border}>
									<Typography
										classes={{
											root: classes.text,
										}}
										variant="caption"
										color="primary">
										MM YYYY
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<div className={classes.profile}>
					<hr />
					<div className={classes.profileDetails}>
						<Paper className={classes.profileFields}>
							<Typography>
								<AccountCircleIcon className={classes.icon} />
							</Typography>

							<hr className={classes.profileFieldSeparator} />

							<Typography>
								<LocationOn className={classes.icon} />
							</Typography>

							<hr className={classes.profileFieldSeparator} />

							<Typography>
								<WebIcon className={classes.icon} />
							</Typography>

							<hr className={classes.profileFieldSeparator} />
							<Typography>
								<CalendarToday className={classes.icon} />
							</Typography>
							<hr className={classes.profileFieldSeparator} />

							<Typography>
								<InfoIcon className={classes.infoIcon} />
							</Typography>
						</Paper>
					</div>
				</div>
			</Paper>
		</Grid>
	);

	return content;
};

export default ProfileSkeleton;
