import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

//Components
import UserBadgeCard from "../components/badges/UserBadgeCard";
import MainLayout from "../components/layouts/MainLayout";
import SideProfile from "../components/profile/SideProfile";
import NavBarMobile from "../components/navigation/mobile/NavBarMobile";
import CreateDealFab from "../components/controls/buttons/deals/CreateDealFab";
import ProfileSection from "../components/sections/ProfileSection";
import NavBarMainMenu from "../components/navigation/NavBarMainMenu";
//MUI
import Hidden from "@material-ui/core/Hidden";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileData } from "../redux/actions/profileActions";
import { getDeals } from "../redux/actions/dataActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	bagdeCard: {
		margin: theme.spacing(1),
		marginBottom: theme.spacing(2),
	},
	title: {
		marginBottom: 20,
	},
}));

const Profile = () => {
	const authUser = useSelector((state) => state.user.credentials);
	const publicData = useSelector((state) => state.profile);
	const deals = useSelector((state) => state.data.deals);
	const dealsRetrieved = useSelector((state) => state.data.retrieved);

	const classes = styles();
	const dispatch = useDispatch();
	let params = useParams();

	useEffect(() => {
		if (!dealsRetrieved) {
			dispatch(getDeals());
		}
		dispatch(getUserProfileData(params));
	}, [dispatch, dealsRetrieved, params]);

	const checkParamsUser = () => {
		let results;
		if (authUser.handle) {
			params.userHandle === authUser.handle
				? (results = true)
				: (results = false);
		} else {
			results = false;
		}
		return results;
	};

	const userProfile = (
		<SideProfile authUserMatchesParams={checkParamsUser()} showLoginSignupActions={false}/>
	);

	return (
		<>
			<MainLayout sidebar={userProfile}>
				<Hidden mdUp>
					<div className={classes.bagdeCard}>
						<UserBadgeCard smallBadge user={publicData.user} />
					</div>
				</Hidden>
				<Hidden smDown>
					<NavBarMainMenu />
				</Hidden>
				<ProfileSection deals={deals} handle={params.userHandle} />
				<Hidden smDown>
					<CreateDealFab form="deal" args={{ type: "create" }} />
				</Hidden>
				<Hidden mdUp>
					<NavBarMobile />
				</Hidden>
			</MainLayout>
		</>
	);
};

export default Profile;
