import React from "react";

//Styles
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	content: {
		maxWidth: 1200,
	},
}));
const defaultProps = { backgroundColor: "#404040" };

const BodyLayout = ({
	children,
	backgroundColor = defaultProps.backgroundColor,
}) => {
	const classes = styles();
	const theme = useTheme();
	const smallDown = useMediaQuery(theme.breakpoints.down("sm"));
	document.body.style.backgroundColor = backgroundColor;
	return (
		<div
			style={
				smallDown ? { margin: "10px 10px" } : { margin: "30px auto" }
			}
			className={classes.content}>
			{children}
		</div>
	);
};

export default BodyLayout;
