import React from "react";
import clsx from "clsx";
//MUI
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	spinnerButton: { position: "relative" },
	spinnerProgress: {
		position: "absolute",
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		margin: "auto",
		color: "#fff",
	},
}));

const SpinnerButton = (props) => {
	const classes = styles();
	return (
		<Button
			size={props.size ? props.size : "small"}
			className={clsx(
				classes.spinnerButton,
				classes.buttonColorWhite,
				props.className
			)}
			disabled={props.disabled}
			fullWidth={props.fullWidth}
			variant={props.variant}
			color={props.color}
			onClick={props.onClick}>
			{props.children}
			{props.loading && (
				<CircularProgress
					size={30}
					className={classes.spinnerProgress}
				/>
			)}
		</Button>
	);
};

export default SpinnerButton;
