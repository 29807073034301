import React, { Fragment } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./pages/Routes";
//import axios from "axios";
//Components
import Alert from "./components/alerts/Alert";
//MUI
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import themeFile from "./util/theme";
//Redux
import { Provider } from "react-redux";
import store from "./redux/store";
import "./App.css";
const theme = createMuiTheme(themeFile);
//axios
//axios.defaults.baseURL = "https://dev.sealdealfinder.com/";

function App() {
	return (
		<Fragment>
			<Provider store={store}>
				<ThemeProvider theme={theme}>
					<Alert />
					<Router>
						<Routes />
					</Router>
				</ThemeProvider>
			</Provider>
		</Fragment>
	);
}

export default App;
