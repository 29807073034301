import PropTypes from "prop-types";
import React from "react";
// Redux
import { useDispatch } from "react-redux";
import { getSubscriptionLiveData } from "../../../../redux/actions/subcriptionActions";
//MUI Components
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Button } from "@material-ui/core";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	button: {
		width: "100%",
		color: "#fff",
	},
	verified: {
		backgroundColor: theme.palette.accent.main,
		color: "#fff",
	},
}));

const SubscriptionLiveDataUpdateButton = ({ user }) => {
	const dispatch = useDispatch();
	const classes = styles();

	const handleClick = () => {
		dispatch(getSubscriptionLiveData(user.userId));
	};

	return (
		<Grid item xs={12}>
			<Button
				id="assignSystemRole"
				className={classes.button}
				variant="contained"
				color="primary"
				onClick={() => handleClick()}>
				Live Data Update
			</Button>
		</Grid>
	);
};

SubscriptionLiveDataUpdateButton.propTypes = {
	user: PropTypes.shape({
		userId: PropTypes.string.isRequired,
	}),
};

export default SubscriptionLiveDataUpdateButton;
