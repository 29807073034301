export const addCommasToNumber = (number) => {
	if (number && number !== 0) {
		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	} else return number;
};

export function secondsToISOString(seconds) {
	const date = new Date(seconds * 1000);
	return date;
}

export const captializeFirstLetter = (str) => {
	if (str && typeof str === "string") {
		let results = str.replace(/^\w/, (c) => c.toUpperCase());
		return results;
	}
};

export const objectNotEmpty = (object) => {
	if (!object) return false;

	if (Object.entries(object).length > 0) {
		return true;
	} else {
		return false;
	}
};

export const getTagLabel = (key) => {
	const label = {
		singleFamily: "Single Family",
		incomeProducing: "Income Producer",
	};
	return label[key];
};

export const CheckFileSize = (size) => {
	console.log(size);
	console.log(`Upload File Size: ${size}`);
	if (size > 5000000) {
		console.log("File too large");
		return true;
	} else return false;
};

export const checkUserRole = (roles, roleToCheck) => {
	let currentlyHasRole = false;
	if (roles) {
		if (roles[`${roleToCheck}`]) {
			currentlyHasRole = roles[`${roleToCheck}`];
		}
	}
	return currentlyHasRole;
};

export const authCreateEditDeals = (roles) => {
	let authorized = false;

	if (roles && (roles.subscriptionPaid || roles.admin === true)) {
		const test = ["admin", "wholesaler"];

		test.map((check) => {
			Object.keys(roles).some(function () {
				if (roles[check] === true) {
					authorized = true;
				}
			});
		});
	}
	return authorized;
};

export const compare = (a, b) => {
	// Use toUpperCase() to ignore character casing
	console.log(a);
	console.log(b);
	const bandA = a.lastName.toUpperCase();
	const bandB = b.lastName.toUpperCase();

	let comparison = 0;
	if (bandA > bandB) {
		comparison = 1;
	} else if (bandA < bandB) {
		comparison = -1;
	}
	return comparison;
};
