import React from "react";
import clsx from "clsx";
//Components
import ContentStandard from "./body/ContentStandard";
//MUI Components
import Grid from "@material-ui/core/Grid";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles(theme => ({
	...theme.customStyles,
	root: {
		flexGrow: 1
	},
	sideProfile: {
		minWidth: 240
	}
}));

const BasicLayout = ({ children, backgroundColor, className }) => {
	const classes = styles();
	return (
		<ContentStandard backgroundColor={backgroundColor}>
			<div className={clsx(classes.root, className)}>
				<Grid
					className={classes.layout}
					container
					justify="space-around"
					spacing={2}>
					<Grid item xs={12}>
						{children}
					</Grid>
				</Grid>
			</div>
		</ContentStandard>
	);
};

export default BasicLayout;
