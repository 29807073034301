import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";
// Redux
import { useDispatch } from "react-redux";
import {
	systemSetUser,
	setGetVerificationFile,
	clearVerificationFile,
} from "../../../../../../redux/actions/systemActions";
import { toggleFormDialog } from "../../../../../../redux/actions/uiActions";
//MUI Components
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	button: {
		width: "100%",
		color: "#fff",
		backgroundColor: theme.palette.action.main,
	},
	verified: {
		backgroundColor: theme.palette.accent.main,
		color: "#fff",
	},
}));

const SystemApplicationButton = ({ user, className }) => {
	const dispatch = useDispatch();
	const classes = styles();

	const handleVerificationClick = () => {
		dispatch(clearVerificationFile());
		dispatch(toggleFormDialog(true, "application", null));
		dispatch(setGetVerificationFile(user.userId));
		dispatch(systemSetUser(user));
	};

	return (
		<Grid item xs={12}>
			<Button
				fullWidth
				id="viewVerificationApplication"
				disabled={
					user.verificationStatus !== "review" && !user.verified
				}
				className={clsx(className, classes.button)}
				variant="contained"
				color="primary"
				onClick={() => handleVerificationClick()}>
				{user.verificationStatus === "review" && !user.verified
					? "Document"
					: user.verified
					? "View Verification File"
					: "No Document"}
			</Button>
		</Grid>
	);
};

SystemApplicationButton.propTypes = {
	className: PropTypes.string,
	user: PropTypes.shape({
		userId: PropTypes.string.isRequired,
		verificationStatus: PropTypes.string,
		verified: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	}),
};

export default SystemApplicationButton;
