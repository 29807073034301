import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";
//Components
import CommentInnerContent from "./CommentInnerContent";
import UserBadge from "../badges/UserBadge";
//MUI Components
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
// Redux
import { useSelector } from "react-redux";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	comment: { marginBottom: 15, padding: 10 },
	commentUserBadge: {
		maxWidth: 125,
		maxHeight: 125,
	},
	commentData: {
		marginLeft: 5,
	},
	ownerComment: {
		backgroundColor: "#a4ecb5",
		color: "#fff",
	},
	ownerLabel: {
		marignBottom: "-40px",
	},
	deleting: {
		backgroundColor: "red",
	},
	blocked: {
		backgroundColor: "lightGrey",
	},
}));

const CommentCard = ({
	comment,
	matchDealOwnerComment,
	matchLoggedInUserDealOwner,
	matchLoggedInUserComment,
	deletingCommentMatch,
}) => {
	const deleting = useSelector((state) => state.commentData.deleting);
	const classes = styles();
	const { userHandle, userImage } = comment;

	const card = (
		<Grid
			item
			container
			key={comment.commentId}
			component={Card}
			className={clsx(
				classes.comment,
				matchDealOwnerComment ? classes.ownerComment : null,
				deleting.active && deletingCommentMatch
					? classes.deleting
					: null,
				comment.blocked ? classes.blocked : null
			)}
			spacing={1}>
			<Grid className={classes.commentUserBadge} item xs={12} sm={3}>
				{userHandle && (
					<UserBadge
						smallBadge
						user={{
							handle: userHandle,
							imageUrl: userImage,
						}}
					/>
				)}
			</Grid>
			<Grid item container xs={9}>
				<Grid item container direction="row">
					<Grid item xs={12}>
						{matchLoggedInUserDealOwner !== "undefined" ? (
							<CommentInnerContent
								comment={comment}
								matchDealOwnerComment={matchDealOwnerComment}
								matchLoggedInUserDealOwner={
									matchLoggedInUserDealOwner
								}
								matchLoggedInUserComment={
									matchLoggedInUserComment
								}
							/>
						) : null}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);

	return <Grid container>{card}</Grid>;
};

CommentCard.propTypes = {
	comment: PropTypes.shape({
		blocked: PropTypes.bool,
		commentId: PropTypes.string.isRequired,
		userHandle: PropTypes.string,
		userImage: PropTypes.string,
	}),
	deletingCommentMatch: PropTypes.bool,
	matchDealOwnerComment: PropTypes.bool,
	matchLoggedInUserComment: PropTypes.bool,
	matchLoggedInUserDealOwner: PropTypes.bool,
};

export default CommentCard;
