//Header
export const SET_HEADERS = "SET_HEADERS";
//Responsive
export const SET_RESPONSIVE = "SET_RESPONSIVE";
//Drawer
export const TOGGLE_NAV_DRAWER = "TOGGLE_NAV_DRAWER";
// User reducer types
export const PURGE_USER = "PURGE_USER";
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_USER = "SET_USER";
export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_ROLES = "EDIT_USER_ROLES";
export const IMAGE_CROPPING = "IMAGE_CROPPING";
export const LOADING_USER = "LOADING_USER";
export const STOP_LOADING_USER = "STOP_LOADING_USER";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const DELETE_READ_NOTIFICATIONS = "DELETE_READ_NOTIFICATIONS";
export const REDIRECT = "REDIRECT";
//Profile reducer
export const SET_PROFILE = "SET_PROFILE";
export const SET_PROFILE_IMAGE = "SET_PROFILE_IMAGE";
export const LOADING_PROFILE = "LOADING_PROFILE";
// UI reducer
export const LOADING_UI = "LOADING_UI";
export const LOADING_DATA = "LOADING_DATA";
export const STOP_LOADING_DATA = "STOP_LOADING_DATA";
//**UI reducer Forms
export const TOGGLE_FORM_DIALOG = "TOGGLE_FORM_DIALOG";
export const TOGGLE_FORM_COMMENT = "TOGGLE_FORM_COMMENT";
export const TOGGLE_FORM_CREATE_DEAL = "TOGGLE_FORM_CREATE_DEAL";
//**UI reducer Nav
export const TOGGLE_NAV_FILTER = "TOGGLE_NAV_FILTER";
// Deals reducer
export const POST_DEAL = "POST_DEAL";
export const SET_LOCATION = "SET_LOCATION";
export const SET_ADDRESS = "SET_ADDRESS";
export const SET_DEALS = "SET_DEALS";
export const SET_DEAL = "SET_DEAL";
export const LIKE_DEAL = "LIKE_DEAL";
export const UNLIKE_DEAL = "UNLIKE_DEAL";
export const EDIT_DEAL = "EDIT_DEAL";
export const IMAGE_PURGE = "IMAGE_PURGE";
export const DELETE_DEAL = "DELETE_DEAL";
export const DELETE_DEALS = "DELETE_DEALS";
export const SET_FILTERS = "SET_FILTERS";
export const SET_FILTERED_DEALS = "SET_FILTERED_DEALS";
export const SET_FILTERED_HANDLE = " SET_FILTERED_HANDLE";

//Inbox
export const INBOX_LOADING = "INBOX_LOADING";
//Conversations
export const SET_CONVERSATIONS = "SET_CONVERSATIONS";
export const SET_CONVERSATION = "SET_CONVERSATION";
export const DELETE_CONVERSATION = "DELETE_CONVERSATION";
export const CONVERSATIONS_LOADING = "CONVERSATIONS_LOADING";
//Messages reducer
export const SET_DIRECT_MESSAGES = "SET_DIRECT_MESSAGES";
export const SET_DIRECT_MESSAGE = "SET_DIRECT_MESSAGE";
export const POST_MESSAGE = "POST_MESSAGE";
export const DELETE_MESSAGE = "SUBMIT_MESSAGE";
export const SUBMIT_MESSAGE = "SUBMIT_MESSAGE";
export const SUBMITING_MESSAGE = "SUBMITING_MESSAGE";
export const EDIT_MESSAGE = "EDIT_MESSAGE";
export const UPDATING_MESSAGE = "UPDATING_MESSAGE";
export const DELETING_MESSAGE = "DELETING_MESSAGE";
//Comments reducer
export const SUBMIT_COMMENT = "SUBMIT_COMMENT";
export const SET_COMMENTS = "SET_COMMENTS";
export const SET_COMMENT = "SET_COMMENT";
export const EDIT_COMMENT = "EDIT_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const SUBMIT_DEALS_COMMENT = "SUBMIT_DEALS_COMMENT";
export const STOP_LOADING_COMMENT = "STOP_LOADING_COMMENT";
export const LOADING_COMMENT = "LOADING_COMMENT";
export const UPDATING_COMMENT = "UPDATING_COMMENT";
export const SUBMITING_COMMENT = "SUBMITING_COMMENT";
export const DELETING_COMMENT = "DELETING_COMMENT";
//Responses reducer
export const CLEAR_RESPONSES = "CLEAR_RESPONSES";
export const SET_MESSAGES = "SET_MESSAGES";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";
export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
//Alerts reducer
export const SET_ALERT = "SET_ALERT";
export const SET_ALERT_SUCCESS = "SET_ALERT_SUCCESS";
export const SET_ALERT_ERROR = "SET_ALERT_ERROR";
export const SET_ALERT_INFO = "SET_ALERT_INFO";
export const CLEAR_ALERT = "CLEAR_ALERT";
export const SET_ALERT_CONFIRMATION = "SET_ALERT_CONFIRMATION";
//System reducer
export const SET_SYSTEM_USER_ROLE = "SET_SYSTEM_USER_ROLE";
export const SET_SYSTEM_USERS = "SET_SYSTEM_USERS";
export const DELETE_SYSTEM_USER = "DELETE_SYSTEM_USER";
export const SET_VERIFICATION_FILE = "SET_VERIFICATION_FILE";
export const SET_SYSTEM_USER = "SET_SYSTEM_USER";
export const CLEAR_SYSTEM_USER = "CLEAR_SYSTEM_USER";
export const LOADING_SYSTEM_DATA = "LOADING_SYSTEM_DATA";
export const STOP_LOADING_SYSTEM_DATA = "STOP_LOADING_SYSTEM_DATA";
export const SET_VERIFIED = "SET_VERIFIED";
export const SET_SYSTEM_FILTERS = "SET_SYSTEM_FILTERS";
export const SET_FILTERED_SYSTEM_USERS = "SET_FILTERED_SYSTEM_USERS";
//Subscriptions reducer
export const SET_PI = "SET_PI";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";
export const SET_PAYMENT_RESPONSE = "SET_PAYMENT_RESPONSE";
export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD";
export const SET_SUBSCRIPTION = "SET_SUBSCRIPTION";
export const SET_BILLING = "SET_BILLING";
export const CLEAR_SUBSCRIPTION = "CLEAR_SUBSCRIPTION";
export const SET_LATEST_INVOICE = "SET_LATEST_INVOICE";
export const LOADING_SUBSCRIPTION = "LOADING_SUBSCRIPTION";
export const LOADING_SYSTEM_UI = "LOADING_SYSTEM_UI";
export const STOP_LOADING_SYSTEM_UI = "STOP_LOADING_SYSTEM_UI";
export const STOP_LOADING_SUBSCRIPTION = "STOP_LOADING_SUBSCRIPTION";
export const SET_SETUP_INTENT = "SET_SETUP_INTENT";
//Checkout system
export const SET_VA_PACKAGE = "SET_VA_PACKAGE";
export const SET_VA_PACKAGES = "SET_VA_PACKAGES";
export const LOADING_CHECKOUT = "LOADING_CHECKOUT";
export const STOP_LOADING_CHECKOUT = "STOP_LOADING_CHECKOUT";
export const SET_CHECKOUT_LOGIN_REDIRECT = "SET_CHECKOUT_LOGIN_REDIRECT";
