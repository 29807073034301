import {
	SET_SUBSCRIPTION,
	CLEAR_SUBSCRIPTION,
	SET_BILLING,
	LOADING_SUBSCRIPTION,
	STOP_LOADING_SUBSCRIPTION,
	SET_PAYMENT_METHOD,
	SET_ALERT,
	SET_ALERT_ERROR,
	SET_MESSAGES,
	SET_ERRORS,
	SET_PAYMENT_RESPONSE,
	SET_SETUP_INTENT,
	CLEAR_RESPONSE,
	EDIT_USER,
	EDIT_USER_ROLES,
	TOGGLE_FORM_DIALOG,
	CLEAR_RESPONSES,
	REDIRECT
} from "../types"
import axios from "axios"
import {
	adjustClientUserType
} from '../../util/UtilityFunctions'

import {
	getUserData
} from "../actions/userActions"



export const updatePaymentDetails = (token) => dispatch => {
	dispatch({
		type: CLEAR_RESPONSES
	})
	dispatch({
		type: SET_ERRORS,
		payload: {}
	})
	dispatch({
		type: LOADING_SUBSCRIPTION
	})
	axios.post("/api/subscription/update/payment", token)
		.then((res) => {
			//console.log(res)
			if (!res.data.message.setupIntent) {

				dispatch({
					type: SET_ALERT,
					payload: {
						type: "success",
						text: res.data.message.success
					}
				})
				dispatch({
					type: SET_BILLING,
					payload: {
						...res.data.message.billing
					}
				})
				dispatch({
					type: SET_PAYMENT_METHOD,
					payload: res.data.message.newPaymentMethod
				})
			} else {
				dispatch({
					type: SET_SETUP_INTENT,
					payload: res.data.message.setupIntent
				})
			}

			dispatch({
				type: STOP_LOADING_SUBSCRIPTION
			})
		})
		.catch((err => {
			console.log(err)
			dispatch({
				type: STOP_LOADING_SUBSCRIPTION
			})

			dispatch({
				type: SET_ERRORS,
				payload: err.response.data
			})
		}))
}


export const updateBillingInformationDocument = (newBillingInformation, intent) => dispatch => {
	console.log('new billing doc')
	let data = {
		newBillingInformation,
		intent
	}
	dispatch({
		type: LOADING_SUBSCRIPTION
	})
	axios.post("/api/subscription/update/billing/document", data)
		.then((res) => {
			dispatch({
				type: SET_ALERT,
				payload: {
					type: "success",
					text: "payment method updated successfully"
				}
			})
			dispatch({
				type: STOP_LOADING_SUBSCRIPTION
			})
			dispatch({
				type: SET_SUBSCRIPTION,
				payload: {
					subscription: res.data.message.updatedSubscription
				}
			})
			/*console.log(res.data.message)
			dispatch({
				type: SET_PAYMENT_METHOD,
				payload: res.data.message.newPaymentMethod
			})*/
		})
		.catch(err => {
			dispatch({
				type: SET_ALERT_ERROR,
				payload: {
					type: "error",
					text: err
				}
			})
			dispatch({
				type: STOP_LOADING_SUBSCRIPTION
			})
		})
}


export const createSubscriptionDocument = (newSubscription) => dispatch => {

	dispatch({
		type: LOADING_SUBSCRIPTION
	})



	axios.post("/api/subscription/create/document", newSubscription)
		.then((res) => {
			dispatch({
				type: SET_SUBSCRIPTION,
				payload: {
					subscription: res.data.message.subscription,
					latestInvoice: res.data.message.invoice
				}
			})

			dispatch({
				type: SET_ALERT,
				payload: {
					type: "success",
					title: "Success! Monthly Subscription Created",
					text: res.data.message.success
				}
			})

		})
		.catch(err => {
			console.log(err)
			dispatch({
				type: SET_SUBSCRIPTION,
				payload: {}
			})
			dispatch({
				type: SET_ALERT_ERROR,
				payload: {
					type: "error",
					text: err
				}
			})
		})
}

export const createSubscription = (data, user) => dispatch => {
	dispatch({
		type: LOADING_SUBSCRIPTION
	})

	dispatch({
		type: SET_ERRORS,
		payload: {}
	})

	axios.post("/api/charge/subscription", {
			headers: {
				"Content-Type": "text/plain"
			},
			data
		})
		.then((res) => {
			if (!res.data.requires_action) {
				dispatch({
					type: SET_ALERT,
					payload: {
						type: "success",
						title: "Success! Monthly Subscription Created",
						text: res.data.message.success
					}
				})
				dispatch({
					type: SET_SUBSCRIPTION,
					payload: {
						subscription: res.data.message.subscription,
						latestInvoice: res.data.message.latestInvoice
					}
				})

				dispatch({
					type: EDIT_USER_ROLES,
					payload: {
						subscriptionPaid: res.data.message.subscriptionPaid
					}
				})
				dispatch(getUserData(user.userId))
			} else {
				dispatch({
					type: SET_PAYMENT_RESPONSE,
					payload: res.data
				})
			}
		}).catch(err => {
			console.log(err)
			dispatch({
				type: SET_SUBSCRIPTION,
				payload: {}
			})
			dispatch({
				type: SET_ERRORS,
				payload: err.response.data
			})

		})
}

export const cancelSubscription = (data, user) => (dispatch) => {

	dispatch({
		type: LOADING_SUBSCRIPTION
	})
	axios({
			method: 'delete',
			url: '/api/subscription/cancel',
			data: data
		})
		.then((res) => {

			dispatch({
				type: SET_ALERT,
				payload: {
					type: "success",
					text: res.data.message.success
				}
			})
			dispatch({
				type: EDIT_USER,
				payload: res.data.message.profileUpdates
			})

			dispatch({
				type: EDIT_USER_ROLES,
				payload: res.data.message.roleUpdate
			})
			dispatch({
				type: SET_SUBSCRIPTION,
				payload: {
					subscription: {
						...res.data.message.subscription
					}
				}
			})
			dispatch(getUserData(user.userId))
			dispatch({
				type: STOP_LOADING_SUBSCRIPTION
			})


		})
		.catch(err => {
			dispatch({
				type: STOP_LOADING_SUBSCRIPTION
			})
			dispatch({
				type: SET_ALERT,
				payload: {
					type: "error",
					title: err.response.data.error.alert.title,
					text: err.response.data.error.alert.text
				}
			})
		})

}

export const confirmSubscriptionPayment = (data) => dispatch => {
	axios.post("/api/charge/subscription/confirm", {
			headers: {
				"Content-Type": "text/plain"
			},
			data
		})
		.then((res) => {
			dispatch({
				type: SET_MESSAGES,
				payload: res.data.message
			})
		}).catch(err => {
			console.log(err)
		})
}

export const clearServerResponse = () => dispatch => {
	dispatch({
		type: CLEAR_RESPONSE
	});
}

export const getSubscription = () => dispatch => {
	dispatch({
		type: LOADING_SUBSCRIPTION
	})
	dispatch({
		type: CLEAR_SUBSCRIPTION,
	})

	axios.post("/api/subscription")
		.then(res => {

			dispatch({
				type: SET_SUBSCRIPTION,
				payload: res.data
			})

		})
		.catch(err => {
			console.log(err)
			dispatch({
				type: SET_ALERT,
				payload: {
					type: "information",
					title: err.response.data.error.alert.title,
					text: err.response.data.error.alert.text
				}
			})
			dispatch({
				type: STOP_LOADING_SUBSCRIPTION
			})
			dispatch({
				type: TOGGLE_FORM_DIALOG,
				payload: {
					open: false
				}
			})
		})
}

export const getSubscriptionSystem = (userId) => dispatch => {
	dispatch({
		type: LOADING_SUBSCRIPTION
	})
	dispatch({
		type: CLEAR_SUBSCRIPTION,
	})

	axios.post("/api/subscription/system", {
			userId
		})
		.then(res => {

			dispatch({
				type: SET_SUBSCRIPTION,
				payload: res.data
			})

		})
		.catch(err => {
			console.log(err.response.status)
			if (err.response.status !== 400) {
				dispatch({
					type: SET_ALERT,
					payload: {
						type: "error",
						title: err.response.data.error.alert.title,
						text: err.response.data.error.alert.text
					}
				})
			}
			dispatch({
				type: STOP_LOADING_SUBSCRIPTION
			})
		})
}

export const getSubscriptionLiveData = (userId) => dispatch => {
	dispatch({
		type: LOADING_SUBSCRIPTION
	})
	dispatch({
		type: CLEAR_SUBSCRIPTION,
	})

	axios.post("/api/subscription/update/live", {
			userId
		})
		.then(res => {

			dispatch({
				type: SET_SUBSCRIPTION,
				payload: res.data
			})

		})
		.catch(err => {
			if (err.response.data) {
				console.log(err.response.data)
				dispatch({
					type: STOP_LOADING_SUBSCRIPTION
				})
			} else console.log(err)
		})
}

export const getPaymentMethod = (paymentMethod) => dispatch => {
	axios.post("/api/subscription/payment/method", {
			paymentMethod
		})
		.then((currentPaymentMethod) => {
			dispatch({
				type: SET_PAYMENT_METHOD,
				payload: currentPaymentMethod.data
			})
		})
		.catch((err) => {
			console.error(err)
		})
}

export const clearSetupIntent = () => dispatch => {
	dispatch({
		type: SET_SETUP_INTENT,
		payload: null
	})
}
