import PropTypes from "prop-types";
import React from "react";
//Components
import ActionBar from "./ActionBar";
import MainToolbar from "./toolbars/MainToolbar";
import SystemFilterToolbar from "./toolbars/SystemFilterToolbar";
import DealChip from "./../controls/DealChip";
//MUI
import Hidden from "@material-ui/core/Hidden";

const defaultProps = { color: "secondary", filter: false };
const NavBarMainMenu = ({
	color = defaultProps.color,
	filter = defaultProps.filter,
}) => {
	return (
		<ActionBar>
			<Hidden smDown>
				<MainToolbar filter color={color} />
			</Hidden>
			<SystemFilterToolbar color={color} />
		</ActionBar>
	);
};

NavBarMainMenu.propTypes = {
	color: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	filter: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default NavBarMainMenu;
