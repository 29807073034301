import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import clsx from "clsx";
//MUI
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { deleteUser } from "../../../../redux/actions/systemActions";

import { cancelUserAccount } from "../../../../redux/actions/userActions";
import {
	setAlertWithConfirmation,
	clearAlerts,
} from "../../../../redux/actions/alertActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	button: {
		//color: "#fff",
		backgroundColor: theme.palette.warning.dark,
		"&:hover": {
			transform: "scale(1.1)",
			backgroundColor: theme.palette.warning.dark,
		},
	},
	icon: {
		marginRight: 0,
		maxHeight: 19,
		marginTop: "-3px",
	},
}));

const defaultProps = {
	size: "medium",
	showIcon: true,
	deleteSelf: false,
};

const DeleteUserButton = ({
	user,
	menu,
	text,
	variant,
	size = defaultProps.size,
	showIcon = defaultProps.showIcon,
	deleteSelf = defaultProps.deleteSelf,
}) => {
	const alert = useSelector((state) => state.alert);
	const classes = styles();
	const dispatch = useDispatch();

	useEffect(() => {
		//confirmation turn off after server after response--> systemAction setUserRole()
		if (alert.confirmationForm === "userDelete" && alert.confirmed) {
			let confirmedHandle = alert.inputFieldValue
				? alert.inputFieldValue
				: null;
			const data = {
				userId: user.userId,
				confirmedHandle,
				deleteSelf,
			};
			switch (deleteSelf) {
				case true:
					dispatch(cancelUserAccount(data));

					break;
				case false:
					dispatch(deleteUser(data));
					break;
				default:
			}

			dispatch(clearAlerts());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [alert.confirmed]);

	/*	const checkAdminRole = (systemRoles) => {
		let isAdmin = false;
		if (systemRoles) {
			if (systemRoles.admin) {
				isAdmin = systemRoles.admin;
			}
		}
		return isAdmin;
	};
	let isAdmin = checkAdminRole(user.roles);*/

	const alertMsg = (
		<Fragment>
			<Typography component="div" paragraph variant="body1">
				Are you sure you want to delete{" "}
				<Typography
					className={classes.boldText}
					display="inline"
					variant="body1">
					{user.handle}
				</Typography>
				?
			</Typography>
			<Typography component="div" paragraph variant="body1">
				This user (
				<Typography
					className={classes.boldText}
					display="inline"
					variant="body1">
					{user.handle}){" "}
				</Typography>{" "}
				will be removed completely. This action cannot be reversed. The
				user and everything associated with thier account will be
				perminately deleted. This includes deals, comments, inbox
				conversations etc.
			</Typography>
			<Typography paragraph className={classes.boldText} variant="body1">
				Please enter the user name into the field below:
			</Typography>
		</Fragment>
	);

	const handleDelete = () => {
		dispatch(
			setAlertWithConfirmation(
				"userDelete", //form for verification
				"error", //type
				`Confirm Deletion of ${user.handle}?`, //title
				alertMsg, //message
				true, //input field
				`Enter ${user.handle} here`, //input field placeholder
				null //data
			)
		);
	};

	return (
		<Grid item xs={12}>
			<Fragment>
				<Button
					id={user.userId}
					fullWidth
					className={classes.button}
					size={clsx(menu ? "small" : size)}
					variant={variant}
					color={"secondary"}
					aria-label="edit"
					onClick={() => handleDelete()}>
					{showIcon && <DeleteIcon className={classes.icon} />} {text}
				</Button>
			</Fragment>
		</Grid>
	);
};

DeleteUserButton.propTypes = {
	deleteSelf: PropTypes.any,
	fullWidth: PropTypes.any,
	menu: PropTypes.any,
	showIcon: PropTypes.any,
	size: PropTypes.string,
	text: PropTypes.string.isRequired,
	user: PropTypes.shape({
		handle: PropTypes.string.isRequired,
		userId: PropTypes.string.isRequired,
	}),
	variant: PropTypes.string,
};

export default DeleteUserButton;
