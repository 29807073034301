import React from "react";
//MUI
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles(theme => ({
	...theme.customStyles
}));
const FormRestoreImageButton = ({ onClick, buttonWidth }) => {
	const classes = styles();
	return (
		<Grid item xs={12} sm={buttonWidth}>
			<Button
				fullWidth
				className={classes.imageButtons}
				variant="outlined"
				color="primary"
				onClick={onClick}>
				Restore
			</Button>
		</Grid>
	);
};

export default FormRestoreImageButton;
