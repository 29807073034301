import PropTypes from "prop-types";
import React from "react";
//MUI
import Typography from "@material-ui/core/Typography";

const FormTitle = ({ title }) => {
	return (
		<Typography component="span" variant="h6">
			{title ? title : null}
		</Typography>
	);
};

FormTitle.propTypes = {
	title: PropTypes.string.isRequired,
};

export default FormTitle;
