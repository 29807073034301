import PropTypes from "prop-types";
import React, { useEffect } from "react";
//Components
import DealCardSection from "../sections/DealCardSection";
//MUI
import Typography from "@material-ui/core/Typography";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { setFilteredDeals } from "../../redux/actions/dataActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	bagdeCard: {
		margin: theme.spacing(1),
		marginBottom: theme.spacing(2),
	},
	title: {
		marginBottom: 20,
	},
}));
const ProfileSection = ({ deals, handle }) => {
	const filteredDeals = useSelector((state) => state.data.filteredDeals);
	const classes = styles();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setFilteredDeals(deals, { handle }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, deals, handle]);

	return (
		<>
			{handle && (
				<Typography
					className={classes.title}
					variant="h6"
					color="secondary">{`Viewing Deals Made by @${handle}`}</Typography>
			)}

			<DealCardSection
				deals={filteredDeals /*publicData.deals*/}
				publicDeal
			/>
		</>
	);
};

ProfileSection.propTypes = {
	deals: PropTypes.array.isRequired,
	handle: PropTypes.string.isRequired,
};

export default ProfileSection;
