import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";
// Redux
import { useDispatch } from "react-redux";
import { systemSetUser } from "../../../../../../redux/actions/systemActions";
//MUI Components
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { toggleFormDialog } from "../../../../../../redux/actions/uiActions";

import { Button } from "@material-ui/core";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	button: {
		width: "100%",
		color: "#fff",
		//backgroundColor: theme.palette.accent.main
	},
	canceled: {
		backgroundColor: "red",
	},
}));

const SystemSubscriptionButton = ({ user, className }) => {
	const dispatch = useDispatch();
	const classes = styles();

	const handleSubscriptionClick = () => {
		dispatch(systemSetUser(user));
		dispatch(toggleFormDialog(true, "subscriptionInfo", null));
	};

	return (
		<Grid item xs={12}>
			<Button
				fullWidth
				id="viewVerificationApplication"
				disabled={
					user.verificationStatus !== "review" && !user.verified
				}
				className={clsx(
					className,
					classes.button,
					user.subscriptionStatus === "canceled"
						? null //classes.canceled
						: null
				)}
				variant="contained"
				color="primary"
				onClick={() => handleSubscriptionClick()}>
				Subscription
			</Button>
		</Grid>
	);
};

SystemSubscriptionButton.propTypes = {
	className: PropTypes.string,
	user: PropTypes.shape({
		subscriptionStatus: PropTypes.string.isRequired,
		verificationStatus: PropTypes.string.isRequired,
		verified: PropTypes.bool.isRequired,
	}),
};

export default SystemSubscriptionButton;
