import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import clsx from "clsx";
//MUI
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { cancelSubscription } from "../../../../redux/actions/subcriptionActions";

import {
	setAlertWithConfirmation,
	clearAlerts,
} from "../../../../redux/actions/alertActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	cancelButton: {
		//color: "#fff",
		backgroundColor: theme.palette.warning.dark,
		"&:hover": {
			transform: "scale(1.1)",
			backgroundColor: theme.palette.warning.dark,
		},
	},
	icon: {
		marginRight: 0,
		maxHeight: 19,
		marginTop: "-3px",
	},
}));

const defaultProps = {
	size: "medium",
	showIcon: true,
	deleteSelf: false,
};

const CancelSubscriptionButton = ({
	user,
	menu,
	text,
	variant,
	className,
	size = defaultProps.size,
	showIcon = defaultProps.showIcon,
}) => {
	//const subscription = useSelector((state) => state.subscription.data);
	const alert = useSelector((state) => state.alert);
	const classes = styles();
	const dispatch = useDispatch();

	useEffect(() => {
		//confirmation turn off after server after response--> systemAction setUserRole()
		if (
			alert.confirmationForm === "subscriptionCancel" &&
			alert.confirmed
		) {
			let confirmedHandle = alert.inputFieldValue
				? alert.inputFieldValue
				: null;
			const data = {
				userId: user.userId,
				confirmedHandle,
			};
			dispatch(cancelSubscription(data, user));
			dispatch(clearAlerts());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [alert.confirmed]);

	const alertMsg = (
		<Fragment>
			<Typography component="div" paragraph variant="body1">
				Are you sure you want to cancel the subscription for{" "}
				<Typography
					className={classes.boldText}
					display="inline"
					variant="body1">
					{user.handle}
				</Typography>
				?
			</Typography>
			<Typography component="div" paragraph variant="body1">
				This subscription will be removed completely. This action cannot
				be reversed. A new subscription will need to be created if you
				would like your premium account restored.
			</Typography>
			<Typography paragraph className={classes.boldText} variant="body1">
				Please enter user name into the field below:
			</Typography>
		</Fragment>
	);

	const handleDelete = () => {
		dispatch(
			setAlertWithConfirmation(
				"subscriptionCancel", //form for verification
				"error", //type
				`Confirm Subscription Cancelation for ${user.handle}?`, //title
				alertMsg, //message
				true, //input field
				`Enter ${user.handle} here`, //input field placeholder
				null //data
			)
		);
	};

	return (
		<Grid item xs={12}>
			<Fragment>
				<Button
					id={user.userId}
					fullWidth
					className={clsx(
						className,
						classes.cancelButton,
						classes.button
					)}
					size={clsx(menu ? "small" : size)}
					variant={variant}
					color={"secondary"}
					aria-label="cancel subscription"
					onClick={() => handleDelete()}>
					{/*showIcon && <DeleteIcon className={classes.icon} />*/}{" "}
					{text}
				</Button>
			</Fragment>
		</Grid>
	);
};

CancelSubscriptionButton.propTypes = {
	className: PropTypes.any,
	menu: PropTypes.any,
	showIcon: PropTypes.any,
	size: PropTypes.any,
	text: PropTypes.any,
	user: PropTypes.shape({
		handle: PropTypes.any,
		userId: PropTypes.any,
	}),
	variant: PropTypes.any,
};

export default CancelSubscriptionButton;
