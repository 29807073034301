import React, { Fragment, useState, useEffect } from "react";
import { Document, Page, Outline, pdfjs } from "react-pdf";
import clsx from "clsx";
//Components
import SpinnerButton from "../../controls/SpinnerButton";
import DealSpinner from "../../controls/DealSpinner";
//MUI Components
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { systemPostVerification } from "../../../redux/actions/systemActions";
import {
	setAlertWithConfirmation,
	clearAlerts,
} from "../../../redux/actions/alertActions";
// Styles
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	userId: {
		textAlign: "center",
	},
	adminUser: {
		backgroundColor: theme.palette.accent.main,
	},
	adminLabel: {
		color: theme.palette.accent.main,
	},
	confirmedChipColorDeactivated: {
		backgroundColor: "lightGrey",
		color: "grey",
	},
	confirmedChipColorActivated: {
		backgroundColor: theme.palette.accent.main,
		color: "#fff",
	},
	verifyButton: {
		maxWidth: "95%",
	},
	spinner: {
		marginTop: 10,
		height: 100,
	},
}));

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const SystemApplicationForm = (props) => {
	const user = useSelector((state) => state.system.user);
	const alert = useSelector((state) => state.alert);
	const loading = useSelector((state) => state.UI.loading);
	const loadingUI = useSelector((state) => state.system.loadingUI);
	const verificationFile = useSelector(
		(state) => state.system.verificationFile
	);
	const [pages, setPages] = useState({
		numPages: null,
		pageNumber: 1,
		index: 1,
	});

	const dispatch = useDispatch();
	const classes = styles();

	const file = verificationFile.fileSignedUrl;

	useEffect(() => {
		if (alert.confirmationForm === "systemApplication" && alert.confirmed) {
			let verificationStatus;
			if (user.verificationStatus === "review") {
				verificationStatus = "approved";
			} else {
				verificationStatus = "review";
			}
			const status = {
				verified: !user.verified,
				verificationStatus: verificationStatus,
				userId: user.userId,
			};

			dispatch(systemPostVerification(status));
			dispatch(clearAlerts());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [alert.confirmed, dispatch]);

	const onDocumentLoadSuccess = (numberOfPages) => {
		setPages({ ...pages, numPages: numberOfPages._pdfInfo.numPages });
	};

	const theme = useTheme();
	const mediumUp = useMediaQuery(theme.breakpoints.up("md"));
	const screenWidthSmallMedium = useMediaQuery(
		theme.breakpoints.between("sm", "md")
	);
	const screenWidthMediumLarge = useMediaQuery(
		theme.breakpoints.between("md", "lg")
	);
	const screenWidthLargeUp = useMediaQuery(theme.breakpoints.up("lg"));
	const documentScaleWidth = () => {
		if (screenWidthSmallMedium) {
			return 450;
		} else if (screenWidthMediumLarge) {
			return 850;
		} else if (screenWidthLargeUp) {
			return 1000;
		} else {
			return 300;
		}
	};

	const verifyMsg = (
		<Fragment>
			<Typography paragraph variant="body1">
				Are you sure you want to grant premium access to{" "}
				<Typography
					className={classes.boldText}
					display="inline"
					variant="body1">
					{user.handle}
				</Typography>
				?
			</Typography>
		</Fragment>
	);

	const unVerifyMsg = (
		<Fragment>
			<Typography paragraph variant="body1">
				Are you sure you want to remove premium access from{" "}
				<Typography
					className={classes.boldText}
					display="inline"
					variant="body1">
					{user.handle}
				</Typography>
				?
			</Typography>
			<Typography variant="body1">
				This user (
				<Typography
					className={classes.boldText}
					display="inline"
					variant="body1">
					{user.handle}){" "}
				</Typography>{" "}
				may have paid for this service. All premium badges for this user
				will be removed without notification..
			</Typography>
		</Fragment>
	);

	const changePage = (offset) => {
		let page = pages.pageNumber + offset;
		setPages({ ...pages, pageNumber: page });
	};
	const previousPage = () => changePage(-1);
	const nextPage = () => changePage(1);

	const onItemClick = (page) => setPages(page);

	const handleClick = () => {
		dispatch(
			setAlertWithConfirmation(
				"systemApplication",
				!user.verified ? "information" : "error",
				!user.verified
					? "Confirm User Verification"
					: "Confirm User Verification Removal",
				!user.verified ? verifyMsg : unVerifyMsg
			)
		);
	};
	const spinner = (
		<div className={classes.spinner}>
			<DealSpinner size={60} loading />
		</div>
	);

	const controlButtons =
		pages.numPages > 1 ? (
			<Grid item container spacing={2}>
				<Grid item xs={12}>
					<p>
						Page {pages.pageNumber} of {pages.numPages}
					</p>
				</Grid>
				<Grid item sm={3}></Grid>
				<Grid item xs={12} sm={3}>
					<Button
						disabled={pages.pageNumber <= 1}
						fullWidth
						id="next"
						variant="contained"
						color="primary"
						onClick={() => previousPage()}>
						Previous
					</Button>
				</Grid>
				<Grid item xs={12} sm={3}>
					<Button
						disabled={pages.pageNumber >= pages.numPages}
						fullWidth
						id="next"
						variant="contained"
						color="primary"
						onClick={() => nextPage()}>
						Next
					</Button>
				</Grid>
				<Grid item sm={3}></Grid>
			</Grid>
		) : null;

	return (
		!loading && (
			<Fragment>
				<Grid item align="center" xs={12}>
					<Grid container align="center" spacing={2}>
						<Grid item sm={4}></Grid>
						<Grid item xs={12} sm={4}>
							<SpinnerButton
								fullWidth
								variant="contained"
								disabled={loadingUI}
								className={clsx(
									classes.verifyButton,
									!user.verified
										? classes.confirmedChipColorDeactivated
										: classes.confirmedChipColorActivated
								)}
								id={`verifiedButton`}
								onClick={() => handleClick()}>
								{user.verified ? "Verified" : "Verify User"}
							</SpinnerButton>
						</Grid>
						<Grid item sm={4}></Grid>
					</Grid>
				</Grid>

				<Grid
					style={{ marginTop: 20, marginBottom: 50 }}
					item
					align="center"
					xs={12}>
					{file && (
						<Grid container spacing={2}>
							{mediumUp && controlButtons}
							<Grid item xs={12}>
								<Document
									file={file}
									loading={spinner}
									onLoadError={console.error}
									onLoadSuccess={(nums) =>
										onDocumentLoadSuccess(nums)
									}>
									<Outline
										onItemClick={(page) =>
											onItemClick(page)
										}
									/>
									<Page
										width={documentScaleWidth()}
										pageNumber={pages.pageNumber}
										onLoadError={console.error}
										loading={spinner}
									/>
								</Document>
							</Grid>
							{controlButtons}
						</Grid>
					)}
				</Grid>
			</Fragment>
		)
	);
};

export default SystemApplicationForm;
