import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import clsx from "clsx";
//Components
import UserBadge from "../badges/UserBadge";
import DeleteConversationButton from "../controls/buttons/inbox/DeleteConversationButton";
import Notifications from "../../components/navigation/Notifications";
//MUI
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
//Redux
import { useDispatch } from "react-redux";
import { setConversation } from "../../redux/actions/inboxActions";
import { deleteReadNotifications } from "../../redux/actions/userActions";
//Styles
import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	content: {
		padding: theme.spacing(1),
		"&:hover": {
			cursor: "pointer",
		},
	},
	userBadge: {
		maxWidth: 100,
	},
	handle: {
		marginTop: 10,
	},
	badgeIcon: {
		position: "absolute",
		left: "60%",
		top: "118%",
	},
	mailCard: {
		backgroundColor: theme.palette.information.main,
	},
	deleteButton: {
		position: "absolute",
		right: 0,
		top: 0,
		color: theme.palette.warning.main,
	},
	notificationIcon: {
		color: theme.palette.primary.dark,
	},
}));

const ConversationCard = ({
	conversation,
	participant,
	conversationNotifications,
}) => {
	const [userData] = useState(participant);

	const classes = styles();
	const dispatch = useDispatch();

	const handleConversationCardClick = () => {
		dispatch(setConversation(conversation, "messages"));
		deleteRead();
	};

	const deleteRead = () => {
		let unreadNotificationsIds = conversationNotifications
			.filter((not) => !not.read)
			.map((not) => not.notificationId);
		dispatch(deleteReadNotifications(unreadNotificationsIds));
	};

	return (
		<Fragment>
			<Grid
				item
				container
				justify="center"
				alignItems="center"
				className={clsx(
					classes.content,
					classes.formBackground,
					classes.mailCard
				)}
				component={Card}
				xs={12}>
				<div style={{ position: "relative", width: "100%" }}>
					<DeleteConversationButton
						toolTip="Delete Conversation"
						color="primary"
						className={classes.deleteButton}
						conversationId={conversation.conversationId}
						user={userData}
					/>
				</div>
				<Grid
					item
					container
					spacing={2}
					xs={12}
					onClick={() => {
						handleConversationCardClick();
					}}>
					<Grid item xs={12}>
						<div
							style={{
								position: "relative",
								width: "100%",
								height: 10,
							}}>
							<Notifications
								className={classes.notificationIcon}
								conversationNotifications={
									conversationNotifications
								}
							/>
						</div>

						<UserBadge
							noLink
							shadow
							noScaleUp
							className={classes.userBadge}
							handleClassName={classes.handle}
							smallBadge
							user={userData}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Fragment>
	);
};

ConversationCard.propTypes = {
	conversation: PropTypes.object.isRequired,
	participant: PropTypes.object.isRequired,
};

export default ConversationCard;
