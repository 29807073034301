import PropTypes from "prop-types";
import React, { Fragment } from "react";
import clsx from "clsx";
//MUI Components;
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import HomeIcon from "@material-ui/icons/Home";
import WorkIcon from "@material-ui/icons/Work";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import GridList from "@material-ui/core/GridList";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	featurePrimary: { marginLeft: "-15px" },
	root: { padding: 0, color: theme.palette.primary.dark },

	icon: { color: theme.palette.primary.dark },
	divider: { width: "100%" },
}));

const PriceFeatureGrid = ({ features, showMaxNumberOfFeature }) => {
	const classes = styles();

	let numberOfFeaturesToShow;
	let showMoreFeaturesBanner = false;

	if (!features) {
		return <>{null}</>;
	}

	if (showMaxNumberOfFeature < features.length) {
		numberOfFeaturesToShow = showMaxNumberOfFeature;
		showMoreFeaturesBanner = true;
	} else {
		numberOfFeaturesToShow = features.length; //All
	}

	const featuresList = () => {
		let results = [];
		for (var i = 0; i < numberOfFeaturesToShow; i++) {
			console.log(features[i]);
			results.push(featureRow(features[i], false, i));
		}
		if (showMoreFeaturesBanner) {
			results.push(
				featureRow("And More ...", true, showMaxNumberOfFeature + 1)
			);
		}
		return results;
	};

	const featureRow = (feature, bold, key) => (
		<List className={classes.root} key={key}>
			<ListItem
				divider
				disableGutters
				alignItems="flex-start"
				classes={{ divider: classes.divider }}>
				<ListItemAvatar>
					<Avatar
						style={{ background: "white", width: 20, height: 20 }}>
						<HomeIcon className={classes.icon} />
					</Avatar>
				</ListItemAvatar>
				<ListItemText
					classes={{
						primary: clsx(
							classes.featurePrimary,
							bold && classes.boldText
						),
					}}
					primary={feature}
				/>
			</ListItem>
		</List>
	);

	return (
		<Fragment>
			{featuresList()}
			{/*<hr className={classes.rowSeperator} />*/}
		</Fragment>
	);
};

PriceFeatureGrid.propTypes = {
	dataWidth: PropTypes.any,
	labelWidth: PropTypes.any,
	rows: PropTypes.shape({
		map: PropTypes.func,
	}),
};

export default PriceFeatureGrid;
