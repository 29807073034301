import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";

//Components
import MessageCard from "../inbox/MessageCard";
import ConversationCard from "../inbox/ConversationCard";
import SendMessageReplyForm from "../forms/messages/SendMessageReplyForm";
import DealSpinner from "../controls/DealSpinner";
import UserBadge from "../badges/UserBadge";
//MUI
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
//Redux
import { useSelector, useDispatch } from "react-redux";
import {
	getMessagesByConversationId,
	getConversations,
} from "../../redux/actions/inboxActions";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	root: {
		flexGrow: 1,
		borderRadius: 4,
	},
	demo: {
		backgroundColor: theme.palette.background.paper,
	},
	title: {
		margin: theme.spacing(4, 0, 2),
	},
	spinner: { marginTop: 50 },
}));

//////Inbox
const InboxSection = ({ conversations }) => {
	const authUser = useSelector((state) => state.user.credentials);
	const inbox = useSelector((state) => state.inbox);
	const notifications = useSelector((state) => state.user.notifications);
	const loading = inbox.loading;
	const view = inbox.view;
	const conversation = inbox.conversation;
	const messages = inbox.messages;

	const classes = styles();
	const dispatch = useDispatch();

	const getUserData = (conversationData) => {
		if (authUser.userId === conversationData.firstParticipant.id) {
			let secondParticipantData = {
				handle: conversationData.secondParticipant.handle,
				imageUrl: conversationData.secondParticipant.imageUrl,
			};
			return secondParticipantData;
		} else {
			let firstParticipantData = {
				handle: conversationData.firstParticipant.handle,
				imageUrl: conversationData.firstParticipant.imageUrl,
			};
			return firstParticipantData;
		}
	};

	useEffect(() => {
		const getData = () => {
			switch (view) {
				case "messages":
					dispatch(
						getMessagesByConversationId(conversation.conversationId)
					);
					break;
				case "conversations":
					dispatch(getConversations());
					break;
				default:
					getConversations();
			}
		};
		getData();
	}, [view, conversation.conversationId, dispatch]);

	const getUserNotifications = (conversationId) => {
		let conversationNotificationsIds = notifications
			.filter((note) => note.conversationId == conversationId)
			.filter((note) => !note.read);
		return conversationNotificationsIds;
	};

	const conversationCards = !loading
		? conversations
			? conversations.map((conversation) => (
					<Grid key={conversation.conversationId} item sm={3} xs={12}>
						{conversation && authUser.userId && (
							<ConversationCard
								conversation={conversation}
								userId={authUser.userId}
								participant={getUserData(conversation)}
								conversationNotifications={getUserNotifications(
									conversation.conversationId
								)}
							/>
						)}
					</Grid>
			  ))
			: null
		: null;

	const messagesBubles = !loading
		? messages
			? messages.map((message) => (
					<Grid key={message.messageId} item container xs={12}>
						{message && conversation && (
							<MessageCard message={message} />
						)}
					</Grid>
			  ))
			: null
		: null;

	const inboxContent =
		view === "conversations" &&
		conversationCards &&
		conversationCards.length ? (
			<Fragment>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography
							color="secondary"
							align="center"
							variant="h6">
							Inbox Conversations
						</Typography>
					</Grid>
					<Grid item container spacing={2} xs={12}>
						{conversationCards}
					</Grid>
				</Grid>
			</Fragment>
		) : view === "messages" && messagesBubles && messagesBubles.length ? (
			<Fragment>
				<Grid container justify="center">
					<Grid item xs={2}>
						<UserBadge
							className={classes.userBadge}
							smallBadge
							user={getUserData(conversation)}
						/>
					</Grid>
				</Grid>
				{messagesBubles}
				<SendMessageReplyForm />
			</Fragment>
		) : (
			<Grid item xs={12}>
				<Typography color="secondary" align="center" variant="body2">
					No conversation cards found
				</Typography>
			</Grid>
		);

	return (
		<Grid container justify="center" spacing={2} item xs={12}>
			{!loading ? (
				inboxContent
			) : (
				<DealSpinner
					spinnerParentClassName={classes.spinner}
					loading
					size={75}
				/>
			)}
		</Grid>
	);
};

InboxSection.propTypes = {
	conversations: PropTypes.array.isRequired,
};

export default InboxSection;
