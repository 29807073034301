import PropTypes from "prop-types";
import React from "react";
import { FacebookShareButton } from "react-share";
import FacebookIcon from "../../../../images/svg/icon-facebook.svg";
//Components
import ShareButton from "../ShareButton";
//MUI Components
import Grid from "@material-ui/core/Grid";

const FacebookDealShareButton = ({ shareUrl }) => {
	return (
		<Grid item xs={12} sm={3}>
			<FacebookShareButton style={{ width: "100%" }} url={shareUrl}>
				<ShareButton icon={FacebookIcon} text="Facebook" />
			</FacebookShareButton>
		</Grid>
	);
};

FacebookDealShareButton.propTypes = {
	shareUrl: PropTypes.string.isRequired,
};

export default FacebookDealShareButton;
