/**
 * Use the CSS tab above to style your Element's container.
 */
import React from "react";
import clsx from "clsx";
//MUI
import Grid from "@material-ui/core/Grid";

import { CardElement } from "react-stripe-elements";
import { useTheme } from "@material-ui/core/styles";

//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	cardElementWrapper: {
		borderColor: "green",
		borderStyle: "solid",
		borderWidth: 1,
		borderRadius: 4,
		display: "block",
		margin: "10px 0 20px 0",
		maxWidth: 500,
		padding: "5px 7px",
		boxShadow:
			"rgba(81,196,108, 1.14902) 1px 1px 8px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px",
		background: "white",
	},
	cardElement: {
		padding: 10,
	},
}));

const CardSection = () => {
	const theme = useTheme();
	const classes = styles();

	const inlineStyle = {
		base: {
			color: theme.palette.primary.main,
			fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
			fontSmoothing: "antialiased",
			":-webkit-autofill": {
				color: "#fce883",
			},
			fontSize: "16px",
			"::placeholder": {
				color: theme.palette.placeholder.main,
			},

			iconColor: theme.palette.primary.main,
		},
		invalid: {
			color: "#fa755a",
			iconColor: "#fa755a",
		},
		backgroundColor: "white",
	};

	return (
		<Grid item justify="space-between" spacing={2} xs={12}>
			<div className={classes.cardElementWrapper}>
				<Grid item xs={12}>
					<label>
						<CardElement
							className={clsx(
								"MyCardElement",
								classes.cardElement
							)}
							style={inlineStyle}
						/>
					</label>
				</Grid>
			</div>
		</Grid>
	);
};

export default CardSection;
