import background from "../../src/images/background-neutral@300.png";
export default {
	palette: {
		primary: {
			light: "#69FF8C",
			main: "#51C46C",
			dark: "#1A4023",
		},
		secondary: {
			light: "#fff",
			main: "#fff",
		},
		accent: {
			main: "#dbc374",
			//main: "#C2AA2C",
		},
		warning: {
			main: "#c98174",
			dark: "#cb3a20",
		},
		information: {
			main: "#e6f7ed",
			dark: "#2f628e",
		},
		action: {
			main: "#2f628e",
		},
		placeholder: {
			main: "lightGrey",
		},
		stripe: "#525f7f",
		text: {
			primary: "#51C46C",
		},
	},
	customStyles: {
		success: {
			color: "green",
		},
		loginLogo: {
			margin: "20px auto 0 auto",
		},
		form: {
			textAlign: "center",
		},
		image: {
			margin: "20px auto 20px auto",
		},
		pageTitle: {
			margin: "10px auto 10px auto",
		},
		textField: {
			margin: "10px auto 10px auto",
		},
		button: {
			marginTop: 20,
			position: "relative",
		},
		buttonColorWhite: {
			color: "#fff",
		},
		spinner: {
			position: "absolute",
			textAlign: "center",
		},
		spinnerGeneral: {
			display: "block",
			margin: "0 auto",
			textAlign: "center",
		},
		typography: {
			useNextVariants: true,
		},
		customError: {
			color: "red",
			fontSize: "0.8rem",
			marginTop: 10,
		},
		progress: {
			position: "absolute",
		},
		invisibleSeparator: {
			border: "none",
			margin: 10,
		},
		visibleSeparator: {
			width: "100%",
			borderBottom: "1px solid #e6e7e9",
			marginBottom: 10,
		},
		paper: {
			padding: 20,
		},
		sidebarButton: {
			display: "block",
			textAlign: "center",
			margin: "10px auto",
			maxWidth: 300,
		},
		formButton: {
			display: "block",
			textAlign: "center",
			margin: "10px auto",
		},
		profile: {
			"& .image-wrapper": {
				textAlign: "center",
				position: "relative",
				"& button": {
					position: "absolute",
					top: "0%",
					left: "80%",
				},
			},
			"& .profile-image": {
				width: 200,
				height: 200,
				objectFit: "cover",
				maxWidth: "100%",
				borderRadius: "50%",
			},
			"& .profile-details": {
				textAlign: "center",
				"& span, svg": {
					verticalAlign: "middle",
				},
				"& a": {
					color: "#00bcd4",
				},
			},
			"& svg.button": {
				"&:hover": {
					cursor: "pointer",
				},
			},
		},
		buttons: {
			textAlign: "center",
			"& a": {
				margin: "20px 10px",
			},
		},
		cardImage: {
			height: 160,
		},
		card: {
			// Provide some spacing between cards
			margin: "0 15px 25px 15px",

			// Use flex layout with column direction for components in the card
			// (CardContent and CardActions)
			display: "flex",
			flexDirection: "column",

			// Justify the content so that CardContent will always be at the top of the card,
			// and CardActions will be at the bottom
			justifyContent: "space-between",
			minWidth: 265,
		},
		cardContnet: {
			padding: 25,
			objectfit: "cover",
		},
		document: {
			color: "black",
		},
		notVerifiedChip: {
			backgroundColor: "lightGrey",
			color: "grey",
		},
		verifiedChip: {
			backgroundColor: "#51C46C",
			color: "#fff",
		},
		notVerifiedText: {
			color: "grey",
		},

		shadow: {
			color: "black",
			boxShadow: "2px 2px 5px",
		},
		formBackground: {
			backgroundImage: `url(${background})`,
			backgroundColor: "#f2fbf6" /* Used if the image is unavailable */,
			backgroundPosition: "center" /* Center the image */,
			backgroundRepeat: "no-repeat" /* Do not repeat the image */,
			backgroundSize:
				"cover" /* Resize the background image to cover the entire container */,
		},
		border: {
			backgroundColor: "#fff",
			color: "#51C46C",
			borderColor: "#51C46C",
			borderStyle: "solid",
			borderWidth: 1,
			width: "100%",
			padding: "0 5px 0 5px",
			textAlign: "center",
			lineHeight: 0.1,
		},
		boldText: {
			fontWeight: 700,
		},
	},
};
