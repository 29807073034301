import React from "react";
//MUI
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	card: {
		position: "relative",
		display: "flex",
	},
	userId: {
		textAlign: "center",
	},
	adminUser: {
		backgroundColor: theme.palette.primary.main,
	},
	adminLabel: {
		color: theme.palette.accent.main,
	},
	assignAdmin: {
		color: "red",
	},
	confirmedChipColorDeactivated: {
		backgroundColor: "lightGrey",
		color: "grey",
	},
	confirmedChipColorActivated: {
		backgroundColor: theme.palette.accent.main,
		color: "#fff",
	},
	confirmedChip: {
		marginBottom: 10,
	},
	boldText: {
		fontWeight: 700,
	},
}));

function AssignWholesalerAlert({ messageType, user }) {
	const classes = styles();

	const assignMsg = (
		<div>
			<Typography component="div" paragraph variant="body1">
				Are you sure you want to change{" "}
				<Typography
					component="span"
					className={classes.boldText}
					display="inline"
					variant="body1">
					{`${user.handle}'s`}{" "}
				</Typography>
				authorization role to{" "}
				<Typography
					component="span"
					className={classes.boldText}
					display="inline"
					variant="body1">
					&quot;Wholesaler&quot;
					<Typography
						component="span"
						className={classes.boldText}
						display="inline"
						variant="body1">
						($50 PER MONTH ONLY FOR MAINTENANCE. NO PREMIUM CHARGES
						WILL APPLY)
					</Typography>
				</Typography>
				?
			</Typography>
			<Typography component="div" variant="body1">
				Assigning Wholesaler authorization to{" "}
				<Typography
					component="span"
					className={classes.boldText}
					display="inline"
					variant="body1">
					{user.handle}{" "}
				</Typography>
				will allow this user to create and edit deals, in addition to
				future rights granted by this role.
			</Typography>
		</div>
	);

	const unAssignMsg = (
		<div>
			<Typography component="div" paragraph variant="body1">
				Are you sure you want to remove{" "}
				<Typography
					component="span"
					className={classes.boldText}
					display="inline"
					variant="body1">
					Wholesaler
				</Typography>{" "}
				authorization from user{" "}
				<Typography
					component="span"
					className={classes.boldText}
					display="inline"
					variant="body1">
					{user.handle}
				</Typography>
				?
			</Typography>
			<Typography component="div" variant="body1">
				This user (
				<Typography
					component="span"
					className={classes.boldText}
					display="inline"
					variant="body1">
					{user.handle}){" "}
				</Typography>{" "}
				will no longer be able to create and edit deals, nor will this
				use have any future rights granted by this role.
			</Typography>
		</div>
	);

	const messageSelector = () => {
		switch (messageType) {
			case "assignMsg":
				return assignMsg;
				break;
			case "unAssignMsg":
				return unAssignMsg;
				break;
			default:
				break;
		}
	};
	const message = messageSelector();
	return <>{message}</>;
}

export default AssignWholesalerAlert;
