import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
//Components
import PriceFeatureGrid from "../controls/PriceFeatureGrid";
import VirtualAssistantProductCard from "../virtual-assistant/VirtualAssistantProductCard";
//import { assistancePackages } from "../virtual-assistant/VAProducts - Not Used";
import { assistanceCategories } from "../virtual-assistant/VACategories";
import VirtualAssistantCategoryGroup from "../virtual-assistant/VirtualAssistantCategoryGroup";
//import Checkout from "../vaCheckout/Checkout";
//import OneTimePaymentCheckout from "../one-time-payments/Temp-OneTimePaymentCheckout";
//MUI
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { getPackages } from "../../redux/actions/checkoutActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
import CheckoutForm from "../checkout/CheckoutForm";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	spinner: {
		marginTop: 50,
	},

	productColor: { color: theme.palette.primary.dark },
	cardHeader: {
		color: "#fff",
		textAlign: "center",
		backgroundColor: theme.palette.primary.main,
		paddingBottom: 5,
		marginBottom: 0,
		paddingTop: 5,
		marginTop: 0,
	},
	cardSubheaderText: {
		color: "#fff",
	},
	description: {
		fontSize: 10,
	},
}));

export const VirtualAssistantSection = () => {
	const { useEffect } = React;
	const dispatch = useDispatch();
	const loading = useSelector((state) => state.data.loading);
	const packages = useSelector((state) => state.checkout.packages);
	const authUser = useSelector((state) => state.user.credentials);
	const assistancePackage = useSelector(
		(state) => state.checkout.virtualAssistant.package
	);
	const classes = styles();

	useEffect(() => {
		dispatch(getPackages());
	}, []);

	{
		/*let assistancePackageCards = assistancePackages
		? assistancePackages.map((assistancePackage) => (
				<Grid
					className={classes.textColor}
					key={assistancePackage.assistancePackageId}
					item
					container
					alignItems="stretch"
					xs={12}
					sm={4}>
					<VirtualAssistantProductCard
						assistancePackage={assistancePackage}
					/>
				</Grid>
		  ))
		: null;
		*/
	}

	let assistanceCategoryCards = assistanceCategories
		? assistanceCategories.map((assistanceCategory, index) =>
				assistanceCategory.categoryId != "test_category" ? (
					<Grid item xs={12} key={index}>
						<VirtualAssistantCategoryGroup
							assistanceCategory={assistanceCategory}
							assistancePackages={packages}
						/>
					</Grid>
				) : (
					authUser.roles?.tech && (
						<Grid item xs={12} key={index}>
							<VirtualAssistantCategoryGroup
								assistanceCategory={assistanceCategory}
								assistancePackages={packages}
								testCategoryGroup
							/>
						</Grid>
					)
				)
		  )
		: null;

	//const sectionJustify = !assistancePackages ? "center" : "flex-start";

	return (
		<Grid container spacing={2} item xs={12}>
			{assistancePackage ? (
				<CheckoutForm />
			) : (
				assistanceCategories && (
					<Grid item container xs={12} spacing={2}>
						{assistanceCategoryCards}
					</Grid>
				)
			)}
		</Grid>
	);
};

VirtualAssistantSection.propTypes = {
	assistancePackages: PropTypes.array,
};

export default VirtualAssistantSection;
