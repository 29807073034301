import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { checkUserRole } from "../../util/UtilityFunctions";
//Components
import FormOpenButton from "../controls/buttons/forms/FormOpenButton";
import DeleteUserButton from "../controls/buttons/user/DeleteUserButton";
import ReferralCard from "../../components/referrals/ReferralCard";
//MUI
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import { useDispatch, useSelector } from "react-redux";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	accountsCard: {
		backgroundColor: "#fff",
	},
	descriptions: {
		marginTop: 10,
	},
	linkGroup: {
		paddingTop: 10,
		fontSize: "0.8rem",
		textAlign: "center",
		color: theme.palette.primary.main,
	},
	link: {
		color: "#fff",
		color: theme.palette.primary.main,
		textDecoration: "underline",
	},
}));

const divider = (
	<Grid item xs={12}>
		<Divider />
	</Grid>
);

const SettingsCard = () => {
	const user = useSelector((state) => state.user.credentials);
	const classes = styles();
	const isWholesalerAuthNoCharge = checkUserRole(
		user.roles,
		"manualAuthNoCharge"
	);
	return (
		<Grid
			className={clsx(classes.formBackground)}
			item
			xs={12}
			component={Card}>
			<CardHeader align="center" title="Settings" />
			{divider}
			<Grid item container spacing={2} component={CardContent}>
				{(user.type === "wholesaler" || user.type === "support") && (
					<>
						<Grid item container spacing={2}>
							<Grid item xs={12}>
								<ReferralCard user={user} />
							</Grid>
						</Grid>
						{divider}
					</>
				)}
				<Grid item container spacing={2}>
					<Grid item xs={12} sm={3}>
						<FormOpenButton
							form="editProfile"
							btnVariant="contained"
							btnColor="primary"
							btnText="Edit Profile"
						/>
					</Grid>
					<Grid item xs={12} sm={9}>
						<Typography
							className={classes.descriptions}
							variant="body2">
							Edit your user profile.
						</Typography>
					</Grid>
				</Grid>
				{divider}
				<Grid item container spacing={2}>
					{!isWholesalerAuthNoCharge && (
						<>
							<Grid item xs={12} sm={3}>
								<FormOpenButton
									form="editPaymentDetails"
									btnVariant="contained"
									btnColor="primary"
									btnText="Payment Details"
								/>
							</Grid>
							<Grid item xs={12} sm={9}>
								<Typography
									className={classes.descriptions}
									variant="body2">
									Adjust payment information.
								</Typography>
							</Grid>
						</>
					)}
				</Grid>
				{!isWholesalerAuthNoCharge && divider}
				<Grid item container spacing={2}>
					<Grid item xs={12} sm={3}>
						<DeleteUserButton
							user={user}
							text="Cancel User Account"
							showIcon={false}
							deleteSelf={true}
						/>
					</Grid>
					<Grid item xs={12} sm={9}>
						<Typography
							className={classes.descriptions}
							variant="body2">
							Cancel your account. Caution, this cannot be
							reversed. All of your deals and data will be
							deleted.
						</Typography>
					</Grid>
					{divider}
					<Grid item container spacing={2}>
						<Grid item xs={12}>
							<Typography
								className={classes.linkGroup}
								align="center">
								<Link to="/terms" className={classes.link}>
									Terms and Conditions
								</Link>{" "}
								and{" "}
								<Link to="/privacy" className={classes.link}>
									Privacy Policy
								</Link>
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default SettingsCard;
