import React, { Fragment } from "react";

//Components
import CreateDealFab from "../../controls/buttons/deals/CreateDealFab";
import DealMenuButton from "./buttons/DealMenuButton";
import PublicMenuButton from "./buttons/PublicMenuButton";
import ProfileSettingsButton from "./buttons/ProfileSettingsButton";
import BackToTopFab from "./buttons/BackToTopFab";

//MUI
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";

//Redux
import { useSelector } from "react-redux";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	text: {
		padding: theme.spacing(2, 2, 0),
	},
	paper: {
		paddingBottom: 50,
	},
	list: {
		marginBottom: theme.spacing(2),
	},
	subheader: {
		backgroundColor: theme.palette.background.paper,
	},
	appBar: {
		top: "auto",
		bottom: 0,
	},
	grow: {
		flexGrow: 1,
	},
	divider: {
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2),
	},
}));

const DealNavBarMobile = () => {
	const authenticated = useSelector((state) => state.user.authenticated);
	const classes = styles();
	return (
		<Fragment>
			<CssBaseline />
			<AppBar position="fixed" color="primary" className={classes.appBar}>
				<Toolbar>
					{authenticated ? <DealMenuButton /> : <PublicMenuButton />}

					<CreateDealFab
						center
						className={classes.fabButton}
						aria-label="create deal"
						form="deal"
						args={{ type: "create" }}
					/>
					<div className={classes.grow} />
					<ProfileSettingsButton />
				</Toolbar>
			</AppBar>
			<BackToTopFab />
		</Fragment>
	);
};

export default DealNavBarMobile;
