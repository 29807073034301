import React, { Fragment } from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import clsx from "clsx";
//Components
import CheckoutFormSubscription from "../subscriptions/CheckoutFormSubscription";
import UserTypeBadge from "../badges/UserTypeBadge";
//MUI
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
// Redux
import { useSelector } from "react-redux";

//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	main: {
		backgroundColor: "white",

		padding: theme.spacing(3),
	},
	sideProfile: {
		minWidth: 240,
	},
	title: {
		marginBottom: 0,
	},
	subTitle: {
		marginBottom: 10,
	},
}));

const SubscriptionSection = ({ service }) => {
	const user = useSelector((state) => state.user.credentials);
	const classes = styles();

	const env = process.env.REACT_APP_ENV;

	const bull = <span className={classes.bullet}>•</span>;

	let publicKey;
	switch (env) {
		case "production":
			publicKey = process.env.REACT_APP_STRIPE_LIVE_PUBLIC_KEY; //live
			break;
		case "staging":
			publicKey = process.env.REACT_APP_STRIPE_LIVE_PUBLIC_KEY; //live
			break;
		case "development":
			publicKey = process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY; //test
			break;
		default:
			publicKey = process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY;
	}
	const userBadgeInvestor = (
		<>
			<div style={{ maxWidth: 150 }}>
				<UserTypeBadge user={{ verified: true, type: "investor" }} />
			</div>
			<hr />
		</>
	);
	const userBadgeWholesaler = (
		<>
			<div style={{ maxWidth: 150 }}>
				<UserTypeBadge user={{ verified: true, type: "wholesaler" }} />
			</div>
			<hr />
		</>
	);
	const userBadgeSupport = (
		<>
			<div style={{ maxWidth: 150 }}>
				<UserTypeBadge user={{ verified: true, type: "support" }} />
			</div>
			<hr />
		</>
	);

	const serviceInvestor = (
		<>
			<Typography variant="h5" className={classes.title}>
				Upgrade to a Premium Membership for $10 (per month){" "}
				{env == "staging" ? " (Staging)" : ""}
				{env == "development" ? " (Development)" : ""}
			</Typography>
			<Typography
				align="center"
				variant="body1"
				className={classes.subTitle}>
				Show everyone that you are legitimate by upgrading your account
				to Premium*
			</Typography>
			<Typography>Example: {userBadgeInvestor}</Typography>
			<Elements
				style={{
					borderColor: "green",
					borderStyle: "solid",
					borderWidth: 1,
				}}>
				<CheckoutFormSubscription service={service} />
			</Elements>
			<hr />
		</>
	);

	const serviceWholesaler = (
		<>
			<Typography variant="h5" className={classes.title}>
				Wholesaler Premium Membership for $199 (plus $50/month){" "}
				{env == "staging" ? " (Staging)" : ""}
				{env == "development" ? " (Development)" : ""}
			</Typography>
			<Typography
				align="center"
				variant="body1"
				className={classes.subTitle}>
				A $50 dollar per month maintenance fee will be automatically
				charged your account*
			</Typography>
			<Typography
				align="center"
				variant="body1"
				className={classes.subTitle}>
				A premium membership allows you to:
			</Typography>
			<Typography className={classes.boldText}>
				{bull} Create Deals
				<br />
				{bull} Edit Deals
			</Typography>

			<Divider />
			<Elements
				style={{
					borderColor: "green",
					borderStyle: "solid",
					borderWidth: 1,
				}}>
				<CheckoutFormSubscription service={service} />
			</Elements>
			<hr />
		</>
	);

	const serviceWholesalerMaintOnly = (
		<>
			<Typography variant="h5" className={classes.title}>
				Wholesaler Premium Membership Maintenance Fee $50 (per month)
				{env == "staging" ? " (Staging)" : ""}
				{env == "development" ? " (Development)" : ""}
			</Typography>
			<Typography
				align="center"
				variant="body1"
				className={classes.subTitle}>
				A $50 dollar per month maintenance fee will be automatically
				charged your account*
			</Typography>
			<Typography
				align="center"
				variant="body1"
				className={classes.subTitle}>
				A premium membership allows you to:
			</Typography>
			<Typography className={classes.boldText}>
				{bull} Create Deals
				<br />
				{bull} Edit Deals
			</Typography>

			<Divider />
			<Elements
				style={{
					borderColor: "green",
					borderStyle: "solid",
					borderWidth: 1,
				}}>
				<CheckoutFormSubscription service={service} />
			</Elements>
			<hr />
		</>
	);

	const serviceSupport = (
		<>
			<Typography variant="h5" className={classes.title}>
				This Service is for Testing Only $1/Month
				{env == "staging" ? " (Staging)" : ""}
				{env == "development" ? " (Development)" : ""}
			</Typography>
			<Typography
				align="center"
				variant="body1"
				className={classes.subTitle}>
				Show everyone that you are legitimate by upgrading your account
				to Premium*
			</Typography>
			<Typography>Testing User Badge: {userBadgeSupport}</Typography>
			<Elements
				style={{
					borderColor: "green",
					borderStyle: "solid",
					borderWidth: 1,
				}}>
				<CheckoutFormSubscription service={service} />
			</Elements>
			<hr />
		</>
	);

	const servicePlanSelector = () => {
		switch (service) {
			case "investor_premium":
				return serviceInvestor;
				break;
			case "tech_support":
				return serviceSupport;
				break;
			case "wholesaler_premium":
				if (!user.roles.manualAuthMaintenanceFeeOnly) {
					return serviceWholesaler;
				} else {
					return serviceWholesalerMaintOnly;
				}

			default:
				return (
					<>
						<Typography variant="h5" className={classes.title}>
							The plan you selected cannot be located{" "}
							{env == "staging" ? " (Staging)" : ""}
							{env == "development" ? " (Development)" : ""}
						</Typography>
						<Typography
							align="center"
							variant="body1"
							className={classes.subTitle}>
							Please try again later
						</Typography>
					</>
				);
		}
	};

	return (
		<Grid item align="center" sm={12}>
			<StripeProvider apiKey={publicKey}>
				<Paper className={clsx(classes.main, classes.formBackground)}>
					{user.userId ? (
						<>
							{servicePlanSelector()}
							<div className="example">
								<Typography
									align="center"
									variant="body1"
									className={classes.subTitle}>
									*premium accounts will be verified by a
									sealdealfinder.com team member.
								</Typography>
							</div>
						</>
					) : (
						<Fragment>
							<Typography variant="h4" className={classes.title}>
								Subscription Payment Form
							</Typography>
							<Typography
								variant="body1"
								className={classes.subTitle}>
								You must login before you are able to upgrade.
							</Typography>
						</Fragment>
					)}
				</Paper>
			</StripeProvider>
		</Grid>
	);
};

export default SubscriptionSection;
