import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
//MUI
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	buttonColorInform: {
		color: "#fff",
		backgroundColor: theme.palette.information.dark,
	},
}));
const defaultProps = {
	linkTo: "/",
	text: "Link Button",
	size: "medium",
	color: "secondary",
};

const GeneralLinkButton = ({
	menu,
	variant,
	className,
	onClick,
	size = defaultProps.size,
	color = defaultProps.color,
	text = defaultProps.text,
	linkTo = defaultProps.linkTo,
}) => {
	const classes = styles();
	return (
		<Button
			underline="none"
			size={clsx(menu ? "small" : size)}
			fullWidth
			className={clsx(classes.buttonColorWhite, className)}
			variant={variant}
			color={color}
			onClick={onClick}
			component={Link}
			to={linkTo}>
			{text}
		</Button>
	);
};

GeneralLinkButton.propTypes = {
	buttonColorInform: PropTypes.bool,
	className: PropTypes.any,
	color: PropTypes.any,
	linkTo: PropTypes.any,
	menu: PropTypes.any,
	onClick: PropTypes.any,
	size: PropTypes.any,
	text: PropTypes.any,
	variant: PropTypes.any,
};

export default GeneralLinkButton;
