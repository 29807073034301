import {
	SET_LOCATION,
	SET_DEALS,
	SET_DEAL,
	EDIT_DEAL,
	SET_PROFILE,
	POST_DEAL,
	SET_COMMENTS,
	LOADING_DATA,
	STOP_LOADING_DATA,
	LOADING_UI,
	SET_ERRORS,
	SET_MESSAGES,
	SET_FILTERS,
	SET_FILTERED_DEALS,
	SET_FILTERED_HANDLE,
	UNLIKE_DEAL,
	DELETE_DEAL,
	LIKE_DEAL,
	CLEAR_ERRORS,
	CLEAR_MESSAGES,
	CLEAR_RESPONSES,
	SET_ALERT
} from "../types"

import axios from "axios"
//import zipcodes from 'zipcodes


export const setFilters = (filters) => (dispatch) => {
	dispatch({
		type: SET_FILTERS,
		payload: filters
	})

}


export const setFilteredDeals = (deals, filters) => (dispatch) => {

	const filterbyRange = (result, field, filterKey) => {

		if ((filterKey.min !== filterKey.max) && filterKey.max !== "none") {
			result = result.filter(item => item[field] >= filterKey.min);
			result = result.filter(item => item[field] <= filterKey.max);
			result = result.filter(item => item[field] !== null);
		} else if (filterKey.max === "none") {
			result = result.filter(item => item[field] >= filterKey.min);
		}
		return result;
	};

	let {
		handle,
		zipcode,
		notSold,
		price,
		arv,
		assignmentFee,
		rehab
	} = filters;

	let result = deals;
	if (handle) {
		result = result.filter(item => item.userHandle === handle)
	}
	if (zipcode) {
		result = result.filter(item => item.zipcode.toLowerCase().startsWith(zipcode))
	}
	if (notSold) {
		result = result.filter(item => item.status !== "sold")
	}

	if (price) {
		price = JSON.parse(price)
		result = filterbyRange(result, "price", price);
	}
	if (arv) {
		arv = JSON.parse(arv)
		result = filterbyRange(result, "arv", arv);
	}
	if (rehab) {
		rehab = JSON.parse(rehab)
		result = filterbyRange(result, "rehab", rehab);
	}
	if (assignmentFee) {
		assignmentFee = JSON.parse(assignmentFee)
		result = filterbyRange(result, "assignmentFee", assignmentFee);
	}
	if (Object.keys(filters).length === 0) {
		result = null;
	}

	dispatch({
		type: SET_FILTERED_DEALS,
		payload: result
	})

	dispatch({
		type: SET_FILTERED_HANDLE,
		payload: handle
	})

}

export const uploadDealImage = (formData) => (dispatch) => {

	axios.post('/api/deal/image', formData)
		.then(() => {
			//dispatch(getUserData());
		})
		.catch(error => {
			console.log(error.response)
		});
}
export const clearLocation = () => (dispatch) => {
	dispatch({
		type: SET_LOCATION,
		payload: {}
	})
}

export const setDealLocation = (location) => (dispatch) => {
	dispatch({
		type: SET_LOCATION,
		payload: location
	})
}

export const setDeals = (deals) => (dispatch) => {
	dispatch({
		type: SET_DEALS,
		payload: deals
	})
}


//TODO Adjust function name to "location"
export const findAddressByZicode = (zipcode) => (dispatch) => {
	dispatch({
		type: LOADING_UI,
		payload: true
	})
	axios.post('/api/addresses/zipcode', {
			zipcode: zipcode
		})
		.then(res => {
			dispatch({
				type: SET_LOCATION,
				payload: res.data.location
			})
			dispatch({
				type: LOADING_UI,
				payload: false
			})

		})
		.catch(err => {
			console.log(err)
		})
}

//Post an deal
export const postDeal = (dealFields, imageBlob, imageName) => dispatch => {

	//Clear messages before each request
	dispatch({
		type: CLEAR_RESPONSES,
		payload: {}
	})

	const stringToNumber = (string) => {

		if (string && isNaN(string)) {
			let numberString = parseFloat(string.replace(/,/g, '')) //remove commas
			return Number(numberString); //convert string to number
		} else return string
	}
	const addressFields = {
		zipcode: dealFields.zipcode,
		street: dealFields.street,
		city: dealFields.city,
		state: dealFields.state,
	}

	dealFields = filterAddresskeys(dealFields)
	dealFields.price = stringToNumber(dealFields.price)
	dealFields.arv = stringToNumber(dealFields.arv)
	dealFields.assignmentFee = stringToNumber(dealFields.assignmentFee)
	dealFields.rehab = stringToNumber(dealFields.rehab)

	var dealFieldsJSON = JSON.stringify(dealFields);
	var addressFieldsJSON = JSON.stringify(addressFields);
	const formData = new FormData();

	if (imageBlob) {
		formData.append("image", imageBlob, imageName)
	}

	formData.append("deal", dealFieldsJSON);
	formData.append("address", addressFieldsJSON);

	dispatch({
		type: LOADING_DATA
	});

	axios({
			method: 'post',
			url: '/api/deal',
			data: formData,
			config: {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		})
		.then(res => {
			dispatch({
				type: POST_DEAL,
				payload: res.data.deal
			})
			dispatch({
				type: SET_MESSAGES,
				payload: res.data.message
			})

		})
		.catch(err => {
			console.log(err)
			if (err.response.data) {
				console.log(err.response.data)
				dispatch({
					type: SET_ERRORS,
					payload: err.response.data
				})
			}
			dispatch({
				type: STOP_LOADING_DATA
			});
		})
}


//Post an deal
export const editDeal = (dealFields, imageBlob, imageName) => dispatch => {
	//Clear messages before each request
	dispatch({
		type: CLEAR_RESPONSES,
		payload: {}
	})

	const stringToNumber = (string) => {
		if (string) {
			if (typeof string === 'string') {
				let numberString = parseFloat(string.replace(/,/g, '')) //remove commas
				return Number(numberString); //convert string to number
			} else return string
		} else return null
	}
	const addressFields = {
		zipcode: dealFields.zipcode,
		street: dealFields.street,
		city: dealFields.city,
		state: dealFields.state,
	}

	dealFields = filterAddresskeys(dealFields)

	dealFields.price = stringToNumber(dealFields.price)
	dealFields.arv = stringToNumber(dealFields.arv)
	dealFields.assignmentFee = stringToNumber(dealFields.assignmentFee)
	dealFields.rehab = stringToNumber(dealFields.rehab)

	var dealFieldsJSON = JSON.stringify(dealFields);
	var addressFieldsJSON = JSON.stringify(addressFields);
	const formData = new FormData();

	if (imageBlob) {
		formData.append("image", imageBlob, imageName)
	}

	formData.append("deal", dealFieldsJSON);
	formData.append("address", addressFieldsJSON);

	dispatch({
		type: LOADING_DATA
	});

	axios({
			method: 'post',
			url: '/api/deal/edit',
			data: formData,
			config: {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		})
		.then(res => {
			dispatch({
				type: EDIT_DEAL,
				payload: res.data.deal
			})
			dispatch({
				type: SET_MESSAGES,
				payload: res.data.message
			})
		})
		.catch(err => {
			console.log(err)
			if (err.response.data) {
				dispatch({
					type: SET_ERRORS,
					payload: err.response.data
				})
			}
			dispatch({
				type: STOP_LOADING_DATA
			});

		})
}


//Get all deals
export const getDeals = () => dispatch => {
	dispatch({
		type: LOADING_DATA
	});
	axios.get('/api/deals')
		.then(res => {
			dispatch({
				type: SET_DEALS,
				payload: res.data
			})
		}).catch(err => {
			console.log(err)
		})
}


export const clearDeal = () => dispatch => {
	dispatch({
		type: SET_DEAL,
		payload: {}
	})
}


//Get deal
export const getDeal = (dealId) => dispatch => {
	dispatch({
		type: LOADING_DATA
	});

	axios.get(`/api/deal/${dealId}`)
		.then(res => {
			const deal = res.data.deal
			dispatch({
				type: SET_DEAL,
				payload: deal
			})
			dispatch({
				type: SET_LOCATION,
				payload: {
					city: deal.city,
					state: deal.state,
					street: deal.street ? deal.street : ""
				}
			})
			return res.data
		})
		.then(data => {
			const profile = {
				user: {
					dealId: data.deal.dealId,
					userId: data.deal.userId,
					handle: data.deal.userHandle,
					verified: data.deal.userVerified,
					imageUrl: data.deal.userImage,
					type: data.deal.userType
				}
			}
			dispatch({
				type: SET_PROFILE,
				payload: profile
			})
			dispatch({
				type: SET_COMMENTS,
				payload: data.comments
			})
			dispatch({
				type: STOP_LOADING_DATA
			});

		})
		.catch(err => {
			console.log(err)
			dispatch({
				type: STOP_LOADING_DATA
			})
		})
}

//Like an deal
export const likeAgreement = (dealId) => dispatch => {
	axios.get(`/api/deal/${dealId}/like`)
		.then(res => {
			dispatch({
				type: LIKE_DEAL,
				payload: res.data
			})
		})
		.catch(err => console.log(err))
}

//Unlike an deal
export const unlikeAgreement = (dealId) => dispatch => {
	axios.get(`/api/deal/${dealId}/unlike`)
		.then(res => {
			dispatch({
				type: UNLIKE_DEAL,
				payload: res.data
			})
		})
		.catch(err => console.log(err))
}
//Delete an deal
export const deleteDeal = (dealId) => dispatch => {
	dispatch({
		type: LOADING_DATA
	});
	axios.delete(`/api/deal/${dealId}`)
		.then((res) => {
			dispatch({
				type: DELETE_DEAL,
				payload: {
					dealId: dealId
				}
			})
			dispatch({
				type: STOP_LOADING_DATA
			});
			dispatch({
				type: SET_ALERT,
				payload: {
					type: "success",
					title: "Deal Deleted Succesfully",
					text: res.data.alert.text,
					open: true,
					abortFirstLetterCap: false,
				}
			})
		})
		.catch(err => {
			console.log(err)
			dispatch({
				type: SET_ALERT,
				payload: {
					title: "Deal Could Not Be Deleted",
					type: "error",
					text: err.response.data.error,
					open: true,
				}
			})
			dispatch({
				type: STOP_LOADING_DATA
			});
		})
}

export const getUserData = userHandle => dispatch => {
	dispatch({
		type: LOADING_DATA
	});
	axios.get(`/api/user/${userHandle}`)
		.then(res => {
			dispatch({
				type: SET_DEALS,
				payload: res.data.deals
			});
		}).catch(() => {
			dispatch({
				type: SET_DEALS,
				payload: null
			})
		})
}

export const clearErrors = () => dispatch => {
	dispatch({
		type: CLEAR_ERRORS
	});
}
export const clearMessages = () => dispatch => {
	dispatch({
		type: CLEAR_MESSAGES
	});
}

const filterAddresskeys = (object) => {
	let keys = ["city", "state", "address"];
	let i;
	for (i = 0; i < keys.length; i++) {
		delete object[keys[i]];
	}
	return object
}


export const purgeDealImage = (deal) => dispatch => {

	let purgedDeal = {
		...deal,
		imageUrl: `${deal.imageUrl}?temp`
	}

	dispatch({
		type: EDIT_DEAL,
		payload: purgedDeal
	})
	dispatch({
		type: EDIT_DEAL,
		payload: deal
	})

}

export const postDealToFacebook = (deal, message) => dispatch => {

	const data = {
		dealId: deal.dealId,
		message: message
	}

	axios.post(`/api/facebook/post/deal`, data)
		.then(res => {
			dispatch({
				type: SET_ALERT,
				payload: {
					type: "success",
					title: "Deal Posted to Facebook Succesfully",
					text: res.data.message.success,
					data: res.data.message.data
				}
			})
		})
		.catch(err => {
			console.log(err)
			/*dispatch({
				type: SET_ALERT,
				payload: {
					type: "error",
					title: err.data.response.error.alert.title,
					text: err.data.response.error.alert.text
				}
			})*/
		})
}


/*
export const postSignature = (approvalDocument) => dispatch => {
	dispatch({
		type: LOADING_UI
	});
	axios
		.post("/document/sign", approvalDocument)
		.then((res) => {
			dispatch({
				type: POST_SIGNATURE,
				payload: res.data.signature
			})
			dispatch({
				type: STOP_LOADING_UI
			});
			dispatch({
				type: SET_MESSAGES,
				payload: res.data.message
			})
		})
		.catch(err => {
			dispatch({
				type: SET_ERRORS,
				payload: err.response.data
			})
		})
}

export const getTemplateParts = (templateType) => dispatch => {
	dispatch({
		type: LOADING_DATA
	});
	axios
		.post("/template/parts", {
			templateType: templateType
		})
		.then((res) => {
			dispatch({
				type: SET_TEMPLATE,
				payload: res.data
			})
		})
		.catch(err => {
			console.log(err)
			dispatch({
				type: SET_ERRORS,
				payload: err.response.data
			})
		})
}

export const setTemplatePart = (templatePart) => dispatch => {
	dispatch({
		type: SET_TEMPLATE_PART,
		payload: templatePart
	});
}

export const postTemplatePart = (templatePart) => (dispatch) => {

	dispatch({
		type: LOADING_UI
	})
	axios.post('/template', templatePart)
		.then((res) => {
			dispatch({
				type: POST_TEMPLATE,
				payload: res.data.newTemplatePart
			})
			dispatch({
				type: SET_MESSAGES,
				payload: res.data.message
			})

		})
		.catch(err => {
			dispatch({
				type: SET_ERRORS,
				payload: err.response.data
			})
		})
}


export const editTemplatePart = (templatePart) => (dispatch) => {

	dispatch({
		type: LOADING_UI
	})
	axios.post('/template', templatePart)
		.then((res) => {
			dispatch({
				type: EDIT_TEMPLATE,
				payload: templatePart
			})
			dispatch({
				type: SET_MESSAGES,
				payload: res.data.message
			})
		})
		.catch(err => {
			dispatch({
				type: SET_ERRORS,
				payload: err.response.data
			})
		})
}

//Delete a template part
export const deleteTemplatePart = (templatePart) => dispatch => {
	axios.delete('/template/part', {
			data: templatePart
		})
		.then((res) => {
			dispatch({
				type: DELETE_TEMPLATE_PART,
				payload: templatePart
			})
		})
		.catch(err => {
			dispatch({
				type: SET_ERRORS,
				payload: err.response.data
			})
		})
}

export const serverCharge = (payload) => dispatch => {
	axios.post("/charge", {
			headers: {
				"Content-Type": "text/plain"
			},
			data: {
				plan: "plan_GWysNdAuvUIOKp",
				email: "sales@rfdprint.com",
				amount: 5500,
				payload
			}
		})
		.then((res) => {
			dispatch({
				type: SET_PAYMENT_RESPONSE,
				payload: res.data
			})
		}).catch(err => {
			console.log(err)
		})
}

export const confirmPayment = (payload) => dispatch => {
	axios.post("/charge/confirm", {
			headers: {
				"Content-Type": "text/plain"
			},
			data: {
				payload
			}
		})
		.then((res) => {
			console.log(res)
			dispatch({
				type: SET_PAYMENT_RESPONSE,
				payload: res.data
			})
		}).catch(err => {
			console.log(err)
		})
}

export const createSubscription = (data) => dispatch => {
	axios.post("/charge/subscription", {
			headers: {
				"Content-Type": "text/plain"
			},
			data: {
				token: data.paymentMethod,
				plan: data.plan,
				email: data.email
			}
		})
		.then((res) => {
			console.log(res)
			dispatch({
				type: SET_PAYMENT_RESPONSE,
				payload: res.data
			})
		}).catch(err => {
			console.log(err)
		})
}


export const chargePayment = (payload) => dispatch => {
	axios.post("/charge", {
			headers: {
				"Content-Type": "text/plain"
			},
			data: {
				email: "sales@rfdprint.com",
				amount: 5500,
				payload
			}
		})
		.then((res) => {
			dispatch({
				type: SET_PI,
				payload: res.data
			})
		})
		.then(() => {
			//console.log("Purchase Complete!");
		}).catch(err => {
			console.log(err)
		})
}

export const setPI = () => dispatch => {}

export const clearPI = () => dispatch => {
	dispatch({
		type: SET_PI,
		payload: ""
	})
}

*/
