import React from "react";
import { objectNotEmpty } from "../../util/UtilityFunctions";
//Component
import SettingsCard from "../settings/SettingsCard";
//MUI
import Grid from "@material-ui/core/Grid";
//Redux
import { useSelector } from "react-redux";

const SettingsSection = () => {
	const authUser = useSelector((state) => state.user.credentials);
	return (
		<Grid item xs={12}>
			<Grid container>
				{objectNotEmpty(authUser) && <SettingsCard />}
			</Grid>
		</Grid>
	);
};

export default SettingsSection;
