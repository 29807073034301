import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
//Components
import PriceFeatureGrid from "../controls/PriceFeatureGrid";
import VirtualAssistantProductCompactCard from "./VirtualAssistantProductCompactCard";
//MUI
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import HomeIcon from "@material-ui/icons/Home";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Divider from "@material-ui/core/Divider";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { setCheckoutVAPackage } from "../../redux/actions/checkoutActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	spinner: {
		marginTop: 50,
	},
	root: { color: "blue" },
	productColor: {
		color: theme.palette.primary.dark,
	},
	cardHeader: {
		color: "#fff",
		textAlign: "center",
		backgroundColor: theme.palette.primary.main,
		paddingBottom: 5,
		marginBottom: 0,
		paddingTop: 5,
		marginTop: 0,
	},
	generalCategoryBackground: {
		backgroundColor: theme.palette.information.dark,
	},
	generalCategoryText: {
		color: theme.palette.information.dark,
	},
	cardSubheaderText: {
		color: "#fff",
	},
	description: {
		fontSize: 10,
	},
	table: {
		minWidth: 650,
	},
	icon: { color: theme.palette.primary.main },
	productCard: { marginBottom: theme.spacing(2) },
}));
const VirtualAssistanceHeader = ({
	assistanceCategory,
	assistancePackages,
	testCategoryGroup,
}) => {
	const { useEffect } = React;
	const dispatch = useDispatch();
	const classes = styles();

	const theme = useTheme();
	const screenSmallDown = useMediaQuery(theme.breakpoints.down("sm"));

	const handleClick = (selectedPackage) => {
		dispatch(setCheckoutVAPackage(selectedPackage));
	};

	let filteredAssistancePackages = [];

	filteredAssistancePackages = assistancePackages.filter(
		(assistancePackage) =>
			assistancePackage.metadata.categoryId ===
			assistanceCategory.categoryId
	);

	const compare = (a, b) => {
		// Use toUpperCase() to ignore character casing

		const bandA = a.product.metadata.sort;
		const bandB = b.product.metadata.sort;

		let comparison = 0;
		if (bandA > bandB) {
			comparison = 1;
		} else if (bandA < bandB) {
			comparison = -1;
		}
		return comparison;
	};

	let sortedAssistancePackages = filteredAssistancePackages.sort(compare);

	let filteredAssistanceCards = sortedAssistancePackages
		? sortedAssistancePackages.map((assistancePackage) => (
				<Grid
					className={clsx(classes.textColor, classes.productCard)}
					key={assistancePackage.assistancePackageId}
					item
					container
					xs={12}
					sm={3}>
					<VirtualAssistantProductCompactCard
						backgroundClassName={
							assistanceCategory.categoryId ===
								"general_category" &&
							classes.generalCategoryBackground
						}
						textClassName={classes.generalCategoryText}
						assistancePackage={assistancePackage}
					/>
				</Grid>
		  ))
		: null;

	const table = (
		<Card className={classes.textColor}>
			<CardHeader
				classes={{
					root: clsx(
						classes.cardHeader,
						assistanceCategory.categoryId === "general_category" &&
							classes.generalCategoryBackground
					),
					subheader: classes.cardSubheaderText,
				}}
				title={assistanceCategory.name}
				subheader={assistanceCategory.subtitle}
			/>
			<CardContent className={classes.productColor}>
				<GridList
					cellHeight="auto"
					className={classes.gridList}
					cols={assistanceCategory.col || screenSmallDown ? 1 : 3}>
					{assistanceCategory.features.map((feature, index) => (
						<GridListTile key={index} cols={1}>
							<Grid container spacing={1}>
								{!assistanceCategory.noIcon && (
									<Grid item>
										<HomeIcon
											classes={{
												root: classes.icon,
											}}
										/>
									</Grid>
								)}
								<Grid item>
									<Typography
										variant="body2"
										display="inline">
										{feature}
									</Typography>
								</Grid>
							</Grid>
						</GridListTile>
					))}
				</GridList>
				<Divider />
				<CardContent className={classes.productColor}>
					<Grid container spacing={1}>
						{filteredAssistanceCards}
					</Grid>
				</CardContent>
			</CardContent>
			<CardContent>
				{!testCategoryGroup &&
					`
				*These service rates are prepaid before the said services will
				be performed. Clients are the ones who will provide the tools
				that is needed to perform virtual assistants tasks. Virtual
				Assistants are guaranteed well versed and well trained and with
				strong experience related to the said tasks above.`}

				{testCategoryGroup &&
					`	*These are not service are for test purposes only. Please do not purchase or process the products in this pacakage unless you are testing.`}
			</CardContent>
		</Card>
	);

	return <>{table}</>;
};

export default VirtualAssistanceHeader;
