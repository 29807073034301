import React, { Fragment } from "react";
//Components
import ActionBar from "./ActionBar";
import FilterToolbar from "./toolbars/FilterToolbar";
import MainToolbar from "./toolbars/MainToolbar";
//MUI
import Hidden from "@material-ui/core/Hidden";

const defaultProps = { color: "secondary", filter: false };
const NavBarMainMenu = ({
	color = defaultProps.color,
	filter = defaultProps.filter,
}) => {
	return (
		<ActionBar>
			<Hidden smDown>
				<MainToolbar filter={filter} color={color} />
			</Hidden>
			{filter && (
				<Fragment>
					<Hidden smDown>
						<hr
							style={{
								color: "white",
								width: "100%",
							}}
						/>
					</Hidden>
					<FilterToolbar />
				</Fragment>
			)}
		</ActionBar>
	);
};

export default NavBarMainMenu;
