import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";
//MUI
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

const defaultProps = {
	linkTo: "/",
	text: "Deal Board",
	size: "medium",
};

const DealButtonOutlined = ({
	menu,
	variant,
	className,
	size = defaultProps.size,
	text = defaultProps.text,
	linkTo = defaultProps.linkTo,
}) => {
	return (
		<Grid item>
			<Button
				size={clsx(menu ? "small" : size)}
				fullWidth
				className={className}
				variant={variant}
				color={"secondary"}
				component={Link}
				to={linkTo}>
				{text}
			</Button>
		</Grid>
	);
};

DealButtonOutlined.propTypes = {
	className: PropTypes.any,
	linkTo: PropTypes.any,
	menu: PropTypes.any,
	size: PropTypes.any,
	text: PropTypes.any,
	variant: PropTypes.any,
};

export default DealButtonOutlined;
