import axios from 'axios'
import {
	LOADING_UI,
	LOADING_SYSTEM_DATA,
	LOADING_SYSTEM_UI,
	STOP_LOADING_SYSTEM_UI,
	STOP_LOADING_SYSTEM_DATA,
	SET_SYSTEM_USER_ROLE,
	SET_SYSTEM_USERS,
	SET_SYSTEM_USER,
	DELETE_SYSTEM_USER,
	CLEAR_SYSTEM_USER,
	SET_SYSTEM_FILTERS,
	SET_FILTERED_SYSTEM_USERS,
	SET_VERIFICATION_FILE,
	SET_VERIFIED,
	SET_ALERT,
	TOGGLE_FORM_DIALOG
} from "../types"

import {
	compare
} from "../../util/UtilityFunctions"
export const deleteUser = (data) => (dispatch) => {

	axios({
			method: 'delete',
			url: '/api/system/user',
			data: data
		})
		.then(res => {
			dispatch({
				type: DELETE_SYSTEM_USER,
				payload: res.data.success.userId
			});
			dispatch({
				type: SET_ALERT,
				payload: {
					type: "success",
					text: res.data.success.alert
				}
			});
		}).catch(err => {
			console.log(err)
			dispatch({
				type: SET_ALERT,
				payload: {
					type: "error",
					title: err.response.data.error.alert.title,
					text: err.response.data.error.alert.text
				}

			})
		})
}

export const setSystemFilters = (filters) => (dispatch) => {
	dispatch({
		type: SET_SYSTEM_FILTERS,
		payload: filters
	})
}

export const setSystemFilteredUsers = (users, filters) => (dispatch) => {

	const setBooleanFilter = (booleanFilter) => {
		let show
		switch (booleanFilter) {
			case "showTrue":
				show = true
				break;
			case "showFalse":
				show = false
				break;
			default:
		}
		return show
	}

	let {
		type,
		roles,
		verified,
		subscriptionPaid,
		systemUserId,
		showAllPending
	} = filters;

	let result = users;

	if (type) {
		result = result.filter(item => item.type === type)
	}

	if (roles === "admin") {
		result = result.filter(item => item.roles.admin === true)
	}

	if (verified) {
		result = result.filter(item => item.verified === setBooleanFilter(verified))
	}

	if (subscriptionPaid) {
		result = result.filter(item => item.roles.subscriptionPaid === setBooleanFilter(subscriptionPaid))
	}

	if (systemUserId && !showAllPending) {
		result = result.filter(item => item.userId === systemUserId)
	}

	if (showAllPending) {
		result = result.filter(item => !item.verified && item.roles.subscriptionPaid)
	}


	if (Object.keys(filters).length === 0) {
		result = null;
	}

	if (result) {
		result = result.sort(compare);
	}

	dispatch({
		type: SET_FILTERED_SYSTEM_USERS,
		payload: result
	})
}

export const systemGetUsers = () => (dispatch) => {
	dispatch({
		type: LOADING_SYSTEM_DATA,
	})

	axios.get('/api/system/users')
		.then(res => {
			dispatch({
				type: SET_SYSTEM_USERS,
				payload: res.data
			})
		}).catch(err => {
			console.log(err.response.data)

			dispatch({
				type: STOP_LOADING_SYSTEM_DATA
			})

			dispatch({
				type: SET_ALERT,
				payload: {
					type: "error",
					title: err.response.data.error.alert.title,
					text: err.response.data.error.alert.text
				}
			})

		})
}

export const systemSetUser = (data) => (dispatch) => {
	dispatch({
		type: LOADING_SYSTEM_DATA,
	})

	dispatch({
		type: SET_SYSTEM_USER,
		payload: data
	})

}

export const clearVerificationFile = () => (dispatch) => {
	dispatch({
		type: SET_VERIFICATION_FILE,
		payload: {}
	})
}

export const setGetVerificationFile = (userId) => (dispatch) => {
	axios.post('/api/system/verification/url', {
			userId: userId
		})
		.then(res => {
			dispatch({
				type: SET_VERIFICATION_FILE,
				payload: res.data
			})

		}).catch(err => {
			console.log(err)
		})
}


export const clearSystemUser = () => (dispatch) => {
	dispatch({
		type: CLEAR_SYSTEM_USER
	})
}

export const setUserRole = (data) => (dispatch) => {
	//Confirmation Alert
	dispatch({
		type: LOADING_UI,
		payload: true
	});

	axios.post('/api/system/user/role', data)
		.then(res => {
			dispatch({
				type: SET_SYSTEM_USER_ROLE,
				payload: data
			})
			dispatch({
				type: LOADING_UI,
				payload: false
			});
			dispatch({
				type: SET_ALERT,
				payload: {
					title: "Success!!",
					type: "success",
					text: "User authorization updated",
					open: true,
					required: false,
					confirmationForm: "",
					abortFirstLetterCap: false,
					confirmed: false
				}
			})
		}).catch(err => {
			dispatch({
				type: LOADING_UI,
				payload: false
			});
			if (err.response.data) {
				console.log(err.response.data)
				dispatch({
					type: SET_ALERT,
					payload: {
						title: "User Has a Paid Subscription",
						type: "error",
						text: err.response.data.error,
						open: true,
					}
				})
			} else {
				dispatch({
					type: SET_ALERT,
					payload: {
						title: "Unable to Change User Authorization",
						type: "error",
						text: "An unexpected error occurred, user authorization was not changed.",
						open: true,
					}
				})
			}
		})
}

export const systemPostVerification = (status) => (dispatch) => {
	dispatch({
		type: LOADING_SYSTEM_UI,
	});

	axios.post('/api/system/users/verify', status)
		.then(res => {

			dispatch({
				type: SET_VERIFIED,
				payload: status
			})
			dispatch({
				type: SET_ALERT,
				payload: {
					title: "Success!!",
					type: "success",
					text: "User verification updated",
					open: true,
					required: false,
					confirmationForm: "",
					abortFirstLetterCap: false,
					confirmed: false
				}
			})

			dispatch(clearSystemUser());
		}).catch(err => {

			dispatch({
				type: STOP_LOADING_SYSTEM_UI,
			});
			dispatch({
				type: SET_ALERT,
				payload: {
					title: "Unable to Change User Verification",
					type: "error",
					text: "An unexpected error occured, user verification was not changed.",
					open: true,
				}
			})

		})
}
