import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
//Components
import GetVirtualAssistantButton from "../controls/buttons/cta/GetVirtualAssistantButton";
//Icons
import LogoIcon from "../logo/LogoIcon";
import LogoWebsite from "../logo/LogoWebsite";
//MUI
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
//Redux
import { useDispatch } from "react-redux";
import {
	setCheckoutVAPackage,
	setCheckoutLoginRedirect,
} from "../../redux/actions/checkoutActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	card: {
		padding: 10,
		backgroundColor: theme.palette.primary.main,
		minHeight: 400,
	},
	loginButton: {
		backgroundColor: theme.palette.primary.main,
	},
	signupButton: {
		backgroundColor: theme.palette.accent.main,
	},
	button: {
		width: "100%",
	},
	linkGroup: {
		paddingTop: 10,
		fontSize: "0.8rem",
		color: "#fff",
	},
	link: {
		color: "#fff",
	},
}));

const LoginSignupActions = ({
	virtualAssistantButton,
	cancelRedirectButton,
}) => {
	const classes = styles();
	const dispatch = useDispatch();

	const handelCancelVAPackageCheckout = () => {
		dispatch(setCheckoutLoginRedirect(""));
		dispatch(setCheckoutVAPackage());
	};

	const loginActions = (
		<Grid item>
			<Card className={classes.card}>
				<CardContent>
					<Grid container spacing={4}>
						<Grid
							item
							container
							justify="center"
							alignItems="center"
							spacing={2}
							xs={12}>
							<LogoIcon />
							<Grid item xs={12}>
								<LogoWebsite />
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid container justify="space-around" spacing={2}>
								<Grid item xs={6}>
									<Button
										className={clsx(
											classes.button,
											classes.loginButton
										)}
										color="secondary"
										variant="outlined"
										component={Link}
										to="/login">
										Login
									</Button>
								</Grid>
								<Grid item xs={6}>
									<Button
										className={clsx(
											classes.button,
											classes.signupButton
										)}
										variant="contained"
										color="secondary"
										component={Link}
										to="/signup">
										Signup
									</Button>
								</Grid>
								{virtualAssistantButton && (
									<>
										<Grid item xs={12}>
											<GetVirtualAssistantButton
												fullWidth
											/>
										</Grid>
									</>
								)}
								{cancelRedirectButton && (
									<Grid item xs={12}>
										<Button
											className={clsx(
												classes.button,
												classes.loginButton
											)}
											color="secondary"
											variant="outlined"
											onClick={() => {
												handelCancelVAPackageCheckout();
											}}>
											Cancel
										</Button>
									</Grid>
								)}
							</Grid>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
			<hr className={classes.profileFieldSeparator} />
			<Typography className={classes.linkGroup} align="center">
				<Link to="/terms" className={classes.link}>
					Terms and Conditions
				</Link>{" "}
				and{" "}
				<Link to="/privacy" className={classes.link}>
					Privacy Policy
				</Link>
			</Typography>
		</Grid>
	);

	return loginActions;
};

LoginSignupActions.propTypes = {};

export default LoginSignupActions;
