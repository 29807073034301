import React from "react";

import logoWebsite from "../../images/svg/icon-logo-website.svg";
import clsx from "clsx";
//MUI
import Grid from "@material-ui/core/Grid";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	logo: {
		maxWidth: "100%",
		display: "block",
		margin: "0 auto",
		width: "100%",
	},
	large: {
		maxWidth: 325,
	},
	small: {
		maxWidth: 165,
	},
}));

const LogoWebsite = ({ small }) => {
	const classes = styles();
	return (
		<Grid item xs={12}>
			<img
				className={clsx(
					classes.logo,
					!small ? classes.large : classes.small
				)}
				src={logoWebsite}
				alt="logo"></img>
		</Grid>
	);
};

export default LogoWebsite;
