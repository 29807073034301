import React from "react";

//Components
import ToolTipButton from "../../components/controls/buttons/ToolTipButton";
//MUI Components

import Grid from "@material-ui/core/Grid";
//MUI Icons
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
// Redux
import { useDispatch } from "react-redux";
import { blockComment } from "../../redux/actions/commentActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	comment: { marginBottom: 15 },
	commentUserBadge: {
		maxWidth: 125,
		maxHeight: 125,
	},
	commentData: {
		marginLeft: 5,
	},
	ownerComment: {
		backgroundColor: "#a4ecb5",
		color: "#fff",
	},
	ownerLabel: {
		marignBottom: "-40px",
	},
}));

const CommentBlockButton = ({ comment }) => {
	const classes = styles();
	const dispatch = useDispatch();

	const handleBlockComment = () => {
		dispatch(blockComment(comment));
	};

	return (
		<Grid item>
			<ToolTipButton
				titleClassName={classes.toolTipButton}
				title={!comment.blocked ? "Block Comment" : "Unblock Comment"}
				btnClassName="button"
				onClick={() => {
					handleBlockComment();
				}}>
				{!comment.blocked ? <RemoveCircleIcon /> : <AddCircleIcon />}
			</ToolTipButton>
		</Grid>
	);
};

export default CommentBlockButton;
