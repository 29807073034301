import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";
//MUI
import Grid from "@material-ui/core/Grid";
//Redux
import { useSelector } from "react-redux";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
//import { CLEAR_ERRORS } from "../redux/types";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	search: {
		textAlign: "center",
		display: "block",
		margin: "0 auto",
		maxWidth: 300,
		color: theme.palette.secondary.main,
	},
	imageWrapper: {
		minHeight: 200,
	},
	editor: {
		maxWidth: 250,
	},
	cropContainer: {
		background: "#333",
	},
	cropperSmall: {
		height: 200,
	},
	cropperLarge: {
		height: 200,
	},
	priceFieldPaper: {
		backgroundColor: theme.palette.primary.main,
		width: "100%",
		padding: 10,
	},
	responseArea: {
		padding: 10,
		borderWidth: 1,
		borderRadius: 4,
		borderStyle: "solid",
	},
	responseAreaSuccess: {
		color: theme.palette.primary.main,
		borderColor: theme.palette.primary.main,
	},
	responseAreaError: {
		color: "red",
		borderColor: "red",
	},
	submitButton: {
		color: "white",
		marginTop: 0,
		[theme.breakpoints.up("sm")]: {
			marginBottom: 10,
		},
	},
	closeIcon: {
		float: "right",
	},
	description: {
		whiteSpace: "pre-line",
	},
}));

const FormGeneral = (props) => {
	const errors = useSelector((state) => state.UI.responses.errors);
	const messages = useSelector((state) => state.UI.responses.messages);
	const classes = styles();

	return (
		<Grid item xs={12}>
			{errors.general && (
				<div
					className={clsx(
						props.className,
						classes.responseArea,
						classes.responseAreaError
					)}>
					<Typography
						variant="body2"
						style={{
							color: "red",
						}}>
						{errors.general}
					</Typography>
				</div>
			)}
			{messages.success && (
				<div
					className={clsx(
						classes.responseArea,
						classes.responseAreaSuccess
					)}>
					<Typography variant="body2" className={classes.success}>
						{messages.success}
					</Typography>
				</div>
			)}
		</Grid>
	);
};

FormGeneral.propTypes = {
	className: PropTypes.string,
};
export default FormGeneral;
