import React, { useState, useRef } from "react";
import copy from "copy-to-clipboard";
//Component
import GeneralButton from "../controls/buttons/GeneralButton";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

const styles = makeStyles({
	root: {
		minWidth: 275,
	},
	bullet: {
		display: "inline-block",
		margin: "0 2px",
		transform: "scale(0.8)",
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function ReferralCard({ user }) {
	const classes = styles();
	const bull = <span className={classes.bullet}>•</span>;

	const [copySuccess, setCopySuccess] = useState("");
	const textAreaRef = useRef(null);

	function copyToClipboard(e) {
		textAreaRef.current.select();
		document.execCommand("copy");
		// This is just personal preference.
		// I prefer to not show the whole text area selected.
		e.target.focus();
		setCopySuccess("Copied!");
	}
	const referralLink = `${
		process.env.REACT_APP_PUBLIC_URL
			? process.env.REACT_APP_PUBLIC_URL
			: "https://sealdealfinder.com"
	}/wholesaler/referral/${user.userId}`;

	return (
		<Card className={classes.root} variant="outlined">
			<CardContent>
				<Typography
					className={classes.title}
					color="textSecondary"
					gutterBottom>
					Referral Code
				</Typography>
				<Typography variant="h5" component="h2" gutterBottom>
					{user.userId}
				</Typography>
				<Typography
					className={classes.title}
					color="textSecondary"
					gutterBottom>
					Referral Link
				</Typography>
				<form>
					<TextareaAutosize
						style={{ width: "100%" }}
						rows={1}
						ref={textAreaRef}
						value={referralLink}
					/>
				</form>
			</CardContent>
			<CardActions>
				{document.queryCommandSupported("copy") && (
					<GeneralButton
						text="Copy Referral Link"
						fullWidth={false}
						variant="contained"
						color="primary"
						onClick={copyToClipboard}></GeneralButton>
				)}
				<Typography
					className={classes.title}
					color="textPrimary"
					gutterTop>
					{copySuccess}
				</Typography>
			</CardActions>
		</Card>
	);
}

export default ReferralCard;
