import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";
//MUI
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
//Redux
import { useSelector } from "react-redux";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	menuButtonRight: {
		marginRight: theme.spacing(2),
		float: "right",
	},
	assistantButton: {
		backgroundColor: theme.palette.information.dark,
		color: "#fff",
	},
}));

const defaultProps = {
	text: "Get a Virtual Assistant",
	variant: "outlined",
};

const GetVirtualAssistantButton = ({
	size,
	fullWidth,
	menu,
	className,
	menuButtonRight,
	text = defaultProps.text,
	variant = defaultProps.variant,
}) => {
	const classes = styles();

	const button = () => (
		<Button
			fullWidth={fullWidth}
			size={clsx(menu ? "small" : size)}
			className={clsx(
				classes.assistantButton,
				menuButtonRight ? classes.menuButtonRight : null,
				className
			)}
			variant={variant}
			color={"secondary"}
			component={Link}
			to={"/virtual-assistant"}>
			{text}
		</Button>
	);

	return <Grid item>{button()}</Grid>;
};

GetVirtualAssistantButton.propTypes = {
	className: PropTypes.string,
	menu: PropTypes.any,
	menuButtonRight: PropTypes.any,
	size: PropTypes.any,
	text: PropTypes.string,
	variant: PropTypes.string,
};

export default GetVirtualAssistantButton;
