import PropTypes from "prop-types";
import React, { Fragment } from "react";
import clsx from "clsx";
//MUI Components;
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	label: {
		fontWeight: 700,
		textAlign: "left",
		fontSize: "0.75rem",
	},
	data: {
		textAlign: "left",
		fontSize: "0.75rem",
	},
	row: { padding: 0 },
	rowSeperator: {
		width: "100%",
		marginTop: 10,
		marginBottom: 0,
	},
	alert: {
		color: "red",
		fontWeight: 700,
	},
}));

const defaultProps = { labelWidth: 6, dataWidth: 6 };

const TextTableGrid = ({
	rows,
	labelWidth = defaultProps.labelWidth,
	dataWidth = defaultProps.dataWidth,
}) => {
	const classes = styles();

	const rowlist = rows.map((row, index) => (
		<Grid
			key={index}
			className={clsx(classes.row, row.alert ? classes.alert : null)}
			spacing={1}
			container
			xs={12}
			component={CardContent}>
			{row.seperator && <hr className={classes.rowSeperator} />}
			<Grid item xs={labelWidth} justify="flex-end">
				<Typography className={classes.label}>
					{`${row.label}:`}
				</Typography>
			</Grid>

			<Grid item xs={dataWidth}>
				<Typography className={classes.data}>{row.data}</Typography>
			</Grid>
		</Grid>
	));

	return <Fragment>{rowlist}</Fragment>;
};

TextTableGrid.propTypes = {
	dataWidth: PropTypes.any,
	labelWidth: PropTypes.any,
	rows: PropTypes.shape({
		map: PropTypes.func,
	}),
};

export default TextTableGrid;
