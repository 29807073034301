import React, { Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
//MUI
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	badge: {
		backgroundColor: theme.palette.accent.main,
		color: "#fff",
		width: "100%",
		padding: "3px 5px 3px 5px",
		textAlign: "center",
		lineHeight: 0.1,
		top: 25,
		marginTop: "-25px",
		position: "relative",
	},
	text: {
		margin: 5,
		borderColor: "#fff",
		borderWidth: 1,
		borderStyle: "solid",
		borderRadius: 4,
		padding: "0px 10px 0px 10px",
	},
	textLarge: {
		margin: 5,
		borderColor: "#fff",
		borderWidth: 1,
		borderStyle: "solid",
		borderRadius: 4,
		padding: 0,
		textAlign: "center",
	},
}));

const DealBadge = ({ type, large, className, txtClassName }) => {
	//const user = useSelector((state) => state.user.credentials);
	const classes = styles();

	const width = large ? 2 : 4;

	const badge = type && (
		<Grid
			elevation={2}
			item
			square={true}
			className={clsx(className, classes.badge)}
			component={Paper}>
			<Grid container justify="center" alignItems="center">
				<Grid item xs={width}></Grid>
				<Grid item xs={width} className={clsx(txtClassName)}>
					<Typography
						justify="center"
						align="center"
						className={clsx(
							txtClassName,
							large ? classes.textLarge : classes.text
						)}
						variant={large ? "body2" : "caption"}>
						{type === "sold" ? "SOLD" : null}
					</Typography>
				</Grid>
				<Grid item xs={width}></Grid>
			</Grid>
		</Grid>
	);
	return <Fragment>{badge}</Fragment>;
};

DealBadge.propTypes = {
	className: PropTypes.string,
	large: PropTypes.bool,
	txtClassName: PropTypes.string,
	type: PropTypes.string,
};

export default DealBadge;
