import axios from 'axios'
import {
	SET_USER,
	EDIT_USER,
	IMAGE_CROPPING,
	LOADING_USER,
	STOP_LOADING_USER,
	SET_ERRORS,
	LOADING_UI,
	SET_UNAUTHENTICATED,
	SET_AUTHENTICATED,
	SET_NOTIFICATIONS,
	DELETE_READ_NOTIFICATIONS,
	SET_SYSTEM_USERS,
	SET_ALERT,
	CLEAR_RESPONSES,
	SET_DEALS,
	SET_PROFILE,
	SET_MESSAGES,
	PURGE_USER,
	SET_PROFILE_IMAGE,
	SET_FILTERED_DEALS,
	REDIRECT,
} from "../types"





export const uploadVerificationFile = (formData) => (dispatch) => {
	dispatch({
		type: LOADING_UI,
		payload: true
	})
	axios.post('/api/system/verification', formData)
		.then(res => {
			dispatch({
				type: SET_ALERT,
				payload: {
					title: "Success!! Your file saved successfully",
					type: "success",
					text: "Your document will be reviewed within the next 1 to 2 days.  You will be notified if your verification is approved.",
					open: true,
					required: false,
					confirmationForm: "",
					abortFirstLetterCap: false,
					confirmed: false
				}
			})
			dispatch({
				type: LOADING_UI,
				payload: false
			})
			dispatch({
				type: EDIT_USER,
				payload: {
					verificationUploaded: true
				}

			})
		}).catch(err => {
			console.log(err)
			console.log(err.response.data)
			/*dispatch({
				type: SET_ALERT,
				payload: {
					type: "error",
					title: err.response.data.error.alert.title,
					text: err.response.data.error.alert.text,
				}
			})*/
			dispatch({
				type: LOADING_UI,
				payload: false
			})
		})
}


export const cancelUserAccount = (data) => (dispatch) => {

	axios({
			method: 'delete',
			url: '/api/user',
			data: data
		})
		.then(res => {
			dispatch({
				type: SET_ALERT,
				payload: {
					type: "success",
					text: res.data.success.alert,
					redirectTo: '/'
				}
			});
		}).catch(err => {
			console.log(err)
			/*dispatch({
				type: SET_ALERT,
				payload: {
					type: "error",
					title: err.response.data.error.alert.title,
					text: err.response.data.error.alert.text
				}

			})*/
		})
}

export const systemGetUsers = (dispatch) => {
	axios.get('/api/system/verify')
		.then(res => {
			dispatch({
				type: SET_SYSTEM_USERS,
				payload: res.data
			})
		}).catch(err => {

		})
}

export const loginUser = (userData, history) => (dispatch) => {
	dispatch({
		type: CLEAR_RESPONSES
	});
	dispatch({
		type: LOADING_UI,
		payload: true

	});

	axios
		.post('/api/login', userData)
		.then((res) => {
			dispatch(getUserData());
			dispatch({
				type: CLEAR_RESPONSES
			});
			dispatch({
				type: SET_AUTHENTICATED
			});
			dispatch({
				type: LOADING_UI,
				payload: false

			});
			//history.push('/');
		})
		.catch((err) => {
			console.log(err)
			if (err.response.data) {
				dispatch({
					type: SET_ERRORS,
					payload: err.response.data
				})
			}
		});
};



export const signupUser = (newUserData, history) => (dispatch) => {
	dispatch({
		type: CLEAR_RESPONSES
	});
	dispatch({
		type: LOADING_UI,
		payload: true
	});
	axios
		.post('/api/signup', newUserData)
		.then((res) => {
			console.log(res.data)
			let user = res.data.user
			dispatch({
				type: SET_USER,
				payload: {
					credentials: user
				}
			})
			dispatch({
				type: SET_AUTHENTICATED
			});
			dispatch({
				type: SET_ALERT,
				payload: {
					type: "success",
					text: res.data.alert.success
				}

			});
			dispatch({
				type: LOADING_UI,
				payload: false
			});
			//history.push('/');
		})
		.catch((err) => {
			console.log(err.response)
			if (err.response.data) {
				dispatch({
					type: SET_ERRORS,
					payload: err.response.data
				});
			}
		});
};

export const logoutUser = () => (dispatch) => {
	axios
		.post('/api/logout')
		.then(() => {
			dispatch({
				type: SET_UNAUTHENTICATED
			});
			dispatch({
				type: PURGE_USER
			});
			dispatch({
				type: REDIRECT,
				payload: ""
			})
		})
		.catch((err) => {
			console.log(err)
		})

}

export const getUserData = (uid) => (dispatch) => {
	dispatch({
		type: LOADING_USER
	});

	axios.get(`/api/user`)
		.then(res => {

			dispatch({
				type: SET_USER,
				payload: res.data
			})
		})
		.catch(err => {
			dispatch({
				type: STOP_LOADING_USER
			});
			if ((err.response.status !== 403) && (err.response.status !== 500)) {
				dispatch({
					type: SET_ALERT,
					payload: {
						type: "error",
						title: err.response.data.error.alert ? err.response.data.error.alert.title : null,
						text: err.response.data.error.alert.text
					}

				})
			} else {
				console.log(err.response.data)
			}
		})
}

export const getUserProfileData = (params) => (dispatch) => {
	dispatch({
		type: LOADING_USER
	});

	axios.get(`/api/user/${params.userHandle}`)
		.then(res => {

			/*dispatch({
				type: SET_USER,
				payload: res.data
			})*/
			dispatch({
				type: SET_DEALS,
				payload: res.data.deals
			})
			dispatch({
				type: SET_PROFILE,
				payload: res.data.user[0]
			})
		})
		.catch(err => {
			console.error(err);
		})
}

export const uploadImage = (formData) => (dispatch) => {

	dispatch({
		type: LOADING_USER
	})

	axios.post('/api/user/image', formData)
		.then(() => {
			dispatch(getUserData());
		})
		.catch(error => {
			console.log(error.response)
		});

}

export const editUserDetails = (userDetails, imageBlob, imageName, deals, filteredDeals) => (dispatch) => {
	//Clear messages before each request
	dispatch({
		type: LOADING_USER
	})

	var userFieldsJSON = JSON.stringify(userDetails);
	const formData = new FormData();

	if (imageBlob) {
		formData.append("image", imageBlob, imageName)
	}
	formData.append("userDetails", userFieldsJSON);

	axios({
			method: 'post',
			url: '/api/user',
			data: formData,
			config: {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		})
		.then(res => {
			dispatch({
				type: EDIT_USER,
				payload: res.data.user
			})
			return res.data
		})
		.then((data) => {
			if (imageBlob) {
				let updatedDealImages = deals.map((deal) => {
					if (deal.userId === data.user.userId) {
						return {
							...deal,
							userImage: data.user.imageUrl
						};
					} else {
						return {
							...deal
						};
					}
				});
				dispatch({
					type: SET_PROFILE_IMAGE,
					payload: data.user.imageUrl
				})
				dispatch({
					type: SET_DEALS,
					payload: updatedDealImages
				});
				if (filteredDeals) {
					let updatedFilteredDealImages = filteredDeals.map((deal) => {
						if (deal.userId === data.user.userId) {
							return {
								...deal,
								userImage: data.user.imageUrl
							};
						} else {
							return {
								...deal
							};
						}
					});
					dispatch({
						type: SET_FILTERED_DEALS,
						payload: updatedFilteredDealImages

					})
				}
			}
			return data
		})
		.then((data) => {
			dispatch({
				type: SET_MESSAGES,
				payload: data.message
			})
		})
		.catch(err => {
			console.log(err)
			dispatch({
				type: SET_ERRORS,
				payload: err.response.data
			})
		})

}

export const imageCropping = (cropping) => dispatch => {
	dispatch({
		type: IMAGE_CROPPING,
		payload: cropping
	})
}

export const deleteReadNotifications = (notificationsIds) => dispatch => {
	axios({
			method: 'delete',
			url: '/api/notifications',
			data: notificationsIds
		})
		.then((res) => {
			notificationsIds.forEach(

				notificationId => {
					dispatch({
						type: DELETE_READ_NOTIFICATIONS,
						payload: notificationId
					})
				});
		})
		.catch(err => console.log(err))
}

export const getNotifications = () => dispatch => {
	axios.get('/api/notifications')
		.then((res) => {
			dispatch({
				type: SET_NOTIFICATIONS,
				payload: res.data.notifications
			})
		})
		.catch(err => console.log(err))
}

export const resetUserPassword = (emailAddress) => dispatch => {
	dispatch({
		type: LOADING_UI,
		payload: true
	});
	axios.post('/api/user/reset/password', emailAddress)
		.then((res) => {
			dispatch({
				type: SET_ALERT,
				payload: {
					type: "success",
					title: "Email Sent",
					text: "An email has been sent to you inbox which will allow you to reset your password",
				}
			})
			dispatch({
				type: LOADING_UI,
				payload: false
			})
		})
		.catch(err => {
			dispatch({
				type: SET_ALERT,
				payload: {
					type: "error",
					title: err.response.data.error.alert.title,
					text: err.response.data.error.alert.text
				}

			})

			dispatch({
				type: LOADING_UI,
				payload: false
			})

		})
}
