import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
//Components
import ProfileSkeleton from "../../util/ProfileSkeleton";
import LoginSignupActions from "./LoginSignupActions";
import UserBadgeCard from "../badges/UserBadgeCard";
import ContactDealMakerButton from "../controls/buttons/inbox/ContactDealMakerButton";
//MUI
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import WebIcon from "@material-ui/icons/Web";
//Icons
import LocationOn from "@material-ui/icons/LocationOn";
import CalendarToday from "@material-ui/icons/CalendarToday";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import InfoIcon from "@material-ui/icons/Info";
//Redux
import { useSelector } from "react-redux";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
//Dayjs
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);
dayjs.tz.guess();

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	paper: {
		padding: 10,
		backgroundColor: theme.palette.information.main,
	},
	paperInnerBorder: {
		borderColor: theme.palette.primary.main,
		boderWidth: 1,
		borderStyle: "solid",
		borderRadius: 4,
		padding: 3,
	},
	profileInner: {
		padding: 5,
	},
	publicProfileButton: {
		color: "#fff",
	},
	handle: {
		color: theme.palette.accent.main,
		textAlign: "center",
		padding: 5,
	},
	profileDetails: {
		color: "white",
		textAlgin: "right",
	},
	profileFields: {
		padding: 10,
		color: theme.palette.primary.main,
	},
	toolTipButton: { color: "white" },
	textBackground: {
		backgroundColor: "white",
		width: "100%",
	},
	profileField: {
		color: "#51C46C !important",
	},
	profileFieldSeparator: {
		color: theme.palette.primary.main,
		width: "100%",
		borderWidth: 0.1,
		border: "dotted",
		borderBottom: "1px solid rgba(0,0,0,0.2)",
		marginBottom: 10,
	},
	icon: {
		verticalAlign: "middle",
		marginRight: 10,
	},
	infoIcon: {
		margin: "0 auto",
		textAlign: "center",
		display: "block",
	},
	hide: {
		display: "none",
	},
}));

const PublicProfile = ({ ctaTo, ctaText, showLoginSignupActions }) => {
	const classes = styles();
	const loadingPublicProfile = useSelector((state) => state.profile.loading);
	const loadingAuthUser = useSelector((state) => state.user.loading);
	const profile = useSelector((state) => state.profile.user);
	const authUser = useSelector((state) => state.user.credentials);
	const authenticated = useSelector((state) => state.user.authenticated);

	useEffect(() => {
		//dispatch(getUserData());
	}, []);

	let publicProfileMarkup =
		profile.handle && !loadingPublicProfile && !loadingAuthUser ? (
			<Grid item>
				<Paper className={clsx(classes.paper)}>
					<div className={classes.paperInnerBorder}>
						<div className={classes.profile}>
							<div className={classes.profileInner}>
								{profile.handle && (
									<Fragment>
										<UserBadgeCard user={profile} />
										<Button
											fullWidth
											className={clsx(
												classes.publicProfileButton,
												classes.sidebarButton
											)}
											variant="contained"
											color="primary"
											component={Link}
											to={ctaTo}>
											{ctaText}
										</Button>
										{authUser.userId !== profile.userId && (
											<ContactDealMakerButton
												user={profile}
											/>
										)}
										<div className={classes.profileDetails}>
											<div
												className={
													classes.profileFields
												}>
												<hr
													className={
														classes.profileFieldSeparator
													}
												/>
												<Typography variant="body2">
													<AccountCircleIcon
														className={classes.icon}
													/>
													{profile.handle}
												</Typography>

												<hr
													className={
														classes.profileFieldSeparator
													}
												/>
												{profile.location && (
													<Fragment>
														<Typography>
															<LocationOn
																className={
																	classes.icon
																}
															/>
															{profile.location}
														</Typography>
														<hr
															className={
																classes.profileFieldSeparator
															}
														/>
													</Fragment>
												)}
												{profile.website && (
													<Fragment>
														<Typography>
															<WebIcon
																className={
																	classes.icon
																}
															/>
															{profile.website}
														</Typography>
														<hr
															className={
																classes.profileFieldSeparator
															}
														/>
													</Fragment>
												)}

												<Typography>
													<CalendarToday
														className={classes.icon}
													/>
													{`Joined ${dayjs(
														profile.createdAt
													).format("MMM YYYY")}`}
												</Typography>

												{profile.bio && (
													<Fragment>
														<hr
															className={
																classes.profileFieldSeparator
															}
														/>
														<Typography>
															<InfoIcon
																className={
																	classes.infoIcon
																}
															/>
															{profile.bio}
														</Typography>
													</Fragment>
												)}
												<hr
													className={
														classes.profileFieldSeparator
													}
												/>
											</div>
										</div>
									</Fragment>
								)}
							</div>
						</div>
					</div>
				</Paper>
			</Grid>
		) : (
			<Fragment>
				<ProfileSkeleton />
			</Fragment>
		);

	return (
		<Grid container direction="column" spacing={2}>
			{publicProfileMarkup}
			{!authenticated && showLoginSignupActions ? (
				<LoginSignupActions />
			) : null}
		</Grid>
	);
};

PublicProfile.propTypes = {
	ctaText: PropTypes.string.isRequired,
	ctaTo: PropTypes.string.isRequired,
	showLoginSignupActions: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.bool,
	]),
};

PropTypes.Profile = {};

export default PublicProfile;
