import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
//Components
import dealImagePlaceholder from "../../images/deal-placeholder-500-300@300.png";
//MUI
import CardMedia from "@material-ui/core/CardMedia";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
}));

const DealImageWithErrorChecking = ({
	src,
	fallbackSrc,
	className,
	style,
	smallBadge,
	shadow,
}) => {
	const [state, setState] = useState({
		src: src,
		errored: false,
	});

	const classes = styles();

	const onError = () => {
		setState({
			...state,
			src: dealImagePlaceholder,
			errored: true,
		});
	};

	return (
		<>
			<CardMedia
				src={state.src}
				/** * Important part. Without that, the rendered code will be * a `<div />` with `backgroundImage: url("...")` as style. */
				component="img"
				/** * Additional props are passed to the defined component, * so onError will be recognized by our `img`. */
				onError={(e) => {
					onError(encodeURI);
					/** * Any code. For instance, changing the `src` prop with a fallback url. * In our code, I've added `e.target.className = fallback_className` for instance. */
				}}
			/>
		</>
	);
};

DealImageWithErrorChecking.propTypes = {
	src: PropTypes.string,
	fallbackSrc: PropTypes.string,
};

export default DealImageWithErrorChecking;
