import PropTypes from "prop-types";
import React from "react";
import { TwitterShareButton } from "react-share";
import TwitterIcon from "../../../../images/svg/icon-twitter.svg";
//Components
import ShareButton from "../ShareButton";
//MUI Components
import Grid from "@material-ui/core/Grid";

const TwitterDealShareButton = ({ shareUrl }) => {
	return (
		<Grid item xs={12} sm={3}>
			<TwitterShareButton
				style={{ width: "100%" }}
				url={shareUrl}
				hashtag={["sealdealfinder"]}>
				<ShareButton icon={TwitterIcon} text="Tweet" />
			</TwitterShareButton>
		</Grid>
	);
};

TwitterDealShareButton.propTypes = {
	shareUrl: PropTypes.string.isRequired,
};

export default TwitterDealShareButton;
