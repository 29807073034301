import React, { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
//Components
import SpinnerButton from "../../controls/SpinnerButton";
import { DealTextField } from "../../controls/DealTextFields";
//MUI Components
import Grid from "@material-ui/core/Grid";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { resetUserPassword } from "../../../redux/actions/userActions";
//Styles
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	textfields: { padding: theme.spacing(2) },
}));

const ResetPassWordForm = () => {
	const loading = useSelector((state) => state.UI.loading);
	const errors = useSelector((state) => state.UI.responses.errors);
	const dispatch = useDispatch();

	const [input, setInput] = useState({ emailAddress: "" });

	const handleInputChange = (e) => {
		setInput({
			...input,
			[e.currentTarget.name]: e.currentTarget.value,
		});
	};
	const classes = styles();

	const handleSubmit = () => {
		dispatch(resetUserPassword(input));
	};

	return (
		<Grid item xs={12}>
			<Grid item></Grid>
			<Grid
				className={classes.textfields}
				item
				xs={12}
				container
				spacing={2}>
				<DealTextField
					halfWidth
					id="emailAddress"
					name="emailAddress"
					type="text"
					label="Email"
					helperText={errors.emailAddress}
					error={errors.emailAddress ? true : false}
					value={input.emailAddress}
					onChange={handleInputChange}
				/>
				<Grid item xs={12}>
					<SpinnerButton
						loading={loading}
						fullWidth
						variant="contained"
						color="primary"
						onClick={() => handleSubmit()}>
						Submit
					</SpinnerButton>
				</Grid>
			</Grid>
			<Grid item></Grid>
		</Grid>
	);
};

export default ResetPassWordForm;
