import React, { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Helmet } from "react-helmet";
//Components
import blankAvatar from "../images/placeholder-avatar-3d@300.png";
import UserImage from "../components/image/UserImage";
//MUI
import Slider from "@material-ui/core/Slider";
import Grid from "@material-ui/core/Grid";
//Redux
import { useSelector } from "react-redux";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	search: {
		textAlign: "center",
		display: "block",
		margin: "0 auto",
		maxWidth: 300,
		color: theme.palette.secondary.main,
	},
	imageWrapper: {
		minHeight: 200,
	},
	editor: {
		maxWidth: 200,
	},
	cropContainer: {
		background: "#333",
	},
	cropper: {
		height: 200,
		width: 200,
	},

	priceFieldPaper: {
		backgroundColor: theme.palette.primary.main,
		width: "100%",
		padding: 10,
	},
	formButton: {
		color: "white",
		marginTop: 0,
		[theme.breakpoints.up("sm")]: {
			marginBottom: 10,
		},
	},
	description: {
		whiteSpace: "pre-line",
	},
}));
const UserImageCropper = (
	{ active, imageFile, imageUrl, matches, type, sliderDisabled },
	ref
) => {
	const cropping = useSelector((state) => state.user.imageCropping);
	const [zoom, setZoom] = useState(0.01);
	const classes = styles();

	const handleZoomSliderChange = (event, zoom) => {
		setZoom(zoom);
	};

	const sliderZoomControl = (
		<Slider
			disabled={sliderDisabled}
			value={zoom}
			min={0.1}
			max={2}
			step={0.001}
			onChange={handleZoomSliderChange}
		/>
	);

	const cropEditor = cropping ? (
		<Cropper
			className={classes.cropper}
			ref={ref}
			src={imageFile}
			style={{ width: "100%" }}
			// Cropper.js options
			aspectRatio={4 / 4}
			disabled={true}
			autoCrop={true}
			autoCropArea={1}
			guides={false}
			zoomOnWheel={false}
			zoomTo={zoom}
			modal={true}
			viewMode={2}
			circularCrop={true}
		/>
	) : (
		<UserImage
			noScaleUp
			style={{
				maxWidth: "100%",
				width: 200,
				height: 200,
				borderRadius: "50%",
			}}
			src={imageUrl ? imageUrl : blankAvatar}
		/>
	);
	const headerStyle = (
		<Helmet>
			<style>
				{`.cropper-crop-box, .cropper-view-box {
    					border-radius: 50%;
						}

					.cropper-view-box {
    					box-shadow: 0 0 0 1px #39f;
    					outline: 0 ;
					}`}
			</style>
		</Helmet>
	);

	return (
		<Grid item>
			{headerStyle}
			{cropEditor}
			{sliderZoomControl}
		</Grid>
	);
};

export default React.forwardRef(UserImageCropper);
