import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
//Components
import DealNavBarMobile from "../components/navigation/mobile/DealNavBarMobile";
import MainLayout from "../components/layouts/MainLayout";
import DealSection from "../components/sections/DealSection";
import DealNavBar from "../components/navigation/DealNavBar";
import PublicProfile from "../components/profile/PublicProfile";
import AuthProfile from "../components/profile/AuthProfile";
import CreateDealFab from "../components/controls/buttons/deals/CreateDealFab";
//MUI Components
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
// Redux
import { useDispatch, useSelector } from "react-redux";
import {
	getDeal,
	clearDeal,
	clearLocation,
} from "../redux/actions/dataActions";

const Deals = () => {
	const authUser = useSelector((state) => state.user.credentials);
	const deal = useSelector((state) => state.data.deal);

	const dispatch = useDispatch();

	let params = useParams();
	useEffect(() => {
		dispatch(clearDeal());
		dispatch(getDeal(params.dealId));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const userProfile = (
		<PublicProfile
			ctaText={`View User's Deals`}
			ctaTo={`/profiles/${deal.userHandle}`}
		/>
	);

	const authProfile = (
		<AuthProfile
			ctaTo={`/profiles/${authUser.handle}`}
			ctaText="View My Deals"
		/>
	);

	return (
		<MainLayout
			sidebar={authProfile}
			sidebarRight={
				deal.userHandle !== authUser.handle ? (
					userProfile
				) : (
					<Typography>Ad Space</Typography>
				)
			}>
			<Hidden xsDown>
				<DealNavBar />
			</Hidden>
			<DealSection />
			<Hidden smDown>
				<CreateDealFab form="deal" args={{ type: "create" }} />
			</Hidden>
			<Hidden mdUp>
				<DealNavBarMobile />
			</Hidden>
		</MainLayout>
	);
};

export default Deals;
