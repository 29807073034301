import React, { Fragment } from "react";
import clsx from "clsx";
//Components

//MUI
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// Redux
import { useSelector } from "react-redux";

//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	main: {
		backgroundColor: "white",

		padding: theme.spacing(3),
	},
	sideProfile: {
		minWidth: 240,
	},
	title: {
		marginBottom: 0,
	},
	subTitle: {
		marginBottom: 10,
	},
}));

const PrivacySection = () => {
	const user = useSelector((state) => state.user.credentials);
	const classes = styles();

	return (
		<Grid item align="center" xs={12}>
			<Paper
				className={clsx(classes.main, classes.formBackground)}
				component={Card}>
				<CardContent>
					<Typography variant="h4" className={classes.title}>
						Privacy Policy
					</Typography>
					<Typography variant="body1" className={classes.subTitle}>
						By using this website you signify that you are in
						agreement and accept the policy information below.
					</Typography>

					<Typography variant="body2" align="left">
						<p>
							Sealdealfinder.com is committed to protecting the
							privacy and accuracy of confidential information to
							the extent possible, subject to provisions of state
							and federal law. Other than as required by laws that
							guarantee public access to certain types of
							information, or in response to subpoenas or other
							legal instruments that authorize access, personal
							information is not actively shared. In particular,
							we do not re-distribute or sell personal information
							collected on our web servers.
						</p>
						<p>
							<strong>Information collected: </strong>
							<br />
							&nbsp;
							<strong>
								sealdealfinder.com servers collect the following
								analytics:{" "}
							</strong>
						</p>
						<ul>
							<li>
								Internet Protocol (IP) address of computer being
								used
							</li>
							<li>web pages requested</li>
							<li>referring web page</li>
							<li>browser used</li>
							<li>date and time</li>
						</ul>
						<p>
							sealdealfinder.com<strong>&nbsp;</strong>does not
							associate this data to individual user identities.
						</p>
						<p>
							<strong>
								Cookies
								<br />
								&nbsp;
							</strong>
							Sealdealfinder.com site may use &quot;cookies&quot;
							in order to deliver web content specific to
							individual users&apos; interests or to keep{" "}
							<em>track</em> of online purchasing transactions.
							Sensitive personal information is not stored within
							cookies.
							<strong>
								<br />
								&nbsp;
							</strong>
						</p>
						<p>
							<strong>Webforms</strong>
							<br />
							&nbsp;Sealdealfinder.com uses web-forms on this
							site. These forms require users to give contact
							information. The users name and email and in some
							cases the zip code is collected. Contact information
							from the registration form is used only to send or
							display material relating to the purpose for which
							it was collected and will not be sold to another
							party. <br />
							&nbsp;
						</p>
						<p>
							<strong>Use of collected information:</strong>
						</p>
						<ul>
							<li>
								Sealdealfinder.com will only use personal
								information collected from this site for the
								purpose of communication back to individuals who
								contact us via the site.
							</li>
							<li>
								Sealdealfinder.com uses browser-IP-address
								information and anonymous-browser history to
								report information about site accesses and for
								profiling purposes. This information is used to
								improve Web presentation and utilization. IP
								address information may also be used for
								troubleshooting purposes.
							</li>
						</ul>
						<p>&nbsp;</p>
						<p>
							<strong>
								Distribution of collected information:
							</strong>
						</p>
						<ul>
							<li>
								Sealdealfinder.com will not disclose, without
								your consent, personal information collected
								about you, except for certain explicit
								circumstances in which disclosure is required by
								law.
							</li>
							<li>
								Third party service from RFDPrint which is an
								independent contractor, is used to maintain the
								website.&nbsp; This service provider has admin
								access to most data the user provides on the
								site to the degree necessary to carry out
								website development, maintenance , technical
								support and reporting the site owner.
							</li>
							<li>
								Stripe payment processing service is used to
								collect and store payment information. Billing
								and card charges will be handled via this
								service.&nbsp; Credit card numbers are not
								stored on the sealdealfinder website, however
								secure and encrypted tokens that identify the
								card&nbsp; to be charge&nbsp; on the Stripe
								server will be stored on the sealdealfinder
								server.&nbsp;
							</li>
						</ul>
						<p>
							This Privacy Statement does not apply to the other
							Sealdealfinder web sites. You should review the
							privacy statement posted on other Sealdealfinder
							sites when you visit them.
						</p>
						<p>
							<strong>Privacy Statement Revisions:</strong>
						</p>
						<p>
							This Privacy Statement was last revised on
							11/5/2020. We may change this Privacy Statement at
							any time and for any reason. We encourage you to
							review this Privacy Statement each time you visit
							the web site.
						</p>
						<p>
							If we make a significant change to our Privacy
							Statement, we will post a notice on the homepage of
							our web site for a period of time after the change
							is made.
						</p>
						<p>
							<strong>Responsibility for External Sites:</strong>
						</p>
						<p>
							This website may contain links to other web sites.
							Some of those web sites may be operated by third
							parties. We provide the links for your convenience,
							but we do not review, control, or monitor the
							privacy practices of web sites operated by others.{" "}
							<br />
							&nbsp;
							<br />
							&nbsp;We are not responsible for the performance of
							web sites operated by third parties or for your
							business dealings with them. Therefore, whenever you
							leave this web site we recommend that you review
							each web site&apos;s privacy practices and make your
							own conclusions regarding the adequacy of these
							practices.
						</p>
						<p>
							<strong>Document Retention</strong>
						</p>
						<p>
							Any private documents submitted for services or
							badges will be stored on sealdealfinder.com for a
							period of 90 days, after that time the document will
							be deleted from the server.
						</p>
						<p>
							<strong>How to Contact Us</strong>
						</p>
						<p>
							While using this website, you may encounter
							hypertext links to the Web pages of other
							organizations not directly affiliated with
							Sealdealfinder.com . Sealdealfinder .com does not
							control the content or information practices of
							external organizations.&nbsp; We recommend you
							review the privacy statements of these
							organizations.
						</p>
						<p>
							You may request to view any of personal data that is
							stored on our servers at anytime by emailing{" "}
							<u>info@sealdealfindercom</u>, please allow at least
							72 hours for a response to your request.
						</p>
					</Typography>
				</CardContent>
			</Paper>
		</Grid>
	);
};

export default PrivacySection;
