import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import splashScreen from "../../images/splash-screen-logo.png";
import splashScreenSmall from "../../images/splash-screen-small.png";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
//Components
import FullScreenLayout from "./FullScreenLayout";
import LogoInline from "../logo/LogoInline";
import OpenResetPasswordLink from "../controls/buttons/forms/OpenResetPasswordLink";
//MUI Components
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
//Styles
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	splashScreen: {
		backgroundImage: `url(${splashScreen})`,
		minWidth: "100vh",
		minHeight: "100vh",
	},
	splashScreenSmall: {
		backgroundImage: `url(${splashScreenSmall})`,
		minHeight: "100vh",
	},
	root: {
		position: "relative",
		backgroundColor: "#404040",
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
	},
	pageTitle: {
		color: "#fff",
		textAlign: "center",
		fontSize: "3rem",
	},
	form: {
		display: "none",
		maxWidth: "100%",
		backgroundColor: "rgba(255,255,255,.6)",
		padding: theme.spacing(6),
		borderRadius: 4,
	},
	center: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	},
	centerSmallScreen: {
		width: "100%",
		position: "absolute",
		top: "50%",
		transform: "translateY(-50%)",
	},
	formTitle: {
		color: "#fff",
		textAlign: "center",
		fontSize: "1.5rem",
		textShadow: "2px 2px 4px #000000",
	},
	linkInfoText: {
		color: "#fff",
		textShadow: "2px 2px 4px #000000",
	},
	link: {
		color: "#fff",
		fontWeight: 700,
		textDecoration: "underline",
		textShadow: "2px 2px 4px #000000",
	},
	fadeElementIn: {
		display: "block",
	},
	wholesalerLinkText: {
		color: theme.palette.primary.dark,
		textShadow: "2px 2px 4px #000",
		marginTop: 10,
	},
	wholesalerLink: {
		color: theme.palette.primary.dark,
	},
}));

const defalutProps = { title: "Seal Deal Finder" };

const Login = ({
	children,
	title = defalutProps.title,
	linkTo,
	linkInfoText,
	linkText,
	pngLogo,
}) => {
	let location = useLocation();
	const baseLocationWholesaler = location.pathname.startsWith(
		"/wholesaler/referral/"
	);
	const baseLocationLogin = location.pathname.startsWith("/login");
	const classes = styles();
	const [fadeIn, setfadeIn] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setfadeIn(true);
		}, 1000);
	}, []);

	const theme = useTheme();
	const screenSmallUp = useMediaQuery(theme.breakpoints.up("sm"));

	return (
		<FullScreenLayout>
			<Grid item>
				<Grid
					container
					className={clsx(
						screenSmallUp
							? classes.splashScreen
							: classes.splashScreenSmall,
						classes.root,
						fadeIn ? classes.fadeElementIn : classes.hide
					)}
					style={{ height: "100%" }}>
					<Grid item sm />
					<Grid
						item
						sm={5}
						className={clsx(
							screenSmallUp
								? classes.center
								: classes.centerSmallScreen,
							classes.form,
							fadeIn && classes.fadeElementIn
						)}>
						{pngLogo ? (
							<LogoInline />
						) : (
							<Typography
								align="center"
								variant="h1"
								className={classes.pageTitle}>
								Seal Deal Finder
							</Typography>
						)}
						<Typography variant="h2" className={classes.formTitle}>
							{title}
						</Typography>
						{children}
						<br />
						<Typography
							component="div"
							className={classes.linkInfoText}
							variant="body2"
							color="secondary">
							{linkInfoText}{" "}
							<Link to={linkTo} className={classes.link}>
								{linkText}
							</Link>{" "}
							<OpenResetPasswordLink />
						</Typography>
						{!baseLocationWholesaler && !baseLocationLogin && (
							<Typography
								component="div"
								className={clsx(
									classes.wholesalerLinkText,
									classes.linkInfoText
								)}
								variant="body2"
								color="secondary">
								are you a wholesaler?{" "}
								<Link
									to="/wholesaler/referral/Enter Referral Code"
									className={clsx(
										classes.wholesalerLink,
										classes.link
									)}>
									signup here
								</Link>{" "}
							</Typography>
						)}
					</Grid>
					<Grid item sm />
				</Grid>
			</Grid>
		</FullScreenLayout>
	);
};

Login.propTypes = {
	children: PropTypes.any,
	linkInfoText: PropTypes.string,
	linkText: PropTypes.string,
	linkTo: PropTypes.string,
	pngLogo: PropTypes.any,
	title: PropTypes.string,
};

export default Login;
