import React from "react";
//Components
import ContentStandard from "./body/ContentStandard";
//MUI Components
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	dealCard: {
		backgroundColor: "#51C46C",
	},
	root: {
		flexGrow: 1,
	},
	sideProfile: {
		minWidth: 240,
	},
}));

const MainLayout = ({ children, sidebar, backgroundColor }) => {
	const classes = styles();
	return (
		<ContentStandard backgroundColor={backgroundColor}>
			<div className={classes.root}>
				<Grid container justify="space-around" spacing={2}>
					<Hidden smDown>
						<Grid
							className={classes.sideProfile}
							item
							sm={3}
							md={3}
							lg={3}>
							{sidebar}
						</Grid>
					</Hidden>
					<Grid item xs={12} md={9} sm={12}>
						<Grid container item xs={12} justify="space-evenly">
							{children}
						</Grid>
					</Grid>
				</Grid>
			</div>
		</ContentStandard>
	);
};

export default MainLayout;
