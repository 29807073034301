import React from "react";
//MUI Components
import Grid from "@material-ui/core/Grid";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles(theme => ({
	...theme.customStyles,
	rightGroup: {
		marginRight: theme.spacing(2),
		float: "right",
		flexGrow: 1
	}
}));

const RightButtonGroup = ({ children }) => {
	const classes = styles();
	return (
		<Grid item className={classes.rightGroup}>
			{children}
		</Grid>
	);
};

export default RightButtonGroup;
