import PropTypes from "prop-types";
import React from "react";
//Component
import FormHeader from "./FormHeader";
//MUI
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
//Redux
import { useSelector, useDispatch } from "react-redux";
import {
	toggleFormDialog,
	clearResponses,
} from "../../../redux/actions/uiActions";
import { imageCropping } from "../../../redux/actions/userActions";
//import { clearLocation } from "../../../redux/actions/dataActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	dialogContent: {
		overflow: "hidden",
	},
	button: {
		float: "right",
	},
	toolTipButton: { color: "white" },
}));

const defaultProps = {
	titleText: "Form Title",
	maxWidth: "md",
};

const Form = ({
	titleText = defaultProps.titleText,
	maxWidth = defaultProps.maxWidth,
	component,
	form,
	fullScreen,
}) => {
	const dialog = useSelector((state) => state.UI.dialog);
	const dispatch = useDispatch();
	const classes = styles();
	const handleClose = () => {
		//dispatch(clearLocation());
		dispatch(clearResponses());
		dispatch(toggleFormDialog(false, null));
		dispatch(imageCropping(false));
	};

	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up("sm"));

	return (
		dialog.open &&
		component && (
			<Dialog
				classes={{
					paper: classes.formBackground,
				}}
				fullScreen={!matches ? true : false}
				scroll="body"
				disableBackdropClick={true}
				open={dialog.open}
				onClose={() => handleClose()}
				fullWidth
				maxWidth={maxWidth}>
				<FormHeader
					handleClose={() => handleClose()}
					titleText={titleText}
				/>
				<DialogContent
					style={fullScreen && !matches ? { padding: "5px 0" } : null}
					dividers>
					{component}
				</DialogContent>
			</Dialog>
		)
	);
};

Form.propTypes = {
	component: PropTypes.element,
	form: PropTypes.string,
	fullScreen: PropTypes.any,
	maxWidth: PropTypes.string,
	titleText: PropTypes.string,
};

export default Form;
