import PropTypes from "prop-types";
import React, { Fragment } from "react";
import userImagePlaceholder from "../../images/placeholder-avatar-3d@177.png";
import { Link } from "react-router-dom";
import clsx from "clsx";
//Components
import UserTypeBadge from "./UserTypeBadge";
import UserImage from "../image/UserImage";
//MUI
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	profile: {
		marginTop: 0,
	},
	badgeProfileImage: {
		maxWidth: "100%",
		borderRadius: "100%",

		margin: "10px 0 0 0",
		display: "block",
	},
	smallBadgeProfileImage: {
		maxWidth: "60%",
		margin: "10px auto",
		marginBottom: "-5px",

		borderRadius: "100%",
		display: "block",
	},
	handle: {
		color: theme.palette.accent.main,
		textShadow: "1px 1px 2px #000",
		marginTop: 3,
	},
	userBadge: { minHeight: 95 },
}));

const UserBadge = ({
	user,
	noHandle,
	noLink,
	noType,
	userHandle,
	smallBadge,
	userImage,
	userType,
	userVerified,
	noScaleUp,
	shadow,
	hideVerified,
	className,
	handleClassName,
}) => {
	const classes = styles();
	const { handle, imageUrl } = user;

	const badge = (
		<Grid
			className={classes.userBadge}
			item
			container
			justify="space-around"
			xs={12}>
			<Grid item xs={10}>
				<UserImage
					noScaleUp={noScaleUp}
					smallBadge={smallBadge}
					className={clsx(
						className,
						smallBadge
							? classes.smallBadgeProfileImage
							: classes.badgeProfileImage,
						shadow && classes.shadow
					)}
					src={imageUrl ? imageUrl : userImagePlaceholder}
					onError={userImagePlaceholder}
					alt="User Image"
				/>
			</Grid>
			{!noHandle && (
				<Typography
					noWrap
					className={clsx(classes.handle, handleClassName)}
					variant="caption">
					@{handle}
				</Typography>
			)}
			{!noType && (
				<Grid item xs={12}>
					<UserTypeBadge hideVerified={hideVerified} user={user} />
				</Grid>
			)}
		</Grid>
	);

	return !noLink ? (
		<Link to={`/profiles/${handle}`}>{badge}</Link>
	) : (
		<Fragment>{badge}</Fragment>
	);
};

UserBadge.propTypes = {
	className: PropTypes.string,
	handleClassName: PropTypes.string,
	hideVerified: PropTypes.any,
	noHandle: PropTypes.any,
	noLink: PropTypes.any,
	noScaleUp: PropTypes.any,
	noType: PropTypes.any,
	shadow: PropTypes.any,
	smallBadge: PropTypes.any,
	user: PropTypes.object.isRequired,
	userHandle: PropTypes.string,
	userImage: PropTypes.string,
	userType: PropTypes.string,
	userVerified: PropTypes.bool,
};

export default UserBadge;
