import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
//Components
import LogoutButton from "../../../controls/buttons/LogoutButton";
import ToggleFiltersButton from "../../../controls/buttons/ToggleFiltersButton";
import GeneralLinkButton from "../../../controls/buttons/GeneralLinkButton";
import UserBadgeCard from "../../../badges/UserBadgeCard";
import InboxLinkButton from "../../../controls/buttons/inbox/InboxLinkButton";
import GetVerifiedButton from "../../../controls/buttons/user/GetVerifiedButton";
//MUI Components
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
// Redux
import { useSelector } from "react-redux";

//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	toolBar: {
		padding: theme.spacing(1),
	},
	menuButtonRight: {
		marginRight: theme.spacing(2),
		float: "right",
		flexGrow: 1,
	},
	menuButtonCenter: {},
	verifiedText: {
		color: theme.palette.accent.main,
	},
	verifiedButton: {
		backgroundColor: theme.palette.accent.main,
		color: "#fff",
	},
	grow: {
		flexGrow: 1,
	},
	fullwidth: {
		width: "100%",
	},
	dividerColor: {
		color: "white",
	},
	firstButton: { marginTop: theme.spacing(1) },
}));

const MainToolbar = ({ color, center, filter }) => {
	const authenticated = useSelector((state) => state.user.authenticated);
	const authUser = useSelector((state) => state.user.credentials);
	const classes = styles();

	let adminAuthorized = false;
	if (authUser.roles) {
		adminAuthorized = authUser.roles.admin;
	}

	return (
		<Grid item xs={12}>
			<Grid
				align="center"
				justify="center"
				container
				spacing={2}
				className={classes.toolBar}>
				<Grid item className={center ? classes.fullwidth : null}>
					<UserBadgeCard user={authUser} />
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<Grid
					item
					className={clsx(
						center ? classes.firstButton : null,
						center ? classes.fullwidth : null
					)}>
					<Button
						fullWidth
						variant={center ? "outlined" : "text"}
						size="small"
						color={color}
						component={Link}
						to={`/`}>
						Deal Board
					</Button>
				</Grid>

				<Grid item className={center ? classes.fullwidth : null}>
					<InboxLinkButton
						small
						menu
						variant="outlined"
						userHandle={authUser.handle}
					/>
				</Grid>

				<Grid item className={center ? classes.fullwidth : null}>
					<GeneralLinkButton
						size="small"
						id="dynamic-cta-button"
						text="View My Deals"
						variant="outlined"
						linkTo={`/profiles/${authUser.handle}`}
					/>
				</Grid>

				{adminAuthorized && (
					<Fragment>
						<Grid
							item
							className={center ? classes.fullwidth : null}>
							<Button
								fullWidth
								variant={center ? "outlined" : "text"}
								size="small"
								color={color}
								component={Link}
								to={`/system`}>
								Admin Console
							</Button>
						</Grid>
					</Fragment>
				)}
				{!authUser.verified && authUser.userId && (
					<Grid
						item
						xs={12}
						className={center ? classes.fullwidth : null}>
						<GetVerifiedButton />
					</Grid>
				)}

				<Grid item xs={12}>
					<Divider />
				</Grid>
				{authenticated && (
					<Grid item className={center ? classes.fullwidth : null}>
						<GeneralLinkButton
							size="small"
							id="settings-page-button"
							text="Settings"
							variant="outlined"
							linkTo={`/settings/${authUser.handle}`}
						/>
					</Grid>
				)}
				<Grid item container spacing={2}>
					<Grid item xs={12}>
						<LogoutButton fullWidth />
					</Grid>
				</Grid>
				{filter && (
					<Hidden smDown>
						<ToggleFiltersButton />
					</Hidden>
				)}
			</Grid>
		</Grid>
	);
};

MainToolbar.propTypes = {
	center: PropTypes.any,
	color: PropTypes.any,
	filter: PropTypes.any,
};

export default MainToolbar;
