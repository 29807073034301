import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
//Components
import NavBarMainMenu from "../components/navigation/NavBarMainMenu";
import NavBarMobile from "../components/navigation/mobile/NavBarMobile";
import SettingsSection from "../components/sections/SettingsSection";
import MainLayout from "../components/layouts/MainLayout";
import SideProfile from "../components/profile/SideProfile";
//MUI
import Hidden from "@material-ui/core/Hidden";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileData } from "../redux/actions/profileActions";

const Settings = () => {
	const authUser = useSelector((state) => state.user.credentials);
	const dispatch = useDispatch();
	let params = useParams();

	useEffect(() => {
		dispatch(getUserProfileData(params));
		// react-hooks/exhaustive-deps
	}, [params, dispatch]);

	const checkParamsUser = () => {
		let results;
		if (authUser.handle) {
			params.userHandle === authUser.handle
				? (results = true)
				: (results = false);
		} else {
			results = false;
		}
		return results;
	};

	const userProfile = (
		<SideProfile authUserMatchesParams={checkParamsUser()} />
	);

	return (
		<MainLayout id="settings-page" sidebar={userProfile}>
			<Hidden smDown>
				<NavBarMainMenu />
			</Hidden>
			{authUser ? <SettingsSection /> : <div>Not Authorized</div>}
			<Hidden mdUp>
				<NavBarMobile />
			</Hidden>
		</MainLayout>
	);
};

export default Settings;
