import PropTypes from "prop-types";
import React, { useState } from "react";
// Components
import ToolTipButton from "../../components/controls/buttons/ToolTipButton";
import CommentBody from "./CommentBody";
import CommentDeleteButton from "./CommentDeleteButton";
import CommentBlockButton from "./CommentBlockButton";
import CommentEdit from "./CommentEdit";
//MUI Component
import Grid from "@material-ui/core/Grid";
//Icons
import EditIcon from "@material-ui/icons/Edit";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	messageArea: { textAlign: "center", marginBottom: 20 },
	submitButton: {
		textAlign: "center",
		display: "block",
		margin: "0 auto",
		width: "100%",
	},
	editForm: {
		display: "block",
	},
	hidden: {
		display: "hidden",
	},
	editButton: { margin: 0, position: "absolute", right: 0, top: "-15px" },
	comment: {
		position: "relative",
	},
	actionButtons: {
		marginTop: 15,
	},
}));

const CommentInnerContent = ({
	comment,
	matchDealOwnerComment,
	matchLoggedInUserDealOwner,
	matchLoggedInUserComment,
}) => {
	const classes = styles();
	const [show, setShow] = useState(false);

	const handleCommentEdit = () => {
		setShow(!show);
	};

	const handleClose = () => {
		setShow(!show);
	};

	return (
		<Grid>
			<div className={classes.comment}>
				<Grid container>
					<Grid item xs={11}>
						{show ? (
							<CommentEdit
								comment={comment}
								onClose={() => {
									handleClose();
								}}
							/>
						) : (
							<CommentBody
								comment={comment}
								matchDealOwnerComment={matchDealOwnerComment}
								matchLoggedInUserDealOwner={
									matchLoggedInUserDealOwner
								}
							/>
						)}
					</Grid>
					<Grid item xs={1}>
						<Grid
							className={classes.actionButtons}
							container
							direction="column"
							justify="space-between"
							alignItems="flex-end"
							spacing={2}>
							{!show && matchLoggedInUserComment && (
								<Grid item>
									<ToolTipButton
										titleClassName={classes.toolTipButton}
										title="Edit Comment"
										onClick={() => {
											handleCommentEdit();
										}}>
										<EditIcon />
									</ToolTipButton>
								</Grid>
							)}
							{!show && matchLoggedInUserComment && (
								<CommentDeleteButton
									commentId={comment.commentId}
									comment={comment}
								/>
							)}
							{matchLoggedInUserDealOwner &&
								!matchDealOwnerComment && (
									<CommentBlockButton comment={comment} />
								)}
						</Grid>
					</Grid>
				</Grid>
			</div>
		</Grid>
	);
};

CommentInnerContent.propTypes = {
	comment: PropTypes.shape({
		commentId: PropTypes.string.isRequired,
	}),
	matchDealOwnerComment: PropTypes.bool,
	matchLoggedInUserComment: PropTypes.bool,
	matchLoggedInUserDealOwner: PropTypes.bool,
};

export default CommentInnerContent;
