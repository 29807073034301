import React from "react";
//Components
import PrivacySection from "../components/sections/PrivacySection";
import AuthProfile from "../components/profile/AuthProfile";
import MainLayout from "../components/layouts/MainLayout";
import NavBarMainMenu from "../components/navigation/NavBarMainMenu";
import NavBarMobile from "../components/navigation/mobile/NavBarMobile";
//MUI
import Hidden from "@material-ui/core/Hidden";
// Redux
import { useSelector } from "react-redux";

const Privacy = () => {
	const user = useSelector((state) => state.user.credentials);

	const authProfile = (
		<AuthProfile
			user={user}
			ctaTo={`/profiles/${user.handle}`}
			ctaText="View My Deals"
		/>
	);
	return (
		<MainLayout sidebar={authProfile}>
			<Hidden smDown>
				<NavBarMainMenu />
			</Hidden>

			<PrivacySection />

			<Hidden mdUp>
				<NavBarMobile />
			</Hidden>
		</MainLayout>
	);
};
export default Privacy;
