import React from "react";
//Components
import ToolTipButton from "../../components/controls/buttons/ToolTipButton";
//MUI Components
import Grid from "@material-ui/core/Grid";
//MUI Icons
import DeleteIcon from "@material-ui/icons/Delete";
// Redux
import { useDispatch } from "react-redux";
import { deleteComment } from "../../redux/actions/commentActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	comment: { marginBottom: 15 },
	commentUserBadge: {
		maxWidth: 125,
		maxHeight: 125,
	},
	commentData: {
		marginLeft: 5,
	},
	ownerComment: {
		backgroundColor: "#a4ecb5",
		color: "#fff",
	},
	ownerLabel: {
		marignBottom: "-40px",
	},
}));

const CommentDeleteButton = ({ comment }) => {
	const classes = styles();
	const dispatch = useDispatch();

	const handleDeleteComment = (commentId, dealId) => {
		const comment = {
			commentId: commentId,
			dealId: dealId,
		};
		dispatch(deleteComment(comment));
	};

	return (
		<Grid item>
			<ToolTipButton
				titleClassName={classes.toolTipButton}
				title="Delete Comment"
				btnClassName="button"
				onClick={() => {
					handleDeleteComment(comment.commentId, comment.dealId);
				}}>
				<DeleteIcon />
			</ToolTipButton>
		</Grid>
	);
};

export default CommentDeleteButton;
