import {
	SET_CONVERSATIONS,
	SET_CONVERSATION,
	DELETE_CONVERSATION,
	DELETE_MESSAGE,
	SET_DIRECT_MESSAGES,
	SET_DIRECT_MESSAGE,
	INBOX_LOADING
} from "../types"


const initialState = {
	conversations: [],
	conversation: {},
	message: {},
	messages: [],
	deleting: {
		active: false,
		messageId: "",
	},
	view: "conversations",
	loading: false,
}

export default function(state = initialState, action) {
	switch (action.type) {
		case SET_CONVERSATIONS:
			return {
				...state,
				conversations: action.payload,
					loading: false,
			};
		case SET_CONVERSATION:
			return {
				...state,
				conversation: action.payload.conversation,
					view: action.payload.view,
					loading: false,
			};
		case DELETE_CONVERSATION:
			return {
				...state,
				conversations: [...state.conversations.filter((conversation) => conversation.conversationId !== action.payload)],
					conversation: {}
			};
		case SET_DIRECT_MESSAGES:
			return {
				...state,
				messages: action.payload,
					loading: false,

			};
		case INBOX_LOADING:
			return {
				...state,
				loading: action.payload,

			};
		case SET_DIRECT_MESSAGE:
			return {
				...state,
				messages: [...state.messages, action.payload],
					message: action.payload,
					loading: false
			};
		case DELETE_MESSAGE:
			console.log(action.payload)
			return {
				...state,
				messages: [...state.messages.filter((message) => message.messageId !== action.payload)],
					//messageCount: state.messageCount - 1,
					deleting: {
						active: false,
						commentId: "",
					}

			};
		default:
			return state

	}
}
