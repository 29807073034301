import React, { Fragment, useState, useEffect } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Helmet } from "react-helmet";
//import placeholder from "../images/deal-placeholder.jpg";
import placeholder from "../images/deal-placeholder-500-300@300.png";
import DealImageWithErrorChecking from "../components/image/DealImageWithErrorChecking";
//MUI
import Slider from "@material-ui/core/Slider";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	search: {
		textAlign: "center",
		display: "block",
		margin: "0 auto",
		maxWidth: 300,
		color: theme.palette.secondary.main,
	},
	imageWrapper: {
		minHeight: 200,
	},
	editor: {
		maxWidth: 250,
	},
	cropContainer: {
		background: "#333",
	},
	cropperSmall: {
		height: 200,
	},
	cropperLarge: {
		height: 200,
	},
	priceFieldPaper: {
		backgroundColor: theme.palette.primary.main,
		width: "100%",
		padding: 10,
	},
	formButton: {
		color: "white",
		marginTop: 0,
		[theme.breakpoints.up("sm")]: {
			marginBottom: 10,
		},
	},
	description: {
		whiteSpace: "pre-line",
	},
}));
const ImageCropper = (
	{ imageFile, dealImageUrl, matches, type, sliderDisabled },
	ref
) => {
	const [zoom, setZoom] = useState(0.01);
	const classes = styles();

	const handleZoomSliderChange = (event, zoom) => {
		setZoom(zoom);
	};

	useEffect(() => {
		require("./sqCropper.css");
	}, []);

	const sliderZoomControl = (
		<Slider
			disabled={false}
			value={zoom}
			min={0.1}
			max={2}
			step={0.001}
			onChange={handleZoomSliderChange}
		/>
	);

	console.log(zoom);

	const cropEditor = imageFile ? (
		<Cropper
			className={matches ? classes.cropperLarge : classes.cropperSmall}
			ref={ref}
			src={imageFile ? imageFile : "./images/no-img.png"}
			style={{ width: "100%" }}
			// Cropper.js options
			zoomTo={zoom} // TEMP: restore the zoom component when package is updated
			aspectRatio={20 / 10}
			disabled={true}
			autoCrop={true}
			autoCropArea={1}
			scalable={true}
			guides={false}
			zoomOnWheel={true}
			modal={false}
			viewMode={2}
		/>
	) : (
		<DealImageWithErrorChecking
			style={{
				maxWidth: "100%",
				borderWidth: 2,
				borderStyle: "solid",
			}}
			src={type === "edit" ? dealImageUrl : placeholder}
		/>
	);

	const headerStyle = (
		<Helmet>
			<style>
				{`.cropper-crop-box, .cropper-view-box {
    				border-radius: 0;}`}
			</style>
		</Helmet>
	);

	return (
		<Fragment>
			{headerStyle}
			{cropEditor}
			{sliderZoomControl}
		</Fragment>
	);
};

export default React.forwardRef(ImageCropper);
