import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";
//MUI
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
//Redux
//Redux
import { useDispatch } from "react-redux";
import { setConversation } from "../../../../redux/actions/inboxActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	root: {
		color: "#fff",
	},
}));

const defaultProps = {
	size: "medium",
};

const InboxLinkButton = ({
	size = defaultProps.size,
	menu,
	variant,
	className,
}) => {
	const dispatch = useDispatch();

	const handleInboxLinkClick = () => {
		dispatch(
			setConversation(null /*clears conversation*/, "conversations")
		);
	};

	const classes = styles();
	return (
		<Button
			underline="none"
			size={clsx(menu ? "small" : size)}
			fullWidth
			className={clsx(classes.root, className)}
			variant={variant}
			color={"secondary"}
			component={Link}
			to={`/inbox/rfdprint`}
			onClick={() => {
				handleInboxLinkClick();
			}}>
			Inbox
		</Button>
	);
};

InboxLinkButton.propTypes = {
	className: PropTypes.string,
	menu: PropTypes.any,
	size: PropTypes.string,
	variant: PropTypes.string,
};

export default InboxLinkButton;
