import React, { Fragment, useState, useEffect } from "react";
//Functions
import { addCommasToNumber } from "../../../util/UtilityFunctions";
// Components
import DealSelect from "../../controls/DealSelect";
import { DealTextField } from "../../controls/DealTextFields";
import DealChip from "../../controls/DealChip";
import DealSpinner from "../../controls/DealSpinner";
//MUI Components
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
//Redux
import { useSelector, useDispatch } from "react-redux";
import {
	setFilters,
	setFilteredDeals,
	findAddressByZicode,
	clearLocation,
} from "../../../redux/actions/dataActions";

const styles = makeStyles((theme) => ({
	...theme.customStyles,

	sideProfile: {
		minWidth: 240,
	},
	actionitems: {
		backgroundColor: "rgba(255, 255, 255, 1)",
		color: `${theme.palette.primary.main} !important`,
		borderRadius: 6,
	},
	search: {
		maxWidth: 200,
	},
	placeholder: {
		color: "blue",
	},
	filterExpandIcon: {
		marginRight: theme.spacing(2),
		float: "right",
		flexGrow: 1,
	},
	locationSpinner: {
		color: theme.palette.secondary.main,
	},

	activatedHideSoldChip: {
		color: theme.palette.secondary.main,
		backgroundColor: theme.palette.accent.main,
	},
	deactivatedHideSoldChip: {
		backgroundColor: theme.palette.secondary.main,
	},
}));

const ActionItemsDealBoard = () => {
	const filters = useSelector((state) => state.data.filters);
	const deals = useSelector((state) => state.data.deals);
	const deal = useSelector((state) => state.data.deal);
	const location = useSelector((state) => state.data.location);
	const loading = useSelector((state) => state.UI.loading);
	const navFilter = useSelector((state) => state.UI.navFilter);
	const [input, setInput] = useState({ notSold: false });

	const classes = styles();
	const dispatch = useDispatch();

	const handleSelectChange = (field) => (e) => {
		let value;
		if (field !== "notSold") {
			value = e.target.value;
		} else {
			value = !input.notSold;
		}
		e.preventDefault();
		setInput({
			...input,
			[field]: value,
		});
		//console.log(input);
	};

	const handleInputChange = (e) => {
		e.preventDefault(e);
		setInput({
			...input,
			[e.currentTarget.name]: e.currentTarget.value,
		});
		if (e.currentTarget.value.length >= 5) {
			dispatch(findAddressByZicode(e.currentTarget.value));
		} else {
			dispatch(clearLocation());
		}
	};
	const handleZipcodeSearch = () => {
		dispatch(findAddressByZicode(input.zipcode));
	};
	const handleEnterKeyPressed = (e) => {
		if (e.keyCode === 13) {
			handleZipcodeSearch();
		}
	};

	useEffect(() => {
		setInput(filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
		dispatch(setFilters(input));
		dispatch(setFilteredDeals(deals, input));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deal, input]);

	const option = {
		rangeValues: [
			{
				min: 1,
				max: 999,
			},
			{
				min: 1000,
				max: 9999,
			},
			{
				min: 10000,
				max: 49999,
			},
			{
				min: 50000,
				max: 99999,
			},
			{
				min: 100000,
				max: 249999,
			},
			{
				min: 250000,
				max: 499999,
			},
			{
				min: 500000,
				max: 749999,
			},
			{
				min: 750000,
				max: 999999,
			},
			{
				min: 1000000,
				max: "none",
			},
		],
	};

	const displayRangeValues = (min, max) => {
		return max !== "none"
			? `$${addCommasToNumber(min)} - $${addCommasToNumber(max)}`
			: `$${addCommasToNumber(min)} & above`;
	};

	return (
		<Fragment>
			<Grid item xs={12}>
				<Grid
					container
					direction="row"
					spacing={2}
					justify="space-between"
					alignItems="center">
					<Grid item xs={12} sm={4}>
						<DealTextField
							className={classes.actionitems}
							name="zipcode"
							type="text"
							label="Filter by Zip Code"
							value={input.zipcode}
							placeholder="Filter by Zip Code"
							variant="outlined"
							onChange={(e) => handleInputChange(e)}
							onKeyDown={(e) => handleEnterKeyPressed(e)}
							color="secondary"></DealTextField>
					</Grid>

					<Grid item xs={12} sm={5}>
						<Typography
							component="div"
							align="left"
							variant="body2">
							{!loading ? (
								location && location.city ? (
									`Displaying results for ${location.city}, ${
										location.state
											? location.state
											: `State Unknown`
									}`
								) : (
									`Enter zipcode to view deals in your area`
								)
							) : (
								<DealSpinner size={30} loading={loading} />
							)}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={3}>
						<DealChip
							id="hideSold"
							name="hideSold"
							className={classes.soldChip}
							activatedClassName={classes.activatedHideSoldChip}
							deactivatedClassName={
								classes.deactivatedHideSoldChip
							}
							label={
								input.notSold
									? "Show Sold Deals"
									: "Hide Sold Deals"
							}
							checked={input.notSold}
							onClick={handleSelectChange("notSold")}
							value={input.notSold}
						/>
					</Grid>
				</Grid>
				{navFilter.show && filters ? (
					<Grid
						item
						container
						direction="row"
						spacing={2}
						justify="space-between"
						alignItems="center">
						<Hidden smDown>
							<hr
								style={{
									color: "white",
									width: "100%",
								}}
							/>
						</Hidden>
						<Grid item xs={12} sm={3}>
							<DealSelect
								rangeString
								id="price"
								label="Price"
								value={JSON.stringify(input.price)}
								handleSelectChange={handleSelectChange(
									"price"
								)}>
								<MenuItem
									value={JSON.stringify({
										min: 0,
										max: 0,
									})}>
									<em>All</em>
								</MenuItem>
								{option.rangeValues.map((range) => (
									<MenuItem
										key={range.min + range.max}
										value={JSON.stringify({
											min: range.min,
											max: range.max,
										})}>
										{displayRangeValues(
											range.min,
											range.max
										)}
									</MenuItem>
								))}
							</DealSelect>
						</Grid>
						<Grid item xs={12} sm={3}>
							<DealSelect
								rangeString
								id="arv"
								label="ARV"
								value={JSON.stringify(input.arv)}
								handleSelectChange={handleSelectChange("arv")}>
								<MenuItem
									value={JSON.stringify({
										min: 0,
										max: 0,
									})}>
									<em>All</em>
								</MenuItem>
								{option.rangeValues.map((range) => (
									<MenuItem
										key={range.min + range.max}
										value={JSON.stringify({
											min: range.min,
											max: range.max,
										})}>
										{displayRangeValues(
											range.min,
											range.max
										)}
									</MenuItem>
								))}
							</DealSelect>
						</Grid>
						<Grid item xs={12} sm={3}>
							<DealSelect
								rangeString
								id="rehab"
								label="Rehab"
								value={JSON.stringify(input.rehab)}
								handleSelectChange={handleSelectChange(
									"rehab"
								)}>
								<MenuItem
									value={JSON.stringify({
										min: 0,
										max: 0,
									})}>
									<em>All</em>
								</MenuItem>
								{option.rangeValues.map((range) => (
									<MenuItem
										key={range.min + range.max}
										value={JSON.stringify({
											min: range.min,
											max: range.max,
										})}>
										{displayRangeValues(
											range.min,
											range.max
										)}
									</MenuItem>
								))}
							</DealSelect>
						</Grid>
						<Grid item xs={12} sm={3}>
							<DealSelect
								rangeString
								id="assignmentFee"
								label="Assignment Fee"
								value={JSON.stringify(input.assignmentFee)}
								handleSelectChange={handleSelectChange(
									"assignmentFee"
								)}>
								<MenuItem
									value={JSON.stringify({
										min: 0,
										max: 0,
									})}>
									<em>All</em>
								</MenuItem>
								{option.rangeValues.map((range) => (
									<MenuItem
										key={range.min + range.max}
										value={JSON.stringify({
											min: range.min,
											max: range.max,
										})}>
										{displayRangeValues(
											range.min,
											range.max
										)}
									</MenuItem>
								))}
							</DealSelect>
						</Grid>
					</Grid>
				) : null}
			</Grid>
		</Fragment>
	);
};

export default ActionItemsDealBoard;
