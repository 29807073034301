import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
//Components
import LogoutButton from "../../controls/buttons/LogoutButton";
import CreateDealButton from "../../controls/buttons/deals/CreateDealButton";
import ToggleFiltersButton from "../../controls/buttons/ToggleFiltersButton";
import LoginSignupActions from "../../profile/LoginSignupActions";
import Notifications from "../../navigation/Notifications";
import InboxLinkButton from "../../controls/buttons/inbox/InboxLinkButton";
import GetVerifiedButton from "../../controls/buttons/user/GetVerifiedButton";
//MUI Components
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
// Redux
import { useSelector } from "react-redux";

//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	toolBar: {
		padding: theme.spacing(1),
	},
	menuButtonRight: {
		marginRight: theme.spacing(2),
		float: "right",
		flexGrow: 1,
	},
	menuButtonCenter: {},
	verifiedText: {
		color: theme.palette.accent.main,
	},
	verifiedButton: {
		backgroundColor: theme.palette.accent.main,
		color: "#fff",
	},
	grow: {
		flexGrow: 1,
	},
	fullwidth: {
		width: "100%",
	},
	dividerColor: {
		color: "white",
	},
	notification: {
		marginTop: "-7px",
		marginBottom: "-10px",
	},
	firstButton: { marginTop: theme.spacing(1) },
}));

const MainToolbar = ({ color, center, filter }) => {
	const authenticated = useSelector((state) => state.user.authenticated);
	const authUser = useSelector((state) => state.user.credentials);
	const classes = styles();

	return (
		<Grid item xs={12}>
			<Grid
				align="center"
				justify="center"
				container
				spacing={2}
				className={classes.toolBar}>
				<Grid
					item
					className={clsx(
						center ? classes.firstButton : null,
						center ? classes.fullwidth : null
					)}>
					<Button
						fullWidth
						variant={"outlined"}
						size="small"
						color={color}
						component={Link}
						to={`/`}>
						Deal Board
					</Button>
				</Grid>

				<CreateDealButton
					variant="outlined"
					menu
					aria-label="create deal"
					form="deal"
					args={{ type: "create" }}
				/>

				{authUser.admin && (
					<Fragment>
						<Hidden mdUp>
							<Grid item xs={12}>
								<Divider />
							</Grid>
						</Hidden>

						<Grid
							item
							className={center ? classes.fullwidth : null}>
							<Button
								fullWidth
								variant={center ? "outlined" : "text"}
								size="small"
								color={color}
								component={Link}
								to={`/system`}>
								Admin Console
							</Button>
						</Grid>
					</Fragment>
				)}
				{authenticated && (
					<Grid item className={center ? classes.fullwidth : null}>
						<Grid container>
							<Grid>
								<InboxLinkButton
									small
									menu
									variant="outlined"
									userHandle={authUser.handle}
								/>
							</Grid>
							<Grid className={classes.notification}>
								<Notifications />
							</Grid>
						</Grid>
					</Grid>
				)}
				<div className={classes.grow} />
				{!authUser.verified && authUser.userId && (
					<Grid item className={center ? classes.fullwidth : null}>
						<GetVerifiedButton menu />
					</Grid>
				)}
				<Hidden mdUp>
					<Grid item xs={12}>
						<Divider classes={{ root: classes.dividerColor }} />
					</Grid>
					<Grid item xs={12}>
						{!authenticated ? <LoginSignupActions /> : null}
					</Grid>
				</Hidden>
				<LogoutButton />

				{filter && (
					<Hidden smDown>
						<ToggleFiltersButton />
					</Hidden>
				)}
			</Grid>
		</Grid>
	);
};

MainToolbar.propTypes = {
	center: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	color: PropTypes.string,
	filter: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default MainToolbar;
