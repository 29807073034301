import {
	SET_USER,
	EDIT_USER,
	EDIT_USER_ROLES,
	LOADING_USER,
	IMAGE_CROPPING,
	STOP_LOADING_USER,
	SET_AUTHENTICATED,
	SET_UNAUTHENTICATED,
	LIKE_DEAL,
	UNLIKE_DEAL,
	SET_NOTIFICATIONS,
	DELETE_READ_NOTIFICATIONS,
} from "../types"

const initialState = {
	imageCropping: false,
	authenticated: false,
	loading: false,
	credentials: {},
	likes: [],
	notifications: [],
}
export default function(state = initialState, action) {
	switch (action.type) {
		case SET_AUTHENTICATED:
			return {
				...state,
				authenticated: true
			}
			case SET_UNAUTHENTICATED:
				return initialState;
			case SET_USER:
				return {
					authenticated: true,
						loading: false,
						...action.payload
				};

			case EDIT_USER:
				return {
					...state,
					imageCropping: false,
						loading: false,
						credentials: {
							...state.credentials,
							...action.payload
						}
				};
			case EDIT_USER_ROLES:
				return {
					...state,
					credentials: {
						...state.credentials,
						roles: {
							...state.credentials.roles,
							...action.payload
						}
					}
				};
			case IMAGE_CROPPING:
				return {
					...state,
					imageCropping: action.payload
				};
			case LOADING_USER:
				return {
					...state,
					loading: true
				};
			case STOP_LOADING_USER:
				return {
					...state,
					loading: false
				};
			case LIKE_DEAL:
				return {
					...state,
					likes: [
						...state.likes,
						{
							userHandle: state.credentials.handle,
							dealId: action.payload.dealId
						}
					]
				};
			case UNLIKE_DEAL:
				return {
					...state,
					likes: state.likes.filter(
						(like) => like.dealId !== action.payload.dealId
					)
				};
			case DELETE_READ_NOTIFICATIONS:
				return {
					...state,
					notifications: state.notifications.filter((notification) => notification.notificationId !== action.payload),

				};
			case SET_NOTIFICATIONS:
				return {
					...state,
					notifications: action.payload

				};
			default:
				return state

	}
}
