import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
//MUI
import Button from "@material-ui/core/Button";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
}));
const FormCropButton = ({ onClick, className }) => {
	const classes = styles();
	return (
		<Button
			fullWidth
			id="btn-crop"
			className={clsx(className, classes.imageButtons)}
			variant="contained"
			color="primary"
			onClick={onClick}>
			Crop
		</Button>
	);
};

FormCropButton.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
};

export default FormCropButton;
