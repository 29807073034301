import PropTypes from "prop-types";
import React, { Fragment } from "react";
import clsx from "clsx";
//MUI
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
//Rudux
import { captializeFirstLetter } from "../../util/UtilityFunctions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	text: { textShadow: "1px 1px 2px #fff" },
	success: {
		color: theme.palette.primary.dark,
	},
	error: {
		color: theme.palette.warning.dark,
	},
	information: {
		color: theme.palette.information.dark,
	},
}));

export const AlertContent = ({ alert, test, testType }) => {
	const { text, type } = alert;
	const classes = styles();

	const getClassName = () => {
		let className;
		switch (type) {
			case "success":
				className = classes.success;
				break;
			case "error":
				className = classes.error;
				break;
			case "information":
				className = classes.information;
				break;
			default:
				className = classes.information;
				break;
		}
		return className;
	};

	const handleAutoCap = () => {
		let alertText;
		if (alert.abortFirstLetterCap) {
			alertText = text;
		} else {
			if (text) {
				alertText = captializeFirstLetter(text);
			} else {
				alertText = text;
			}
		}
		return alertText;
	};

	return (
		<Fragment>
			<DialogContent dividers>
				<Typography
					component="div"
					className={clsx(classes.text, getClassName())}
					variant="body1">
					{!test
						? handleAutoCap()
						: "Nulla porta dolor. Pellentesque auctor neque nec urna."}
				</Typography>
			</DialogContent>
		</Fragment>
	);
};

AlertContent.propTypes = {
	alert: PropTypes.shape({
		abortFirstLetterCap: PropTypes.string,
		text: PropTypes.string,
		type: PropTypes.string,
	}),
	test: PropTypes.any,
	testType: PropTypes.any,
};

export default AlertContent;
