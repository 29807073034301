import {
	SET_ALERT,
	SET_ALERT_CONFIRMATION,
	CLEAR_ALERT,
} from "../types"

const initialState = {
	open: false,
	loading: false,
}

export default function(state = initialState, action) {
	switch (action.type) {
		case SET_ALERT:
			return {
				...state,
				...action.payload,
					open: true,
					loading: false
			};
		case SET_ALERT_CONFIRMATION:
			return {
				...state,
				...action.payload,
					open: true,
					loading: false
			};
		case CLEAR_ALERT:
			return {
				...state,
				open: false,
					title: "",
					type: "",
					text: "",
					required: false,
					confirmationForm: "",
					abortFirstLetterCap: false,
					inputField: false,
					inputPlaceholder: "",
					confirmed: false,
					data: "",
					redirectTo: ""
			};

		default:
			return state;
	}

}
