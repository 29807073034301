import {
	SET_LOCATION,
	SET_ADDRESS,
	POST_DEAL,
	SET_DEALS,
	SET_DEAL,
	EDIT_DEAL,
	IMAGE_PURGE,
	DELETE_DEAL,
	LOADING_DATA,
	STOP_LOADING_DATA,
	LIKE_DEAL,
	UNLIKE_DEAL,
	SET_FILTERED_DEALS,
	SET_FILTERED_HANDLE

} from "../types"

const initialState = {
	deals: [],
	deal: {},
	comment: {
		edit: {},
		delete: {}
	},
	location: {
		city: "",
		state: "",
		street: ""
	},
	loading: false,
	response: {},
	filters: {},
	filteredDeals: [],
	filterHandle: null,
	retrieved: false
}
let index;

export default function(state = initialState, action) {
	switch (action.type) {

		case SET_FILTERED_DEALS:
			return {
				...state,
				filteredDeals: action.payload
			};
		case SET_FILTERED_HANDLE:
			return {
				...state,
				filterHandle: action.payload
			};
		case LOADING_DATA:
			return {
				...state,
				loading: true
			};
		case STOP_LOADING_DATA:
			return {
				...state,
				loading: false
			};
		case SET_LOCATION:
			return {
				...state,
				location: action.payload,
					loading: false
			};
		case SET_DEALS:
			return {
				...state,
				deals: action.payload,
					loading: false,
					retrieved: true
			};

		case SET_DEAL:
			return {
				...state,
				deal: action.payload
			};
		case DELETE_DEAL:
			return {
				...state,
				deals: state.deals.filter((deal) => deal.dealId !== action.payload.dealId),
					deal: {},
					loading: false
			};
		case EDIT_DEAL:
			index = state.deals.findIndex((deal) => deal.dealId === action.payload.dealId);
			return {
				...state,
				deals: [
						...state.deals.slice(0, index),
						{
							...state.deals[index],
							...action.payload
						},
						...state.deals.slice(index + 1)
					],
					deal: {
						...state.deal,
						...action.payload
					},
					loading: false
			};
		case IMAGE_PURGE:
			index = state.deals.findIndex((deal) => deal.dealId === action.payload.dealId);
			return {
				...state,
				deals: [
						...state.deals.slice(0, index),
						{
							...state.deals[index],
							imageUrl: action.payload.imageUrl
						},
						...state.deals.slice(index + 1)
					],
					deal: {
						...state.deal,
						imageUrl: action.payload.imageUrl
					},
			};
		case LIKE_DEAL:
		case UNLIKE_DEAL:
			index = state.deals.findIndex(
				(deal) => deal.dealId === action.payload.dealId
			);
			return {
				...state,
				deals: [
						...state.deals.slice(0, index),
						{
							...state[index],
							...action.payload
						},
						...state.deals.slice(index + 1)
					],
					deal: {
						...state.deal,
						likeCount: action.payload.likeCount
					}

			};

		case SET_ADDRESS:
			return {
				...state,
				deal: {
					...state.deal,
					address: action.payload,
					loading: false
				}
			};

		case POST_DEAL:
			return {
				...state,
				deals: [action.payload, ...state.deals],
					deal: action.payload,
					loading: false
			};

		default:
			return state
	}
}
