import PropTypes from "prop-types";
import React from "react";
//Components
import FormUploadFileButton from "../../controls/buttons/forms/FormUploadFileButton";
import FormCropButton from "../../controls/buttons/forms/FormCropButton";
import FormUndoCropButton from "../../controls/buttons/forms/FormUndoCropButton";
import FormRestoreImageButton from "../../controls/buttons/forms/FormRestoreImageButton";

//MUI
import Grid from "@material-ui/core/Grid";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	actionButtons: {
		color: "#fff",
	},
}));
const FormImageControls = ({
	type,
	buttonWidth,
	undoImageCrop,
	handleImageAdd,
	handleCrop,
	handleUndoCrop,
	handleRestoreImage,
	haveImage,
}) => {
	const classes = styles();
	return (
		<Grid item xs={12}>
			<Grid container justify="center" alignItems="center" spacing={3}>
				<FormUploadFileButton
					className={
						(!haveImage
							? classes.imageUploadBtn
							: classes.imageUploadBtnHaveImage,
						classes.actionButtons)
					}
					onClick={handleImageAdd}
					fullWidth={!haveImage ? true : false}
					title={!haveImage ? "Upload New Image" : "Change Image"}
				/>

				{!undoImageCrop && haveImage ? (
					<Grid item xs={12} sm={buttonWidth}>
						<FormCropButton
							className={classes.actionButtons}
							onClick={(e) => {
								handleCrop(e);
							}}
						/>
					</Grid>
				) : (
					haveImage && (
						<Grid item xs={12} sm={buttonWidth}>
							<FormUndoCropButton
								className={classes.actionButtons}
								onClick={handleUndoCrop}
							/>
						</Grid>
					)
				)}
				{type === "edit" && haveImage && (
					<FormRestoreImageButton
						className={classes.actionButtons}
						buttonWidth={buttonWidth}
						onClick={handleRestoreImage}
					/>
				)}
			</Grid>
		</Grid>
	);
};

FormImageControls.propTypes = {
	buttonWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
	handleCrop: PropTypes.func,
	handleImageAdd: PropTypes.func,
	handleRestoreImage: PropTypes.func,
	handleUndoCrop: PropTypes.func,
	haveImage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	type: PropTypes.string,
	undoImageCrop: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default FormImageControls;
