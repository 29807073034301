import PropTypes from "prop-types";
import React from "react";
//MUI Components
import Grid from "@material-ui/core/Grid";
import FormOpenButton from "../forms/FormOpenButton";
// Redux
import { useSelector } from "react-redux";

const defaultProps = {
	btnText: "Contact Deal Maker",
};

const ContactDealMakerButton = ({
	className,
	user,
	icon,
	btnText = defaultProps.btnText,
}) => {
	const authenticated = useSelector((state) => state.user.authenticated);

	const contactButton = (
		<Grid item xs={12}>
			<FormOpenButton
				className={className}
				form="sendMessage"
				args={user}
				btnText={btnText}
				btnVariant="contained"
				btnColor="primary"
			/>
		</Grid>
	);

	return <>{authenticated && contactButton}</>;
};

ContactDealMakerButton.propTypes = {
	btnText: PropTypes.string,
	className: PropTypes.string,
	icon: PropTypes.element,
	size: PropTypes.string,
	user: PropTypes.object.isRequired,
};

export default ContactDealMakerButton;
