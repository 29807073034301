import {
	SET_ALERT,
	CLEAR_ALERT,
} from "../types"


export const clearAlert = () => dispatch => {
	dispatch({
		type: CLEAR_ALERT
	});

}

export const setAlertWithConfirmation = (form, type, title, message, inputField, inputPlaceholder, data, size, btnText, confirmBtnText) => dispatch => {
	let payload = {
		title: title,
		type: type,
		text: message,
		required: true,
		confirmationForm: form,
		data: data,
		abortFirstLetterCap: true,
		inputField: inputField,
		inputPlaceholder: inputPlaceholder,
		confirmed: false,
		size: size,
		btnText: btnText,
		confirmBtnText: confirmBtnText
	}

	dispatch({
		type: SET_ALERT,
		payload: payload
	})
}

export const setAlert = (form, type, title, message, inputField, inputPlaceholder, data) => dispatch => {
	let payload = {
		title: title,
		type: type,
		text: message,
		required: false,
		abortFirstLetterCap: true,
	}

	dispatch({
		type: SET_ALERT,
		payload: payload
	})
}

export const setAlertConfirmed = (inputFieldValue) => dispatch => {

	dispatch({
		type: SET_ALERT,
		payload: {
			confirmed: true,
			inputField: false, // turn off input field on confirmed
			inputFieldValue: inputFieldValue ? inputFieldValue : null
		}
	})
}

export const clearAlerts = () => dispatch => {
	dispatch({
		type: CLEAR_ALERT,
	})
}
