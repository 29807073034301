import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
//Components
import PriceFeatureGrid from "../controls/PriceFeatureGrid";
//MUI
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Divider from "@material-ui/core/Divider";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { setCheckoutVAPackage } from "../../redux/actions/checkoutActions";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	spinner: {
		marginTop: 50,
	},

	productColor: { color: theme.palette.primary.dark },
	cardHeader: {
		color: "#fff",
		textAlign: "center",
		backgroundColor: theme.palette.primary.main,
		paddingBottom: 5,
		marginBottom: 0,
		paddingTop: 5,
		marginTop: 0,
	},
	cardSubheaderText: {
		color: "#fff",
	},
	description: {
		fontSize: 10,
	},
	ctaButton: { width: "100%" },
	cardContent: {
		padding: 8,
	},
	media: {
		width: "100%",
	},
}));
const VirtualAssistantProductCompactCard = ({
	assistancePackage,
	hideCTA,
	backgroundClassName,
	textClassName,
	showMaxNumberOfFeature,
}) => {
	const dispatch = useDispatch();
	const classes = styles();

	const {
		name,
		price,
		descriptionPrimary,
		descriptionSecondary,
		CTAText,
	} = assistancePackage.metadata;
	const { nickname, unit_amount } = assistancePackage;

	const handleClick = (selectedPackage) => {
		dispatch(setCheckoutVAPackage(selectedPackage));
	};
	return (
		<Card
			raised
			elevation={4}
			className={clsx(classes.root, classes.productColor)}>
			<CardActionArea>
				<CardHeader
					classes={{
						root: clsx(classes.cardHeader, backgroundClassName),
						subheader: classes.cardSubheaderText,
					}}
					title={assistancePackage.product.metadata.displayName}
					subheader={`$${unit_amount / 100}`}
				/>
				<CardMedia
					component="img"
					className={classes.media}
					image={assistancePackage.product.images[0]}
					title="Paella dish"
				/>

				<CardContent
					classes={{
						root: clsx(classes.cardContent, textClassName),
					}}>
					<Typography className={classes.description}>
						<Typography
							align="center"
							gutterBottom
							variant="subtitle"
							component="h2">
							{
								assistancePackage.product.metadata
									.displayDescriptionPrimary
							}
						</Typography>
						<Typography
							align="center"
							gutterBottom
							variant="subtitle"
							component="h2">
							{
								assistancePackage.product.metadata
									.displayDescriptionSecondary
							}
						</Typography>
					</Typography>
					<Typography
						variant="body2"
						color="textSecondary"
						component="p"></Typography>
				</CardContent>

				<Divider />
				{!hideCTA && (
					<CardActions>
						<Button
							className={clsx(classes.ctaButton, textClassName)}
							variant="outlined"
							size="small"
							onClick={() => handleClick(assistancePackage)}>
							{assistancePackage.product.metadata.CTAText}
						</Button>
					</CardActions>
				)}
			</CardActionArea>
		</Card>
	);
};

export default VirtualAssistantProductCompactCard;
