import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";

//Components
import SpinnerButton from "../components/controls/SpinnerButton";
import { DealTextField } from "../components/controls/DealTextFields";
import CenterFormsLayout from "../components/layouts/CenterFormsLayout";
import DealSelect from "../components/controls/DealSelect";
import GeneralFormGroup from "../components/controls/selectors/GeneralFormGroup";
import GeneralFormControlTextBox from "../components/controls/selectors/GeneralFormControlTextBox";
//MUI Components
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { signupUser } from "../redux/actions/userActions";
//Styles
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	signupForm: { marginTop: 10 },
	textField: { marginTop: 0 },
	link: {
		color: "#fff",
		fontWeight: 700,
		textDecoration: "underline",
		textShadow: "2px 2px 4px #000000",
	},
}));

const Signup = (props) => {
	const loading = useSelector((state) => state.UI.loading);
	const errors = useSelector((state) => state.UI.responses.errors);
	const dispatch = useDispatch();
	const classes = styles(props);

	const [input, setInput] = useState({
		type: "member",
		email: "",
		password: "",
		firstName: "",
		lastName: "",
		terms: false,
		privacy: false,
	});

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(signupUser(input, props.history));
	};

	const handleTextFormat = (name, value) => {
		let handleLetter = value.toLowerCase();
		if (/\s/.test(handleLetter)) {
			console.log("placeholder");
		} else {
			setInput({
				...input,
				[name]: handleLetter,
			});
		}
	};

	const handleCheckBoxChange = (event) => {
		setInput({ ...input, [event.target.name]: event.target.checked });
	};

	const formGroupCheckBoxesLabel = (
		<Typography variant="caption" display="inline">
			Check the boxes below if you accept the{" "}
			<Link to="/terms" target="_blank" className={classes.link}>
				Terms and Conditions
			</Link>{" "}
			and{" "}
			<Link to="/terms" target="_blank" className={classes.link}>
				Privacy Policy
			</Link>
		</Typography>
	);
	const handleSelectChange = (name) => (e) => {
		e.preventDefault();
		setInput({
			...input,
			[name]: e.target.value,
		});
	};

	const handleInputChange = (e) => {
		if (e.currentTarget.name === "handle") {
			handleTextFormat(e.currentTarget.name, e.currentTarget.value);
		} else {
			setInput({
				...input,
				[e.currentTarget.name]: e.currentTarget.value,
			});
		}
	};

	return (
		<CenterFormsLayout
			title="Signup"
			linkTo="/login"
			linkInfoText="have an account? login"
			linkText="here"
			pngLogo>
			<form noValidate>
				<Grid container className={classes.signupForm} spacing={2}>
					<DealTextField
						halfWidth
						id="firstName"
						name="firstName"
						type="text"
						label="First Name"
						className={classes.textField}
						helperText={errors.firstName}
						error={errors.firstName ? true : false}
						value={input.firstName}
						onChange={handleInputChange}
					/>
					<DealTextField
						halfWidth
						id="lastName"
						name="lastName"
						type="text"
						label="Last Name"
						className={classes.textField}
						helperText={errors.lastName}
						error={errors.lastName ? true : false}
						value={input.lastName}
						onChange={handleInputChange}
					/>

					{/*<DealSelect
						id="type"
						label="User Type"
						small
						value={input.type}
						className={classes.textField}
						helperText={errors.type}
						error={errors.type ? true : false}
						handleSelectChange={handleSelectChange("type")}>
						<MenuItem value={"wholesaler"}>Wholesaler</MenuItem>
						<MenuItem value={"investor"}>Investor</MenuItem>
					</DealSelect>*/}

					<DealTextField
						id="handle"
						name="handle"
						type="text"
						label="Username"
						className={classes.textField}
						helperText={errors.handle}
						error={errors.handle ? true : false}
						value={input.handle}
						onChange={handleInputChange}
					/>
					<DealTextField
						id="email"
						name="email"
						type="email"
						label="Email"
						className={classes.textField}
						helperText={errors.email}
						error={errors.email ? true : false}
						value={input.email}
						onChange={handleInputChange}
						fullWidth
					/>
					<DealTextField
						id="password"
						name="password"
						type="password"
						label="Password"
						className={classes.textField}
						helperText={errors.password}
						error={errors.password ? true : false}
						value={input.password}
						onChange={handleInputChange}
						fullWidth
					/>
					<DealTextField
						id="confirmPassword"
						name="confirmPassword"
						type="password"
						label="Confirm Password"
						className={classes.textField}
						helperText={errors.confirmPassword}
						error={errors.confirmPassword ? true : false}
						value={input.confirmPassword}
						onChange={handleInputChange}
						fullWidth
					/>

					<GeneralFormGroup
						error={errors.termsPrivacyError}
						groupLabel={formGroupCheckBoxesLabel}>
						<GeneralFormControlTextBox
							name="terms"
							checked={input.terms}
							label={
								<Typography variant="caption">
									Terms and Conditions
								</Typography>
							}
							onChange={handleCheckBoxChange}
						/>
						<GeneralFormControlTextBox
							name="privacy"
							checked={input.privacy}
							label={
								<Typography variant="caption">
									Privacy Policy
								</Typography>
							}
							onChange={handleCheckBoxChange}
						/>
					</GeneralFormGroup>

					{errors.general && (
						<Typography
							variant="body2"
							className={classes.generalError}>
							{errors.general}
						</Typography>
					)}
					<SpinnerButton
						className={classes.button}
						type="submit"
						color="primary"
						variant="contained"
						disabled={loading}
						loading={loading}
						onClick={handleSubmit}>
						Signup
					</SpinnerButton>
				</Grid>
			</form>
		</CenterFormsLayout>
	);
};

Signup.propTypes = {
	history: PropTypes.any,
};

export default Signup;
