import React, { Fragment } from "react";
//MUI
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	fabRight: {
		opacity: 0.6,
		position: "fixed",
		float: "right",
		bottom: 70,
		right: 10,
	},
}));

const BackToTopFab = () => {
	const classes = styles();

	const handleClick = () => {
		window.scrollTo(0, 0);
	};

	return (
		<Fragment>
			<Fab
				size="medium"
				className={classes.fabRight}
				color="primary"
				aria-label="add"
				onClick={() => handleClick()}>
				<KeyboardArrowUpIcon fontSize="large" color="secondary" />
			</Fab>
		</Fragment>
	);
};

export default BackToTopFab;
