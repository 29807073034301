import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	table: {
		minWidth: 300,
		maxWith: 300,
	},
	divider: {
		height: 2,

		backgroundColor: theme.palette.primary.main,
	},
	header: {
		color: theme.palette.primary.main,
		fontSize: ".9rem",
		fontWeight: 700,
	},
	packageName: {
		fontSize: ".7rem",
		fontWeight: 700,
	},
	packageDescription: {
		fontSize: ".7rem",
		fontWeight: 700,
	},
	packageDescriptionSecondary: {
		fontSize: ".6rem",
		fontWeight: 700,
	},
}));

const CheckoutTable = ({ assistancePackage, price }) => {
	const classes = styles();

	function createData(label, product, details, bold = false) {
		return { label, product, details, bold };
	}
	const packageName = () => (
		<>
			<Typography
				variant="caption"
				classes={{ root: classes.packageName }}>
				{assistancePackage.product.name}
			</Typography>
		</>
	);

	const packageDescription = () => (
		<>
			<Typography
				variant="body2"
				classes={{ root: classes.packageDescription }}>
				{assistancePackage.product.metadata.displayDescriptionPrimary}{" "}
			</Typography>
			<Typography
				variant="body2"
				classes={{ root: classes.packageDescriptionSecondary }}>
				{assistancePackage.product.metadata.displayDescriptionSecondary}
			</Typography>
		</>
	);

	const packageTotal = () => (
		<>
			<Typography
				variant="caption"
				classes={{ root: classes.packageName }}>
				Total
			</Typography>
		</>
	);

	const packagePrice = () => (
		<>
			<Typography
				variant="body2"
				classes={{ root: classes.packageDescription }}>
				{price}
			</Typography>
		</>
	);

	const rows = [
		createData(packageName(), packageDescription(), null, true),
		createData(packageTotal(), packagePrice()),
	];

	return (
		<TableContainer component={Paper}>
			<Typography className={classes.header}>Checkout</Typography>
			<Divider classes={{ root: classes.divider }} />
			<Table
				className={classes.table}
				size="small"
				aria-label="a dense table">
				<TableBody>
					{rows.map((row) => (
						<TableRow key={row.label}>
							<TableCell component="th" scope="row">
								{row.label}
							</TableCell>
							<TableCell
								align="center"
								className={clsx(row.bold && classes.boldText)}>
								{row.details}
								{row.product}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default CheckoutTable;
