import PropTypes from "prop-types";
import React from "react";
import ToolTip from "@material-ui/core/Tooltip";

//MUI Icons
import IconButton from "@material-ui/core/IconButton";

const ToolTipButton = ({
	children,
	title,
	btnClassName,
	titleClassName,
	onClick,
	edge,
}) => (
	<ToolTip className={titleClassName} title={title} placement="top">
		<IconButton edge={edge} onClick={onClick} className={btnClassName}>
			{children}
		</IconButton>
	</ToolTip>
);

ToolTipButton.propTypes = {
	btnClassName: PropTypes.string,
	children: PropTypes.element,
	edge: PropTypes.any,
	onClick: PropTypes.func,
	title: PropTypes.string,
	titleClassName: PropTypes.string,
};

export default ToolTipButton;
