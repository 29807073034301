import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import Truncate from "react-truncate";
//Components
import ToolTipButton from "../../components/controls/buttons/ToolTipButton";
//MUI
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles((theme) => ({
	...theme.customStyles,
	text: {
		fontsize: 1,
	},
	moreLink: {
		color: theme.palette.accent.main,
		cursor: "pointer",
	},
}));

const DealDescription = ({ description, more, lines, title }) => {
	const [expanded] = useState(false);
	const [open, setOpen] = useState(false);
	const classes = styles();

	const handleReadMoreClick = (event) => {
		event.preventDefault();
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const preserveTextReturns = (text, truncatedDescription) => {
		let results;
		if (text) {
			results = text.split("\n").map((item, key) => {
				let removedTagsText = item.replace(/(<([^>]+)>)/gi, "");
				return (
					<Typography component="span" key={key}>
						<>
							{removedTagsText}
							{truncatedDescription}
							<br />
						</>
					</Typography>
				);
			});
		}
		return results;
	};

	return (
		<Fragment>
			<Dialog
				scroll="body"
				open={open}
				onClose={() => handleClose()}
				fullWidth
				maxWidth="sm">
				<ToolTipButton
					className={classes.closeButton}
					title="Close"
					onClick={() => handleClose()}
					titleClassName={classes.closeButton}>
					<CloseIcon />
				</ToolTipButton>
				<DialogTitle>{title} (Description)</DialogTitle>
				<DialogContent classes={{ root: classes.text }}>
					<DialogContentText>
						{preserveTextReturns(description)}
					</DialogContentText>
				</DialogContent>
			</Dialog>
			<Truncate
				trimWhitespace
				lines={!expanded && lines}
				ellipsis={
					<span className={classes.moreLink}>
						...{" "}
						<Typography
							component="span"
							display="inline"
							variant="body2"
							onClick={(e) => handleReadMoreClick(e)}>
							{more}
						</Typography>
					</span>
				}>
				{preserveTextReturns(description, true)}
			</Truncate>
		</Fragment>
	);
};

DealDescription.defaultProps = {
	lines: 8,
	more: "Read More",
	less: "Show less",
};

DealDescription.propTypes = {
	description: PropTypes.string.isRequired,
	lines: PropTypes.number,
	less: PropTypes.string,
	more: PropTypes.string,
	title: PropTypes.string.isRequired,
};

export default DealDescription;
