import React, { useEffect } from "react";
//Components

import SystemCardDetail from "../../system/cards/SystemCardDetail";
import SystemApplicationButton from "../../../components/controls/buttons/system/form/open/SystemApplicationButton";
import SubscriptionDetailCard from "../../system/cards/SubscriptionDetailCard";
import SubscriptionLiveDataUpdateButton from "../../controls/buttons/system/SubscriptionLiveDataUpdateButton";
import SystemAuthorizationForm from "./SystemAuthorizationForm";
import UserBadgeCard from "../../badges/UserBadgeCard";
//MUI Components
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptionSystem } from "../../../redux/actions/subcriptionActions";

//Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
const styles = makeStyles((theme) => ({
	...theme.customStyles,
	card: {
		position: "relative",
		display: "flex",
	},
	userId: {
		textAlign: "center",
	},
	adminUser: {
		backgroundColor: theme.palette.accent.main,
	},
	adminLabel: {
		color: theme.palette.accent.main,
	},
	confirmedChipColorDeactivated: {
		backgroundColor: "lightGrey",
		color: "grey",
	},
	confirmedChipColorActivated: {
		backgroundColor: theme.palette.accent.main,
		color: "#fff",
	},
	sytemAssignment: {
		color: "#fff",
		marginTop: 8,
	},
}));

const SystemSubscriptionInfoForm = () => {
	const user = useSelector((state) => state.system.user);
	const loading = useSelector((state) => state.system.loading);

	const dispatch = useDispatch();
	const classes = styles();

	useEffect(() => {
		let subscriptionUserId = user.userId;
		dispatch(getSubscriptionSystem(subscriptionUserId));
	}, [user.userId, dispatch]);

	return (
		!loading && (
			<Grid item>
				<Grid container>
					<Grid
						container
						spacing={2}
						alignItems="center"
						justify="center">
						<Grid item sm={6}>
							<Typography>
								Name: {`${user.lastName}, ${user.firstName}`}
							</Typography>
						</Grid>
						<Grid item sm={6}>
							<Typography>Username: {user.handle}</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item sm={6}>
							<UserBadgeCard user={user} />
							<SystemCardDetail user={user} />
							<hr style={{ color: "black" }} />
							<SystemAuthorizationForm
								className={classes.sytemAssignment}
								size="medium"
								formStyles
							/>
						</Grid>
						<Grid item container sm={6}>
							<SubscriptionDetailCard />
							<Grid
								container
								alignItems="center"
								justify="flex-end"
								spacing={2}>
								<Grid item></Grid>
								<SystemApplicationButton user={user} />
								<SubscriptionLiveDataUpdateButton user={user} />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		)
	);
};

export default SystemSubscriptionInfoForm;
