import React, { Fragment } from "react";
//Components
import SettingsCard from "../../../settings/SettingsCard";
//Drwawers
import MainDrawer from "./MainDrawer";
import FullWidthDrawer from "./FullWidthDrawer";
//ToolBars
import MobileToolbar from "../toolbars/MobileToolbar";
import DealMobileToolBar from "../toolbars/DealMobileToolbar";
import PublicToolbar from "../toolbars/PublicToolbar";
//Redux
import { useSelector } from "react-redux";

const DrawerLoader = () => {
	const drawer = useSelector((state) => state.UI.drawer);
	return (
		<Fragment>
			{drawer.name === "dealMenu" && (
				<MainDrawer>
					<DealMobileToolBar
						center
						color="secondary"
						variant="contained"
					/>
				</MainDrawer>
			)}
			{drawer.name === "mainMenu" && (
				<MainDrawer>
					<MobileToolbar
						center
						color="secondary"
						variant="contained"
					/>
				</MainDrawer>
			)}
			{drawer.name === "publicMenu" && (
				<FullWidthDrawer>
					<PublicToolbar
						center
						color="secondary"
						variant="contained"
					/>
				</FullWidthDrawer>
			)}
			{drawer.name === "profileMenu" && (
				<FullWidthDrawer>
					<SettingsCard />
				</FullWidthDrawer>
			)}
		</Fragment>
	);
};

export default DrawerLoader;
